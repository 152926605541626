//LIBRARIES
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useT } from "@transifex/react";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import InputWithActions from "../../inputWithActions/InputWithActions";

import { Label, LabelWrapper, Wrapper } from "./StyledComponents";

export interface ExerciseSheetTitleProps {
    setIsTestBeingSynced?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExerciseSheetTitle: React.FC<ExerciseSheetTitleProps> = (props) => {
    const { userInfo, userId } = useGeneralContext();
    const { testName, setTestName, testContent, setIsTitleSynced, saveTest } = useTestCreationContext();

    const t = useT();
    const t_exerciseTitle = t("Exercise sheet title:", { _tags: "CreateTest,ExerciseSheetTitle" });

    const [currentTitle, setCurrentTitle] = useState(testName);
    const [isEditing, setIsEditing] = useState(false);
    const [isNameChanged, setIsNameChanged] = useState(false);

    const saveTestData = useCallback(() => {
        props.setIsTestBeingSynced?.(true);
        saveTest(testContent)
            .then(() => {
                setIsTitleSynced(true);
            })
            .catch(() => {
                setIsTitleSynced(false);
            })
            .finally(() => {
                props.setIsTestBeingSynced?.(false);
            });
    }, [props, saveTest, setIsTitleSynced, testContent]);

    useEffect(() => {
        if (!(testContent?.length > 0)) return;

        if (isNameChanged) {
            if (userId && !userInfo.isAnonymousUser) saveTestData();
            setIsNameChanged(false);
        } else {
            setIsTitleSynced(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testName]);

    function saveChanges() {
        if (testName) {
            if (currentTitle !== "" && isNameChanged) {
                setTestName(currentTitle);
            } else {
                setCurrentTitle(testName);
                setIsTitleSynced(true);
            }
        }
        setIsEditing(false);
    }

    function discardChanges() {
        setIsEditing(false);
        setIsNameChanged(false);
        setCurrentTitle(testName);
        setIsTitleSynced(true);
    }

    function updateTitle(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        setIsNameChanged(value !== testName);
        setCurrentTitle(value);
        if (testContent?.length > 0) setIsTitleSynced(false);
    }

    return (
        <Wrapper>
            <LabelWrapper>
                <Label>{t_exerciseTitle}</Label>
            </LabelWrapper>
            <InputWithActions
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                currentText={currentTitle}
                onChange={updateTitle}
                onSave={saveChanges}
                onDiscard={discardChanges}
                isSaveButtonDisabled={currentTitle === ""}
            />
        </Wrapper>
    );
};

export default ExerciseSheetTitle;
