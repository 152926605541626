// LIBRARIES
import React from "react";

// COMPONENTS
import Loading from "./Loading";
import { useGeneralContext } from "../../context/AppGeneralContext";

const LoadingView: React.FC = (props) => {
    const { isDataBeingLoaded } = useGeneralContext();

    return isDataBeingLoaded ? <Loading {...props} /> : null;
};

export default LoadingView;
