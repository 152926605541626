import React, { useState } from "react";
import { RTContextProps } from "p6m-contexts";
import { useGeneralContext } from "./AppGeneralContext";
import { useTestCreationContext } from "./TestCreationContext";
import { useBookContext } from "./BookContext";
import { getRequest, postRequest, putRequest, deleteRequest } from "../helpers/networkHelper";
import { v4 as uuidv4 } from "uuid";
import { logEventWithProps } from "../logging/Logger";
import { useHistory } from "react-router-dom";
import { useT } from "@transifex/react";
import { AeaClass, AeaExerciseModel, AeaGroupedExercisesContent, DeleteExercisesOrGroupProperties } from "p6m-p6u";
import { processExercisesListResponse } from "../helpers/UserExercises";

const RTContext = React.createContext<RTContextProps | undefined>(undefined);

function useRTContext() {
    const context = React.useContext(RTContext);

    if (!context) {
        throw new Error("RTContext must be used inside the provider");
    }
    const { userId, setCreatingMode, makePxpRequest, setIsDataBeingLoaded } = useGeneralContext();

    const {
        testName,
        vocabularyDrawerContent,
        selectedBookData,
        setSelectedBookData,
        setTestName,
        setPreviouslyUsedCardsIds,
        selectedUserSubject,
        setSelectedUserSubject,
        userUsedOwnSubjectsInfo,
        setShouldSubjectSelectionBeDisabled,
        canLogEvent,
        resetAppStatus,
        reloadUserSubjects,
        openWarningModal,
    } = useTestCreationContext();

    const { userUsedBooksInfo, setSelectBandFilter } = useBookContext();

    const history = useHistory();
    const t = useT();

    const t_exampleClass = t("My first class", { _tags: "CreateTest,CreateClass" });
    const t_errorInsertingClass = t("There was an error while creating the new Class.", {
        _tags: "CreateTest,CreateClass",
    });
    const t_errorDeletingClass = t("There was an error while deleting the Class.", {
        _tags: "CreateTest,CreateClass",
    });
    const t_cantUpdateClass = t("Error while updating the class.", { _tags: "CreateTest,CreateClass" });
    const t_deleteExerciseError = t("There was an error while deleting the Exercise.", {
        _tags: "CreateTest,CreateClass",
    });
    const t_problemLoadingExercises = t("There was an error while loading your Exercises.", {
        _tags: "CreateTest,CreateClass",
    });

    const {
        setAeaClasses,
        currentExerciseGroupId,
        setCurrentExerciseGroupId,
        userExercisesGroupedData,
        setUserExercisesGroupedData,
        isFirstRtHomeLoad,
        setIsFirstRtHomeLoad,
    } = context;

    async function loadClassesAndExercisesForUser(isCalledFromHome?: boolean) {
        if (userId) {
            const classesResponse = await reloadAeaClasses();
            const exercisesResponse = await reloadUserExercises();
            await reloadUserSubjects();

            if (isCalledFromHome && isFirstRtHomeLoad) {
                if (classesResponse.length === 0) {
                    startNewRecurringExerciseForGroup("");
                } else if (Object.keys(exercisesResponse || {}).length === 0) {
                    startNewRecurringExerciseForGroup("");
                }
                setIsFirstRtHomeLoad(false);
            }
        }
    }

    async function reloadUserExercises() {
        // const userExercises = [];
        setIsDataBeingLoaded(true);
        try {
            const exercisesListResponse = await makePxpRequest("GET", "cardsTestList");
            let processedResponse: AeaGroupedExercisesContent = {};
            if (exercisesListResponse.data.replyContent.objects) {
                processedResponse = processExercisesListResponse(
                    exercisesListResponse.data.replyContent.objects,
                    userId
                );
                setUserExercisesGroupedData(processedResponse);
            }
            setIsDataBeingLoaded(false);
            return Promise.resolve(processedResponse);
        } catch (e) {
            openWarningModal(t_problemLoadingExercises);
            console.log(e);
        }
        setIsDataBeingLoaded(false);
    }

    async function reloadAeaClasses() {
        let userClasses: AeaClass[] = [];
        try {
            const classListResponse = await getRequest("aea/classes/");
            const classListResponseArray: AeaClass[] = Object.values(classListResponse.data);
            userClasses = classListResponseArray.sort((c1: AeaClass, c2: AeaClass) => {
                return (c2.dateInserted || 0) - (c1.dateInserted || 0);
            });
        } catch (e) {
            console.log(e);
        }
        setAeaClasses(userClasses);
        return userClasses;
    }

    async function createAeaClass(name: string) {
        try {
            const createClassResponse = await postRequest("aea/classes/", { name });
            if (canLogEvent()) {
                logEventWithProps("Created Class", {
                    groupId: createClassResponse.data.token,
                });
            }
        } catch (e) {
            openWarningModal(t_errorInsertingClass);
        }
        await reloadAeaClasses();
    }

    async function updateAeaClass(classId: string, newName: string) {
        try {
            await putRequest(`aea/classes/${classId}/`, { name: newName });
        } catch (e) {
            return Promise.reject(t_cantUpdateClass);
        }
        await reloadAeaClasses();
    }

    async function saveUserExercise() {
        let classIdToUse = currentExerciseGroupId;
        if (classIdToUse.length === 0) {
            // create new group.
            try {
                const newClassResponse = await postRequest("aea/classes/", { name: t_exampleClass });
                classIdToUse = newClassResponse.data.token;
            } catch (e) {
                return Promise.reject("Error while creating the class");
            }
        }

        const publisherId = selectedBookData.publisherBook?.PublisherID || "";
        const exerciseId = uuidv4();
        let ownWordsCount = 0;
        const cardIds = Object.values(vocabularyDrawerContent).map((card) => {
            if (!card.wordContent) {
                return null;
            }
            if ("uuid" in card.wordContent) {
                return {
                    id: card.wordContent?.uuid,
                    ownerId: publisherId,
                };
            } else if ("isUserCard" in card.wordContent) {
                ownWordsCount++;
                return {
                    id: card.wordContent?.id,
                    ownerId: card.wordContent?.ownerId,
                };
            }

            return null;
        });
        const requestParameters: any = {
            testName,
            cardIds,
            groupId: classIdToUse,
        };

        if (publisherId === "" && selectedUserSubject.id) {
            requestParameters.subjectContentId = {
                id: selectedUserSubject.id,
                ownerId: selectedUserSubject.ownerId,
            };
        } else {
            requestParameters.subjectContentId = {
                id: selectedBookData.band?.UUID,
                ownerId: publisherId,
            };
        }

        const exerciseCreationResponse = await makePxpRequest("POST", "cardsTestWithShare/" + exerciseId, {
            ...requestParameters,
        });
        if (!exerciseCreationResponse.data.httpCode || exerciseCreationResponse.data.httpCode !== 200) {
            return Promise.reject("Error");
        }

        if (canLogEvent()) {
            logEventWithProps("Created Exercise", {
                exerciseId,
                groupId: classIdToUse,
                publisherId,
                bookId: selectedBookData.band?.ID,
                language: selectedBookData.subject?.ID,
                wordsCount: cardIds.length,
                ownWordsCount,
            });
        }

        resetAppStatus();

        localStorage.setItem("lsRt", exerciseId);

        return Promise.resolve();
    }

    async function deleteUserExercises(exerciseIds: Array<string>) {
        const idsWithErrors = [];
        for (let i = 0; i < exerciseIds.length; i++) {
            const id = exerciseIds[i];
            const exerciseDeletionResponse = await makePxpRequest("DELETE", "cardsTest/" + id);
            if (exerciseDeletionResponse.data.replyContent !== "Done") {
                idsWithErrors.push(id);
            }
        }
        loadClassesAndExercisesForUser();
        return Promise.resolve(idsWithErrors);
    }

    async function deleteUserExerciseClassOrBook(deleteProperties: DeleteExercisesOrGroupProperties) {
        setIsDataBeingLoaded(true);
        if (deleteProperties.itemType === "EXERCISE") {
            const deleteExerciseResponse = await deleteUserExercises([deleteProperties.exerciseId!]);
            if (deleteExerciseResponse.length !== 0) {
                openWarningModal(t_deleteExerciseError);
            }
        } else if (deleteProperties.itemType === "BOOK" || deleteProperties.itemType === "GROUP") {
            let exercises: Array<AeaExerciseModel> = [];
            if (deleteProperties.itemType === "BOOK") {
                exercises = Object.values(
                    userExercisesGroupedData[deleteProperties.groupId!][deleteProperties.bookId!]
                );
            } else {
                if (userExercisesGroupedData[deleteProperties.groupId!]) {
                    exercises = Object.values(userExercisesGroupedData[deleteProperties.groupId!]).flat() || [];
                }
            }
            const exercisesIds = exercises.map((e) => (e.id ? e.id : undefined)).filter((v): v is string => !!v);
            let deleteExerciseResponse = [];
            if (exercisesIds.length > 0) {
                deleteExerciseResponse = await deleteUserExercises(exercisesIds);
                if (deleteExerciseResponse.length !== 0) {
                    openWarningModal(t_deleteExerciseError);
                }
            }

            if (deleteExerciseResponse.length === 0 && deleteProperties.itemType === "GROUP") {
                //delete group.
                try {
                    const deleteClassResponse = await deleteRequest("aea/classes/", {
                        token: deleteProperties.groupId,
                    });
                    if (deleteClassResponse.data.error) {
                        throw new Error(deleteClassResponse.data.error);
                    }
                } catch (e) {
                    console.log(e);
                    openWarningModal(t_errorDeletingClass);
                }
                await reloadAeaClasses();
            }
        }
        setIsDataBeingLoaded(false);
    }

    function startNewRecurringExerciseForGroup(groupId: string, withOwnContent?: boolean) {
        setCreatingMode("RECURRING_TASK");
        setTestName("Aufgabe");
        setCurrentExerciseGroupId(groupId);

        if (userExercisesGroupedData[groupId]) {
            const nonAvailableBooksUuids = Object.keys(userExercisesGroupedData[groupId]);
            setSelectBandFilter(nonAvailableBooksUuids);
        }

        if (withOwnContent) {
            history.push("/create/select-own-source");
        } else {
            history.push("/create/select-book");
        }
    }

    async function addNewVocabularyToBookInGroup(groupId: string, contentUuid: string, isUserOwnContent: boolean) {
        setCreatingMode("RECURRING_TASK");
        setTestName("Aufgabe");
        setCurrentExerciseGroupId(groupId);

        if (isUserOwnContent) {
            if (userUsedOwnSubjectsInfo[contentUuid]) {
                setSelectedUserSubject(userUsedOwnSubjectsInfo[contentUuid]);
            } else {
                const userSubjectsResponse = await makePxpRequest("GET", `/${userId}/subjects/${contentUuid}`);
                if (!userSubjectsResponse.data || !userSubjectsResponse.data.replyContent) {
                    throw new Error("No data was loaded");
                }
                setSelectedUserSubject(userSubjectsResponse.data.replyContent);
            }
            setShouldSubjectSelectionBeDisabled(true);
        } else {
            if (userUsedBooksInfo[contentUuid]) {
                setSelectedBookData(userUsedBooksInfo[contentUuid]);
            } else {
                const bandInfoResponse = await getRequest("p6u/get-band-info/", { bandId: contentUuid, isUuid: true });
                if (!bandInfoResponse || !bandInfoResponse.data) {
                    throw new Error("No data was loaded");
                }
                setSelectedBookData(bandInfoResponse.data);
            }
        }

        if (userExercisesGroupedData[groupId][contentUuid]) {
            const currentBookExercises = userExercisesGroupedData[groupId][contentUuid];
            let usedCardsIds: any = currentBookExercises.map((exercise) => exercise.cardIds?.map((c) => c.id));
            usedCardsIds = usedCardsIds.flat();
            setPreviouslyUsedCardsIds(usedCardsIds);
        }

        history.push(isUserOwnContent ? "/create/select-own-source" : "/create/select-vocabulary");
    }

    return {
        ...context,
        loadClassesAndExercisesForUser,
        createAeaClass,
        updateAeaClass,
        saveUserExercise,
        deleteUserExerciseClassOrBook,
        startNewRecurringExerciseForGroup,
        addNewVocabularyToBookInGroup,
    };
}

function RTContextProvider(props: any) {
    const [aeaClasses, setAeaClasses] = useState<Array<AeaClass>>([]);
    const [currentExerciseGroupId, setCurrentExerciseGroupId] = useState("");
    const [userExercisesGroupedData, setUserExercisesGroupedData] = useState<AeaGroupedExercisesContent>({});
    const [isFirstRtHomeLoad, setIsFirstRtHomeLoad] = useState(true);

    const value = {
        aeaClasses,
        setAeaClasses,
        currentExerciseGroupId,
        setCurrentExerciseGroupId,
        userExercisesGroupedData,
        setUserExercisesGroupedData,
        isFirstRtHomeLoad,
        setIsFirstRtHomeLoad,
    };

    return (
        <RTContext.Provider
            value={value}
            {...props}
        />
    );
}

export { RTContextProvider, useRTContext };
