// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";
import {
    AeaClass,
    AeaExerciseModel,
    AvailableBandsInfo,
    AvailableUserOwnSubjectsInfo,
    DeleteExercisesOrGroupProperties,
} from "p6m-p6u";
import ArrowButton from "../../../basic/arrowButton/ArrowButton";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import ExerciseAndBookInfo from "../../../basic/create/exerciseAndBookInfo/ExerciseAndBookInfo";

export interface RtClassItemProps {
    classData: AeaClass;
    isClassOpen: boolean;
    deleteClassCallback?: () => any;
    toggleClassOpenStatusCallback?: (classId: string, val: boolean) => any;
    editClassNameFn: (classId: string) => any;
    startNewTaskCreationFn: (classId: string, withOwnContent?: boolean) => any;
    classExercisesContent?: { [key: string]: Array<AeaExerciseModel> };
    bookInfoData: AvailableBandsInfo;
    getBookInfoFn: (bookUuids: Array<string>) => any;
    userUsedOwnSubjectsInfo: AvailableUserOwnSubjectsInfo;
    getSubjectInfoFn: (subjectUuids: Array<string>) => any;
    addVocabularyToExerciseFn: (classId: string, bookId: string, isOwnSubject: boolean) => any;
    openDeleteModalFn: (properties: DeleteExercisesOrGroupProperties) => any;
    toggleCopyModalFn: (val: boolean) => any;
    canLogEvent: () => any;
}

const RtClassItemOuterWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
    user-select: none;
`;

const RtClassItemInnerWrapper = styled.div`
    width: 95%;
    background: white;
    padding: 1rem;
    border-radius: 0.5rem;

    @media (max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px) {
        width: 100%;
        padding: 1rem 0.5rem;
    }
`;

const RtClassItemHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        gap: 0;
    }
`;

const RtClassItemDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
`;

const TestNameWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
    }
`;

const RtClassItemOpenActions = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const StyledArrowButton = styled(ArrowButton)`
    padding: 0 !important;
`;

const TestName = styled.span`
    font-weight: bold;
    display: block;
    flex: 1;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin-left: 0.3rem;
    }
`;

const ClassContentWrapper = styled.div``;

const NormalSizeIcon = styled(PhaseSixIcon)`
    font-size: 1.8rem;
`;

const BigSizeIcon = styled(PhaseSixIcon)`
    font-size: 2.5rem;
`;

const DeleteClassWrapper = styled.div`
    cursor: pointer;
`;

const EditClassNameWrapper = styled.div``;

const AddNewBookWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
`;

const RtClassItem: React.FC<RtClassItemProps> = (props) => {
    const {
        classData,
        isClassOpen,
        toggleClassOpenStatusCallback,
        editClassNameFn,
        addVocabularyToExerciseFn,
        canLogEvent,
        startNewTaskCreationFn,
        bookInfoData,
        getBookInfoFn,
        classExercisesContent,
        openDeleteModalFn,
        toggleCopyModalFn,
        userUsedOwnSubjectsInfo,
        getSubjectInfoFn,
    } = props;

    const [areClassDetailsOpen, setAreClassDetailsOpen] = useState(isClassOpen);

    const [sortedExercisesContent, setSortedExercisesContent] = useState<Array<Array<AeaExerciseModel>>>(
        Object.values(classExercisesContent || {})
    );

    const t = useT();
    const t_newBook = t("New Book", { _tags: "CreateTest,RtClassItem" });
    const t_selfContent = t("New Task with Own Content", { _tags: "CreateTest,RtClassItem" });

    const getBooksAndSubjectsInfoAndCover = useCallback(
        () => {
            let missingBookIds: Array<string> = [];
            let missingSubjectIds: Array<string> = [];

            Object.entries(classExercisesContent || {}).forEach(([key, content]) => {
                if (content[0]) {
                    if (content[0].isOwnContentTask) {
                        if (!userUsedOwnSubjectsInfo.hasOwnProperty(key)) {
                            missingSubjectIds.push(key);
                        }
                    } else {
                        if (!bookInfoData.hasOwnProperty(key)) {
                            missingBookIds.push(key);
                        }
                    }
                }
            });

            if (missingBookIds.length > 0) {
                getBookInfoFn(missingBookIds);
            }
            if (missingSubjectIds.length > 0) {
                getSubjectInfoFn(missingSubjectIds);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [classExercisesContent, getBookInfoFn, getSubjectInfoFn]
    );

    useEffect(() => {
        if (isClassOpen) {
            getBooksAndSubjectsInfoAndCover();
        }
        setAreClassDetailsOpen(isClassOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClassOpen]);

    useEffect(() => {
        if (toggleClassOpenStatusCallback) {
            toggleClassOpenStatusCallback(classData.token!, areClassDetailsOpen);
        }
        if (areClassDetailsOpen) {
            getBooksAndSubjectsInfoAndCover();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areClassDetailsOpen, classExercisesContent]);

    // sort books by name
    useEffect(() => {
        let bookIds: Array<string> = [];
        let subjectIds: Array<string> = [];

        Object.entries(classExercisesContent || {}).forEach(([key, content]) => {
            if (content[0]) {
                if (content[0].isOwnContentTask) {
                    subjectIds.push(key);
                } else {
                    bookIds.push(key);
                }
            }
        });

        let allBooksHaveInfo = bookIds.every((bId) => bookInfoData.hasOwnProperty(bId));
        let allSubjectsHaveInfo = subjectIds.every((sId) => userUsedOwnSubjectsInfo.hasOwnProperty(sId));
        if (allBooksHaveInfo && allSubjectsHaveInfo) {
            let sortedObjects = Object.values(classExercisesContent || {}).sort((a1, a2) => {
                if (a1[0] && a2[0]) {
                    let a1Id = a1[0].subjectContentId?.id;
                    let a2Id = a2[0].subjectContentId?.id;

                    if (a1Id && a2Id) {
                        let a1Name = a1[0].isOwnContentTask
                            ? userUsedOwnSubjectsInfo[a1Id].name || ""
                            : bookInfoData[a1Id]?.band?.Name || "";
                        let a2Name = a2[0].isOwnContentTask
                            ? userUsedOwnSubjectsInfo[a2Id].name || ""
                            : bookInfoData[a2Id]?.band?.Name || "";
                        return a1Name.localeCompare(a2Name);
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            });
            setSortedExercisesContent(sortedObjects);
        }
    }, [classExercisesContent, bookInfoData, userUsedOwnSubjectsInfo]);

    function toggleDetails() {
        setAreClassDetailsOpen(!areClassDetailsOpen);
    }

    function deleteModalOpen(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        openDeleteModalFn({ itemType: "GROUP", groupId: classData.token! });
    }

    return (
        <RtClassItemOuterWrapper>
            <RtClassItemInnerWrapper>
                <RtClassItemHeader onClick={toggleDetails}>
                    <RtClassItemDetails>
                        <BigSizeIcon name={"group"} />
                        <TestNameWrapper>
                            <TestName>{classData.name}</TestName>
                            <EditClassNameWrapper
                                onClick={() => {
                                    editClassNameFn(classData.token!);
                                }}
                            >
                                <NormalSizeIcon name={"edit-avatar"} />
                            </EditClassNameWrapper>
                        </TestNameWrapper>
                    </RtClassItemDetails>
                    <RtClassItemOpenActions>
                        <DeleteClassWrapper onClick={deleteModalOpen}>
                            <NormalSizeIcon name={"trash"} />
                        </DeleteClassWrapper>
                        <StyledArrowButton
                            direction={areClassDetailsOpen ? "UP" : "DOWN"}
                        />
                    </RtClassItemOpenActions>
                </RtClassItemHeader>
                {areClassDetailsOpen && (
                    <ClassContentWrapper>
                        <>
                            {sortedExercisesContent.map((content) => {
                                let bookId = content[0].subjectContentId?.id || "";
                                let isOwnSubject = content[0].isOwnContentTask || false;
                                if (content.length > 0 && bookId) {
                                    return (
                                        <ExerciseAndBookInfo
                                            bookExercises={content}
                                            bookInfoData={bookInfoData}
                                            subjectInfoData={userUsedOwnSubjectsInfo}
                                            bookId={bookId}
                                            key={classData.token + "_" + bookId}
                                            toggleCopyModalFn={toggleCopyModalFn}
                                            canLogEvent={canLogEvent}
                                            openDeleteModalFn={openDeleteModalFn}
                                            groupId={classData.token!}
                                            addVocabularyToExercisesFn={() => {
                                                addVocabularyToExerciseFn(classData.token!, bookId, isOwnSubject);
                                            }}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </>
                        <AddNewBookWrapper>
                            <StyledButtonWithIcon
                                iconPosition={"LEFT"}
                                icon={"add"}
                                buttonStyle={"BLANK"}
                                onClick={() => {
                                    startNewTaskCreationFn(classData.token!);
                                }}
                            >
                                {t_newBook}
                            </StyledButtonWithIcon>
                            <StyledButtonWithIcon
                                iconPosition={"LEFT"}
                                icon={"add"}
                                buttonStyle={"BLANK"}
                                onClick={() => {
                                    startNewTaskCreationFn(classData.token!, true);
                                }}
                            >
                                {t_selfContent}
                            </StyledButtonWithIcon>
                        </AddNewBookWrapper>
                    </ClassContentWrapper>
                )}
            </RtClassItemInnerWrapper>
        </RtClassItemOuterWrapper>
    );
};

export default RtClassItem;
