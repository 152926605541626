import React from "react";
import ExerciseStarterText from "../../detailsSubComponents/exerciseStarterText/ExerciseStarterText";
import ExerciseEmptyLines from "../../detailsSubComponents/exerciseEmptyLines/ExerciseEmptyLines";

import { Wrapper } from "../../StyledComponents";

export interface DetailsFreeTextProps {}

const DetailsFreeText: React.FC<DetailsFreeTextProps> = (props) => {
    return (
        <Wrapper>
            <ExerciseStarterText />
            <ExerciseEmptyLines linesAmount={6}/>
        </Wrapper>
    );
};

export default DetailsFreeText;
