import styled from "styled-components";
import DesignConstants from "../../../../constants/DesignConstants";

export const ExerciseWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    background: white;
    overflow: hidden;
    border: ${DesignConstants.BORDERS.THIN_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
    }
`;

export const ExerciseTitleAndInfoWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    opacity: ${(props) => (props.disabled ? DesignConstants.OPACITY.HALF : 1)};
`;

export const ExerciseTitleSideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    justify-content: center;
    gap: ${DesignConstants.SPACES.S};
    padding: ${DesignConstants.SPACES.L};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.L} 0 ${DesignConstants.SPACES.M};
        margin-bottom: ${DesignConstants.SPACES.XS};

        &.infoOpen {
            padding-bottom: 0;
            gap: ${DesignConstants.SPACES.XS};
            margin-bottom: 0;
        }
    }
`;

export const ExerciseImageSideWrapper = styled.div`
    display: flex;
    flex: 4;
    overflow: hidden;
    position: relative; //to anchor the absolute children
    border-left: ${DesignConstants.BORDERS.THIN_GREY};

    &.infoOpen {
        align-self: stretch;
        align-items: center;
        border: none;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        height: 100px;
        border-left: none;
        align-items: flex-start;
        border-top: ${DesignConstants.BORDERS.THIN_GREY};

        &.infoOpen {
            border-top: ${DesignConstants.BORDERS.THIN_GREY};
        }
    }
`;

export const ExerciseTypeImageWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute; //needed to properly cut the images
    align-items: flex-start;
    padding: ${DesignConstants.SPACES.S};

    opacity: ${(props) => (props.disabled ? DesignConstants.OPACITY.HALF : 1)};

    &.infoOpen {
        align-items: center;
        padding: 0 ${DesignConstants.SPACES.L} 0 0;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        height: 15vw; //depends on width to adapt based on image width
        position: relative;
        padding: 3%;

        &.infoOpen {
            align-items: center;
            padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.S};
            height: auto;
        }
    }
`;

export const ToggledImagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.M};
`;

export const ExerciseTypeImage = styled.img`
    width: 100%;
    object-fit: cover;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        align-self: flex-start;
    }
`;

export const ExerciseNameQuestionmarkWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ExerciseInfo = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const StyledQuestionMarkIcon = styled.span<{ color?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${DesignConstants.FONT_SIZES.S};
    color: ${DesignConstants.COLORS.DARK_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    width: ${DesignConstants.FONT_SIZES.L};
    height: ${DesignConstants.FONT_SIZES.L};
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
`;

export const ExerciseQuestionMarkWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: ${DesignConstants.SPACES.XS};

    &:hover {
        color: ${DesignConstants.COLORS.P6_ORANGE};
        ${StyledQuestionMarkIcon} {
            color: ${DesignConstants.COLORS.P6_ORANGE};
            border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.P6_ORANGE};
        }
    }

    &.infoOpen {
        ${StyledQuestionMarkIcon} {
            color: ${DesignConstants.COLORS.P6_ORANGE};
            border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.P6_ORANGE};
        }

        &:hover {
            ${StyledQuestionMarkIcon} {
                color: ${DesignConstants.COLORS.DARK_GREY};
                border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
            }
        }
    }
`;

export const ExerciseDescriptionWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex: 1;
    max-width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    align-self: center;
    font-size: ${DesignConstants.FONT_SIZES.L}
    line-height: 1.4;
    hyphens: auto;
    text-align: justify;

    opacity: ${(props) => (props.disabled ? DesignConstants.OPACITY.HALF : 1)};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin-bottom: ${DesignConstants.SPACES.S};
    }
`;
