import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";

export const ConnectOuterWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${DesignConstants.SPACES.M};
`;

export const WordsColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    gap: ${DesignConstants.SPACES.S};
`;

export const ConnectWrapper = styled.div<{ rightColumn?: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.rightColumn ? "row-reverse" : "row")};
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.MID_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    padding: ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.XXS};
`;

export const ConnectWord = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: ${DesignConstants.FONT_SIZES.M};
    color: ${DesignConstants.COLORS.DARK_GREY};
`;

export const Circle = styled.div<{ color?: string }>`
    display: flex;
    height: ${DesignConstants.SIZES.ICON_XXS};
    width: ${DesignConstants.SIZES.ICON_XXS};
    border-radius: ${DesignConstants.SIZES.ICON_XXS};
    background-color: ${(props) => (props.color ? props.color : DesignConstants.COLORS.DARK_GREY)};
`;

export const RemoveIcon = styled(PhaseSixIcon)`
    display: flex;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    cursor: pointer;
`;

export const RemoveIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.M};
`;
