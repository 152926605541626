import { JointTestAnswersEntry, JointTestExerciseItemAnswer, StudentTestAnswer } from "p6m-p6u";

export const getCountsForJointExerciseItem = (entry: JointTestAnswersEntry): [number, number, number, number] => {
    let content: Array<JointTestExerciseItemAnswer> = Object.values(entry["answers"]).flat();
    let systemSuggestedItems = 0,
        reviewedItems = 0,
        totalItems = 0,
        commentsAdded = 0;

    content.forEach((a) => {
        systemSuggestedItems += a.systemSuggestedGrade !== "not-graded" ? 1 : 0;
        reviewedItems += a.teacherGrade !== "not-graded" ? 1 : 0;
        commentsAdded += a.teacherComment !== "" ? 1 : 0;
        totalItems++;
    });

    return [systemSuggestedItems, reviewedItems, totalItems, commentsAdded];
};
export const getPercentagesForIndividualExerciseReview = (entry: JointTestAnswersEntry): [number, number, number] => {
    let content: Array<JointTestExerciseItemAnswer> = Object.values(entry["answers"]).flat();
    let markedAsCorrect = 0,
        markedAsIncorrect = 0,
        commentsAdded = 0;

    content.forEach((a) => {
        switch (a.teacherGrade) {
            case "correct":
                markedAsCorrect++;
                break;
            case "incorrect":
                markedAsIncorrect++;
                break;
        }
        commentsAdded += a.teacherComment?.length ? 1 : 0;
    });

    const totalAnsw = content.length;

    let markedAsCorrectPerc = totalAnsw > 0 ? (markedAsCorrect * 100) / totalAnsw : 0;
    let markedAsIncorrectPerc = totalAnsw > 0 ? (markedAsIncorrect * 100) / totalAnsw : 0;

    return [Math.round(markedAsCorrectPerc), Math.round(markedAsIncorrectPerc), commentsAdded];
};

export const getPercentagesForStudentIndReview = (entry: StudentTestAnswer): [number, number, number] => {
    let content = entry.questionAnswers || {};
    let markedAsCorrect = 0,
        markedAsIncorrect = 0,
        commentsAdded = 0,
        totalAnsw = 0;

    Object.values(content).forEach((exercise) => {
        exercise.studentAnswers?.forEach((a) => {
            switch (a.teacherGrade) {
                case "correct":
                    markedAsCorrect++;
                    break;
                case "incorrect":
                    markedAsIncorrect++;
                    break;
            }
            commentsAdded += a.teacherIndComment?.length ? 1 : 0;
            commentsAdded += a.teacherComment?.length ? 1 : 0;
            totalAnsw++;
        });
    });

    let markedAsCorrectPerc = totalAnsw > 0 ? (markedAsCorrect * 100) / totalAnsw : 0;
    let markedAsIncorrectPerc = totalAnsw > 0 ? (markedAsIncorrect * 100) / totalAnsw : 0;

    return [Math.round(markedAsCorrectPerc), Math.round(markedAsIncorrectPerc), commentsAdded];
};
