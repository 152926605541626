import React from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import CreateInfoModal from "../InfoModal/CreateInfoModal";
import { useT } from "@transifex/react";
import styled from "styled-components";

export interface WarningModalProps {}

const WarningModalContentWrapper = styled.div`
    text-align: left;
    width: 100%;
    padding-right: 1rem;
`;

const WarningModal: React.FC<WarningModalProps> = (props) => {
    const { warningModalContent, setWarningModalContent, isWarningModalVisible, setIsWarningModalVisible } =
        useTestCreationContext();

    const t = useT();
    const t_continue = t("Continue", { _tags: "CreateTest,UnitAndCardsWrapper" });

    function closeWarningModal() {
        setWarningModalContent(null);
        setIsWarningModalVisible(false);
    }

    return (
        <CreateInfoModal
            isOpen={isWarningModalVisible}
            onRequestClose={closeWarningModal}
            continueAction={closeWarningModal}
            continueText={t_continue}
            compact
        >
            <WarningModalContentWrapper>{warningModalContent}</WarningModalContentWrapper>
        </CreateInfoModal>
    );
};

export default WarningModal;
