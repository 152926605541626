import GapSentenceList from "./gapSentenceList/GapSentenceList";
import ExerciseWordBox from "../../detailsSubComponents/exerciseWordBox/ExerciseWordBox";
import AddVocabularyButton from "../../detailsSubComponents/addVocabularyButton/AddVocabularyButton";

import { Wrapper } from "../../StyledComponents";

interface DetailsGapSentenceProps {
    hasWords: boolean;
    onRemoveWord: (cardId?: string) => void;
    isSelectExerciseVocabularyOpen: boolean;
    onClickAddVocabularyButton: () => void;
}

const DetailsGapSentence = (props: DetailsGapSentenceProps) => {
    const renderWordBox = () => <ExerciseWordBox onRemoveWord={props.onRemoveWord} />;
    const renderWordList = () => <GapSentenceList onRemoveWord={props.onRemoveWord} />;

    return (
        <Wrapper>
            {props.hasWords && renderWordBox()}
            {props.hasWords && renderWordList()}
            {!props.isSelectExerciseVocabularyOpen && (
                <AddVocabularyButton onClickAddVocabularyButton={props.onClickAddVocabularyButton} />
            )}
        </Wrapper>
    );
};

export default DetailsGapSentence;
