const logEvent = (eventName: string) => {
    logEventWithProps(eventName, {});
};

const logEventWithProps = (eventName: string, props: { [key: string]: any }) => {
    if (!window.dataLayer) return;

    window.dataLayer.push({ event: "logEvent", eventName: eventName, eventProperties: props });
};

const setUserIdGaEvent = (userId: string) => {
    if (!window.dataLayer) return;

    window.dataLayer.push({ event: "setUserId", userId: userId });
};

const setVersionName = (versionName: string) => {
    if (!window.dataLayer) return;

    window.dataLayer.push({ event: "setVersionName", versionName: versionName });
};

const setGaUserId = (userId: string) => {
    if (!window.dataLayer) return;

    window.dataLayer.push({ gaUserID: userId });
};

export { logEvent, logEventWithProps, setUserIdGaEvent, setVersionName, setGaUserId };
