import React from "react";
import { TestDetailsWord, UserOwnCard, VerbtrainingSelectedWord } from "p6m-p6u";
import { sanitizeText } from "../../../helpers/TestCreation";

import {
    ListingWrapper,
    WordListingItemWrapper,
    WordListingItem,
    DeleteWordWrapper,
    DeleteWordIcon,
} from "./StyledComponents";

export interface ExerciseVocabularyListingProps {
    vocabularyList: Array<TestDetailsWord | VerbtrainingSelectedWord | UserOwnCard>;
    listType: "DOUBLE" | "QUESTION" | "ANSWER" | "GAP";
    onRemove: (cardId?: string) => any;
    isConnectWordsExercise: boolean;
    removeCardCountRestriction?: boolean;
}

const ExerciseVocabularyListing: React.FC<ExerciseVocabularyListingProps> = (props) => {
    const { vocabularyList, listType, onRemove, isConnectWordsExercise, removeCardCountRestriction } = props;

    return (
        <ListingWrapper>
            {vocabularyList.map((w) => {
                if ("id" in w) {
                    return (
                        <WordListingItemWrapper key={"wordListing_" + w.id}>
                            {(listType === "DOUBLE" || listType === "QUESTION") && (
                                <WordListingItem
                                    className={!isConnectWordsExercise && !w.isQuestionShown ? "blur" : ""}
                                >
                                    {sanitizeText(w.question || "")}
                                </WordListingItem>
                            )}
                            {(listType === "DOUBLE" || listType === "ANSWER") && (
                                <WordListingItem className={!isConnectWordsExercise && w.isQuestionShown ? "blur" : ""}>
                                    {sanitizeText(w.answer || "")}
                                </WordListingItem>
                            )}
                            {listType === "GAP" && "gap_sentence" in w && (
                                <WordListingItem>{w.gap_sentence}</WordListingItem>
                            )}
                            {listType !== "GAP" && (
                                <DeleteWordWrapper
                                    onClick={() => onRemove(w.id)}
                                    className={
                                        !removeCardCountRestriction && vocabularyList.length <= 3 ? "disabled" : ""
                                    }
                                >
                                    <DeleteWordIcon name={"trash"} />
                                </DeleteWordWrapper>
                            )}
                        </WordListingItemWrapper>
                    );
                } else {
                    return null;
                }
            })}
        </ListingWrapper>
    );
};

export default ExerciseVocabularyListing;
