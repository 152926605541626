import * as React from "react";
import styled from "styled-components/macro";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";
import { UT } from "@transifex/react/dist";
import { useEffect, useState } from "react";
import { getReadableTimeFromSeconds } from "../../../helpers/DateTime";
import moment from "moment";
import { ReactComponent as TimerIconSvg } from "../../../assets/icons/time.svg";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";

export interface TimerProps {
    className?: string;
    timeEndCallbackFunction: () => any;
    timeLimit: string;
}

const TimerIcon = styled(SvgIcon)`
    height: 30px;
    margin-right: 0.8rem;
    border-radius: 6px;

    &.Warning5min {
        fill: ${(props) => props.theme.base.primaryColor};

        > path {
            fill: ${(props) => props.theme.base.primaryColor};
        }
    }

    &.Warning1min {
        height: 35px;

        > path {
            stroke: ${(props) => props.theme.base.primaryColor};
            stroke-width: 3px;
        }
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin: 0;
    }
`;

const TimerWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex: 2;
    }
`;

const TimeIntervalText = styled.p`
    &.Warning5min {
        color: ${(props) => props.theme.base.primaryColor};
    }

    &.Warning1min {
        font-size: 1.3rem;
        font-weight: bold;
    }
`;

let timerInitialized = false;

const Timer: React.FC<TimerProps> = (props) => {
    const [isLessThan5min, setIsLessThan5min] = useState(false);
    const [isLessThan1min, setIsLessThan1min] = useState(false);
    const [countDown, setCountDown] = useState<number>(-1);

    const { testEndTimestamp, setIsTimeOver, isTimeOver } = useStudentPracticeContext();

    const [readableTime, setReadableTime] = useState<string>("00:00");

    useEffect(() => {
        if (testEndTimestamp) {
            let countdownVal = testEndTimestamp - moment().unix();
            setCountDown(countdownVal <= 0 ? 0 : countdownVal);
            timerInitialized = true;
        }
    }, [testEndTimestamp]);

    useEffect(() => {
        let currentTimeout: any;

        if (timerInitialized && countDown >= 0) {
            if (!isLessThan5min && countDown <= 300) {
                setIsLessThan5min(true);
            }
            if (!isLessThan1min && countDown <= 60) {
                setIsLessThan1min(true);
            }
            if (countDown <= 0) {
                setIsTimeOver(true);
            } else {
                currentTimeout = setTimeout(() => {
                    setCountDown(countDown - 1);
                }, 1000);
            }
            setReadableTime(getReadableTimeFromSeconds(countDown));
        }

        return () => {
            clearTimeout(currentTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testEndTimestamp, countDown, timerInitialized]);

    useEffect(() => {
        if (isTimeOver) {
            props.timeEndCallbackFunction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTimeOver]);

    return (
        <TimerWrapper className={!!props.className ? props.className : ""}>
            <TimerIcon
                svgComponent={TimerIconSvg}
                className={`${isLessThan5min ? "Warning5min" : ""} ${isLessThan1min ? "Warning1min" : ""}`}
            />
            <TimeIntervalText
                className={`${isLessThan5min ? "Warning5min" : ""} ${isLessThan1min ? "Warning1min" : ""}`}
            >
                <UT
                    _str="Noch: {readableTime}"
                    readableTime={readableTime}
                    _inline
                    _tags="timer"
                />
            </TimeIntervalText>
        </TimerWrapper>
    );
};

export default Timer;
