import * as React from "react";
import { T, useT } from "@transifex/react/dist";

import {
    BulletPointWrapper,
    BoldBulletPointText,
    BoldBulletPointTextWrapper,
    AnonymousModalContent,
    HowToContinueText,
    OptionTitleText,
    WithoutAccountWrapper,
    WithAccountWrapper,
} from "./StyledComponents";
import StyledButton from "../styledButton/StyledButton";
import DesignConstants from "../../constants/DesignConstants";
import BulletPoint from "../bulletPoint/BulletPoint";
import CreateInfoModal from "../create/InfoModal/CreateInfoModal";
import { TeacherTestDetails, TestQuestionModel } from "p6m-p6u";
import PDFDownloadButton from "../pdfBasic/PDFDownloadButton/PDFDownloadButton";
import { useTestCreationContext } from "../../context/TestCreationContext";

export interface AnonymousSaveTestModalProps {
    isOpen: boolean;
    onRequestClose: () => any;
    cancelAction?: () => any;
    cancelText?: string;
    saveTestAndLoginOrRegister: () => any;
    testContent?: TestQuestionModel[];
}

const AnonymousSaveTestModal: React.FC<AnonymousSaveTestModalProps> = (props) => {
    const { prepareAnonymousTestDetails } = useTestCreationContext();
    const t = useT();
    const t_howWouldYouLikeToContinue = t("How would you like to continue?", { _tags: "CreateTest,Summary" });
    const t_withAccount = t("With Account", { _tags: "CreateTest,Summary" });
    const t_withoutAccount = t("Without Account", { _tags: "CreateTest,Summary" });
    const t_free = t("free", { _tags: "CreateTest,Summary" });
    const t_savingAndEditing = t("Save & edit tests", { _tags: "CreateTest,Summary" });
    const t_digitalTests = t("Digital tests (for PC, smartphone or tablet)", { _tags: "CreateTest,Summary" });
    const t_onlineReview = t("Time saving online reviews", { _tags: "CreateTest,Summary" });
    const t_loginOrRegisterNow = t("Log in or register now", { _tags: "CreateTest,Summary" });
    const t_exercisePDF = t("Exercise Sheet", { _tags: "CreateTest,PDFCreation" });
    const t_downloadPDF = t("Download PDF", { _tags: "CreateTest,Summary" });

    const testDetails: TeacherTestDetails | undefined = props.testContent
        ? prepareAnonymousTestDetails(props.testContent)
        : undefined;

    return (
        <CreateInfoModal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            alignButtonsLeft
            cancelAction={props.cancelAction}
            compact
            marginToButtons={DesignConstants.SPACES.S}
        >
            <AnonymousModalContent>
                <HowToContinueText>{t_howWouldYouLikeToContinue}</HowToContinueText>
                <WithoutAccountWrapper>
                    <OptionTitleText>{t_withoutAccount}</OptionTitleText>
                    <PDFDownloadButton
                        fileName={(testDetails?.name ?? t_exercisePDF) + ".pdf"}
                        buttonText={t_downloadPDF}
                        testDetails={testDetails}
                    />
                </WithoutAccountWrapper>
                <WithAccountWrapper>
                    <OptionTitleText> {t_withAccount}</OptionTitleText>
                    <StyledButton
                        onClick={props.saveTestAndLoginOrRegister}
                        arrowDirection={"NONE"}
                        buttonStyle={"ORANGE_BLANK"}
                    >
                        {t_loginOrRegisterNow}
                    </StyledButton>
                    <BulletPointWrapper>
                        <BulletPoint>
                            <BoldBulletPointTextWrapper>
                                <T
                                    _str={"{x} – for you and your students"}
                                    x={<BoldBulletPointText>{t_free}</BoldBulletPointText>}
                                    _inline
                                    _tags="CreateTest,Summary"
                                />
                            </BoldBulletPointTextWrapper>
                        </BulletPoint>
                        <BulletPoint>{t_savingAndEditing}</BulletPoint>
                        <BulletPoint>{t_digitalTests}</BulletPoint>
                        <BulletPoint>{t_onlineReview}</BulletPoint>
                    </BulletPointWrapper>
                </WithAccountWrapper>
            </AnonymousModalContent>
        </CreateInfoModal>
    );
};

export default AnonymousSaveTestModal;
