import styled from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../constants/DesignConstants";

export const ListingWrapper = styled.div`
    width: 100%;
`;

export const WordListingItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${DesignConstants.SPACES.XS};
    padding: ${DesignConstants.SPACES.XS};
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    font-size: ${DesignConstants.FONT_SIZES.M};

    &:last-child {
        border-bottom: none;
    }
`;

export const WordListingItem = styled.div`
    flex: 1;
    user-select: none;

    &.blur {
        opacity: ${DesignConstants.OPACITY.HALF};
    }
`;

export const DeleteWordWrapper = styled.div`
    width: ${DesignConstants.SPACES.M};
    cursor: pointer;

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        cursor: not-allowed;
    }
`;

export const DeleteWordIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;
