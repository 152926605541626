import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

export const GeneralWrapper = styled.div`
    padding-bottom: ${DesignConstants.SPACES.XXL};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding-bottom: ${DesignConstants.SPACES.XXXL};
    }
`;

export const SelectedSourceWrapper = styled.div<{ multipleSources?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${(props) => (props.multipleSources ? DesignConstants.SPACES.XS : 0)};

    :last-child {
        margin: 0;
    }
`;

export const SelectBookVocabularyWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const BookWrapper = styled.div<{ borderActive?: boolean; showBorder?: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    border: ${(props) =>
        props.borderActive
            ? props.showBorder
                ? `${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.P6_ORANGE}`
                : `${DesignConstants.BORDERS.THIN} transparent`
            : ""};
    padding: ${(props) => (props.borderActive ? DesignConstants.SPACES.XS : "")};
    cursor: ${(props) => (props.borderActive ? "pointer" : "")};
`;

export const DifferentSourceWrapper = styled.div<{ alignBottom?: boolean }>`
    display: flex;
    flex-direction: column;
    align-self: ${(props) => (props.alignBottom ? "flex-end" : "")};
`;

export const BookHeaderInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    background: white;
    gap: ${DesignConstants.SPACES.S};
    margin-bottom: ${DesignConstants.SPACES.M};
`;

export const BookImageWrapper = styled.div`
    margin-right: ${DesignConstants.SPACES.S};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BookNameWrapper = styled.div``;

export const BookName = styled.h4`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    font-size: ${DesignConstants.FONT_SIZES.L};
    margin: 0;
`;

export const SmallSelectWrapper = styled.div`
    flex: 1;
    padding-right: ${DesignConstants.SPACES.XS};

    input {
        color: black;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

export const ExerciseToSpan = styled.span`
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const UnitsWrapper = styled.div`
    margin-top: ${DesignConstants.SPACES.XS};
`;

export const Line = styled.div`
    border-bottom: ${DesignConstants.BORDERS.THIN_GREY};
    width: 100%;
`;

export const StickyWrapper = styled.div`
    position: sticky;
    bottom: ${DesignConstants.SPACES.XXL};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    width: calc(100vw - ${DesignConstants.SPACES.L});
    max-width: calc(${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px - ${DesignConstants.SPACES.L});
    padding: ${DesignConstants.SPACES.XS} 0;
`;

export const PleaseChooseVocabulary = styled.div`
    margin: ${DesignConstants.SPACES.S} 0;
`;
