// LIBRARIES
import React from "react";
import { useT } from "@transifex/react/dist";
import { CompletedProgressBar, Wrapper, EmptyProgressBar, InnerProgressBar, ExerciseName } from "./StyledComponents";

export interface ProgressDetailItemProps {
    completedExercises?: number;
    totalExercises?: number;
    exerciseNumber: number;
    onClick?: () => any;
}

const ProgressDetailItem: React.FC<ProgressDetailItemProps> = (props) => {
    const t = useT();
    const t_exercise = t("Exercise", { _tags: "progressDetailItem,modal" });

    let BarComponent;

    if (props.completedExercises === props.totalExercises && props.totalExercises != null) {
        BarComponent = <CompletedProgressBar />;
    } else if (props.completedExercises && props.totalExercises) {
        const progressPerc = (props.completedExercises * 100) / props.totalExercises;
        BarComponent = (
            <EmptyProgressBar>
                <InnerProgressBar style={{ width: progressPerc + "%" }} />
            </EmptyProgressBar>
        );
    } else {
        BarComponent = <EmptyProgressBar />;
    }

    return (
        <Wrapper onClick={props.onClick}>
            <ExerciseName>
                {t_exercise} {props.exerciseNumber}
            </ExerciseName>
            {BarComponent}
        </Wrapper>
    );
};

export default ProgressDetailItem;
