// LIBRARIES
import React, { useRef, useState } from "react";
import ProgressDetailItem from "../../../basic/progressDetailItem/ProgressDetailItem";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";
import { UT } from "@transifex/react";
import { ReactComponent as NextArrow } from "../../../assets/icons/skip-card.svg";
import { StudentAnswerItem } from "p6m-p6u";
import { Wrapper, TopCTAWrapper, BottomCTAWrapper, BottomText, StyledArrowIcon } from "./StyledComponents";

export interface ProgressDetailProps {
    changeSelectedExercise: (id: string) => any;
    showTopCta?: boolean;
    showBottomCta?: boolean;
    bottomCtaCallback?: () => any;
}

const ProgressDetail: React.FC<ProgressDetailProps> = (props) => {
    let ProgressItems = [];
    const wrapperRef = useRef<HTMLDivElement>(null);

    const { studentTestAnswer } = useStudentPracticeContext();
    const { changeSelectedExercise } = props;

    if (props.showTopCta) {
        ProgressItems.push(
            <TopCTAWrapper key={"topCta"}>
                <UT
                    _str="Click on an exercise to start!"
                    _inline
                    _tags="overview,progressDetail"
                />
            </TopCTAWrapper>
        );
    }
    for (let key in studentTestAnswer.questionAnswers) {
        const studentAnswerItem = studentTestAnswer.questionAnswers[key];
        const exerciseNumber = Number(key.replace("e", ""));

        if (studentAnswerItem.exerciseType === "verbTraining") {
            let availableInputFieldsCount = 0;
            let progressedWords = 0;
            studentAnswerItem.studentAnswers?.forEach((sa: StudentAnswerItem) => {
                if (!sa.verbtrainingAnswers) {
                    return;
                }
                sa.verbtrainingAnswers.forEach((va) => {
                    if (va.answer === "") {
                        availableInputFieldsCount++;
                    }
                    if (va.studentAnswer !== "") {
                        progressedWords++;
                    }
                });
            });

            if (progressedWords === 0) {
                ProgressItems.push(
                    <ProgressDetailItem
                        key={key}
                        exerciseNumber={exerciseNumber}
                        onClick={() => changeSelectedExercise(key)}
                    />
                );
            } else {
                ProgressItems.push(
                    <ProgressDetailItem
                        completedExercises={progressedWords}
                        totalExercises={availableInputFieldsCount}
                        key={key}
                        exerciseNumber={exerciseNumber}
                        onClick={() => changeSelectedExercise(key)}
                    />
                );
            }
        } else if (studentAnswerItem.studentAnswers) {
            const noWords = studentAnswerItem.studentAnswers.length;
            let answeredWords = 0;

            studentAnswerItem.studentAnswers.forEach((sa: StudentAnswerItem) =>
                sa.studentAnswer !== "" ? answeredWords++ : false
            );

            if (answeredWords === 0) {
                ProgressItems.push(
                    <ProgressDetailItem
                        key={key}
                        exerciseNumber={exerciseNumber}
                        onClick={() => changeSelectedExercise(key)}
                    />
                );
            } else {
                ProgressItems.push(
                    <ProgressDetailItem
                        completedExercises={answeredWords}
                        totalExercises={noWords}
                        key={key}
                        exerciseNumber={exerciseNumber}
                        onClick={() => changeSelectedExercise(key)}
                    />
                );
            }
        } else if (studentAnswerItem.hasOwnProperty("jumbledWordsAnswer")) {
            const hasProgress = studentAnswerItem.jumbledWordsAnswer!.length > 0;
            ProgressItems.push(
                <ProgressDetailItem
                    completedExercises={hasProgress ? 1 : 0}
                    totalExercises={1}
                    key={key}
                    exerciseNumber={exerciseNumber}
                    onClick={() => changeSelectedExercise(key)}
                />
            );
        } else if (studentAnswerItem.hasOwnProperty("textAnswer")) {
            const hasProgress = studentAnswerItem.textAnswer!.length > 0;
            ProgressItems.push(
                <ProgressDetailItem
                    completedExercises={hasProgress ? 1 : 0}
                    totalExercises={1}
                    key={key}
                    exerciseNumber={exerciseNumber}
                    onClick={() => changeSelectedExercise(key)}
                />
            );
        }
    }

    const [hasHoverOnArrow, setHasHoverOnArrow] = useState(false);

    if (props.showBottomCta && props.bottomCtaCallback) {
        ProgressItems.push(
            <BottomCTAWrapper
                key={"finalOverview"}
                onClick={props.bottomCtaCallback}
                onMouseEnter={() => {
                    setHasHoverOnArrow(true);
                }}
                onMouseLeave={() => {
                    setHasHoverOnArrow(false);
                }}
            >
                <BottomText>
                    <UT
                        _str="Go to final overview"
                        _inline
                        _tags="overview,progressDetail"
                    />
                    <StyledArrowIcon
                        svgComponent={NextArrow}
                        color={hasHoverOnArrow ? "orange" : undefined}
                    />
                </BottomText>
            </BottomCTAWrapper>
        );
    }

    return <Wrapper ref={wrapperRef}>{ProgressItems}</Wrapper>;
};

export default ProgressDetail;
