import { TestDetailsItem } from "p6m-p6u";
import React from "react";
import PDFTable from "../pdfSubComponents/PDFTable";
import { ExerciseDirections } from "../../../enums/directions";

interface PDFStandardExerciseProps {
    exercise: TestDetailsItem;
    isSolution?: boolean;
}

const PDFStandardExercise: React.FC<PDFStandardExerciseProps> = (props: PDFStandardExerciseProps) => {
    return (
        <PDFTable
            selectedWords={props.exercise?.selectedWords ?? []}
            showAllQuestions={props.exercise?.direction === ExerciseDirections.QUESTION_TO_ANSWER || props.isSolution}
            showAllAnswers={props.exercise?.direction === ExerciseDirections.ANSWER_TO_QUESTION || props.isSolution}
        />
    );
};

export default PDFStandardExercise;
