import {
    Grade,
    JointTestAnswers,
    JointTestExerciseItemAnswer,
    StudentAnswerReviewItem,
    StudentQuestionAnswer,
    StudentTestAnswer,
} from "p6m-p6u";

export const generateJointData = function (resultArray: Array<StudentTestAnswer>) {
    let jointData: JointTestAnswers = {};

    resultArray.forEach((studentResponseEntry) => {
        if (studentResponseEntry.questionAnswers) {
            Object.keys(studentResponseEntry.questionAnswers).forEach((questionId) => {
                // the (individual's) questionId is the exercise id (used for grouping), which can be e.g. 'e1', 'e2' or 'e3'
                const answ: StudentQuestionAnswer = studentResponseEntry.questionAnswers![questionId];
                switch (answ.exerciseType) {
                    case "textOnly":
                    case "jumbledWords":
                        const currentAnswerOwner = {
                            answerId: studentResponseEntry.id || "",
                            exerciseId: questionId,
                        };
                        // Prepare student review answer in case it doesn't exist.
                        const studentReviewAnswer: JointTestExerciseItemAnswer = {
                            question: answ.jumbledWords?.join(", ") || "",
                            studentAnswer: answ.jumbledWordsAnswer?.trim() || answ.textAnswer || "",
                            answerOwner: [currentAnswerOwner],
                            teacherGrade:
                                answ.studentAnswers && answ.studentAnswers[0].teacherGrade
                                    ? answ.studentAnswers[0].teacherGrade
                                    : "not-graded",
                            systemSuggestedGrade:
                                answ.studentAnswers && answ.studentAnswers[0].systemSuggestedGrade
                                    ? answ.studentAnswers[0].systemSuggestedGrade
                                    : "not-graded",
                            teacherComment:
                                answ.studentAnswers && answ.studentAnswers[0].teacherComment
                                    ? answ.studentAnswers[0].teacherComment
                                    : "",
                        };
                        if (!jointData.hasOwnProperty(questionId)) {
                            jointData[questionId] = {
                                exerciseType: answ.exerciseType as string,
                                answers: {},
                            };
                        }
                        jointData[questionId].answers[studentResponseEntry.id!] = [studentReviewAnswer];
                        break;
                    default:
                        let iterableAnswers: Array<StudentAnswerReviewItem> = answ.studentAnswers || [];

                        iterableAnswers.forEach((sa) => {
                            if (!sa.id) {
                                return;
                            }
                            // If question id doesn't exist in the object, add it.
                            if (!jointData.hasOwnProperty(questionId)) {
                                jointData[questionId] = {
                                    exerciseType: answ.exerciseType,
                                    answers: {},
                                };
                            }
                            let questionCurrAnsw = jointData[questionId].answers;
                            // Prepare answer owner
                            let currentAnswerOwner = {
                                answerId: studentResponseEntry.id || "",
                                exerciseId: questionId,
                                wordId: sa.id,
                            };
                            // Prepare student review answer in case it doesn't exist.
                            let studentReviewAnswer: JointTestExerciseItemAnswer = {
                                question: sa.question || sa.answer,
                                studentAnswer: sa.studentAnswer?.trim(),
                                gap_sentence: sa.gap_sentence || "",
                                answerOwner: [currentAnswerOwner],
                                teacherGrade: sa.teacherGrade || "not-graded",
                                systemSuggestedGrade: sa.systemSuggestedGrade || "not-graded",
                                teacherComment: sa.teacherComment || "",
                                gradeCounts: {
                                    "not-graded":
                                        sa.teacherGrade && sa.teacherGrade === "not-graded"
                                            ? 1
                                            : !sa.teacherGrade
                                            ? 1
                                            : 0,
                                    correct: sa.teacherGrade && sa.teacherGrade === "correct" ? 1 : 0,
                                    incorrect: sa.teacherGrade && sa.teacherGrade === "incorrect" ? 1 : 0,
                                },
                            };

                            if (answ.exerciseType === "verbTraining") {
                                studentReviewAnswer = {
                                    ...studentReviewAnswer,
                                    verbName: sa.verbName,
                                    conjugationName: sa.conjugationName,
                                };
                            }

                            // If current word doesn't exist in the model, then add it
                            if (!questionCurrAnsw.hasOwnProperty(sa.id)) {
                                questionCurrAnsw[sa.id] = [studentReviewAnswer];
                            } else {
                                // If word exists, then add owner, otherwise, add it to the model.
                                const foundAnswer = questionCurrAnsw[sa.id].find(
                                    (i) => i.studentAnswer === sa.studentAnswer?.trim()
                                );
                                if (foundAnswer) {
                                    foundAnswer.answerOwner!.push(currentAnswerOwner);
                                    if (sa.teacherGrade) {
                                        if (!foundAnswer.gradeCounts) {
                                            foundAnswer.gradeCounts = {
                                                "not-graded": 0,
                                                correct: 0,
                                                incorrect: 0,
                                            };
                                        }
                                        foundAnswer.gradeCounts![sa.teacherGrade as string] += 1;
                                    }
                                } else {
                                    questionCurrAnsw[sa.id].push(studentReviewAnswer);
                                }
                            }
                        });
                }
            });
        }
    });

    // Ensuring consistency in grading when they're changed individually.
    Object.keys(jointData).forEach((jointDataKey) => {
        let dataAt = jointData[jointDataKey];

        if (dataAt.answers) {
            Object.keys(dataAt.answers).forEach((answKey) => {
                jointData[jointDataKey].answers[answKey].forEach((indAnsw, i) => {
                    if (indAnsw.gradeCounts) {
                        if (indAnsw.gradeCounts["correct"] === 0 && indAnsw.gradeCounts["incorrect"] === 0) {
                            return;
                        }

                        if (
                            indAnsw.gradeCounts["correct"] > indAnsw.gradeCounts["incorrect"] &&
                            indAnsw.teacherGrade === "incorrect"
                        ) {
                            jointData[jointDataKey].answers[answKey][i].teacherGrade = "correct";
                        } else if (
                            indAnsw.gradeCounts["incorrect"] > indAnsw.gradeCounts["correct"] &&
                            indAnsw.teacherGrade === "correct"
                        ) {
                            jointData[jointDataKey].answers[answKey][i].teacherGrade = "incorrect";
                        }
                    }
                });
            });
        }
    });

    const orderedJointData = Object.keys(jointData)
        .sort()
        .reduce((r: any, k) => {
            r[k] = jointData[k];
            return r;
        }, {});

    return orderedJointData;
};

// Adds grade properties and flattens/creates the studentAnswers array on new answers comming from the
// students view.
export const processNewResultsArray = function (results: Array<any>): Array<StudentTestAnswer> {
    let resultArray: Array<StudentTestAnswer> = [];
    results.forEach((data) => {
        for (let questionAnswersKey in data.questionAnswers) {
            let stdAnswers = data.questionAnswers[questionAnswersKey].studentAnswers || [];

            // add grade property to each answer, watch for special cases
            let emptyGradeProperties = {
                teacherGrade: "not-graded" as Grade,
                systemSuggestedGrade: "not-graded" as Grade,
                teacherIndComment: "",
                teacherComment: "",
            };
            switch (data.questionAnswers![questionAnswersKey]!.exerciseType) {
                case "jumbledWords":
                    if (!stdAnswers || !stdAnswers[0]) {
                        data.questionAnswers[questionAnswersKey].studentAnswers = [
                            {
                                id: "jw",
                                question: "jw",
                                studentAnswer: data.questionAnswers[questionAnswersKey].jumbledWordsAnswer,
                                ...emptyGradeProperties,
                            },
                        ];
                    }

                    break;
                case "textOnly":
                    if (!stdAnswers || !stdAnswers[0]) {
                        data.questionAnswers[questionAnswersKey].studentAnswers = [
                            {
                                id: "tO",
                                question: "tO",
                                studentAnswer: data.questionAnswers[questionAnswersKey].textAnswer,
                                ...emptyGradeProperties,
                            },
                        ];
                    }
                    break;
                case "verbTraining":
                    // Flattening the array
                    let verbTrainingFlatAnswers: Array<StudentAnswerReviewItem> = [];
                    stdAnswers.forEach((stdAnsw: any, index: any, stdArray: any) => {
                        stdAnsw.verbtrainingAnswers?.forEach((vtAnsw: any, vtIndex: any, verbAnswersArray: any) => {
                            if (vtAnsw.answer !== "") {
                                return;
                            }
                            verbTrainingFlatAnswers.push({
                                id: stdAnsw.id + "_" + vtAnsw.id,
                                verbName: stdAnsw.verbName,
                                conjugationName: vtAnsw.conjName,
                                ...emptyGradeProperties,
                                ...vtAnsw,
                            });
                        });
                    });
                    data.questionAnswers[questionAnswersKey].studentAnswers = [...verbTrainingFlatAnswers];
                    break;
                default:
                    stdAnswers.forEach((stdAnsw: any, index: any, stdArray: any) => {
                        stdArray[index] = {
                            ...emptyGradeProperties,
                            ...stdAnsw,
                        };
                    });
            }
        }

        resultArray.unshift(data);
    });
    return resultArray;
};
