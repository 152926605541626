import styled from "styled-components";
import DesignConstants from "../../constants/DesignConstants";

export const ExerciseNumber = styled.div<{ isHoveringRow: boolean }>`
    height: ${DesignConstants.SPACES.L};
    width: ${DesignConstants.SPACES.L};
    border-radius: ${DesignConstants.BORDER_RADIUS.XSMALL_CONTAINER};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: ${DesignConstants.FONT_SIZES.S};
    background: ${({ isHoveringRow }) => isHoveringRow && DesignConstants.COLORS.LIGHTEST_GREY};
`;

export const ToolTipWrapper = styled.div<{ totalCount: number }>`
    display: flex;
    height: ${DesignConstants.SPACES.L};
    align-items: center;
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    position: absolute;
    right: ${({ totalCount }) =>
        `calc(${DesignConstants.SPACES.L} * ${totalCount} + ${DesignConstants.SPACES.XS} * ${totalCount - 1})`};
    white-space: nowrap;
    padding: 0 ${DesignConstants.SPACES.XS};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        top: -${DesignConstants.SPACES.L};
        left: 0;
        right: auto;
        padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.XS} 0;
    }
`;

export const ToolTipText = styled.span`
    display: block;
    font-size: ${DesignConstants.FONT_SIZES.M};
    color: ${DesignConstants.COLORS.DARK_GREY};
`;

export const limitedWidth = `calc(${DesignConstants.SPACES.L} * 3 + ${DesignConstants.SPACES.XS} * 2)`; // this variable is also referenced in the StyledComponent.ts of the CardListingCardItem.tsx file, to ensure consistency across styles

export const ExercisesNumbersWrapper = styled.div<{ isLimitedToTwo?: boolean }>`
    display: flex;
    justify-content: end;
    gap: ${DesignConstants.SPACES.XS};
    position: relative;
    ${({ isLimitedToTwo }) =>
        isLimitedToTwo &&
        `
        min-width: ${limitedWidth}; // forces the flex container to occupy the predefined space, as width alone doesn't achieve this
        max-width: ${limitedWidth};
    `}

    height: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        justify-content: start;
    }

    &:hover {
        cursor: default;
    }

    &.disabled {
        cursor: not-allowed;
    }
`;
