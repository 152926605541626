import React from "react";
import { useT } from "@transifex/react";
import StyledButtonWithIcon from "../../../../../../basic/styledButtonWithIcon/StyledButtonWithIcon";

export interface AddVocabularyButtonProps {
    onClickAddVocabularyButton: () => void;
}

const AddVocabularyButton: React.FC<AddVocabularyButtonProps> = (props) => {
    const t = useT();
    const t_addWords = t("Add words", { _tags: "CreateTest,ExerciseDetails" });

    return (
        <div>
            <StyledButtonWithIcon
                buttonStyle={"BLANK"}
                icon={"add"}
                iconPosition={"LEFT"}
                onClick={props.onClickAddVocabularyButton}
            >
                {t_addWords}
            </StyledButtonWithIcon>
        </div>
    );
};

export default AddVocabularyButton;
