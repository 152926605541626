// LIBRARIES
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { T, useT, UT } from "@transifex/react";
import HighlightedAnswer from "../../basic/highlightedAnswer/HighlightedAnswer";
import { useStudentReviewContext } from "../../context/StudentReviewTestContext";
import StudentReviewGradeDisplay from "../../complex/studentReviewGradeDisplay/StudentReviewGradeDisplay";
import StudentReviewSummaryBox from "../../basic/studentReviewSummaryBox/StudentReviewSummaryBox";
import { getDateFromMilliseconds } from "../../helpers/DateTime";
import DownloadReviewPDF from "../result-pdf/StudentResultPDF";

import {
    Wrapper,
    TestNameWrapper,
    ExamContentWrapper,
    ExamContentRow,
    ExerciseTitleWrapper,
    TestName,
    StudentsCTAsWrapper,
    TextAnswerTitles,
    BasicAnswerTitles,
    ExerciseRowContent,
    AnswerTitle,
    QuestionTitle,
    ActionTitle,
    TextAnswerRowWrapper,
    BaseAnswerRowWrapper,
    BaseQuestionContent,
    BaseAnswerContent,
    TextAnswerContent,
    ActionsWrapper,
    ExerciseDescription,
    CommentTextArea,
    StudentOverallCommentText,
    StudentOverallCommentWrapper,
} from "./StyledComponents";

export interface StudentReviewProps {}

const StudentReview: React.FC<StudentReviewProps> = (props) => {
    const { shareId } = useParams<{ shareId: string }>();
    const { setShareId, resultData, teacherTestData, linkExpDate, dataLoaded } = useStudentReviewContext();

    useEffect(() => {
        if (shareId) {
            setShareId(shareId);
        }
    }, [shareId, setShareId]);

    const t = useT();
    const t_question = t("Question", { _tags: "StudentReview" });
    const t_answer = t("Answer", { _tags: "StudentReview" });
    const t_action = t("Actions", { _tags: "StudentReview" });
    const t_noQuestion = t("<< no question >>", { _tags: "StudentReview" });
    const t_noAnswer = t("<< empty answer >>", { _tags: "StudentReview" });
    const t_words = t("Words:", { _tags: "StudentReview" });
    const t_studentOverallComment = t("Additional Note from your Teacher", { _tags: "StudentReview" });
    const t_notFound = t("Sharing result was not found.", { _tags: "StudentReview" });

    let exercisesContent: Array<JSX.Element> = [];

    function getTextAnswerTitle(key: string) {
        return (
            <TextAnswerTitles key={key}>
                <AnswerTitle className={"big"}>{t_answer}</AnswerTitle>
                <ActionTitle>{t_action}</ActionTitle>
            </TextAnswerTitles>
        );
    }

    function getBasicTitle(key: string) {
        return (
            <BasicAnswerTitles key={key}>
                <QuestionTitle>{t_question}</QuestionTitle>
                <AnswerTitle>{t_answer}</AnswerTitle>
                <ActionTitle>{t_action}</ActionTitle>
            </BasicAnswerTitles>
        );
    }

    if (resultData.questionAnswers) {
        Object.keys(resultData.questionAnswers)
            .sort()
            .forEach((eId, i) => {
                const exerciseAnswer = resultData.questionAnswers![eId];

                let rowContent: Array<JSX.Element> = [];

                switch (exerciseAnswer.exerciseType) {
                    case "textOnly":
                        let stdAnsws = exerciseAnswer.studentAnswers;
                        if (stdAnsws && stdAnsws[0]) {
                            let stdAnsw = stdAnsws[0];
                            rowContent.push(getTextAnswerTitle("title_" + eId));
                            rowContent.push(
                                <TextAnswerRowWrapper key={"only_" + stdAnsw.id}>
                                    <TextAnswerContent>
                                        <HighlightedAnswer grade={stdAnsw.teacherGrade}>
                                            {stdAnsw.studentAnswer || t_noAnswer}
                                        </HighlightedAnswer>
                                    </TextAnswerContent>
                                    <ActionsWrapper>
                                        <StudentReviewGradeDisplay
                                            grade={stdAnsw.teacherGrade}
                                            teacherComment={stdAnsw.teacherComment}
                                            teacherIndComment={stdAnsw.teacherIndComment}
                                        />
                                    </ActionsWrapper>
                                </TextAnswerRowWrapper>
                            );
                        }
                        break;
                    case "jumbledWords":
                        let jumbledAnswers = exerciseAnswer.studentAnswers;
                        if (jumbledAnswers && jumbledAnswers[0]) {
                            let jumbledAnswer = jumbledAnswers[0];
                            rowContent.push(getTextAnswerTitle("title_" + eId));
                            rowContent.push(
                                <TextAnswerRowWrapper key={"jumbl_" + jumbledAnswer.id}>
                                    <TextAnswerContent>
                                        <HighlightedAnswer grade={jumbledAnswer.teacherGrade}>
                                            {jumbledAnswer.studentAnswer || t_noAnswer}
                                        </HighlightedAnswer>
                                    </TextAnswerContent>
                                    <ActionsWrapper>
                                        <StudentReviewGradeDisplay
                                            grade={jumbledAnswer.teacherGrade}
                                            teacherComment={jumbledAnswer.teacherComment}
                                            teacherIndComment={jumbledAnswer.teacherIndComment}
                                        />
                                    </ActionsWrapper>
                                </TextAnswerRowWrapper>
                            );
                        }
                        break;
                    default:
                        rowContent.push(getBasicTitle("title" + eId));
                        exerciseAnswer.studentAnswers?.forEach((sa, index) => {
                            let QuestionTextComponent;
                            switch (exerciseAnswer.exerciseType) {
                                case "verbTraining":
                                    QuestionTextComponent = (
                                        <BaseQuestionContent>
                                            {sa.verbName} ({sa.conjugationName})
                                        </BaseQuestionContent>
                                    );
                                    break;
                                case "fillInTheGap":
                                    QuestionTextComponent = (
                                        <BaseQuestionContent>
                                            [{sa.question}]
                                            <br />
                                            {sa.gap_sentence}
                                        </BaseQuestionContent>
                                    );
                                    break;
                                default:
                                    QuestionTextComponent = (
                                        <BaseQuestionContent>
                                            {sa.answer ? sa.answer : sa.question || t_noQuestion}
                                        </BaseQuestionContent>
                                    );
                            }
                            rowContent.push(
                                <BaseAnswerRowWrapper key={"row_" + sa.id + "_" + index}>
                                    {QuestionTextComponent}
                                    <BaseAnswerContent>
                                        <HighlightedAnswer grade={sa.teacherGrade}>
                                            {sa.studentAnswer || t_noAnswer}
                                        </HighlightedAnswer>
                                    </BaseAnswerContent>
                                    <ActionsWrapper>
                                        <StudentReviewGradeDisplay
                                            grade={sa.teacherGrade}
                                            teacherComment={sa.teacherComment}
                                            teacherIndComment={sa.teacherIndComment}
                                        />
                                    </ActionsWrapper>
                                </BaseAnswerRowWrapper>
                            );
                        });
                }

                let exerciseDesc = "";
                let exerciseExtraInfo: string = "";
                let exerciseStarterText = "";
                let exerciseNum: number = Number(eId.replace("e", ""));
                if (teacherTestData && teacherTestData.content && teacherTestData.content[exerciseNum - 1]) {
                    exerciseDesc = teacherTestData.content[exerciseNum - 1].extraComments || "";

                    if (
                        teacherTestData.content[exerciseNum - 1].questionMode === "jumbledWords" &&
                        teacherTestData.content[exerciseNum - 1].fillWords
                    ) {
                        exerciseExtraInfo +=
                            t_words + " " + (teacherTestData.content[exerciseNum - 1].fillWords?.join(" || ") || "");
                    }

                    if (teacherTestData.content[exerciseNum - 1].textFieldStarterText) {
                        exerciseStarterText = teacherTestData.content[exerciseNum - 1].textFieldStarterText || "";
                    }
                }

                exercisesContent.push(
                    <ExamContentRow key={eId}>
                        <ExerciseTitleWrapper>
                            <T
                                _str="Exercise {exerciseNo}"
                                exerciseNo={exerciseNum}
                                _inline
                                _tags="StudentReview"
                            />
                        </ExerciseTitleWrapper>
                        <ExerciseDescription>
                            {exerciseDesc}
                            {exerciseExtraInfo && <br />}
                            {exerciseExtraInfo && exerciseExtraInfo}
                            {exerciseStarterText && <br />}
                            {exerciseStarterText}
                        </ExerciseDescription>
                        <ExerciseRowContent>{rowContent}</ExerciseRowContent>
                    </ExamContentRow>
                );
            });
    }

    if (dataLoaded === "NOT_FOUND") {
        return (
            <Wrapper>
                <p>{t_notFound}</p>
            </Wrapper>
        );
    }

    return (
        <>
            <Wrapper>
                <TestNameWrapper>
                    <TestName>
                        <UT
                            _str="Results for: {testName}."
                            testName={teacherTestData.name || ""}
                            _inline
                            _tags="StudentReview"
                        />
                    </TestName>
                </TestNameWrapper>
                <ExamContentWrapper>{exercisesContent}</ExamContentWrapper>

                <StudentReviewSummaryBox resultData={resultData} />

                <StudentOverallCommentWrapper key={"overall_comment"}>
                    <StudentOverallCommentText>{t_studentOverallComment}</StudentOverallCommentText>
                    <CommentTextArea
                        value={resultData.teacherComment || ""}
                        readOnly
                    />
                    {linkExpDate && (
                        <StudentOverallCommentText>
                            <UT
                                _str="The results link expires at the {expDate}. Download the result now as PDF to secure access to your result"
                                expDate={getDateFromMilliseconds(linkExpDate)}
                                _inline
                                _tags="StudentReview"
                            />
                        </StudentOverallCommentText>
                    )}
                </StudentOverallCommentWrapper>
            </Wrapper>

            <StudentsCTAsWrapper>
                {dataLoaded === "LOADED" && resultData && teacherTestData && (
                    <DownloadReviewPDF
                        resultData={resultData}
                        teacherTestData={teacherTestData}
                    />
                )}
            </StudentsCTAsWrapper>
        </>
    );
};

export default StudentReview;
