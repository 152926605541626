import styled from "styled-components/macro";

export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
    }
`;

export const ExerciseTypeContainer = styled.div`
    width: 90%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 95%;
    }
`;
