import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as NextArrow } from "../../../assets/icons/skip-card.svg";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";
import { useT } from "@transifex/react/dist";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";

export interface ArrowButtonProps {
    className?: string;
    currentIndex?: number;
    answersLength?: number;
    focusSwitchCallback?: (nextIndex: number) => any;
    endInputCallback?: () => any;
}

const BaseArrowCss = css`
    width: 2rem;
    cursor: pointer;
`;

const InputControlAreaWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: fixed;
    width: 80%;
    z-index: 3;
`;

const UpArrow = styled(SvgIcon)`
    ${BaseArrowCss};
    transform: rotate(-90deg);
`;
const DownArrow = styled(SvgIcon)`
    ${BaseArrowCss};
    transform: rotate(90deg);
`;

const ArrowWrapper = styled.div`
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #f6f6f6;
    box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
    border: solid 1px rgb(0 0 0 /10%);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FinishInputBtn = styled.button`
    border-radius: 2rem;
    width: 70%;
    padding: 0.5rem;
    border: none;
    background: #f6f6f6;
    box-shadow: 0 2px 0 rgb(0 0 0 / 10%);
    z-index: 2;
`;

const FinishInputTextWrapper = styled.span``;
const InputControlArea: React.FC<ArrowButtonProps> = (props) => {
    const t = useT();
    const t_finishInput = t("Finish Input", { _tags: "finishInputBtn,practice" });

    // const [currentYOffset, setCurrentYOffset] = useState(0);
    // const [currentInnerHeight, setCurrentInnerHeight] = useState(0);
    // const [previousInnerHeight, setPreviousInnerHeight] = useState(0);
    const InputControlAreaRef = useRef<HTMLDivElement>(null);

    const { mobileScreenResolutions, setMobileScreenResolutions } = useStudentPracticeContext();

    // console.log("rerendered", window.innerHeight);

    // const scrollListener = function() {
    //     setCurrentYOffset(window.pageYOffset);
    // }
    const heightListener = function () {
        // setPreviousInnerHeight(currentInnerHeight);
        // setCurrentInnerHeight(window.innerHeight);
        let innerHeight = window.innerHeight;
        if (innerHeight > 0 && !mobileScreenResolutions.includes(innerHeight)) {
            // console.log([innerHeight, ...mobileScreenResolutions])
            setMobileScreenResolutions([innerHeight, ...mobileScreenResolutions]);
        }
    };

    // useEffect(() => {
    //     // console.log("resize! " + "current " + window.innerHeight, " array", mobileScreenResolutions);
    //     // console.log("bool: ", Number(currentInnerHeight));
    //     // if (Number(previousInnerHeight) > 0 && Number(previousInnerHeight) < Number(currentInnerHeight)) {
    //     //     console.log("try to end input")
    //     //     endInput()
    //     // }
    //
    //     if (window.innerHeight > Math.min(...mobileScreenResolutions)) {
    //         console.log("try to end input")
    //         endInput()
    //     }
    // }, [mobileScreenResolutions])

    const endInput = function () {
        InputControlAreaRef.current?.blur();
        if (props.endInputCallback) {
            props.endInputCallback();
        }
    };

    // Adding and removing the listeners on load/unload
    useEffect(() => {
        // window.addEventListener('scroll', scrollListener)
        window.addEventListener("resize", heightListener);
        // scrollListener()
        heightListener();
        return () => {
            // window.removeEventListener('scroll', scrollListen er)
            window.removeEventListener("resize", heightListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Updating the button position on resize, scroll
    useEffect(() => {
        if (InputControlAreaRef.current && window.innerHeight > 0) {
            // console.log("chaging height of button", InputControlAreaRef.current.style.top)
            // InputControlAreaRef.current.style.top = `${currentYOffset + currentInnerHeight - 15 - InputControlAreaRef.current.clientHeight}px`;
            InputControlAreaRef.current.style.top = `${
                window.innerHeight - 15 - InputControlAreaRef.current.clientHeight
            }px`;
            // InputControlAreaRef.current.style.top = `calc(100vh - ${15 + InputControlAreaRef.current.clientHeight}px`;
            // console.log("chaging height of button", InputControlAreaRef.current.style.bottom)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [InputControlAreaRef.current, window.innerHeight]);

    const { currentIndex, answersLength, focusSwitchCallback } = props;
    const shouldUseArrows = !!props.focusSwitchCallback && currentIndex !== undefined && answersLength !== undefined;

    const handleClickOnArrow = function (goTo: "next" | "previous") {
        if (shouldUseArrows) {
            const indexRequested = currentIndex! + (goTo === "next" ? 1 : -1);
            focusSwitchCallback!(indexRequested);
        }
    };

    return (
        <InputControlAreaWrapper ref={InputControlAreaRef}>
            {shouldUseArrows && currentIndex! !== 0 && (
                <ArrowWrapper onClick={() => handleClickOnArrow("previous")}>
                    <UpArrow svgComponent={NextArrow} />
                </ArrowWrapper>
            )}

            <FinishInputBtn onClick={endInput}>
                <FinishInputTextWrapper>{t_finishInput}</FinishInputTextWrapper>
            </FinishInputBtn>

            {shouldUseArrows && currentIndex! !== answersLength! - 1 && (
                <ArrowWrapper onClick={() => handleClickOnArrow("next")}>
                    <DownArrow svgComponent={NextArrow} />
                </ArrowWrapper>
            )}
        </InputControlAreaWrapper>
    );
};

export default InputControlArea;
