import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";

export const StandardWrapper = styled.div`
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    display: flex;
    flex-direction: column;
    background-color: white;
`;

export const StandardWordRow = styled.div`
    width: 100%;
    display: flex;
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.LIGHTEST_GREY};

    &:last-child {
        border-bottom: none;
    }
`;

export const WordWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.S};
    border-right: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.LIGHTEST_GREY};
    cursor: pointer;
`;

export const QuestionAndAnswer = styled.div`
    display: flex;
    flex: 1;
`;

export const Word = styled.div`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    font-size: ${DesignConstants.FONT_SIZES.M};
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.XS};
    border-top: ${DesignConstants.BORDERS.MEDIUM} white;
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} white;

    &.blur {
        opacity: ${DesignConstants.OPACITY.HALF};
        font-weight: ${DesignConstants.FONT_WEIGHTS.NORMAL};
        margin-right: ${DesignConstants.SPACES.S};
        border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.MID_GREY};
        flex: 1;
    }
`;

export const WordIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    cursor: pointer;
`;

export const WordControl = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.M};
`;
