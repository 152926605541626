import { InternalAxiosRequestConfig } from "axios";
import { cp } from "../../config";

export default function openCmsApiAuthTokenInterceptor(
    request: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
    if (request.url?.indexOf("api_key") === -1) {
        request.url += (request.url.indexOf("?") === -1 ? "?" : "&") + "api_key=" + cp.cfg.REACT_APP_API_KEY;
    }

    return request;
}
