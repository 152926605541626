import styled from "styled-components";
import FlagIcon from "../../../../../basic/flagIcon/FlagIcon";
import PhaseSixIcon from "../../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../../constants/DesignConstants";

export const DirectionSelectorWrapper = styled.div`
    background: white;
    display: flex;
    cursor: pointer;
    align-items: center;
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.MID_GREY};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    justify-content: space-around;
    align-self: flex-start;
    border-radius: ${DesignConstants.SPACES.XL};
    width: fit-content;
    margin: 0 ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        margin-bottom: ${DesignConstants.SPACES.XL};
        border: none;
    }
`;

export const StyledFlagIcon = styled(FlagIcon)`
    color: ${DesignConstants.COLORS.DARK_GREY};
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM};

    svg {
        width: ${DesignConstants.SPACES.M};
        margin-right: 8px;
        border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    }
`;

export const StyledPhase6Icon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    margin-top: 1px;
`;

export const DirectionItem = styled.div`
    width: auto;
    text-align: center;
    margin: 0 auto;
    padding: 0 ${DesignConstants.SPACES.M};
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM};
    font-size: ${DesignConstants.FONT_SIZES.L};
    height: ${DesignConstants.SIZES.ICON_XS};

    &.selected {
        border-radius: ${DesignConstants.SPACES.XL};
        background: white;
        border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.DARK_GREY};
    }
`;
