import { View } from "@react-pdf/renderer";
import React from "react";
import PDFEmptyLines from "../pdfSubComponents/PDFEmptyLines";
import PDFWordBox from "../pdfSubComponents/PDFWordBox";
import { TestDetailsItem } from "p6m-p6u";
import PDFTable from "../pdfSubComponents/PDFTable";
import { sanitizePDFText } from "../../../helpers/PDFHelper";
import { ExerciseDirections } from "../../../enums/directions";

interface PDFCreateTextExerciseProps {
    exercise: TestDetailsItem;
    isSolution?: boolean;
}

const PDFCreateTextExercise: React.FC<PDFCreateTextExerciseProps> = (props) => {
    const renderCreateTextExercise = () => {
        const selectedLearningWords =
            props.exercise.selectedWords?.map((w) =>
                "id" in w && w.id
                    ? sanitizePDFText(
                          props.exercise.direction === ExerciseDirections.ANSWER_TO_QUESTION ? w.answer : w.question
                      )
                    : ""
            ) ?? [];

        return (
            <View>
                <PDFWordBox words={selectedLearningWords} />
                <PDFEmptyLines
                    // one line per each 3 words but max 6 lines
                    linesAmount={Math.min(Math.ceil(selectedLearningWords.length / 3), 6)}
                />
            </View>
        );
    };

    return !props.isSolution ? (
        renderCreateTextExercise()
    ) : (
        <PDFTable
            selectedWords={props.exercise?.selectedWords ?? []}
            showAllQuestions
            showAllAnswers
        />
    );
};

export default PDFCreateTextExercise;
