// LIBRARIES
import React from "react";
import styled from "styled-components";

export interface ExerciseListingSideIconProps {
    isLast: boolean;
    isListClosed: boolean;
    hasMoreThanOneItem: boolean;
    className?: string;
}

const ExerciseIconWrapper = styled.div`
    width: 2rem;
    height: 3rem;
    display: flex;
    align-items: center;
    position: relative;
`;

const ExerciseIconLine = styled.div`
    width: 1px;
    height: 100%;
    border-left: solid 1px #888;
    position: absolute;
    left: calc(50% - 1px);
    top: 0;

    &.half-line {
        height: 50%;
    }
`;

const ExerciseIconLowerDashedLine = styled.div`
    width: 1px;
    height: 50%;
    border-left: dashed 1px #888;
    margin: 1.5rem auto 0;
    position: absolute;
    left: calc(50% - 1px);
    bottom: 0;
`;

const ExerciseIconCircle = styled.div`
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    border: solid 1px #888;
    border-radius: 50%;
    background: white;
`;

const ExerciseListingSideIcon: React.FC<ExerciseListingSideIconProps> = (props) => {
    const { isLast, isListClosed, hasMoreThanOneItem } = props;

    return (
        <ExerciseIconWrapper className={props.className || ""}>
            {isListClosed && hasMoreThanOneItem && <ExerciseIconLowerDashedLine />}
            <ExerciseIconLine className={isLast || (isListClosed && hasMoreThanOneItem) ? "half-line" : ""} />
            <ExerciseIconCircle />
        </ExerciseIconWrapper>
    );
};

export default ExerciseListingSideIcon;
