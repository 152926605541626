import * as React from "react";
import styled from "styled-components";
import StyledButton, { StyledButtonProps } from "../styledButton/StyledButton";
import PhaseSixIcon, { TIconNames } from "../phaseSixIcon/PhaseSixIcon";

export interface StyledButtonWithIconProps {
    className?: string;
    iconPosition: "LEFT" | "RIGHT";
    children?: React.ReactNode;
    icon: TIconNames;
    iconColor?: string;
}

const ContentWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 5px;
    gap: 5px;

    &.LEFT {
        flex-direction: row-reverse;
    }
`;

const ChildrenWrapper = styled.div`
    width: 100%;
`;

const IconWrapper = styled.div`
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const StyledIcon = styled(PhaseSixIcon)`
    font-size: 1.5rem;
`;

const StyledButtonWithIcon: React.FC<
    StyledButtonWithIconProps & StyledButtonProps & React.HTMLAttributes<HTMLButtonElement>
> = (props) => {
    let { children, iconPosition, iconColor, ...styledBtnProps } = props;
    styledBtnProps.arrowDirection = "NONE";

    if (!iconColor && props.buttonStyle === "ORANGE_BLANK") {
        iconColor = "#FC7C00";
    }

    return (
        <StyledButton {...styledBtnProps}>
            <ContentWrapper className={`${iconPosition}`}>
                <ChildrenWrapper>{children}</ChildrenWrapper>
                <IconWrapper>
                    <StyledIcon
                        name={props.icon}
                        color={iconColor}
                    />
                </IconWrapper>
            </ContentWrapper>
        </StyledButton>
    );
};

export default StyledButtonWithIcon;
