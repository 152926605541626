// LIBRARIES
import React from "react";
import { useLocation } from "react-router-dom";
import { useGeneralContext } from "../../context/AppGeneralContext";
import { Header, MainWrapper, AnonymousPlaceholderTop, BodyWrapper, StyledLogo } from "./StyledComponents";

const ContentWithHeader: React.FunctionComponent<any> = ({ children }) => {
    const location = useLocation();
    const { isIframeMode } = useGeneralContext();

    const shouldUseGrayBackground = location.pathname.includes("/create");

    return (
        <MainWrapper className={`${shouldUseGrayBackground ? "gray" : ""} ${isIframeMode ? "fill" : ""}`}>
            {!isIframeMode ? (
                <Header id={"aea-header"}>
                    <StyledLogo />
                </Header>
            ) : (
                <AnonymousPlaceholderTop />
            )}
            <BodyWrapper isIframeMode={isIframeMode}>{children}</BodyWrapper>
        </MainWrapper>
    );
};

export default ContentWithHeader;
