import * as React from "react";
import styled from "styled-components/macro";
import SvgIcon from "../svgIcon/SvgIcon";

// Flags
import { ReactComponent as DanishFlag } from "../../assets/flags/danish.svg";
import { ReactComponent as DutchFlag } from "../../assets/flags/dutch.svg";
import { ReactComponent as EnglishFlag } from "../../assets/flags/english.svg";
import { ReactComponent as FinnishFlag } from "../../assets/flags/finnish.svg";
import { ReactComponent as FrenchFlag } from "../../assets/flags/french.svg";
import { ReactComponent as GermanFlag } from "../../assets/flags/german.svg";
import { ReactComponent as GreekFlag } from "../../assets/flags/greek.svg";
import { ReactComponent as ItalianFlag } from "../../assets/flags/italian.svg";
import { ReactComponent as LatinFlag } from "../../assets/flags/latin.svg";
import { ReactComponent as NorwegianFlag } from "../../assets/flags/norwegian.svg";
import { ReactComponent as PolishFlag } from "../../assets/flags/polish.svg";
import { ReactComponent as PortugueseFlag } from "../../assets/flags/portuguese.svg";
import { ReactComponent as RussianFlag } from "../../assets/flags/russian.svg";
import { ReactComponent as SpanishFlag } from "../../assets/flags/spanish.svg";
import { ReactComponent as SwedishFlag } from "../../assets/flags/swedish.svg";
import { ReactComponent as TurkishFlag } from "../../assets/flags/turkish.svg";
import { ReactComponent as UkranianFlag } from "../../assets/flags/ukranian.svg";
import { ReactComponent as AncientGreekFlag } from "../../assets/flags/ancientgreek.svg";
import { ReactComponent as ArabicFlag } from "../../assets/flags/arabic.svg";
import { ReactComponent as BrasilFlag } from "../../assets/flags/brasilianisches portugiesisch.svg";
import { ReactComponent as ChineseFlag } from "../../assets/flags/chinese.svg";
import { ReactComponent as Other } from "../../assets/flags/other.svg";
import { useT } from "@transifex/react";

export interface FlagIconProps {
    languageName: string;
    color?: string;
    className?: string;
    hideName?: boolean;
}

const StyledFlagIcon = styled(SvgIcon)`
    height: 30px;
    margin-right: 0.8rem;
    border-radius: 6px;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin: 0;
        border-radius: 10px;
    }
`;

const TestLanguageWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        align-items: center;
        margin-left: 0.5rem;
    }
`;

const LanguageName = styled.span`
    font-size: 1.1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        font-size: 1.3rem;
        margin-left: 0.5rem;
    }
`;

const FlagIcon: React.FC<FlagIconProps> = (props) => {
    const t = useT();
    const t_other = t("Other", { _tags: "CreateTest,FlagIcon" });

    let selectedFlag;

    let langName = props.languageName;

    // if (langName === "D&auml;nisch") {
    //     langName = "Dänisch";
    // } else if (langName === "T&uuml;kisch") {
    //     langName = "Türkisch";
    // } else if (langName === "Franz&ouml;sisch") {
    //     langName = "Französisch";
    // }

    switch (langName) {
        case "Dänisch":
        case "da":
            langName = "Dänisch";
            selectedFlag = DanishFlag;
            break;
        case "Deutsch":
        case "de":
            langName = "Deutsch";
            selectedFlag = GermanFlag;
            break;
        case "Englisch":
        case "en":
            langName = "Englisch";
            selectedFlag = EnglishFlag;
            break;
        case "Finnisch":
        case "fi":
            langName = "Finnisch";
            selectedFlag = FinnishFlag;
            break;
        case "Französisch":
        case "fr":
            langName = "Französisch";
            selectedFlag = FrenchFlag;
            break;
        case "Griechisch":
        case "el":
            langName = "Griechisch";
            selectedFlag = GreekFlag;
            break;
        case "Italienisch":
        case "it":
            langName = "Italienisch";
            selectedFlag = ItalianFlag;
            break;
        case "Lateinisch":
        case "la":
            langName = "Lateinisch";
            selectedFlag = LatinFlag;
            break;
        case "Niederländisch":
        case "nl":
            langName = "Niederländisch";
            selectedFlag = DutchFlag;
            break;
        case "Norwegisch":
        case "no":
            langName = "Norwegisch";
            selectedFlag = NorwegianFlag;
            break;
        case "Polnisch":
        case "pl":
            langName = "Polnisch";
            selectedFlag = PolishFlag;
            break;
        case "Portugiesisch":
        case "pt":
            langName = "Portugiesisch";
            selectedFlag = PortugueseFlag;
            break;
        case "Russisch":
        case "ru":
            langName = "Russisch";
            selectedFlag = RussianFlag;
            break;
        case "Schwedisch":
        case "sv":
            langName = "Schwedisch";
            selectedFlag = SwedishFlag;
            break;
        case "Spanisch":
        case "es":
            langName = "Spanisch";
            selectedFlag = SpanishFlag;
            break;
        case "Türkisch":
        case "tr":
            langName = "Türkisch";
            selectedFlag = TurkishFlag;
            break;
        case "Ukrainisch":
        case "uk":
            langName = "Ukrainisch";
            selectedFlag = UkranianFlag;
            break;
        case "Brasilianisches Portugiesisch":
            langName = "Brasilianisches Portugiesisch";
            selectedFlag = BrasilFlag;
            break;
        case "Arabisch":
        case "ar":
            langName = "Arabisch";
            selectedFlag = ArabicFlag;
            break;
        case "Alt-Griechisch":
        case "ag":
            langName = "Alt-Griechisch";
            selectedFlag = AncientGreekFlag;
            break;
        case "Chinesisch":
        case "zh":
            langName = "Chinesisch";
            selectedFlag = ChineseFlag;
            break;
        default:
            selectedFlag = Other;
            langName = t_other;
    }

    return (
        <TestLanguageWrapper className={props.className}>
            <StyledFlagIcon svgComponent={selectedFlag} />
            {props.hideName ? null : <LanguageName>{langName}</LanguageName>}
        </TestLanguageWrapper>
    );
};

export default FlagIcon;
