import React from "react";
import { useT } from "@transifex/react";
import { useHistory } from "react-router-dom";
import { useTestCreationContext } from "../../../context/TestCreationContext";

import AccordionTab from "../../../basic/accordionTab/AccordionTab";

interface SummaryVocabularyAccordionProps {}

const SummaryVocabularyAccordion: React.FC<SummaryVocabularyAccordionProps> = (props) => {
    const { vocabularyDrawerContent } = useTestCreationContext();

    const history = useHistory();

    const t = useT();

    const t_ChooseVocabulary = t("Choose vocabulary:", { _tags: "CreateTest,Tab" });
    const t_WhatToPractice = t("What should be practised?", { _tags: "CreateTest,Tab" });

    const availableVocabulary = Object.values(vocabularyDrawerContent).length || 0;

    return (
        <AccordionTab
            mainIconName={"vocabulary-list-unset"}
            title={t_ChooseVocabulary}
            description={t_WhatToPractice}
            counterValue={`(${availableVocabulary})`}
            onClickEdit={() => {
                history.push("/create/select-vocabulary");
            }}
        />
    );
};

export default SummaryVocabularyAccordion;
