import React, { ReactElement, useEffect, useState } from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useBookContext } from "../../../context/BookContext";
import { useHistory } from "react-router-dom";
import UnitAndCardsWrapper from "../../../complex/create/unitAndCardsWrapper/UnitAndCardsWrapper";
import { useT, UT } from "@transifex/react";
import StyledButton from "../../../basic/styledButton/StyledButton";
import CtasWrapper from "../../../wrapper/create/ctasWrapper/CtasWrapper";
import DeleteCardWarningModal from "../../../basic/create/deleteCardWarningModal/DeleteCardWarningModal";
import CreateInfoModal from "../../../basic/create/InfoModal/CreateInfoModal";
import AccordionTab from "../../../basic/accordionTab/AccordionTab";
import { CoverImage } from "p6-react-shared";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import UserContentCover from "../../../basic/userContentCover/UserContentCover";
import SelectSearch, { fuzzySearch } from "react-select-search";
import SelectBookVocabularyRT from "../selectBookVocabularyRT/SelectBookVocabularyRT";
import ExerciseSheetTitle from "../../../basic/create/ExerciseSheetTitle/ExerciseSheetTitle";
import {
    GeneralWrapper,
    SelectedSourceWrapper,
    SelectBookVocabularyWrapper,
    BookHeaderInfo,
    BookImageWrapper,
    BookNameWrapper,
    BookName,
    ExerciseToSpan,
    UnitsWrapper,
    BookWrapper,
    Line,
    DifferentSourceWrapper,
    PleaseChooseVocabulary,
    SmallSelectWrapper,
} from "./StyledComponents";

import { StickyWrapper } from "../../../basic/accordionTab/StyledComponents";

import { SourceType } from "../../../enums/sources";
import { UserSubject } from "p6m-p6u";

export interface SelectBookVocabularyProps {}

const SelectBookVocabulary: React.FC<SelectBookVocabularyProps> = (props) => {
    const {
        selectedBookData,
        loadVocabularyForBand,
        selectedBandUnits,
        removeCardsFromSelectedWords,
        availableCards,
        vocabularyDrawerContent,
        openWarningModal,
        testContent,
        selectedUserSubject,
        userOwnSubjects,
        userUnitCards,
        userOwnSubjectUnits,
        shouldSubjectSelectionBeDisabled,
        setShouldSubjectSelectionBeDisabled,
        loadUserUnitsForSubject,
        removeContentFromVocabularyDrawer,
        unitsToPreloadIds,
    } = useTestCreationContext();

    const { lastSourceSelected, setLastSourceSelected } = useBookContext();

    const { creatingMode } = useGeneralContext();

    const hasAlreadySelectedABook = !!selectedBookData.band?.ID;
    const hasAlreadySelectedOwnContent = !!selectedUserSubject.id;
    const hasSelectedBoth = hasAlreadySelectedABook && hasAlreadySelectedOwnContent;

    const history = useHistory();

    const t = useT();
    const t_selectDifferentSource = t("Select different source", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_selectedBook = t("Selected book:", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_selectedContent = t("Selected source:", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_back = t("Back", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_chooseMinVocabulary = t("Please select at least 3 words", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_continue = t("Continue", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_confirm = t("Confirm Selection", { _tags: "CreateTest,SelectVocabularySource" });
    const t_ChooseVocabulary = t("Choose vocabulary:", { _tags: "CreateTest,Tab" });
    const t_WhatToPractice = t("What should be practised?", { _tags: "CreateTest,Tab" });
    const t_DesignExerciseUnit = t("Design exercise unit:", { _tags: "CreateTest,Tab" });
    const t_HowToPractice = t("How should be practised?", { _tags: "CreateTest,Tab" });
    const t_selectVocabulary = t("Please choose the vocabulary for your exercise sheet / your test.", {
        _tags: "CreateTest,SelectBookVocabulary",
    });

    const t_selectSubject = t("Select a Subject", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_noResults = t("No Results", { _tags: "CreateTest,SelectBookVocabulary" });

    const [currentWordForDeletion, setCurrentWordForDeletion] = useState("");
    const [isCurrentWordForDeletionModalOpen, setIsCurrentWordForDeletionModalOpen] = useState(false);

    const [currentUnitCardsForDeletion, setCurrentUnitCardsForDeletion] = useState<Array<string>>([]);
    const [isDeleteAllUnitCardsModalOpen, setIsDeleteAllUnitCardsModalOpen] = useState(false);

    const [currentSourceType, setCurrentSourceType] = useState<SourceType | undefined>(
        lastSourceSelected ?? SourceType.BOOK
    );

    const [selectedSubjectId, setSelectedSubjectId] = useState("");
    const [selectedSubject, setSelectedSubject] = useState<UserSubject>({});

    const loadVocabulary = async (bandId: string) => {
        await loadVocabularyForBand(bandId);
    };

    useEffect(() => {
        if (selectedBookData.band && selectedBookData.band.ID) {
            loadVocabulary(selectedBookData.band.ID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBookData]);

    useEffect(() => {
        if (selectedSubjectId) {
            if (selectedUserSubject.id !== selectedSubjectId && selectedSubjectId !== "") {
                removeContentFromVocabularyDrawer(creatingMode === "TEST" ? "OWN" : "ALL");
            }
            loadUserUnitsForSubject(selectedSubjectId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubjectId]);

    useEffect(() => {
        if (selectedUserSubject?.id && selectedUserSubject.id !== selectedSubjectId) {
            setSelectedSubjectId(selectedUserSubject.id);
            setSelectedSubject(selectedUserSubject);
        } else if (
            selectedSubjectId &&
            (userOwnSubjectUnits.length === 0 || userOwnSubjectUnits[0]?.subjectId !== selectedSubjectId)
        ) {
            loadUserUnitsForSubject(selectedSubjectId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserSubject]);

    function toggleSourceType(clickedSourceType: SourceType) {
        if (hasSelectedBoth) {
            setCurrentSourceType(clickedSourceType);
        }
    }
    const updateSubject = (selectedValue: any) => {
        setSelectedSubjectId(selectedValue as string);

        const currentSubject = userOwnSubjects.find((s) => s.id === selectedValue);
        if (currentSubject) {
            setSelectedSubject(currentSubject);
        }
    };

    function handleCreateExerciseBtnClick() {
        if (Object.keys(vocabularyDrawerContent).length >= 3) {
            setShouldSubjectSelectionBeDisabled(true);
            history.push("/create/summary");
        } else {
            openWarningModal(t_chooseMinVocabulary);
        }
    }

    function closeDeleteWordWarningModal() {
        setIsCurrentWordForDeletionModalOpen(false);
    }

    function openDeleteModalFn(wordId: string) {
        setCurrentWordForDeletion(wordId);
        setIsCurrentWordForDeletionModalOpen(true);
    }

    function openDeleteUnitCardsFn(cardsToDelete: Array<string>) {
        setCurrentUnitCardsForDeletion(cardsToDelete);
        setIsDeleteAllUnitCardsModalOpen(true);
    }

    function deleteCardsFromUnit() {
        removeCardsFromSelectedWords(currentUnitCardsForDeletion);
        setIsDeleteAllUnitCardsModalOpen(false);
    }

    function handleBackButton() {
        setLastSourceSelected(undefined);
        setShouldSubjectSelectionBeDisabled(true);
        history.push("/create/choose-source");
    }

    const renderSourceHeader = (
        image: ReactElement,
        sourceString: string,
        sourceName: string,
        sourceKind: SourceType
    ) => {
        return (
            <BookWrapper
                showBorder={sourceKind === currentSourceType}
                borderActive={hasSelectedBoth || (hasAlreadySelectedABook && currentSourceType === SourceType.OWN)}
                onClick={() => {
                    toggleSourceType(sourceKind);
                }}
            >
                {image}
                <BookNameWrapper>
                    <ExerciseToSpan>{sourceString}</ExerciseToSpan>
                    <BookName>{sourceName}</BookName>
                </BookNameWrapper>
            </BookWrapper>
        );
    };

    const renderSelectedBook = () => {
        const bookImage = (
            <BookImageWrapper>
                {selectedBookData.band?.Image && (
                    <CoverImage
                        imageId={"https://www.phase-6.de" + selectedBookData.band?.Image}
                        size={"small"}
                    />
                )}
            </BookImageWrapper>
        );
        return renderSourceHeader(
            bookImage,
            t_selectedBook,
            (selectedBookData?.publisherBook?.BookName ?? "") +
                " (" +
                (selectedBookData?.publisherBook?.PublisherName ?? "") +
                ") (" +
                (selectedBookData.band?.ShortName ?? "") +
                ")",
            SourceType.BOOK
        );
    };

    const renderSelectedOwn = () => {
        const userContentImage = (
            <BookImageWrapper>
                <UserContentCover
                    primaryLanguage={selectedUserSubject.id !== "" ? selectedUserSubject.primaryLang! : "other"}
                    secondaryLanguage={selectedUserSubject.id !== "" ? selectedUserSubject.secondaryLang : ""}
                    small
                />
            </BookImageWrapper>
        );
        return renderSourceHeader(userContentImage, t_selectedContent, selectedUserSubject.name ?? "", SourceType.OWN);
    };

    const renderOwnSelection = () => {
        if (hasAlreadySelectedOwnContent || lastSourceSelected === SourceType.OWN) {
            return (
                <BookWrapper
                    showBorder={SourceType.OWN === currentSourceType}
                    borderActive={hasSelectedBoth || (hasAlreadySelectedABook && currentSourceType === SourceType.OWN)}
                    onClick={() => {
                        toggleSourceType(SourceType.OWN);
                    }}
                >
                    <BookImageWrapper>
                        <UserContentCover
                            primaryLanguage={selectedSubjectId !== "" ? selectedSubject.primaryLang! : "other"}
                            secondaryLanguage={selectedSubjectId !== "" ? selectedSubject.secondaryLang : ""}
                            small
                        />
                    </BookImageWrapper>
                    <SmallSelectWrapper>
                        <SelectSearch
                            options={userOwnSubjects.map((s) => {
                                return { name: s.name!, value: s.id! };
                            })}
                            search
                            filterOptions={fuzzySearch}
                            emptyMessage={t_noResults}
                            placeholder={t_selectSubject}
                            value={selectedSubjectId}
                            onChange={updateSubject}
                            disabled={shouldSubjectSelectionBeDisabled}
                        />
                    </SmallSelectWrapper>
                </BookWrapper>
            );
        } else {
            return <div />;
        }
    };

    const renderSelectedSources = () => (
        <BookHeaderInfo>
            <SelectedSourceWrapper
                multipleSources={hasSelectedBoth || (hasAlreadySelectedABook && lastSourceSelected === SourceType.OWN)}
            >
                {hasAlreadySelectedABook && renderSelectedBook()}
                {shouldSubjectSelectionBeDisabled
                    ? hasAlreadySelectedOwnContent && renderSelectedOwn()
                    : renderOwnSelection()}
            </SelectedSourceWrapper>
            <DifferentSourceWrapper
                alignBottom={hasSelectedBoth || (hasAlreadySelectedABook && lastSourceSelected === SourceType.OWN)}
            >
                <StyledButton
                    onClick={() => {
                        setLastSourceSelected(undefined);
                        setShouldSubjectSelectionBeDisabled(true);
                        history.push("/create/choose-source");
                    }}
                    arrowDirection={"NONE"}
                    buttonStyle={"BLANK"}
                >
                    {t_selectDifferentSource}
                </StyledButton>
            </DifferentSourceWrapper>
        </BookHeaderInfo>
    );

    const renderBookCards = () => {
        const unitCards: JSX.Element[] = [];
        let shouldScrollToUnitIfContentVisible = true;
        for (let i = 0; i < selectedBandUnits.length; i++) {
            const u = selectedBandUnits[i];
            if (u.id) {
                const hasCards = availableCards.hasOwnProperty(u.id) && availableCards[u.id].items;
                const isContentVisible = unitsToPreloadIds.indexOf(u.id) !== -1;
                unitCards.push(
                    <UnitAndCardsWrapper
                        unitContent={u}
                        key={u.id}
                        openDeleteModalFn={openDeleteModalFn}
                        openDeleteUnitCardsFn={openDeleteUnitCardsFn}
                        cardsList={hasCards ? availableCards[u.id].items : []}
                        totalUnitCardsCount={hasCards ? availableCards[u.id].count : 0}
                        hideBottomLine={i === selectedBandUnits.length - 1}
                        isContentVisible={isContentVisible}
                        shouldScrollToUnit={shouldScrollToUnitIfContentVisible && isContentVisible}
                    />
                );
                if (isContentVisible) {
                    shouldScrollToUnitIfContentVisible = false;
                }
            }
        }
        return <UnitsWrapper>{unitCards}</UnitsWrapper>;
    };

    const renderOwnCards = () => {
        return (
            <UnitsWrapper>
                <div>
                    {Object.entries(userUnitCards).map(([id, cards]) => {
                        const hasCards = cards.length;
                        const unit = userOwnSubjectUnits.find((u) => u.id === id);
                        return (
                            <UnitAndCardsWrapper
                                unitContent={unit!}
                                key={id}
                                isUserOwnContent
                                openDeleteModalFn={openDeleteModalFn}
                                openDeleteUnitCardsFn={openDeleteUnitCardsFn}
                                cardsList={hasCards ? cards : []}
                                totalUnitCardsCount={hasCards ? cards.length : 0}
                            />
                        );
                    })}
                    {userOwnSubjectUnits.length === 0 && <p>{t_noResults}</p>}
                </div>
            </UnitsWrapper>
        );
    };

    const renderVocabulary = () => {
        return (
            <SelectBookVocabularyWrapper>
                {selectedBandUnits.length > 0 && currentSourceType === SourceType.BOOK && renderBookCards()}
                {userOwnSubjects.length > 0 && currentSourceType === SourceType.OWN && renderOwnCards()}
            </SelectBookVocabularyWrapper>
        );
    };

    const renderAccordionTabVocabulary = () => {
        const availableVocabulary = Object.values(vocabularyDrawerContent).length || 0;

        return (
            <AccordionTab
                mainIconName={"vocabulary-list-unset"}
                title={t_ChooseVocabulary}
                description={t_WhatToPractice}
                counterValue={`(${availableVocabulary})`}
                showAccordionTabDetails
                isActive
                enableTextPulse
            >
                {renderSelectedSources()}
                <Line />
                <PleaseChooseVocabulary>{t_selectVocabulary}</PleaseChooseVocabulary>
                {renderVocabulary()}
            </AccordionTab>
        );
    };

    const renderAccordionTabExercises = () => {
        if (testContent.length > 0) {
            const amountExercises = testContent.length;
            //TODO: StickyWrapper is a temporary solution until we improve the footer
            // changing the position from fixed to sticky might help with the footer height change
            return (
                <StickyWrapper>
                    <AccordionTab
                        mainIconName={"clipboard"}
                        title={t_DesignExerciseUnit}
                        description={t_HowToPractice}
                        counterValue={"(" + amountExercises + ")"}
                        onClickEdit={() => {
                            setLastSourceSelected(undefined);
                            history.push("/create/summary");
                        }}
                    />
                </StickyWrapper>
            );
        } else {
            return <div />;
        }
    };

    const renderFooter = () => {
        return (
            <CtasWrapper showScrollToTop>
                <StyledButton
                    onClick={handleBackButton}
                    arrowDirection={"LEFT"}
                    buttonStyle={"BLANK"}
                >
                    {t_back}
                </StyledButton>
                {creatingMode === "TEST" && (
                    <StyledButton
                        onClick={handleCreateExerciseBtnClick}
                        arrowDirection={"NONE"}
                        buttonStyle={"PRIMARY"}
                        disabled={Object.keys(vocabularyDrawerContent).length === 0}
                    >
                        {t_confirm}
                    </StyledButton>
                )}
            </CtasWrapper>
        );
    };

    const renderDeleteCardWarningModal = () => {
        return (
            <DeleteCardWarningModal
                isModalOpen={isCurrentWordForDeletionModalOpen}
                closeModalFn={closeDeleteWordWarningModal}
                wordId={currentWordForDeletion}
            />
        );
    };

    const renderDeleteAllUnitCardsModal = () => {
        return (
            <CreateInfoModal
                isOpen={isDeleteAllUnitCardsModalOpen}
                onRequestClose={() => {
                    setIsDeleteAllUnitCardsModalOpen(false);
                }}
                continueAction={deleteCardsFromUnit}
                continueText={t_continue}
                cancelAction={() => {
                    setIsDeleteAllUnitCardsModalOpen(false);
                }}
                compact
            >
                <UT
                    _str="Warning: Unchecking this box deletes all words from the basket and exercises for which those words have been used."
                    _inline
                    _tags="CreateTest,SelectBookVocabulary"
                />
            </CreateInfoModal>
        );
    };

    if (creatingMode === "RECURRING_TASK") {
        return <SelectBookVocabularyRT />;
    } else {
        return (
            <GeneralWrapper>
                <ExerciseSheetTitle />
                {renderAccordionTabVocabulary()}
                {renderAccordionTabExercises()}
                {renderFooter()}
                {renderDeleteCardWarningModal()}
                {renderDeleteAllUnitCardsModal()}
            </GeneralWrapper>
        );
    }
};

export default SelectBookVocabulary;
