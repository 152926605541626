import React from "react";
import { TestQuestionModel } from "p6m-p6u";
import { SummaryModes } from "../../../views/create/summary/Summary";
import { SummaryOpenModals } from "../../../views/create/summary/SummaryModals";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { Exercises, ExerciseTypes } from "../../../enums/exercises";
import ExerciseItemHeader from "./exerciseItemHeader/ExerciseItemHeader";
import ExerciseInfo from "./exerciseInfo/ExerciseInfo";
import ExerciseDetails from "./exerciseDetails/ExerciseDetails";
import SelectExerciseVocabulary from "./selectExerciseVocabulary/SelectExerciseVocabulary";

import { Wrapper } from "./StyledComponents";

interface ExerciseItemProps {
    index: number;
    exerciseData?: TestQuestionModel;
    currentExerciseId: string | undefined;
    isCreateNewExercise?: boolean;
    hasWords?: boolean;
    setCurrentMode: React.Dispatch<React.SetStateAction<SummaryModes>>;
    setOpenModal: React.Dispatch<React.SetStateAction<SummaryOpenModals | undefined>>;
    onOpenExerciseContentModal: () => void;
    saveTestDataDebounced: (newTestContent: TestQuestionModel[]) => void;
    exerciseVocabularyOpenId: string;
    setExerciseVocabularyOpenId: React.Dispatch<React.SetStateAction<string>>;
}

const ExerciseItem: React.FC<ExerciseItemProps> = (props) => {
    const isExerciseOpen = props.currentExerciseId === props.exerciseData?.exerciseId;
    const isNewExercise = props.isCreateNewExercise && isExerciseOpen;
    const { currentExerciseModel, setCurrentExerciseModel } = useTestCreationContext();

    const isSelectExerciseVocabularyOpen = props.exerciseVocabularyOpenId === props.exerciseData?.exerciseId;

    const renderHeader = () => (
        <ExerciseItemHeader
            isNewExercise={isNewExercise}
            isEditMode={isExerciseOpen}
            exerciseData={props.exerciseData!}
            index={props.index}
            setCurrentMode={props.setCurrentMode}
            setOpenModal={props.setOpenModal}
            onOpenExerciseContentModal={props.onOpenExerciseContentModal}
            saveTestDataDebounced={props.saveTestDataDebounced}
            onAction={() => {
                props.setExerciseVocabularyOpenId("");
                setCurrentExerciseModel({});
            }}
        />
    );

    const renderInfo = () => <ExerciseInfo hasWords={props.hasWords} />;

    const renderDetails = () => (
        <ExerciseDetails
            hasWords={props.hasWords}
            isSelectExerciseVocabularyOpen={isSelectExerciseVocabularyOpen}
            onClickAddVocabularyButton={() => props.setExerciseVocabularyOpenId(props.exerciseData?.exerciseId || "")}
        />
    );

    const renderVocabulary = () => <SelectExerciseVocabulary onClose={() => props.setExerciseVocabularyOpenId("")} />;

    return (
        <Wrapper>
            {props.exerciseData && props.exerciseData.exerciseId && renderHeader()}
            {isExerciseOpen && (
                <>
                    {renderInfo()}
                    {renderDetails()}
                    {isSelectExerciseVocabularyOpen &&
                        currentExerciseModel.questionMode !== ExerciseTypes.get(Exercises.FREE_TEXT) &&
                        renderVocabulary()}
                </>
            )}
        </Wrapper>
    );
};

export default ExerciseItem;
