import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { BandUnit, UnitCard, UserOwnCard, UserUnit } from "p6m-p6u";
import styled from "styled-components";
import ArrowButton from "../../../basic/arrowButton/ArrowButton";
import { useT } from "@transifex/react";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import ExerciseInputField from "../../../basic/exerciseInputField/ExerciseInputField";
import StyledButton from "../../../basic/styledButton/StyledButton";
import { shuffle } from "lodash";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import CardListingCardItemRT from "../../../basic/create/cardListingCardItemRT/CardListingCardItemRT";
import DesignConstants from "../../../constants/DesignConstants";

export interface UnitAndCardsWrapperProps {
    unitContent: BandUnit | UserUnit;
    totalUnitCardsCount?: number;
    cardsList?: Array<UnitCard | UserOwnCard>;
    openDeleteModalFn: (cardId: string) => any;
    openDeleteUnitCardsFn: (cardsToDelete: Array<string>) => any;
    isUserOwnContent?: boolean;
}

const UnitWrapper = styled.div`
    background: white;
    padding: 1rem 0;
    margin: 0.25rem 0;
    user-select: none;
    scroll-margin-top: 85px;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        scroll-margin-top: 70px;
    }

    :first-child {
        border-radius: 1rem 1rem 0 0;
    }

    :last-child {
        border-radius: 0 0 1rem 1rem;
    }
`;

const UnitHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
`;

const SelectionWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 0.5rem;
    width: 2rem;
    justify-content: space-around;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        align-items: flex-start;
        align-self: flex-start;
        padding-top: 5px;
    }
`;

const CheckIcon = styled(PhaseSixIcon)`
    font-size: 1.2rem;
`;

const RandomAndNameWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        gap: 0.5rem;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: 1rem;
`;

const CardListingWrapper = styled.div`
    margin-top: 1rem;
    border-top: solid 2px ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    max-height: 20rem;
    overflow-y: auto;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        max-height: unset;
    }
`;

const UnitNameWrapper = styled.div`
    width: 100%;
    cursor: pointer;
`;

const RandomSelectionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
`;

const SelectedCardsCountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
`;

const RandomCardNumberSelectorWrapper = styled.div`
    width: 70px;
    margin-right: 5px;

    > div {
        padding: 0;
    }
`;

const StyledExerciseInputField = styled(ExerciseInputField)`
    width: 70px;
    height: 40px;
    flex: unset;
    margin: 0;
    background: #ffffff;
    border-radius: 100px 0 0 100px;
    border: solid 1px #ccc;
    overflow: hidden;

    > div {
        padding: 0;
        background-color: orange;
    }

    > input {
        box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.08);
        padding: 0 0.5rem 0 1rem;
        border: none;
    }
`;

const StyledRandomSelectionButton = styled(StyledButton)`
    text-transform: capitalize !important;
    font-size: 0.9rem;
    min-width: unset;
    min-height: 40px !important;
    padding: 0 1rem !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

const OpenClosedWrapper = styled.div`
    cursor: pointer;
`;

const StyledArrowButton = styled(ArrowButton)`
    padding: 0 !important;
`;

const UserWarningWrapper = styled.div`
    margin-top: 1rem;
    padding: 0 1rem;
`;

const UnregisteredUserWarning = styled.span`
    font-style: italic;
`;

const Checkbox = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 0.2rem;
    border: solid 1px #999;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    font-weight: normal;
    text-transform: lowercase;

    &.checked {
        border-color: #fc7c00;
        background: #fc7c00;
        color: white;
    }

    &.partial {
        border-color: #888888;
        background: #888888;
        color: white;
    }
`;

const CenterDash = styled.span`
    font-size: 1.5rem;
    transform: translate(0px, -3px);
`;

const UnitAndCardsWrapperRT: React.FC<UnitAndCardsWrapperProps> = (props) => {
    const {
        availableCards,
        vocabularyDrawerContent,
        loadCardsForUnit,
        removeCardsFromSelectedWords,
        addCardsToSelectedWords,
        maxAmountOfCardsSelectable,
        openWarningModal,
        loadUserUnitCards,
    } = useTestCreationContext();
    const { userId, setIsDataBeingLoaded, isDataBeingLoaded, creatingMode } = useGeneralContext();
    const { unitContent, totalUnitCardsCount, cardsList } = props;

    const [isUnitContentVisible, setIsUnitContentVisible] = useState(false);
    const [hasAllCardsSelected, setHasAllCardsSelected] = useState(false);
    const [randomCardsInputVal, setRandomCardsInputVal] = useState(10);

    const [selectedCardsAmount, setSelectedCardsAmount] = useState(0);
    const unitWrapperRef = useRef<HTMLDivElement>(null);

    const restCardsCount = (totalUnitCardsCount || 0) - (cardsList?.length || 0);

    const t = useT();
    const t_restOfContent = t("The other {restCards} cards from this Unit will be available after Registration", {
        restCards: restCardsCount,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });
    const t_noCards = t("No cards available for this unit", { _tags: "CreateTest,UnitAndCardsWrapper" });
    const t_loading = t("Loading cards", { _tags: "CreateTest,UnitAndCardsWrapper" });
    const t_randomSelection = t("Random selection", { _tags: "CreateTest,UnitAndCardsWrapper" });

    const t_cantAddCards = t("It's not possible to add more than {maxAmountOfCards}", {
        maxAmountOfCards: maxAmountOfCardsSelectable,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });
    const t_moreRandomThanCards = t("It's not possible to add more random cards than available on the unit", {
        maxAmountOfCards: maxAmountOfCardsSelectable,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });
    const t_incompleteAdd = t(
        "It's not possible to add more than {maxAmountOfCards}, some cards were selected until the limit was reached.",
        { maxAmountOfCards: maxAmountOfCardsSelectable, _tags: "CreateTest,UnitAndCardsWrapper" }
    );
    const t_selectedCards = t("{selectedCardsAmount} cards selected", {
        selectedCardsAmount,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });

    let selectedCardIds = Object.keys(vocabularyDrawerContent);
    let selectedCardContent = Object.values(vocabularyDrawerContent);
    const checkIfAllCardsSelected = useCallback(() => {
        let areAllCardsInUnitSelected = true;
        let selectedCards = 0;
        if (cardsList && cardsList.length > 0 && selectedCardIds.length > 0) {
            for (let i = 0; i < cardsList.length; i++) {
                const cardId = String(cardsList[i].id);
                if (cardId) {
                    if (!selectedCardIds.includes(cardId)) {
                        areAllCardsInUnitSelected = false;
                    }
                    if (!areAllCardsInUnitSelected) {
                        break;
                    }
                }
            }
            setHasAllCardsSelected(areAllCardsInUnitSelected);

            for (let i = 0; i < cardsList.length; i++) {
                const cardId = String(cardsList[i].id);
                if (cardId) {
                    if (selectedCardIds.includes(cardId)) {
                        selectedCards++;
                    }
                }
            }
            setSelectedCardsAmount(selectedCards);
        } else {
            setHasAllCardsSelected(false);

            // Check if there's no cards because we're editing an exercise, and show the correct amount of cards.
            if (selectedCardIds.length > 0 && unitContent && unitContent.id) {
                let selectedCardsInUnit = selectedCardContent.filter((dw) => {
                    return dw.wordContent && unitContent.id && dw.wordContent.unitId === String(unitContent.id);
                });
                setSelectedCardsAmount(selectedCardsInUnit.length);
            } else {
                setSelectedCardsAmount(0);
            }
        }
    }, [cardsList, selectedCardIds, selectedCardContent, unitContent]);

    useEffect(() => {
        if (cardsList && cardsList.length > 0 && cardsList.length < 10) {
            setRandomCardsInputVal(cardsList.length);
        }
    }, [cardsList]);

    useEffect(() => {
        checkIfAllCardsSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCardIds]);

    useEffect(() => {
        if (unitContent && unitContent.id && localStorage.getItem("open_" + unitContent.id)) {
            setIsUnitContentVisible(true);
            unitWrapperRef.current?.scrollIntoView();
            if (unitWrapperRef.current) {
                unitWrapperRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            localStorage.removeItem("open_" + unitContent.id);
        }
    }, [unitContent]);

    if (!unitContent || !unitContent.id) {
        return <></>;
    }

    let unitId = unitContent.id;

    function toggleContentList() {
        // If currently is not visible, we load on open the cards for this unit.
        if (!isUnitContentVisible) {
            if (!availableCards.hasOwnProperty(unitId)) {
                setIsDataBeingLoaded(true);
                if (props.isUserOwnContent) {
                    loadUserUnitCards(unitId);
                } else {
                    loadCardsForUnit(unitId);
                }
            }
        }
        setIsUnitContentVisible(!isUnitContentVisible);
    }

    function openGlobalWarningModal(type: "CANT_ADD" | "INCOMPLETE_ADD" | "RAND_BIGG_UNIT") {
        let content;
        if (type === "CANT_ADD") {
            content = t_cantAddCards;
        } else if (type === "INCOMPLETE_ADD") {
            content = t_incompleteAdd;
        } else if (type === "RAND_BIGG_UNIT") {
            content = t_moreRandomThanCards;
        }

        openWarningModal(content);
    }

    function handleCheckboxClick() {
        if (cardsList) {
            if (selectedCardsAmount > 0) {
                // Cant use array.map here without the {string|undefined)[] warning, even after filtering the undefined
                // So supressing the warning.
                // @ts-ignore

                let cardsToDelete: Array<string> = [];
                let needsWarningModal = false;

                cardsList.forEach((c) => {
                    if ("isUserCard" in c) {
                    }
                    if (c.id) {
                        cardsToDelete.push(c.id);
                        if (
                            !needsWarningModal &&
                            vocabularyDrawerContent[c.id] &&
                            vocabularyDrawerContent[c.id].exerciseIds &&
                            vocabularyDrawerContent[c.id].exerciseIds!.length > 0
                        ) {
                            needsWarningModal = true;
                        }
                    }
                });

                if (cardsToDelete.length > 0) {
                    if (needsWarningModal) {
                        props.openDeleteUnitCardsFn(cardsToDelete);
                    } else {
                        removeCardsFromSelectedWords(cardsToDelete);
                    }
                }
            } else {
                let addingResponse = addCardsToSelectedWords(cardsList);
                if (addingResponse === "CANT_ADD" || addingResponse === "INCOMPLETE_ADD") {
                    openGlobalWarningModal(addingResponse);
                }
            }
        }
    }

    const shouldShowRestOfContentWarning = cardsList && !userId && restCardsCount > 0;

    const maxRandomCardsPerUnit = cardsList
        ? Math.min(cardsList.length, maxAmountOfCardsSelectable - Object.keys(vocabularyDrawerContent).length)
        : 0;

    function handleRandomSelectionClick() {
        if (randomCardsInputVal > maxRandomCardsPerUnit) {
            openGlobalWarningModal("RAND_BIGG_UNIT");
        } else {
            let possibleCardsToAdd = cardsList;
            // Remove the cards that are already selected
            possibleCardsToAdd = possibleCardsToAdd?.filter(
                (c) => !Object.keys(vocabularyDrawerContent).includes(c.id!)
            );
            // shuffle cards to make it random, and not orderly
            possibleCardsToAdd = shuffle(possibleCardsToAdd);
            // Removing rest of items
            possibleCardsToAdd = possibleCardsToAdd.slice(0, randomCardsInputVal);

            let addingResponse = addCardsToSelectedWords(possibleCardsToAdd);
            if (addingResponse === "CANT_ADD" || addingResponse === "INCOMPLETE_ADD") {
                openGlobalWarningModal(addingResponse);
            }
        }
    }

    return (
        <UnitWrapper ref={unitWrapperRef}>
            <UnitHeaderWrapper onClick={isUnitContentVisible ? () => {} : toggleContentList}>
                {isUnitContentVisible && (
                    <SelectionWrapper>
                        <Checkbox
                            className={hasAllCardsSelected ? "checked" : selectedCardsAmount > 0 ? "partial" : ""}
                            onClick={handleCheckboxClick}
                        >
                            {hasAllCardsSelected ? (
                                <CheckIcon name={"exercise-ok"} />
                            ) : selectedCardsAmount > 0 ? (
                                <CenterDash>-</CenterDash>
                            ) : (
                                ""
                            )}
                        </Checkbox>
                    </SelectionWrapper>
                )}
                <RandomAndNameWrapper>
                    <UnitNameWrapper onClick={isUnitContentVisible ? toggleContentList : () => {}}>
                        {unitContent.name}
                    </UnitNameWrapper>
                    {isUnitContentVisible && creatingMode === "TEST" && (
                        <RandomSelectionWrapper>
                            <RandomCardNumberSelectorWrapper>
                                <StyledExerciseInputField
                                    type={"number"}
                                    max={maxRandomCardsPerUnit}
                                    min={1}
                                    value={randomCardsInputVal}
                                    onChange={(e) => {
                                        setRandomCardsInputVal(Number(e.target.value));
                                    }}
                                />
                            </RandomCardNumberSelectorWrapper>
                            <StyledRandomSelectionButton
                                onClick={handleRandomSelectionClick}
                                arrowDirection={"NONE"}
                                buttonStyle={"BLANK"}
                                textStyle={"NO_TRANSFORMATION"}
                            >
                                {t_randomSelection}
                            </StyledRandomSelectionButton>
                        </RandomSelectionWrapper>
                    )}{" "}
                    {(!isUnitContentVisible || creatingMode === "RECURRING_TASK") && selectedCardsAmount > 0 && (
                        <SelectedCardsCountWrapper>
                            <p>({t_selectedCards})</p>
                        </SelectedCardsCountWrapper>
                    )}
                </RandomAndNameWrapper>
                <IconWrapper>
                    <OpenClosedWrapper>
                        <StyledArrowButton
                            onClick={isUnitContentVisible ? toggleContentList : () => {}}
                            direction={isUnitContentVisible ? "UP" : "DOWN"}
                        />
                    </OpenClosedWrapper>
                </IconWrapper>
            </UnitHeaderWrapper>
            {isUnitContentVisible && (
                <CardListingWrapper>
                    {(!cardsList || cardsList.length === 0) && !isDataBeingLoaded && (
                        <UserWarningWrapper>{t_noCards}</UserWarningWrapper>
                    )}
                    {(!cardsList || cardsList.length === 0) && isDataBeingLoaded && (
                        <UserWarningWrapper>{t_loading}</UserWarningWrapper>
                    )}
                    {cardsList &&
                        cardsList.length > 0 &&
                        cardsList.map((c) => {
                            return (
                                <CardListingCardItemRT
                                    card={c}
                                    openModalCallback={() => {
                                        props.openDeleteModalFn(c.id!);
                                    }}
                                    hideGapSentences={creatingMode === "RECURRING_TASK"}
                                    isChecked={
                                        vocabularyDrawerContent &&
                                        !c.id &&
                                        Object.keys(vocabularyDrawerContent).includes(c.id!)
                                    }
                                    key={c.id}
                                />
                            );
                        })}

                    {shouldShowRestOfContentWarning && (
                        <UserWarningWrapper>
                            <UnregisteredUserWarning>{t_restOfContent}</UnregisteredUserWarning>
                        </UserWarningWrapper>
                    )}
                </CardListingWrapper>
            )}
        </UnitWrapper>
    );
};

export default UnitAndCardsWrapperRT;
