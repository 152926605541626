import styled from "styled-components";
import DesignConstants from "../../../../../constants/DesignConstants";

export const ExerciseTypeInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${DesignConstants.SPACES.S};
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.LIGHTEST_GREY};
    padding-bottom: ${DesignConstants.SPACES.M};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
    }
`;

export const ExerciseTypeWrapper = styled.div<{ isSelected: boolean; isTextRight: boolean }>`
    display: flex;
    flex-direction: ${({ isTextRight }) => (isTextRight ? "row-reverse" : "row")};
    flex: 1;
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    overflow: hidden;
    border: ${({ isSelected }) => (isSelected ? DesignConstants.BORDERS.SEMI_THIN : DesignConstants.BORDERS.THIN)};
    border-color: ${({ isSelected }) =>
        isSelected ? DesignConstants.COLORS.DARK_GREY : DesignConstants.COLORS.LIGHT_GREY};
    cursor: pointer;
`;

export const ExerciseTypeTextWrapper = styled.div<{ isTextRight: boolean }>`
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    border-right: ${({ isTextRight }) =>
        isTextRight ? "" : `${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY}`};
    border-left: ${({ isTextRight }) =>
        isTextRight ? `${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY}` : ""};
    padding: ${DesignConstants.SPACES.XS};
`;

export const ExerciseTypeText = styled.p`
    text-align: center;
`;

export const ExerciseTypeImageWrapper = styled.div<{ isSelected: boolean }>`
    flex: 1;
    overflow: hidden;
    padding: ${DesignConstants.SPACES.XS};
    background: ${({ isSelected }) =>
        isSelected ? DesignConstants.COLORS.LIGHTEST_GREY : DesignConstants.COLORS.BACKGROUND_LIGHT};
    height: 100px;
`;

export const ExerciseTypeImage = styled.img`
    height: 160%;
    object-fit: cover;
`;
