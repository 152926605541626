export const getUrlParameter = (parameter: string): string => {
    return new URLSearchParams(window.location.search).get(parameter) || "";
};

export const getTestId = (): string => {
    return getUrlParameter("testId");
};

export const getIsTestRun = (): string => {
    return getUrlParameter("testRun");
};

export const getIsLocalTestRun = (): string => {
    return getUrlParameter("localTestRun");
};

// Small function to ensure that the pathname always has the / before redirecting user in relative paths
export const getCorrectPathname = (pathname: string): string => {
    if (pathname.lastIndexOf("/") !== pathname.length - 1) {
        return pathname + "/";
    }
    return pathname;
};
