import React, { useCallback, useEffect, useState } from "react";
import { useT } from "@transifex/react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { Exercises, ExerciseTypes } from "../../../enums/exercises";
import { sanitizeText } from "../../../helpers/TestCreation";
import CreateInfoModal from "../../../basic/create/InfoModal/CreateInfoModal";
import AnonymousSaveTestModal from "../../../basic/AnonymousSaveTestModal/AnonymousSaveTestModal";

import {
    ExerciseContentWrapper,
    ExerciseExtraCommentsWrapper,
    ExerciseExtraComments,
    ExerciseWordsWrapper,
    ExerciseWordsList,
    ExerciseWordItem,
} from "./StyledComponents";

export enum SummaryOpenModals {
    DELETION_FORBIDDEN = "DELETION_FORBIDDEN",
    UNUSED_INITIAL_VOCABULARY = "UNUSED_INITIAL_VOCABULARY",
    ANONYMOUS_USER_REGISTRATION = "ANONYMOUS_USER_REGISTRATION",
}

interface SummaryModalsProps {
    openModalType: SummaryOpenModals | undefined;
    exerciseContentModalExerciseIndex: number;
    onClose: () => void;
    finishTestEditing: () => void;
}

const SummaryModals: React.FC<SummaryModalsProps> = (props) => {
    const { saveTest, testContent } = useTestCreationContext();
    const isFreeTextExercise =
        testContent[props.exerciseContentModalExerciseIndex]?.questionMode === ExerciseTypes.get(Exercises.FREE_TEXT);

    const t = useT();

    const t_ok = t("Ok", { _tags: "CreateTest,Summary" });
    const t_canNotDeleteLastExercise = t(
        "This exercise can't be deleted. The test needs to contain at least one exercise.",
        { _tags: "CreateTest,Summary" }
    );
    const t_unusedInitialVocabulary = t(
        "Warning! Some of the cards you've chosen have not been used in any of the exercises. The cards will not be saved for further editing of the exercise. Are you sure you want to continue?",
        { _tags: "CreateTest,Summary" }
    );

    const [devCommand, setDevCommand] = useState<string>("");

    const saveAnonymousTest = (openInNewTab?: boolean) => {
        try {
            saveTest(testContent, true, openInNewTab);
        } catch (e) {
            console.log("error");
        }
    };

    const handleKeyDownForDev = useCallback(
        (event: KeyboardEvent) => {
            // this function is for debugging only to test a certain behavior on live without disturbing the user experience
            const updatedDevCommand = devCommand + event.key;

            if (updatedDevCommand.includes("devdevdev")) {
                // trigger dev behaviour and reset
                saveAnonymousTest(true);
                setDevCommand("");
            } else {
                setDevCommand(updatedDevCommand);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [devCommand]
    );

    useEffect(() => {
        if (props.openModalType === SummaryOpenModals.ANONYMOUS_USER_REGISTRATION) {
            // anonymous modal open
            document.addEventListener("keydown", handleKeyDownForDev);
        }

        return () => {
            // clean up
            document.removeEventListener("keydown", handleKeyDownForDev);
        };
    }, [props.openModalType, handleKeyDownForDev]);

    const renderExerciseContentModalContent = () => (
        <ExerciseContentWrapper>
            {props.exerciseContentModalExerciseIndex > -1 && isFreeTextExercise ? (
                <ExerciseExtraCommentsWrapper>
                    <ExerciseExtraComments>
                        {testContent[props.exerciseContentModalExerciseIndex] &&
                            testContent[props.exerciseContentModalExerciseIndex].extraComments}
                    </ExerciseExtraComments>
                </ExerciseExtraCommentsWrapper>
            ) : (
                <ExerciseWordsWrapper>
                    <ExerciseWordsList>
                        {testContent[props.exerciseContentModalExerciseIndex] &&
                            testContent[props.exerciseContentModalExerciseIndex].selectedWords?.map((w) => {
                                if ("question" in w) {
                                    return (
                                        <ExerciseWordItem key={w.id}>{sanitizeText(w.question || "")}</ExerciseWordItem>
                                    );
                                } else if ("name" in w) {
                                    return <ExerciseWordItem key={w.ID}>{sanitizeText(w.name || "")}</ExerciseWordItem>;
                                }
                                return null;
                            })}
                    </ExerciseWordsList>
                </ExerciseWordsWrapper>
            )}
        </ExerciseContentWrapper>
    );

    return (
        <>
            <CreateInfoModal
                isOpen={props.exerciseContentModalExerciseIndex > -1}
                onRequestClose={props.onClose}
                compact
            >
                {renderExerciseContentModalContent()}
            </CreateInfoModal>
            <CreateInfoModal
                isOpen={props.openModalType === SummaryOpenModals.UNUSED_INITIAL_VOCABULARY}
                onRequestClose={props.onClose}
                continueText={t_ok}
                continueAction={props.finishTestEditing}
                cancelAction={props.onClose}
                compact
            >
                {t_unusedInitialVocabulary}
            </CreateInfoModal>
            <CreateInfoModal
                isOpen={props.openModalType === SummaryOpenModals.DELETION_FORBIDDEN}
                onRequestClose={props.onClose}
                continueText={t_ok}
                continueAction={props.onClose}
                compact
            >
                {t_canNotDeleteLastExercise}
            </CreateInfoModal>
            <AnonymousSaveTestModal
                isOpen={props.openModalType === SummaryOpenModals.ANONYMOUS_USER_REGISTRATION}
                onRequestClose={props.onClose}
                saveTestAndLoginOrRegister={() => saveAnonymousTest(false)}
                cancelAction={props.onClose}
                testContent={testContent}
            />
        </>
    );
};

export default SummaryModals;
