// LIBRARIES
import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { useTestReviewContext } from "../../context/TestReviewContext";
import { Grade } from "p6m-p6u";
import { ReactComponent as CorrectIcon } from "../../assets/icons/exercise-ok.svg";
import { ReactComponent as IncorrectIcon } from "../../assets/icons/exercise-failed.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/annotation-alt.svg";
import SvgIcon from "../../basic/svgIcon/SvgIcon";

export interface TestReviewItemActionsProps {
    exerciseId: string;
    itemId: string;
    itemIndex: number;
    hasInconsistentGrades: boolean;
    addChangesToResults: (changedAnswer: string) => void;
}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
`;

const AnswerIconBaseCss = css`
    border-radius: 5px;
    text-align: center;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
`;

const AddCommentIconWrapper = styled.div`
    ${AnswerIconBaseCss};

    &.checked {
        background: #666;
        color: white;
    }
`;

const CorrectAnswerIconWrapper = styled.div`
    ${AnswerIconBaseCss};
    border: solid 1px green;
    color: green;

    &.checked {
        background: green;
        color: white;
    }

    &.inconsistent {
        background: #00800088;
        color: white;
        border: dashed 1px #00800088;
    }
`;
const IncorrectAnswerIconWrapper = styled.div`
    ${AnswerIconBaseCss};
    border: solid 1px red;
    color: red;

    &.checked {
        background: red;
        color: white;
    }

    &.inconsistent {
        background: #ff000088;
        color: white;
        border: dashed 1px #ff000088;
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
`;

const CommentWrapper = styled.div`
    margin-top: 1rem;
    width: 100%;
`;

const CommentTextArea = styled.textarea`
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    resize: none;
`;

const TestReviewJointExerciseActions: React.FC<TestReviewItemActionsProps> = (props) => {
    const { jointResultsData, updateJointDataAt } = useTestReviewContext();
    const { exerciseId, itemId, itemIndex, hasInconsistentGrades } = props;
    let currentAnsw = jointResultsData[exerciseId].answers[itemId][itemIndex];

    const [isCommentBoxVisible, setIsCommentBoxVisible] = useState(currentAnsw.teacherComment !== "");

    const setAnswerAs = function (val: Grade) {
        const changedResults = updateJointDataAt(exerciseId, itemId, itemIndex, val, currentAnsw.teacherComment || "");
        if (changedResults) {
            changedResults.forEach((updatedResult) => {
                if (updatedResult) {
                    props.addChangesToResults(updatedResult);
                }
            });
        }
    };

    const setAnswerAsCorrect = () => {
        setAnswerAs("correct");
    };
    const setAnswerAsIncorrect = () => {
        setAnswerAs("incorrect");
    };

    const toggleCommentBox = () => {
        setIsCommentBoxVisible(!isCommentBoxVisible);
    };

    const updateTeacherComment = (text: string) => {
        // console.log("updating with text", text)
        const changedResults = updateJointDataAt(
            exerciseId,
            itemId,
            itemIndex,
            currentAnsw.teacherGrade || "not-graded",
            text
        );
        if (changedResults) {
            changedResults.forEach((updatedResult) => {
                if (updatedResult) {
                    props.addChangesToResults(updatedResult);
                }
            });
        }
    };

    return (
        <Wrapper>
            <ActionsWrapper>
                <CorrectAnswerIconWrapper
                    className={`${
                        hasInconsistentGrades ? "inconsistent" : currentAnsw.teacherGrade === "correct" ? "checked" : ""
                    }`}
                    onClick={setAnswerAsCorrect}
                >
                    <SvgIcon
                        svgComponent={CorrectIcon}
                        color={`${currentAnsw.teacherGrade === "correct" ? "white" : "green"}`}
                    />
                </CorrectAnswerIconWrapper>
                <IncorrectAnswerIconWrapper
                    className={`${
                        hasInconsistentGrades
                            ? "inconsistent"
                            : currentAnsw.teacherGrade === "incorrect"
                            ? "checked"
                            : ""
                    }`}
                    onClick={setAnswerAsIncorrect}
                >
                    <SvgIcon
                        svgComponent={IncorrectIcon}
                        color={`${currentAnsw.teacherGrade === "incorrect" ? "white" : "red"}`}
                    />
                </IncorrectAnswerIconWrapper>
                <AddCommentIconWrapper
                    onClick={toggleCommentBox}
                    className={`${isCommentBoxVisible ? "checked" : ""}`}
                >
                    <SvgIcon
                        svgComponent={CommentIcon}
                        color={`${isCommentBoxVisible ? "white" : "#666"}`}
                    />
                </AddCommentIconWrapper>
            </ActionsWrapper>
            {isCommentBoxVisible && (
                <CommentWrapper>
                    <CommentTextArea
                        onBlur={(e) => {
                            updateTeacherComment(e.target.value);
                        }}
                        defaultValue={currentAnsw.teacherComment}
                        draggable={false}
                    />
                </CommentWrapper>
            )}
        </Wrapper>
    );
};

export default TestReviewJointExerciseActions;
