import styled from "styled-components";
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../constants/DesignConstants";

//TODO: use design constants when redesigning this part

export const CardWrapper = styled.div`
    width: 100%;
    padding: 0.5em 1rem;
    border-bottom: solid 2px ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    background: white;
    display: flex;
    cursor: pointer;
`;

export const CheckboxWrapper = styled.div`
    margin: 0;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &.half-opacity {
        opacity: 0.6;
    }
`;

export const QuestionWrapper = styled.div`
    flex: 1;
    padding: 0 0.4rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
`;

export const AnswerWrapper = styled.div`
    flex: 1;
    padding: 0 0.4rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
`;

export const QuestionAnswerWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    &.half-opacity {
        opacity: 0.6;
    }
`;

export const TextSpan = styled.span`
    font-size: inherit;

    &.checked {
        color: #fc7c00;
    }
`;

export const Checkbox = styled.div`
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 0.2rem;
    border: solid 1px #999;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    font-weight: normal;
    text-transform: lowercase;

    &.checked {
        border-color: #fc7c00;
        background: #fc7c00;
        color: white;
    }
`;

export const GapSentenceWrapper = styled.div`
    padding: 0.5rem 0.4rem 0;
    width: 100%;
    font-size: 0.9rem;
`;

export const SmallP6Icon = styled(PhaseSixIcon)`
    font-size: 2rem;

    &.orange {
        color: #fc7c00;
    }
`;
export const CheckIcon = styled(PhaseSixIcon)`
    font-size: 1.2rem;
`;

export const CardAlreadyInUseIconWrapper = styled.div`
    width: 2rem;
    height: 2rem;
`;
