import styled, { keyframes } from "styled-components";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../constants/DesignConstants";

const animate = keyframes`
    from {
        opacity: ${DesignConstants.OPACITY.HALF};
    }
    to {
        opacity: 1;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    user-select: none;
    animation: ${animate} 1s;
    height: ${DesignConstants.SPACES.XXL};
    padding: 2.2rem ${DesignConstants.SPACES.S};

    &.blur {
        background: ${DesignConstants.COLORS.TRANSPARENT_GREY};
    }
`;

export const ExerciseNumber = styled.div`
    display: flex;
    height: 2.5rem;
    width: 2.5rem;
    padding: ${DesignConstants.SPACES.S};
    border-radius: 10px;
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    align-items: center;
    justify-content: space-around;
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
`;

export const NameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${DesignConstants.SPACES.XS};
    margin-left: ${DesignConstants.SPACES.S};
`;

export const ExerciseName = styled.span`
    display: flex;
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-weight: ${DesignConstants.FONT_WEIGHTS.BOLD};
`;

export const VocabularyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    cursor: pointer;
    font-size: ${DesignConstants.FONT_SIZES.M};
    padding: ${DesignConstants.SPACES.XS};

    justify-content: center;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
    mask-image: linear-gradient(to right, white 88%, transparent 100%);
    overflow: hidden;
    margin-right: ${DesignConstants.SPACES.M};
    margin-left: ${DesignConstants.SPACES.M};
`;

export const ExerciseWordsCount = styled.span`
    display: flex;
    white-space: nowrap;
`;

export const ExerciseWords = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    gap: ${DesignConstants.SPACES.XXS};
`;

export const ExerciseWord = styled.div`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
`;

export const Separator = styled.span`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;

export const VocabularySeparator = styled.div`
    display: flex;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    font-weight: ${DesignConstants.FONT_WEIGHTS.BOLD};
`;

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${DesignConstants.SPACES.S};
`;

export const StyledIcon = styled(PhaseSixIcon)`
    width: ${DesignConstants.SPACES.L};
    font-size: ${DesignConstants.FONT_SIZES.XXL};
`;

export const Action = styled.div`
    cursor: pointer;
`;

export const ArrowActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
