import { useT } from "@transifex/react";
import { Exercises } from "../enums/exercises";

export enum SentenceVariants {
    SHORT_SENTENCE,
    LONG_SENTENCE,
}

export enum Locales {
    DE = "DE",
}

export interface ExerciseDescriptionVariants {
    [SentenceVariants.SHORT_SENTENCE]: string;
    [SentenceVariants.LONG_SENTENCE]: string;
}

export type LocalisedDescriptions = {
    [locale in Locales]: ExerciseDescriptionVariants[];
};

export type AvailableExerciseDescriptions = {
    [exerciseType in Exercises]: LocalisedDescriptions;
};

const useExerciseDescriptionStructure = () => {
    const t = useT();

    // STANDARD
    const t_standardDesc = t("Fill in the missing vocabulary words in the correct language.", {
        _tags: "CreateTest",
    });

    // CONNECT_WORDS
    const t_connectWordsDesc = t("Connect the correct translations.", { _tags: "CreateTest" });

    // FILL_GAP
    const t_fillGapDesc = t("Complete the sentences with the correct translations of the given words.", {
        _tags: "CreateTest",
    });

    // WRITE_SENTENCES
    const t_writeSentence = t("Write a sentence", {
        _tags: "CreateTest",
    });
    const t_writeSentenceDesc = t("Make a complete sentence with the translations of each of the given words.", {
        _tags: "CreateTest",
    });
    const t_pluralFormation = t("Form the plural", {
        _tags: "CreateTest",
    });
    const t_pluralFormationDesc = t("Form the plural of the translations of the given words in a complete sentence.", {
        _tags: "CreateTest",
    });
    const t_findOpposite = t("Form the opposite", {
        _tags: "CreateTest",
    });
    const t_findOppositeDesc = t("Form the opposite of the translations of the given words in a complete sentence.", {
        _tags: "CreateTest",
    });
    const t_buildAdverb = t("Form the adverb", {
        _tags: "CreateTest",
    });
    const t_buildAdverbDesc = t("Form the adverb of the translations of the given words in a complete sentence.", {
        _tags: "CreateTest",
    });

    // CREATE_TEXT
    const t_createText = t("Create a short text", { _tags: "CreateTest" });
    const t_createTextDesc = t(
        "Using the translations of the given words, think of a short text (at least 3 coherent sentences) in which you use the words.",
        { _tags: "CreateTest" }
    );
    const t_findSimilarities = t("Find similarities", {
        _tags: "CreateTest",
    });
    const t_findSimilaritiesDesc = t("What do the given words have in common?", {
        _tags: "CreateTest",
    });
    const t_groupTheWords = t("Group the words", {
        _tags: "CreateTest",
    });
    const t_groupTheWordsDesc = t("Categorise the words into at least two groups and explain the chosen groups.", {
        _tags: "CreateTest",
    });
    const t_organiseTheWords = t("Organise the words", {
        _tags: "CreateTest",
    });
    const t_organiseTheWordsDesc = t(
        "Arrange the words in a certain order (e.g. size, importance, chronological order) and explain your decision.",
        { _tags: "CreateTest" }
    );

    // VERBTRAINING
    const t_verbtrainingDesc = t("Fill in the correct verb form in the blank.", { _tags: "CreateTest" });

    // SHORT_SENTENCE is only necessary for WRITE_SENTENCE and CREATE_TEXT so far, because these provide task suggestions
    const availableExerciseDescriptions: AvailableExerciseDescriptions = {
        [Exercises.STANDARD]: {
            [Locales.DE]: [
                {
                    [SentenceVariants.SHORT_SENTENCE]: "",
                    [SentenceVariants.LONG_SENTENCE]: t_standardDesc,
                },
            ],
        },
        [Exercises.WRITE_SENTENCE]: {
            [Locales.DE]: [
                {
                    [SentenceVariants.SHORT_SENTENCE]: t_writeSentence,
                    [SentenceVariants.LONG_SENTENCE]: t_writeSentenceDesc,
                },
                {
                    [SentenceVariants.SHORT_SENTENCE]: t_pluralFormation,
                    [SentenceVariants.LONG_SENTENCE]: t_pluralFormationDesc,
                },
                {
                    [SentenceVariants.SHORT_SENTENCE]: t_findOpposite,
                    [SentenceVariants.LONG_SENTENCE]: t_findOppositeDesc,
                },
                {
                    [SentenceVariants.SHORT_SENTENCE]: t_buildAdverb,
                    [SentenceVariants.LONG_SENTENCE]: t_buildAdverbDesc,
                },
            ],
        },
        [Exercises.CREATE_TEXT]: {
            [Locales.DE]: [
                {
                    [SentenceVariants.SHORT_SENTENCE]: t_createText,
                    [SentenceVariants.LONG_SENTENCE]: t_createTextDesc,
                },
                {
                    [SentenceVariants.SHORT_SENTENCE]: t_findSimilarities,
                    [SentenceVariants.LONG_SENTENCE]: t_findSimilaritiesDesc,
                },
                {
                    [SentenceVariants.SHORT_SENTENCE]: t_groupTheWords,
                    [SentenceVariants.LONG_SENTENCE]: t_groupTheWordsDesc,
                },
                {
                    [SentenceVariants.SHORT_SENTENCE]: t_organiseTheWords,
                    [SentenceVariants.LONG_SENTENCE]: t_organiseTheWordsDesc,
                },
            ],
        },
        [Exercises.FILL_GAP]: {
            [Locales.DE]: [
                {
                    [SentenceVariants.SHORT_SENTENCE]: "",
                    [SentenceVariants.LONG_SENTENCE]: t_fillGapDesc,
                },
            ],
        },
        [Exercises.CONNECT_WORDS]: {
            [Locales.DE]: [
                {
                    [SentenceVariants.SHORT_SENTENCE]: "",
                    [SentenceVariants.LONG_SENTENCE]: t_connectWordsDesc,
                },
            ],
        },
        [Exercises.VERBTRAINING]: {
            [Locales.DE]: [
                {
                    [SentenceVariants.SHORT_SENTENCE]: "",
                    [SentenceVariants.LONG_SENTENCE]: t_verbtrainingDesc,
                },
            ],
        },
        [Exercises.FREE_TEXT]: {
            [Locales.DE]: [
                {
                    [SentenceVariants.SHORT_SENTENCE]: "",
                    [SentenceVariants.LONG_SENTENCE]: "",
                },
            ],
        },
    };

    return { availableExerciseDescriptions };
};

export default useExerciseDescriptionStructure;
