import styled from "styled-components";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import DesignConstants from "../../../constants/DesignConstants";

export const GeneralWrapper = styled.div`
    padding-bottom: 5rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding-bottom: 10rem;
    }
`;
export const SelectBookVocabularyWrapper = styled.div`
    width: 100%;
    margin: 0 0 1rem 0;
    min-height: calc(100vh - 310px);
    position: relative;
`;
export const BookHeaderInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    padding: 0.5rem 0.8rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    background: white;
`;
export const BookImageWrapper = styled.div`
    //width: auto;
    margin-right: 1rem;
    height: 100%;
`;
export const BookNameWrapper = styled.div``;
export const BookName = styled.h4`
    font-weight: normal;
    font-size: 1.25rem;
    margin: 0;
`;

export const ExerciseToSpan = styled.span`
    font-size: 0.9rem;
`;
export const UnitsWrapper = styled.div``;
export const BigButtonWithIcon = styled(StyledButtonWithIcon)`
    min-width: 25% !important;
`;

export const TitleWrapper = styled.div`
    text-align: center;

    &.space {
        margin: ${DesignConstants.SPACES.S} 0;
    }
`;

export const Title = styled.h3``;
