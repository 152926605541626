import { useT } from "@transifex/react";
import { Exercises } from "../enums/exercises";
import { getExerciseTypeByQuestionMode } from "../helpers/ExerciseTypesHelper";

const useExerciseTitle = () => {
    const t = useT();

    const t_standardTitle = t("Standard", { _tags: "CreateTest,SelectExerciseType" });
    const t_connectWordsTitle = t("Connect Words", { _tags: "CreateTest,SelectExerciseType" });
    const t_gapSentenceTitle = t("Gap sentence", { _tags: "CreateTest,SelectExerciseType" });
    const t_createSentenceTitle = t("Create sentence", { _tags: "CreateTest,SelectExerciseType" });
    const t_createTextTitle = t("Write Text", { _tags: "CreateTest,SelectExerciseType" });
    const t_freeTextTitle = t("Free Text", { _tags: "CreateTest,SelectExerciseType" });
    const t_verbtrainingTitle = t("Verbtraining", { _tags: "CreateTest,SelectExerciseType" });
    const t_textInput = t("Textinput", { _tags: "CreateTest" });

    const ExerciseTypesExerciseName = new Map<Exercises, string>([
        [Exercises.STANDARD, t_standardTitle],
        [Exercises.WRITE_SENTENCE, t_createSentenceTitle],
        [Exercises.CREATE_TEXT, t_createTextTitle],
        [Exercises.FILL_GAP, t_gapSentenceTitle],
        [Exercises.CONNECT_WORDS, t_connectWordsTitle],
        [Exercises.VERBTRAINING, t_verbtrainingTitle],
        [Exercises.FREE_TEXT, t_freeTextTitle],
    ]);

    function getExerciseNameFromQuestionMode(questionMode?: string) {
        let exerciseName;
        if (questionMode) {
            const exerciseKey = getExerciseTypeByQuestionMode(questionMode);
            if (exerciseKey) {
                if (exerciseKey === Exercises.WRITE_SENTENCE || exerciseKey === Exercises.CREATE_TEXT) {
                    exerciseName = t_textInput;
                } else {
                    exerciseName = ExerciseTypesExerciseName.get(exerciseKey);
                }
            }
        }
        return exerciseName || "";
    }

    return { getExerciseNameFromQuestionMode, ExerciseTypesExerciseName };
};

export default useExerciseTitle;
