import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

const xxs = parseFloat(DesignConstants.SPACES.XXS);
const xs = parseFloat(DesignConstants.SPACES.XS);
const result = xxs + xs + "rem";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
    padding: ${result} ${DesignConstants.SPACES.M};
    background: white;
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
`;

export const LabelWrapper = styled.div``;

export const Label = styled.p`
    border: ${DesignConstants.BORDERS.MEDIUM};
    border-color: transparent;
    pointer-events: none;
    user-select: none;
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;
