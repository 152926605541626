import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import { limitedWidth } from "../../usedInExerciseNumber/StyledComponents";

export const TextSpan = styled.span`
    font-size: inherit;

    &.checked {
        color: ${DesignConstants.COLORS.P6_ORANGE};
    }
`;

export const Checkbox = styled.div`
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 0.2rem;
    border: solid 1px ${DesignConstants.COLORS.MID_GREY};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: normal;
    text-transform: lowercase;

    &.checked {
        border-color: ${DesignConstants.COLORS.P6_ORANGE};
        background: ${DesignConstants.COLORS.P6_ORANGE};
        color: white;
    }
`;

export const CardWrapper = styled.div`
    width: 100%;
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.L};
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};

        ${TextSpan} {
            &.checked {
                color: ${DesignConstants.COLORS.P6_ORANGE_DARKER};
            }
        }

        ${Checkbox} {
            &.checked {
                border-color: ${DesignConstants.COLORS.P6_ORANGE_DARKER};
                background: ${DesignConstants.COLORS.P6_ORANGE_DARKER};
            }
        }
    }
`;

export const CheckboxWrapper = styled.div`
    margin: 0;
    width: ${DesignConstants.FONT_SIZES.XXL};
    display: flex;
    align-items: center;
    justify-content: space-around;

    &.half-opacity {
        opacity: ${DesignConstants.OPACITY.SIX};
    }
`;

export const QuestionAnswerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const QuestionWrapper = styled.div`
    flex: 1;
    padding: 0 ${DesignConstants.SPACES.XXS};
    font-size: ${DesignConstants.FONT_SIZES.M};
    display: flex;
    align-items: center;
    min-height: ${DesignConstants.SPACES.L};
`;

export const AnswerWrapper = styled.div`
    flex: 1;
    padding: 0 ${DesignConstants.SPACES.XXS};
    font-size: ${DesignConstants.FONT_SIZES.M};
    display: flex;
    align-items: center;
`;

export const ContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
        align-items: start;
    }
`;

export const RowContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &.half-opacity {
        opacity: ${DesignConstants.OPACITY.SIX};
    }
`;

export const GapSentenceWrapper = styled.div`
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.XXS} 0;
    width: 100%;
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const CheckIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;

export const UsedInExerciseNumberPlaceholder = styled.div`
    min-width: ${limitedWidth};
    max-width: ${limitedWidth};
`;
