// LIBRARIES
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { useTestReviewContext } from "../../context/TestReviewContext";
import { Grade, StudentAnswerReviewItem } from "p6m-p6u";
import { ReactComponent as CorrectIcon } from "../../assets/icons/exercise-ok.svg";
import { ReactComponent as IncorrectIcon } from "../../assets/icons/exercise-failed.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/annotation-alt.svg";
import SvgIcon from "../../basic/svgIcon/SvgIcon";

export interface TestReviewIndStudentActionsProps {
    resultId: string;
    exerciseId: string;
    answerId: string;
    isReviewMode?: boolean;
}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
`;

const AnswerIconBaseCss = css`
    border-radius: 5px;
    text-align: center;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    margin: 0px 3px;
`;

const AddCommentIconWrapper = styled.div`
    ${AnswerIconBaseCss};

    &.checked {
        background: #666;
        color: white;
    }
`;

const CorrectAnswerIconWrapper = styled.div`
    ${AnswerIconBaseCss};
    border: solid 1px green;
    color: green;

    &.checked {
        background: green;
        color: white;
    }
`;
const IncorrectAnswerIconWrapper = styled.div`
    ${AnswerIconBaseCss};
    border: solid 1px red;
    color: red;

    &.checked {
        background: red;
        color: white;
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    cursor: pointer;
`;

const CommentWrapper = styled.div`
    margin-top: 1rem;
    width: 100%;
`;

const CommentTextArea = styled.textarea`
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    resize: none;

    &[readonly] {
        background: #cccc;
    }
`;

const TestReviewIndStudentActions: React.FC<TestReviewIndStudentActionsProps> = (props) => {
    const { testIndividualResults, updateIndividualDataAt } = useTestReviewContext();
    const { exerciseId, resultId, answerId, isReviewMode } = props;

    let studentTest, currentExercise, currentAnswData: StudentAnswerReviewItem | undefined;

    studentTest = testIndividualResults.find((t) => t.id === resultId);
    if (studentTest && studentTest.questionAnswers) {
        currentExercise = studentTest.questionAnswers[exerciseId];
    }
    if (currentExercise && currentExercise.studentAnswers) {
        currentAnswData = currentExercise.studentAnswers!.find((a) => a.id === answerId);
    }

    const [isCommentBoxVisible, setIsCommentBoxVisible] = useState(
        !!currentAnswData && !!currentAnswData.teacherIndComment
    );

    useEffect(() => {
        if (currentAnswData && !!currentAnswData.teacherIndComment) {
            setIsCommentBoxVisible(true);
        } else {
            setIsCommentBoxVisible(false);
        }
    }, [answerId, currentAnswData]);

    let currentAnsw: StudentAnswerReviewItem;
    if (!currentAnswData) {
        return <div />;
    } else {
        currentAnsw = currentAnswData;
    }

    const setAnswerAs = function (val: Grade) {
        updateIndividualDataAt(resultId, exerciseId, answerId, val, currentAnsw!.teacherIndComment || "");
    };

    const setAnswerAsCorrect = () => {
        setAnswerAs("correct");
    };
    const setAnswerAsIncorrect = () => {
        setAnswerAs("incorrect");
    };

    const toggleCommentBox = () => {
        setIsCommentBoxVisible(!isCommentBoxVisible);
    };

    const updateTeacherComment = (text: string) => {
        updateIndividualDataAt(resultId, exerciseId, answerId, currentAnsw!.teacherGrade || "not-graded", text);
    };

    if (isReviewMode) {
        return (
            <Wrapper>
                <ActionsWrapper>
                    {currentAnsw.teacherGrade === "correct" && (
                        <CorrectAnswerIconWrapper className={"checked"}>
                            <SvgIcon
                                svgComponent={CorrectIcon}
                                color={"white"}
                            />
                        </CorrectAnswerIconWrapper>
                    )}
                    {currentAnsw.teacherGrade === "incorrect" && (
                        <IncorrectAnswerIconWrapper className={"checked"}>
                            <SvgIcon
                                svgComponent={IncorrectIcon}
                                color={"white"}
                            />
                        </IncorrectAnswerIconWrapper>
                    )}
                </ActionsWrapper>
                {currentAnsw.teacherIndComment && (
                    <CommentWrapper>
                        <CommentTextArea
                            defaultValue={currentAnsw.teacherIndComment}
                            readOnly
                            draggable={false}
                        />
                    </CommentWrapper>
                )}
                {currentAnsw.teacherComment && (
                    <CommentWrapper>
                        <CommentTextArea
                            defaultValue={currentAnsw.teacherComment}
                            readOnly
                            key={resultId + exerciseId + answerId}
                            draggable={false}
                        />
                    </CommentWrapper>
                )}
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <ActionsWrapper>
                <CorrectAnswerIconWrapper
                    className={`${currentAnsw.teacherGrade === "correct" ? "checked" : ""}`}
                    onClick={setAnswerAsCorrect}
                >
                    <SvgIcon
                        svgComponent={CorrectIcon}
                        color={`${currentAnsw.teacherGrade === "correct" ? "white" : "green"}`}
                    />
                </CorrectAnswerIconWrapper>
                <IncorrectAnswerIconWrapper
                    className={`${currentAnsw.teacherGrade === "incorrect" ? "checked" : ""}`}
                    onClick={setAnswerAsIncorrect}
                >
                    <SvgIcon
                        svgComponent={IncorrectIcon}
                        color={`${currentAnsw.teacherGrade === "incorrect" ? "white" : "red"}`}
                    />
                </IncorrectAnswerIconWrapper>
                <AddCommentIconWrapper
                    onClick={toggleCommentBox}
                    className={`${isCommentBoxVisible ? "checked" : ""}`}
                >
                    <SvgIcon
                        svgComponent={CommentIcon}
                        color={`${isCommentBoxVisible ? "white" : "#666"}`}
                    />
                </AddCommentIconWrapper>
            </ActionsWrapper>
            {isCommentBoxVisible && (
                <CommentWrapper>
                    <CommentTextArea
                        onBlur={(e) => {
                            updateTeacherComment(e.target.value);
                        }}
                        defaultValue={currentAnsw.teacherIndComment}
                        draggable={false}
                    />
                </CommentWrapper>
            )}
            {!!currentAnsw.teacherComment && (
                <CommentWrapper>
                    <CommentTextArea
                        defaultValue={currentAnsw.teacherComment}
                        readOnly
                        draggable={false}
                    />
                </CommentWrapper>
            )}
        </Wrapper>
    );
};

export default TestReviewIndStudentActions;
