// LIBRARIES
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { StudentTestAnswer } from "p6m-p6u";
import { useTestReviewContext } from "../../../context/TestReviewContext";
import { useHistory, useParams } from "react-router-dom";
import { getDateFromMilliseconds } from "../../../helpers/DateTime";
import { T, useT } from "@transifex/react";
import HighlightedAnswer from "../../../basic/highlightedAnswer/HighlightedAnswer";
import TestReviewIndStudentActions from "../../../complex/testReviewIndStudentActions/TestReviewIndStudentActions";
import TestReviewSummaryBox from "../../../basic/testReviewSummaryBox/TestReviewSummaryBox";
import { debounce } from "lodash";
import ReactTooltip from "react-tooltip";
import InconsistentGradeOnExerciseIcon from "../../../basic/inconsistentGradeOnExerciseIcon/InconsistentGradeOnExerciseIcon";
import StyledButton from "../../../basic/styledButton/StyledButton";
import { saveStudentTestResult } from "../../../networking/tests";

export interface ReviewIndividualStudentProps {}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const UserNameAndSelectorWrapper = styled.div`
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    width: 100%;
    background: #f5f5f5;
    border-radius: 0.5rem;
    text-align: center;
    display: flex;
    align-items: center;
`;

const NameSelector = styled.select`
    width: 60%;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    background: #fff;
`;

const ExamContentWrapper = styled.div`
    width: 100%;
`;

const ExamContentRow = styled.div`
    padding: 1.5rem;
    /* border: 2px solid #ddd; */
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
    border-radius: 1rem;
    margin: 0 0 1rem 0;

    &:nth-child(even) {
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 1rem;
    }
`;

const ExerciseTitleWrapper = styled.h3`
    font-weight: 500;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin-bottom: 0.5rem;
    }
`;

const StudentsCTAsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    left: 0;
    padding: 2rem;
    bottom: 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    background: #fff;

    > :only-child {
        margin: 0 auto;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 1rem;
    }
`;

const TextAnswerTitles = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 0;
`;

const BasicAnswerTitles = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 0;
`;

const ExerciseRowContent = styled.div``;

const AnswerTitle = styled.div`
    flex: 3;
    text-align: left;

    &.big {
        flex: 5;
    }
`;

const QuestionTitle = styled.div`
    flex: 2;
    text-align: left;
`;

const ActionTitle = styled.div`
    flex: 1;
    text-align: left;
`;

const TextAnswerRowWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 0.5rem 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 0.5rem 0;
    }
`;

const BaseAnswerRowWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 0.5rem 0;
    border-bottom: dashed 1px #ccc;

    :last-child {
        border: none;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 0.5rem 0;
    }
`;

const BaseQuestionContent = styled.div`
    flex: 2;
    line-height: 1.5;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding-right: 10px;
        word-break: break-word;
    }
`;

const BaseAnswerContent = styled.div`
    flex: 3;
    display: flex;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        word-break: break-word;
    }
`;

const TextAnswerContent = styled.div`
    flex: 5;
`;

const ActionsWrapper = styled.div`
    flex: 1;
    text-align: center;
`;

const ExerciseDescription = styled.p`
    font-size: 1rem;
    margin: 1rem 0 1.5rem;
    line-height: 1.5;
`;

const CommentTextArea = styled.textarea`
    width: 80%;
    height: 4rem;
    border-radius: 5px;
    margin-bottom: 1rem;
`;

const StudentOverallCommentText = styled.p`
    margin-bottom: 1rem;
`;

const StudentOverallCommentWrapper = styled.div`
    margin-bottom: 5rem;
`;

const EvaluationName = styled.p`
    margin-right: 1rem;
    font-weight: bold;
`;

const updateCommentDebounced = debounce(
    (updateStudentIndividualOverallComment, newText, resultId) => {
        // console.log("debouncing comment update");
        updateStudentIndividualOverallComment(resultId, newText);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

const updateAnswerDebounced = debounce(
    async (saveStudentTestResult, currentTestId, answer, setReviewChanged) => {
        await saveStudentTestResult(currentTestId, answer.id, answer);
        setReviewChanged(false);
    },
    10000,
    {
        leading: true,
        trailing: true,
    }
);

const ReviewIndividualStudent: React.FC<ReviewIndividualStudentProps> = (props) => {
    const { testId, resultId } = useParams<{ testId: string; resultId: string }>();
    const {
        testIndividualResults,
        studentTestContent,
        updateStudentIndividualOverallComment,
        jointResultsData,
        currentTestId,
    } = useTestReviewContext();
    const history = useHistory();

    const [currentStudentAnswer, setCurrentStudentAnswer] = useState<StudentTestAnswer>({});
    const [currentAnswerId, setCurrentAnswerId] = useState("");
    const [individualStudentOverallComment, setIndividualStudentOverallComment] = useState("");

    const [reviewChanged, setReviewChanged] = useState(false);

    useEffect(() => {
        if (resultId && resultId !== currentAnswerId) {
            // save any present changes from previous student
            if (reviewChanged) {
                updateAnswerDebounced.cancel(); //stop debounced function
                saveStudentTestResult(currentTestId, currentStudentAnswer.id ?? "", currentStudentAnswer);
                setReviewChanged(false);
            }

            setCurrentAnswerId(resultId);
            let currentResult = testIndividualResults.find((r) => r.id === resultId) || {};
            setCurrentStudentAnswer(currentResult);
            setIndividualStudentOverallComment(currentResult.teacherComment || "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultId, setCurrentStudentAnswer, testIndividualResults, currentAnswerId]);

    useEffect(() => {
        //change in overall results, must be mine
        if (resultId && resultId === currentAnswerId) {
            //only run if ready and not on start
            updateAnswerDebounced(saveStudentTestResult, currentTestId, currentStudentAnswer, setReviewChanged);
            if (!reviewChanged) setReviewChanged(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testIndividualResults]);

    useEffect(() => {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 50);
    }, [jointResultsData]);

    const t = useT();
    const t_back = t("Back to Overview", { _tags: "ReviewIndividualStudent" });
    const t_question = t("Question", { _tags: "ReviewIndividualStudent" });
    const t_answer = t("Answer", { _tags: "ReviewIndividualStudent" });
    const t_action = t("Actions", { _tags: "ReviewIndividualStudent" });
    const t_noQuestion = t("<< no question >>", { _tags: "ReviewIndividualStudent" });
    const t_noAnswer = t("<< empty answer >>", { _tags: "ReviewIndividualStudent" });
    const t_words = t("Words:", { _tags: "ReviewIndividualStudent" });
    const t_evaluationName = t("Individual Evaluation for:", { _tags: "ReviewIndividualStudent" });

    const t_next = t("Next Student", { _tags: "ReviewIndividualStudent" });
    const t_studentOverallComment = t("You can add a comment for the student here:", {
        _tags: "ReviewIndividualStudent",
    });

    const changeSelectedStudent = function (event: React.ChangeEvent<HTMLSelectElement>) {
        history.push(`/review/${testId}/student/${event.target.value}`);
    };

    let exercisesContent: Array<JSX.Element> = [];

    function getTextAnswerTitle(key: string) {
        return (
            <TextAnswerTitles key={key}>
                <AnswerTitle className={"big"}>{t_answer}</AnswerTitle>
                <ActionTitle>{t_action}</ActionTitle>
            </TextAnswerTitles>
        );
    }

    function getBasicTitle(key: string) {
        return (
            <BasicAnswerTitles key={key}>
                <QuestionTitle>{t_question}</QuestionTitle>
                <AnswerTitle>{t_answer}</AnswerTitle>
                <ActionTitle>{t_action}</ActionTitle>
            </BasicAnswerTitles>
        );
    }

    if (currentStudentAnswer.questionAnswers) {
        Object.keys(currentStudentAnswer.questionAnswers)
            .sort()
            .forEach((eId, i) => {
                const exerciseAnswer = currentStudentAnswer.questionAnswers![eId];
                const jointDataForExercise = jointResultsData[eId];

                let rowContent: Array<JSX.Element> = [];

                switch (exerciseAnswer.exerciseType) {
                    case "textOnly":
                        let stdAnsws = exerciseAnswer.studentAnswers;
                        if (stdAnsws && stdAnsws[0]) {
                            let stdAnsw = stdAnsws[0];
                            rowContent.push(getTextAnswerTitle("title_" + eId));
                            rowContent.push(
                                <TextAnswerRowWrapper key={"only_" + stdAnsw.id}>
                                    <TextAnswerContent>
                                        <HighlightedAnswer grade={stdAnsw.teacherGrade}>
                                            {stdAnsw.studentAnswer || t_noAnswer}
                                        </HighlightedAnswer>
                                    </TextAnswerContent>
                                    <ActionsWrapper>
                                        <TestReviewIndStudentActions
                                            exerciseId={eId}
                                            answerId={stdAnsw.id!}
                                            resultId={resultId}
                                        />
                                    </ActionsWrapper>
                                </TextAnswerRowWrapper>
                            );
                        }
                        break;
                    case "jumbledWords":
                        let jumbledAnswers = exerciseAnswer.studentAnswers;
                        if (jumbledAnswers && jumbledAnswers[0]) {
                            let jumbledAnswer = jumbledAnswers[0];
                            rowContent.push(getTextAnswerTitle("title_" + eId));
                            rowContent.push(
                                <TextAnswerRowWrapper key={"jumbl_" + jumbledAnswer.id}>
                                    <TextAnswerContent>
                                        <HighlightedAnswer grade={jumbledAnswer.teacherGrade}>
                                            {jumbledAnswer.studentAnswer || t_noAnswer}
                                        </HighlightedAnswer>
                                    </TextAnswerContent>
                                    <ActionsWrapper>
                                        <TestReviewIndStudentActions
                                            exerciseId={eId}
                                            answerId={jumbledAnswer.id!}
                                            resultId={resultId}
                                        />
                                    </ActionsWrapper>
                                </TextAnswerRowWrapper>
                            );
                        }
                        break;
                    default:
                        rowContent.push(getBasicTitle("title" + eId));
                        exerciseAnswer.studentAnswers?.forEach((sa, index) => {
                            let hasInconsistentGrades = false;
                            let inconsCorrectCount = 0,
                                inconsIncorrectCount = 0;
                            if (sa.id && jointDataForExercise.answers.hasOwnProperty(sa.id)) {
                                // console.log("dataAt", )
                                let jointAnswers = jointDataForExercise.answers[sa.id];
                                let commonAnswer = jointAnswers.find(
                                    (a) => a.answerOwner && a.answerOwner.find((aO) => aO.answerId === resultId)
                                );

                                if (commonAnswer && commonAnswer.gradeCounts) {
                                    let counts = commonAnswer.gradeCounts;
                                    hasInconsistentGrades =
                                        counts.hasOwnProperty("correct") &&
                                        counts["correct"] > 0 &&
                                        counts.hasOwnProperty("incorrect") &&
                                        counts["incorrect"] > 0;
                                    if (hasInconsistentGrades) {
                                        inconsCorrectCount = counts["correct"];
                                        inconsIncorrectCount = counts["incorrect"];
                                    }
                                }
                            }

                            let QuestionTextComponent;
                            switch (exerciseAnswer.exerciseType) {
                                case "verbTraining":
                                    QuestionTextComponent = (
                                        <BaseQuestionContent>
                                            {sa.verbName} ({sa.conjugationName})
                                        </BaseQuestionContent>
                                    );
                                    break;
                                case "fillInTheGap":
                                    QuestionTextComponent = (
                                        <BaseQuestionContent>
                                            [{sa.question}]
                                            <br />
                                            {sa.gap_sentence}
                                        </BaseQuestionContent>
                                    );
                                    break;
                                default:
                                    QuestionTextComponent = (
                                        <BaseQuestionContent>
                                            {sa.answer ? sa.answer : sa.question || t_noQuestion}
                                        </BaseQuestionContent>
                                    );
                            }
                            rowContent.push(
                                <BaseAnswerRowWrapper key={"row_" + sa.id + "_" + index}>
                                    {QuestionTextComponent}
                                    <BaseAnswerContent>
                                        <HighlightedAnswer grade={sa.teacherGrade}>
                                            {sa.studentAnswer || t_noAnswer}
                                        </HighlightedAnswer>
                                        {hasInconsistentGrades && (
                                            <InconsistentGradeOnExerciseIcon
                                                correct={inconsCorrectCount}
                                                incorrect={inconsIncorrectCount}
                                                tooltipId={"tooltipReviewIndividualStudent"}
                                            />
                                        )}
                                    </BaseAnswerContent>
                                    <ActionsWrapper>
                                        <TestReviewIndStudentActions
                                            exerciseId={eId}
                                            answerId={sa.id!}
                                            resultId={resultId}
                                            key={eId + "_actions"}
                                        />
                                    </ActionsWrapper>
                                </BaseAnswerRowWrapper>
                            );
                        });
                }

                let exerciseDesc = "";
                let exerciseStarterText = "";
                let exerciseExtraInfo: string = "";
                let exerciseNum: number = Number(eId.replace("e", ""));
                if (studentTestContent && studentTestContent.content && studentTestContent.content[exerciseNum - 1]) {
                    exerciseDesc = studentTestContent.content[exerciseNum - 1].extraComments || "";

                    if (
                        studentTestContent.content[exerciseNum - 1].questionMode === "jumbledWords" &&
                        studentTestContent.content[exerciseNum - 1].fillWords
                    ) {
                        exerciseExtraInfo +=
                            t_words + " " + (studentTestContent.content[exerciseNum - 1].fillWords?.join(" || ") || "");
                    }

                    if (studentTestContent.content[exerciseNum - 1].textFieldStarterText) {
                        exerciseStarterText = studentTestContent.content[exerciseNum - 1].textFieldStarterText || "";
                    }
                }

                exercisesContent.push(
                    <ExamContentRow key={eId}>
                        <ExerciseTitleWrapper>
                            <T
                                _str="Exercise {exerciseNo}"
                                exerciseNo={exerciseNum}
                                _inline
                                _tags="ReviewIndividualStudent"
                            />
                        </ExerciseTitleWrapper>
                        <ExerciseDescription>
                            {exerciseDesc}
                            {exerciseExtraInfo && <br />}
                            {exerciseExtraInfo && exerciseExtraInfo}
                            {exerciseStarterText && <br />}
                            {exerciseStarterText}
                        </ExerciseDescription>
                        <ExerciseRowContent>{rowContent}</ExerciseRowContent>
                    </ExamContentRow>
                );
            });
    }

    const currentExerciseIndex = testIndividualResults.findIndex((i) => i.id === resultId);
    const isLastExerciseId = currentExerciseIndex === testIndividualResults.length - 1;

    const goToNextView = function () {
        let path = `/review/${testId}`;

        if (!isLastExerciseId) {
            path += "/student/" + testIndividualResults[currentExerciseIndex + 1].id;
        }

        history.push(path);
    };

    // Use effect to debounce the setting of the overall comment to avoid rendering bugs, and many renders, saving to data structure.
    useEffect(() => {
        updateCommentDebounced(updateStudentIndividualOverallComment, individualStudentOverallComment, resultId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [individualStudentOverallComment]);

    return (
        <Wrapper>
            <UserNameAndSelectorWrapper>
                <EvaluationName>{t_evaluationName}</EvaluationName>
                <NameSelector
                    value={resultId}
                    onChange={changeSelectedStudent}
                >
                    {Object.values(testIndividualResults).map((r, i) => (
                        <option
                            key={"sel_" + i}
                            value={r.id}
                        >
                            {r.studentName} - ({r.sentTime ? getDateFromMilliseconds(r.sentTime) : ""})
                        </option>
                    ))}
                </NameSelector>
            </UserNameAndSelectorWrapper>
            <ExamContentWrapper>{exercisesContent}</ExamContentWrapper>

            <TestReviewSummaryBox resultId={resultId} />

            <StudentOverallCommentWrapper key={resultId + "_comment"}>
                <StudentOverallCommentText>{t_studentOverallComment}</StudentOverallCommentText>
                <CommentTextArea
                    value={individualStudentOverallComment}
                    onChange={(e) => {
                        setIndividualStudentOverallComment(e.target.value);
                    }}
                />
            </StudentOverallCommentWrapper>

            <StudentsCTAsWrapper>
                {!isLastExerciseId && (
                    <StyledButton
                        buttonStyle={"BLANK"}
                        onClick={() => {
                            history.push("/review/" + testId);
                        }}
                    >
                        {t_back}
                    </StyledButton>
                )}
                <StyledButton
                    onClick={goToNextView}
                    buttonStyle={"PRIMARY"}
                >
                    {isLastExerciseId ? t_back : t_next}
                </StyledButton>
            </StudentsCTAsWrapper>
            <ReactTooltip
                effect="solid"
                place={"right"}
                id={"tooltipReviewIndividualStudent"}
            />
        </Wrapper>
    );
};

export default ReviewIndividualStudent;
