import { shuffle } from "lodash";
import {
    TestDetailsWord,
    TestQuestionItem,
    TestQuestionModel,
    VerbtrainingAnswerItem,
    VerbtrainingSelectedWord,
    VerbtrainingTensesResponseItem,
} from "p6m-p6u";
import { ExerciseDirections } from "../enums/directions";

export const removeAnswersFromTest = (
    testContent: Array<TestQuestionModel>,
    availableVerbTrainingTenses: Array<VerbtrainingTensesResponseItem>
): Array<TestQuestionItem> => {
    let newTest: Array<TestQuestionItem> = [];

    testContent.forEach((t, index) => {
        let newTestElement: TestQuestionItem = {};
        const { selectedWords, selectedTenses, ...exercise } = t;
        switch (t.questionMode) {
            case "fillInTheGap":
                newTestElement = { ...exercise };
                const exerciseDirection = exercise?.direction ?? ExerciseDirections.QUESTION_TO_ANSWER; // fall back to standard direction for legacy tests without direction

                const fillInTheGapWords: Array<string> = [];
                const newSelectedWords: any = [];

                (t.selectedWords as Array<TestDetailsWord>)?.forEach((word) => {
                    const { answer, question, answer_example, ...item } = word;

                    newSelectedWords.push(
                        exerciseDirection === ExerciseDirections.ANSWER_TO_QUESTION
                            ? { ...item, answer }
                            : { ...item, question }
                    );
                    fillInTheGapWords.push(
                        (exerciseDirection === ExerciseDirections.ANSWER_TO_QUESTION ? answer : question) ?? ""
                    );
                });

                newTestElement.selectedWords = newSelectedWords ?? [];
                newTestElement.fillWords = fillInTheGapWords;
                break;
            case "standard":
                newTestElement = { ...exercise };
                if (Number(exercise.direction) === 0) {
                    newTestElement.selectedWords =
                        (t.selectedWords as Array<TestDetailsWord>)?.map(({ answer, ...item }) => item) || [];
                } else if (Number(exercise.direction) === 1) {
                    newTestElement.selectedWords =
                        (t.selectedWords as Array<TestDetailsWord>)?.map(({ question, ...item }) => item) || [];
                } else {
                    newTestElement.selectedWords =
                        (t.selectedWords as Array<TestDetailsWord>)?.map(
                            ({ answer, question, isQuestionShown, ...item }) =>
                                isQuestionShown ? { ...item, question } : { ...item, answer }
                        ) || [];
                }
                break;
            case "writeSentence":
                newTestElement = { ...exercise };
                // Support for direction in the exercises created with the new UI.
                if (typeof exercise.direction !== "undefined") {
                    newTestElement.selectedWords =
                        (t.selectedWords as Array<TestDetailsWord>)?.map(
                            ({ answer, question, isQuestionShown, ...item }) =>
                                isQuestionShown || exercise.direction !== ExerciseDirections.ANSWER_TO_QUESTION
                                    ? { ...item, question }
                                    : { ...item, answer }
                        ) || [];
                } else {
                    newTestElement.selectedWords = (t.selectedWords as Array<TestDetailsWord>)?.map(
                        ({ answer, ...item }) => item
                    );
                }
                break;
            case "jumbledWords":
                newTestElement = { ...exercise };
                let jumbledWordFillWords: Array<string> = [];
                // Support for direction in the exercises created with the new UI.
                if (typeof exercise.direction !== "undefined") {
                    newTestElement.selectedWords =
                        (t.selectedWords as Array<TestDetailsWord>)?.map(
                            ({ answer, question, isQuestionShown, ...item }) => {
                                if (typeof isQuestionShown !== "undefined") {
                                    return isQuestionShown ? { ...item, question } : { ...item, answer };
                                } else {
                                    return exercise.direction === ExerciseDirections.ANSWER_TO_QUESTION
                                        ? { ...item, answer }
                                        : { ...item, question };
                                }
                            }
                        ) || [];
                    if (exercise.direction === ExerciseDirections.ANSWER_TO_QUESTION) {
                        newTestElement.selectedWords.forEach((w) =>
                            w.answer ? jumbledWordFillWords.push(w.answer) : false
                        );
                    } else {
                        newTestElement.selectedWords.forEach((w) =>
                            w.question ? jumbledWordFillWords.push(w.question) : false
                        );
                    }
                } else {
                    newTestElement.selectedWords =
                        (t.selectedWords as Array<TestDetailsWord>)?.map(({ answer, ...item }) => item) || [];
                    newTestElement.selectedWords.forEach((w) =>
                        w.question ? jumbledWordFillWords.push(w.question) : false
                    );
                }

                newTestElement.fillWords = jumbledWordFillWords;
                break;
            case "verbinden":
                newTestElement = { ...exercise };
                newTestElement.selectedWords = t.selectedWords;
                let answerWords: Array<string> = [];
                (t.selectedWords as Array<TestDetailsWord>)?.forEach((w) =>
                    w.answer ? answerWords.push(w.answer) : false
                );
                newTestElement.fillWords = answerWords;
                break;
            case "verbTraining":
                const {
                    direction,
                    includeEmptyFreeTextField,
                    answerEmptyOn,
                    answerEmptyOnRand,
                    blankSpaceType,
                    selectedTenses,
                    ...rest
                } = t;
                // Creating an object with the info from conjugations needed for easier processing
                let readableSelectedTenses: Array<VerbtrainingTensesResponseItem> = [];
                selectedTenses?.forEach((st) => {
                    let av = availableVerbTrainingTenses.find((v) => v.ID === st);
                    if (av && !av.hidden) {
                        let { modus, uiDisplayOnly } = av;
                        readableSelectedTenses.push({
                            ID: st,
                            name: av.displayName ? av.displayName : av.name,
                            modus,
                            uiDisplayOnly,
                        });
                    }
                });

                newTestElement = { gapMode: blankSpaceType, ...rest };
                // Looping through the words to add them to the newTestElement
                newTestElement.selectedWords = (t.selectedWords as Array<VerbtrainingSelectedWord>)?.map((sw) => {
                    const { ID, name, conjugations } = sw;
                    let objToReturn = {
                        id: ID,
                        name: name,
                        requestedConjugations: [] as Array<VerbtrainingAnswerItem>,
                    };

                    // Checking which should be the gapTenses in the exercise.
                    // If random was selected, we shuffle everytime the selected tenses for the exercise
                    // and remove the ones that are gonna be empty.
                    // If it was a column option, then we just pass the ones that were selected by the user
                    let gapTenses: Array<number>;
                    if (blankSpaceType === "random") {
                        let answerEmptyOnRandCopy = answerEmptyOnRand || 1;
                        readableSelectedTenses = shuffle(readableSelectedTenses);
                        let reducedTenses = [...readableSelectedTenses];
                        reducedTenses.splice(0, reducedTenses.length - answerEmptyOnRandCopy);
                        gapTenses = reducedTenses.map((t) => t.ID || 0) || [];
                    } else {
                        if (answerEmptyOn) {
                            gapTenses = [...answerEmptyOn];
                        }
                    }

                    // Looping through all the selected tenses, and then decided if we include them in the solution
                    // or not.
                    readableSelectedTenses.forEach((t) => {
                        let conj = { ID: t.ID, conjName: t.name, answer: "" } as VerbtrainingAnswerItem;
                        if (!t.ID) {
                            return;
                        }
                        let includeSolution = gapTenses.indexOf(t.ID) === -1;
                        if (includeSolution) {
                            var isTenseDisplayOnly = t.modus && t.uiDisplayOnly;
                            if (isTenseDisplayOnly) {
                                switch (t.ID) {
                                    case 16:
                                        conj.answer = sw.germanTranslation || "";
                                        break;
                                    case 17:
                                        conj.answer = sw.infinitive || "";
                                        break;
                                    case 15:
                                        conj.answer = sw.gerund || "";
                                        break;
                                }
                            } else {
                                var conjugation = conjugations?.find((c) => t.ID === c.tenseID);
                                conj.answer = (conjugation && conjugation.conjugated) || "?";
                            }
                        }
                        objToReturn.requestedConjugations.push(conj);
                    });
                    return objToReturn;
                });
                break;
            case "textOnly":
                let { questionMode, extraComments, textFieldStarterText } = t;
                newTestElement = { questionMode, extraComments, textFieldStarterText: textFieldStarterText || "" };
                break;
            default:
                break;
        }
        //Adding an Id to the question before saving it to the server
        newTestElement.id = "e" + (index + 1);
        newTest.push(newTestElement);
    });
    return newTest;
};

export const getContentErrorsDescription = (errors: Array<string>) => {
    let errorDescs: Array<string> = [];
    errors.forEach((e) => {
        if (e === "verbinden") {
            errorDescs.push("Wörter verbinden: Wählen Sie mindestens 3 und maximal 5 Vokabeln aus.");
        } else if (e === "textOnly") {
            errorDescs.push("Individuelle Textaufgabe: Bitte fügen Sie ein leeres Textfeld hinzu.");
        }
    });

    return errorDescs;
};

export const checkContentForErrors = (testContent: Array<TestQuestionModel>) => {
    let contentErrors: Array<string> = [];
    testContent.forEach((t) => {
        if (t.questionMode === "textOnly") {
            if (t.includeEmptyFreeTextField === false) {
                if (contentErrors.indexOf("textOnly") === -1) {
                    contentErrors.push("textOnly");
                }
            }
        }

        if (t.questionMode === "verbinden" && t.selectedWords && t.selectedWords.length > 5) {
            if (contentErrors.indexOf("verbinden") === -1) {
                contentErrors.push("verbinden");
            }
        }
    });
    return getContentErrorsDescription(contentErrors);
};
