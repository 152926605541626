import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { shuffle } from "lodash";
import DesignConstants from "../../../constants/DesignConstants";
import { TestDetailsItem } from "p6m-p6u";
import PDFTable from "../pdfSubComponents/PDFTable";
import { sanitizePDFText } from "../../../helpers/PDFHelper";

interface PDFConnectWordsExerciseProps {
    exercise: TestDetailsItem;
    isSolution?: boolean;
}

const PDFConnectWordsExercise: React.FC<PDFConnectWordsExerciseProps> = (props) => {
    const renderConnectWords = () => {
        const selectedWordsQuestions =
            props.exercise.selectedWords?.map((w) => ("id" in w && w.id ? w.question : "")) ?? [];
        const selectedWordsAnswers =
            props.exercise.selectedWords?.map((w) => ("id" in w && w.id ? w.answer : "")) ?? [];

        const renderWordsColumn = (words: Array<string | undefined>, isLeftColumn: boolean) => {
            return (
                words?.length > 0 && (
                    <View style={styles.column}>
                        {words.map((word, index) => (
                            <View
                                style={styles.wordContainer}
                                key={`word_${index}`}
                            >
                                <View style={[styles.circleContainer, isLeftColumn ? { right: -12 } : { left: -12 }]}>
                                    {isLeftColumn && <Text style={styles.circleContainerText}>{String.fromCharCode(65 + index)}</Text>}
                                </View>

                                <Text
                                    key={`word_${index}`}
                                    style={[styles.text, { color: "white" }]}
                                >
                                    {sanitizePDFText(word)}
                                </Text>
                                {/* Necessary to center the text within the border: */}
                                <Text
                                    key={`word_${index}`}
                                    style={[styles.text, { position: "absolute", alignSelf: "center" }]}
                                >
                                    {sanitizePDFText(word)}
                                </Text>
                            </View>
                        ))}
                    </View>
                )
            );
        };

        return (
            <View style={styles.container}>
                {renderWordsColumn(selectedWordsQuestions, true)}
                {renderWordsColumn(shuffle(selectedWordsAnswers), false)}
            </View>
        );
    };

    return !props.isSolution ? (
        renderConnectWords()
    ) : (
        <PDFTable
            selectedWords={props.exercise?.selectedWords ?? []}
            showAllQuestions
            showAllAnswers
        />
    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_XL,
        paddingHorizontal: DesignConstants.PDF.GAP_M,
    },
    column: {
        width: "100%",
        flexDirection: "column",
        gap: DesignConstants.PDF.GAP_XS,
        justifyContent: "space-between",
    },
    text: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        textAlign: "center",
    },
    wordContainer: {
        borderRadius: DesignConstants.PDF.BORDER_RADIUS_M,
        borderColor: DesignConstants.PDF.GREY,
        borderWidth: DesignConstants.PDF.BORDER_WIDTH_S,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: DesignConstants.PDF.GAP_XS,
        paddingHorizontal: DesignConstants.PDF.GAP_S,
    },
    circleContainer: {
        border: 1,
        position: "absolute",
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 24,
        backgroundColor: "white",
    },
    circleContainerText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.FONT_WEIGHTS.BOLD,
        textAlign: "center",
    }
});

export default PDFConnectWordsExercise;
