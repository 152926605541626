import { useState } from "react";
import { useT } from "@transifex/react";
import { useMediaQuery } from "react-responsive";

import { ExercisesNumbersWrapper, ToolTipWrapper, ToolTipText, ExerciseNumber } from "./StyledComponents";

export interface UsedInExerciseNumberProps {
    isHoveringRow: boolean;
    numsOfUsedExercises: number[];
    isLimitedToTwo?: boolean;
}

const UsedInExerciseNumber: React.FC<UsedInExerciseNumberProps> = (props) => {
    const [showExerciseNumberToolTip, setShowExerciseNumberToolTip] = useState(false);

    const t = useT();
    const t_alreadyUsedIn = t("Already used in", { _tags: "CreateTest,CardListingCardItem" });

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    const isMobileView = useMediaQuery({ query: lowerBorderQuery });

    const mobileMaxDisplay = 2;
    const desktopMaxDisplay = 5;

    const maxDisplayCount = props.isLimitedToTwo || isMobileView ? mobileMaxDisplay : desktopMaxDisplay;
    const displayedExercises = props.numsOfUsedExercises.slice(0, maxDisplayCount);
    const hasMoreExercises = props.numsOfUsedExercises.length > maxDisplayCount;

    return (
        <ExercisesNumbersWrapper
            onMouseEnter={() => setShowExerciseNumberToolTip(true)}
            onMouseLeave={() => setShowExerciseNumberToolTip(false)}
            onClick={(e) => e.stopPropagation()}
            isLimitedToTwo={props.isLimitedToTwo}
        >
            {showExerciseNumberToolTip && (
                <ToolTipWrapper totalCount={displayedExercises.length + (hasMoreExercises ? 1 : 0)}>
                    <ToolTipText>{t_alreadyUsedIn}</ToolTipText>
                </ToolTipWrapper>
            )}
            {displayedExercises?.map((n: number) => (
                <ExerciseNumber
                    key={`exerciseNumber_${n}`}
                    isHoveringRow={props.isHoveringRow}
                >
                    {n}
                </ExerciseNumber>
            ))}
            {props.numsOfUsedExercises?.length > maxDisplayCount && (
                <ExerciseNumber isHoveringRow={props.isHoveringRow}>{`...`}</ExerciseNumber>
            )}
        </ExercisesNumbersWrapper>
    );
};

export default UsedInExerciseNumber;
