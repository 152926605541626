import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { TeacherTestDetails } from "p6m-p6u";
import PDFFullTest from "../../../complex/pdfCreation/PDFFullTest";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { logEventWithProps } from "../../../logging/Logger";
import { useT } from "@transifex/react";

import {
    PDFDownloadInnerButton,
    PDFDownloadButtonContainer, //necessary to remove the link styling
} from "./StyledComponents";

interface PDFDownloadButtonProps {
    buttonText: string;
    fileName: string;
    testDetails?: TeacherTestDetails;
    secondary?: boolean;
    isSolution?: boolean;
}

const PDFDownloadButton: React.FC<PDFDownloadButtonProps> = (props) => {
    const { canLogEvent, loadInitArticleIdData } = useTestCreationContext();

    const t = useT();
    const t_loading = t("Loading", { _tags: "CreateTest,PDFCreation" });

    return (
        <PDFDownloadButtonContainer>
            <PDFDownloadLink
                document={
                    <PDFFullTest
                        testDetails={props.testDetails}
                        bookArticleId={props.testDetails?.articleId}
                        loadInitArticleIdData={loadInitArticleIdData}
                        isSolution={props.isSolution}
                    />
                }
                fileName={props.fileName}
                onClick={() => {
                    if (canLogEvent()) {
                        logEventWithProps("Vokabeltest v2 Downloaded", {
                            testId: props?.testDetails?.id ?? "",
                            isSolution: props.isSolution,
                        });
                    }
                }}
            >
                {({ loading }) => (
                    <PDFDownloadInnerButton
                        icon={"download"}
                        iconPosition={"RIGHT"}
                        buttonStyle={props.secondary ? "BLANK" : undefined}
                    >
                        {loading ? t_loading : props.buttonText}
                    </PDFDownloadInnerButton>
                )}
            </PDFDownloadLink>
        </PDFDownloadButtonContainer>
    );
};

export default PDFDownloadButton;
