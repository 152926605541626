/* DESIGN CONSTANT INSTRUCTIONS __________________________________________________________

    Please check the files you touch:
        - Please replace any values with the ones from here.
        - Use whichever color or space is the closest.
        - If there is no value that fits your variable, check the p6 design and create it.

    Content:
        - colors:
            - P6: their specific colors like the signature orange
            - all kinds of greys
            - backgrounds: colors that are used as background
            - transparent colors
        - opacity: values like 0.5 used as opacity
        - borders: kinds of borders
        - border radius
        - font sizes: currently named after their values
        - font weights
        - spaces: meaningful important ones only

*/

const DesignConstants = {
    COLORS: {
        // P6 specific colors from their color palettes - - -
        P6_ORANGE_DARKER: "#CC6600", // PRIMARY 500
        P6_ORANGE: "#fc7c00",
        P6_ORANGE_BRIGHTER: "#fca24c", // as used in gradients
        P6_ORANGE_PASTEL: "#fcca97",
        P6_ORANGE_MID_PASTEL: "#FDE3C8",
        P6_ORANGE_LIGHT_PASTEL: "#FEF5EC",

        // additional colors - - - - - - - - - - - - - - - -
        // add here
        PURPLE: "#af00cc",
        RED: "#eb3800",
        RED_BRIGHTER: "#ff7b52",
        YELLOW: "#f5cc00",
        GREEN_BRIGHTER: "#9dda6c",
        GREEN: "#4c8321",
        BLUE: "#00a3ff",

        //greys - - - - - - - - - - - - - - - - - - - - - - -
        LIGHTEST_GREY: "#e8e8e8", // NEUTRAL 200, used for borders
        LIGHT_GREY: "#CCCCCC", // NEUTRAL 250

        MID_GREY: "#999999", // NEUTRAL 300, used for some borders and lines

        DARK_GREY: "#666666", // NEUTRAL 400, used as font color
        DARKEST_GREY: "#333333", // NEUTRAL 500

        // background colors - - - - - - - - - - - - - - - -
        BACKGROUND_LIGHT: "#f6f6f6", //NEUTRAL 100 a very light grey for backgrounds

        // transparent colors - - - - - - - - - - - - - - -
        // try to use one of the greys above, but
        // - if this is not possible or
        // - it's a shadow
        // please feel free to add your transparent color here:
        TRANSPARENT_BLACK: "rgba(0,0,0,0.1)", // some kind of special grey
        TRANSPARENT_WHITE: "rgba(0,0,0,0.4)",
        TRANSPARENT_GREY: "#6666660d",

        TRANSPARENT_SUFFIX: "11",
    },
    OPACITY: {
        // 1 (visible) and 0 (invisible) don't have to be added here
        THREE: 0.3,
        HALF: 0.5,
        SIX: 0.6,
        SEVEN: 0.7,
    },
    BORDERS: {
        THIN: "solid 1px",
        SEMI_THIN: "solid 1.5px",
        MEDIUM: "solid 2px",

        // more specific borders - - - - - - - - - - - - - -
        THIN_GREY: "solid 1px #e8e8e8",
    },
    BORDER_RADIUS: {
        BUTTON: "25px", // as used in a StyledButton
        SMALL_CONTAINER: "1rem", // a small container too high to be rounded 100% on its edges
        XSMALL_CONTAINER: "0.5rem",
    },
    FONT_SIZES: {
        // named after their numbers for now. Will be minimized and renamed in a future issue.

        XXS: "0.6rem", // around 10px
        XS: "0.75rem", // around 12px
        S: "0.9rem", // around 14px SMALL
        M: "1rem", //16px
        L: "1.1rem", // around 18px LARGE
        XL: "1.5rem", // 24px XLARGE
        XXL: "2rem", // 32px

        INHERIT: "inherit",
    },
    FONT_WEIGHTS: {
        NORMAL: 400,
        MEDIUM: 500,
        SEMI_BOLD: 600,
        BOLD: 700,
        EXTRA_BOLD: 800,
        BLACK: 900,
    },
    SPACES: {
        S_OLD: "0.8rem",

        /* meaningful margins and paddings that get reused a lot. For now only:
            - margins between white boxes and / or buttons
            - paddings within these white boxes.
        */
        // new - please use these where possible
        XXS: "0.25rem", // 4px
        XS: "0.5rem", // 8px
        S: "1rem", // 16px, small standard gap between interactive elements e.g. between buttons or exercise types
        M: "1.5rem", // 24px
        L: "2rem", // 32px
        XL: "3rem", //48px
        XXL: "4rem", //64px
        XXXL: "5rem", //80px
        XXXXL: "6rem", //160px
    },
    SIZES: {
        ICON_XXS: "1rem",
        ICON_XS: "2.2rem",
        ICON_M: "3rem",
        ICON_L: "3.5rem",
    },
    PDF: {
        FONTSIZE_REGULAR: 11,
        FONTSIZE_EXERCISE_HEADER: 12,
        FONTSIZE_PAGE_HEADER: 20,

        GAP_XXS: "0.125cm",
        GAP_XS: "0.25cm",
        GAP_S: "0.5cm",
        GAP_M: "1cm",
        GAP_L: "1.5cm",
        GAP_XL: "2.2cm",
        GAP_XXL: "2.8cm",

        PROXIMA_FONT: "ProximaNovaPDF",
        REGULAR: 400,
        SEMIBOLD: 600,

        BORDER_WIDTH_S: 1,
        BORDER_RADIUS_M: 20,

        GREY: "#cccccc", //used for lines

        MIN_PRESENCE_AHEAD: 60, // this part of the following sibling elements (height)
        // needs to be on the same page
    },
};

export default DesignConstants;
