import * as React from "react";
import DesignConstants from "../../constants/DesignConstants";
import { Arrow, Button, ChildrenWrapper, ArrowWrapper, CenteringDiv } from "./StyledComponents";

export interface StyledButtonProps {
    className?: string;
    arrowDirection?: "NONE" | "LEFT" | "RIGHT";
    children?: React.ReactNode;
    disabled?: boolean;
    buttonStyle?: "PRIMARY" | "BLANK" | "LIGHT_BORDER" | "ORANGE_BLANK";
    textStyle?: "UPPERCASE" | "CAPITALIZE" | "NO_TRANSFORMATION";
}

const StyledButton: React.FC<StyledButtonProps & React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    let component;

    const { children, className, ...rest } = props;
    const buttonProps: React.HTMLAttributes<HTMLButtonElement> = rest;

    switch (props.arrowDirection) {
        case "LEFT":
            component = (
                <Arrow
                    name={"chevron-left"}
                    color={props.buttonStyle === "BLANK" ? DesignConstants.COLORS.DARK_GREY : "white"}
                />
            );
            break;
        case "RIGHT":
            component = (
                <Arrow
                    name={"chevron-right"}
                    color={props.buttonStyle === "BLANK" ? DesignConstants.COLORS.DARK_GREY : "white"}
                />
            );
            break;
        case "NONE":
        default:
            component = null;
            break;
    }

    return (
        <Button
            className={`${props.buttonStyle || ""} ${props.arrowDirection || ""}  ${props.textStyle || ""} ${
                props.className || ""
            }`}
            {...buttonProps}
        >
            {props.arrowDirection !== "NONE" && <CenteringDiv />}
            <ChildrenWrapper>{props.children}</ChildrenWrapper>
            {props.arrowDirection !== "NONE" && <ArrowWrapper>{component}</ArrowWrapper>}
        </Button>
    );
};

export default StyledButton;
