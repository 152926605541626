// LIBRARIES
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTestReviewContext } from "../../context/TestReviewContext";
import styled from "styled-components/macro";

export interface ReviewTestWrapperProps {
    children: JSX.Element;
}

const Wrapper = styled.div``;

const ReviewTestWrapper: React.FC<ReviewTestWrapperProps> = (props) => {
    const { testId } = useParams<{ testId: string }>();
    const { currentTestId, setCurrentTestId, testIndividualResults } = useTestReviewContext();

    useEffect(() => {
        // console.log("ReviewTestWrapper::useEffect", testId, currentTestId);
        if (testId && currentTestId !== testId) {
            setCurrentTestId(testId);
        }
    }, [testId, currentTestId, setCurrentTestId]);

    if (testIndividualResults.length === 0) {
        return <p>Loading....</p>;
    }

    return <Wrapper>{props.children}</Wrapper>;
};

export default ReviewTestWrapper;
