import styled from "styled-components";

export const InnerTextWrapper = styled.div`
    width: 70%;
    margin-bottom: 0;
`;

export const CancelText = styled.p`
    text-align: center;
    line-height: 1.5rem;
`;
