import { Exercises, ExerciseTypes } from "../enums/exercises";
import { getExerciseTypeByQuestionMode } from "../helpers/ExerciseTypesHelper";
import useExerciseDescriptionStructure, { Locales, SentenceVariants } from "./useExerciseDescriptionStructure";

const useExerciseDescription = () => {
    const { availableExerciseDescriptions } = useExerciseDescriptionStructure();

    function getExerciseSentences(exerciseType: Exercises, locale: Locales) {
        return availableExerciseDescriptions[exerciseType][locale];
    };

    function getDefaultDescription(exerciseType: Exercises, locale: Locales) {
        return availableExerciseDescriptions[exerciseType][locale][0][SentenceVariants.LONG_SENTENCE]; // uses index 0 as ths is the default sentence in the availableExerciseDescriptions matrix
    };

    function checkIfDescriptionIsUnchanged(exerciseType: Exercises, currentText: string) {
        const exerciseSentences = getExerciseSentences(exerciseType, Locales.DE);
        return exerciseSentences.some((sentence) => sentence[SentenceVariants.LONG_SENTENCE] === currentText);
    };

    function reverseTextInputExerciseType(currentQuestionMode: string) {
        return currentQuestionMode === ExerciseTypes.get(Exercises.WRITE_SENTENCE)
            ? ExerciseTypes.get(Exercises.CREATE_TEXT)
            : ExerciseTypes.get(Exercises.WRITE_SENTENCE);
    };

    const getUpdatedDescriptionForTextInput = (currentQuestionMode: string, currentText: string) => {
        const reversedExerciseType = reverseTextInputExerciseType(currentQuestionMode);

        const currentExerciseType = getExerciseTypeByQuestionMode(currentQuestionMode);
        const oppositeExerciseType = getExerciseTypeByQuestionMode(reversedExerciseType ?? "");

        if (!currentExerciseType || !oppositeExerciseType) return null;

        const isPresentInCurrent = checkIfDescriptionIsUnchanged(currentExerciseType, currentText);
        const isPresentInOpposite = checkIfDescriptionIsUnchanged(oppositeExerciseType, currentText);

        if (isPresentInCurrent || !isPresentInOpposite) {
            return "";
        } else {
            return getDefaultDescription(currentExerciseType, Locales.DE);
        }
    };

    return { getExerciseSentences, getDefaultDescription, getUpdatedDescriptionForTextInput };
};

export default useExerciseDescription;
