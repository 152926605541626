import styled, { keyframes } from "styled-components";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import StyledButton from "../../../basic/styledButton/StyledButton";
import DesignConstants from "../../../constants/DesignConstants";
import { StyledPhase6Icon } from "../../../complex/create/exerciseItem/exerciseInfo/directionSelector/StyledComponents";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";

export const Wrapper = styled.div`
    padding-bottom: ${DesignConstants.SPACES.M};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding-bottom: ${DesignConstants.SPACES.XXXL};
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const ExercisesContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.S};
    padding: ${DesignConstants.SPACES.XS};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        flex-direction: column;
    }
`;

export const StyledButtonWithoutIcon = styled(StyledButton)`
    min-width: 30% !important;
`;

export const AddExerciseButton = styled(StyledButtonWithIcon)`
    align-self: start;
`;

export const TestSyncStatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
    height: ${DesignConstants.SPACES.L};
    margin-bottom: ${DesignConstants.SPACES.XS};
`;

// animation ending at 359 degree to have a clean loop without a duplicated frame at the end / beginning of it
const spinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`;

export const AnimatedLoadingIcon = styled(StyledPhase6Icon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.DARK_GREY};
    animation: ${spinAnimation} 1.5s infinite;
`;

export const TestSyncStatusIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
`;

export const TestSyncStatusText = styled.div`
    font-size: ${DesignConstants.FONT_SIZES.L};
`;

export const ExerciseContentWrapper = styled.div``;

export const ExerciseExtraCommentsWrapper = styled.div`
    max-width: 80vw;
    min-width: min(500px, 80vw);
`;

export const ExerciseExtraComments = styled.p`
    text-align: center;
`;

export const ExerciseWordsWrapper = styled.div`
    user-select: none;
    min-width: min(500px, 80vw);
    padding: 0 ${DesignConstants.SPACES.S};
`;

export const ExerciseWordsList = styled.ul`
    margin-block: 0;
    padding-inline-start: 0;
`;

export const ExerciseWordItem = styled.li``;

export const ExerciseItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.XS};
`;
