// LIBRARIES
import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { ThemeContext } from "styled-components/macro";
import { useT, UT } from "@transifex/react/dist";
import { StudentTestContent } from "p6m-p6u";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as NextArrow } from "../../../assets/icons/skip-card.svg";
import ButtonWithIcon from "../../../basic/buttonWithIcon/ButtonWithIcon";
import { ReactComponent as calendarIcon } from "../../../assets/icons/phase-interval.svg";
import { ReactComponent as timeIcon } from "../../../assets/icons/time.svg";
import { ReactComponent as helpIcon } from "../../../assets/icons/help.svg";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";
import {
    getDateTimeFromMilliseconds,
    getReadableTimeFromTimeLimit,
    isDeadlineAlreadyPassed,
} from "../../../helpers/DateTime";
import FlagIcon from "../../../basic/flagIcon/FlagIcon";

import Modal from "react-modal";
import InfoModal from "../../../complex/students/infoModal/InfoModal";
import MainBackgroundSquircle from "../../../basic/mainBackgroundSquircle/MainBackgroundSquircle";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";
import { getIsLocalTestRun, getIsTestRun } from "../../../helpers/Url";
import { cp } from "../../../config";

export interface IntroProps {
    goToPracticeAction: (studentName: string) => any;
    testData: StudentTestContent;
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
`;

const InfoHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        align-items: unset;
        flex-direction: row-reverse;
        & > div:nth-child(2) {
            margin: 0;
        }
    }
`;

const TestConditionsWrapper = styled(MainBackgroundSquircle)`
    width: 80%;
    padding: 2rem;
    overflow: auto;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        padding: 2rem;
    }
`;

const TestConditionsTeacherDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 3;
    text-align: center;
    margin-bottom: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 0 1rem;

        p {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const StartWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;

    > button {
        width: 80%;
        height: 60px;

        @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
            width: 100%;
        }
    }
`;

const TeacherNameWrapper = styled.div`
    text-align: center;
    flex: 6;
    margin: 0 0 1rem 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
`;

const QuestionIconWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
`;

const TestConditionsInfoWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: normal;
    width: 100%;
    text-align: center;
    flex: 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 2rem 0 0 0;
    }
`;

const InputModalTitleWrapper = styled.div``;

const InputModalTestTime = styled.div`
    color: ${(props) => props.theme.base.primaryColor};
    font-size: 1rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    > svg {
        width: 2rem;
    }

    > p {
        font-size: 1.1rem;
        font-weight: bold;
        margin: 0 0.25rem;
    }
`;

const TestConditionItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
    align-items: center;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 0 1rem;
    }

    &.orange {
        color: ${(props) => props.theme.base.primaryColor};
    }
`;

const TestConditionItemSeparator = styled.div`
    width: 1px;
    border-left: solid 1px #ccc;
    height: 100%;
`;

const NewTestTitle = styled.span`
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        font-weight: bold;
        margin: 0;
        font-size: 1.2rem;
    }
`;

const TeacherName = styled.span`
    font-size: 1.2rem;
    font-weight: bold;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        font-size: 1.2rem;
        font-weight: bold;
    }
`;

const StyledInput = styled.input`
    height: 60px;
    width: 80%;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: inset 0 5px 0 rgb(0 0 0 / 10%);
    border: none;
    text-align: center;
    font-size: 1.6rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        margin-bottom: 0;
    }
`;

const TestConditionIcon = styled(SvgIcon)`
    width: 2.5rem;
    margin-bottom: 0.3rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 2.5rem;
    }
`;

const TestConditionIconTitle = styled.p`
    font-size: 1.1rem;
    margin: 0 0 3px 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: none;
    }
`;

const TestConditionIconDesc = styled.p`
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 3px 0;
`;

const TestConditionsTitle = styled.p`
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    display: none;
`;

const TestConditionsGoodLuck = styled.p`
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    display: none;
`;

const TeacherComment = styled.p`
    text-align: center;
    font-size: 1.3rem;
    margin: 0;
`;

const StyledFlagIcon = styled(FlagIcon)``;

const HelpIconComponent = styled(SvgIcon)`
    height: 2rem;
    cursor: pointer;
`;

const AppInfoSubtitle = styled.p`
    text-align: center;
    font-size: 1.1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        color: inherit;
        font-size: 1.3rem;
    }
`;

const DatePassedText = styled.p`
    text-align: center;
    width: 80%;
    font-size: 2rem;
`;
const TestDescriptionWrapper = styled.div`
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex: 2;
    }
`;
const TestDescriptionInner = styled.div``;
const DataDeletionNote = styled.p`
    color: #666;
`;

Modal.setAppElement("#root");

const Intro: React.FC<IntroProps> = (props) => {
    const t = useT();
    const t_newTest = t("New Exercise", { _tags: "intro,testInfoScreen" });
    const t_assignment = t("Assignment", { _tags: "intro,testInfoScreen" });
    const t_goodLuck = t("Good Luck!", { _tags: "intro,testInfoScreen" });
    const t_dueDate = t("Due date:", { _tags: "intro,testInfoScreen" });
    const t_timeLimit = t("Time limit:", { _tags: "intro,testInfoScreen" });
    const t_hour = t("h", { _tags: "intro,testInfoScreen" });
    const t_startNow = t("Start Now", { _tags: "intro,testInfoScreen" });
    const t_studentName = t("Your name", { _tags: "intro,testInfoScreen" });
    const t_appInfoTitle = t("Are you here for the first time and you dont know what's happening?", {
        _tags: "intro,testInfoScreen",
    });
    const t_appInfoSubtitle = t("Then take a look first at our example exercise:", {
        _tags: "intro,testInfoScreen",
    });
    const t_inputModalContinueCTA = t("Continue", { _tags: "intro,testInfoScreen" });
    const t_appInfoCTA = t("Example Exercise", { _tags: "intro,testInfoScreen" });
    const t_appInfoCTACancel = t("Cancel", { _tags: "intro,testInfoScreen" });

    const { testData } = props;
    const theme = useContext(ThemeContext);

    const { studentTestAnswer } = useStudentPracticeContext();

    const [studentName, setStudentName] = useState(studentTestAnswer.studentName || "");
    const [appInfoModalIsOpen, setAppInfoModalIsOpen] = useState(false);
    const [nameInputModalIsOpen, setNameInputModalIsOpen] = useState(false);

    useEffect(() => {
        if (studentTestAnswer.studentName) {
            setStudentName(studentTestAnswer.studentName);
        }
    }, [studentTestAnswer.studentName]);

    function goToPractice() {
        props.goToPracticeAction(studentName);
    }

    function openInfoModal() {
        setAppInfoModalIsOpen(true);
    }

    function hideInfoModal() {
        setAppInfoModalIsOpen(false);
    }

    function openInputModal() {
        setNameInputModalIsOpen(true);
    }

    function hideInputModal() {
        setNameInputModalIsOpen(false);
    }

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    let isTestWorkable = true;

    if (testData.dueDate && isDeadlineAlreadyPassed(testData.dueDate)) {
        isTestWorkable = false;
    }

    const NameComponent = (
        <TeacherNameWrapper>
            <NewTestTitle>{t_newTest}</NewTestTitle>
            {!!testData.teacherName && <TeacherName>{testData.teacherName}</TeacherName>}
        </TeacherNameWrapper>
    );

    let TestLanguageComponent;

    if (testData.language) {
        TestLanguageComponent = <StyledFlagIcon languageName={testData.language.answerLanguageName} />;
    }

    const QuestionPopupComponent = (
        <QuestionIconWrapper onClick={openInfoModal}>
            <HelpIconComponent svgComponent={helpIcon} />
        </QuestionIconWrapper>
    );

    let TestConditionsComponent;

    if (isTestWorkable) {
        TestConditionsComponent = (
            <TestConditionsWrapper>
                <TestDescriptionWrapper>
                    <TestDescriptionInner>
                        {NameComponent}
                        {!!testData.teacherComment && (
                            <TestConditionsTeacherDescWrapper>
                                <TestConditionsTitle>{t_assignment}</TestConditionsTitle>
                                <TeacherComment>{testData.teacherComment}</TeacherComment>
                                <TestConditionsGoodLuck> {t_goodLuck}</TestConditionsGoodLuck>
                            </TestConditionsTeacherDescWrapper>
                        )}
                    </TestDescriptionInner>
                </TestDescriptionWrapper>

                {(!!testData.timeLimit || !!testData.dueDate) && (
                    <TestConditionsInfoWrapper>
                        {!!testData.dueDate && (
                            <TestConditionItemWrapper>
                                <TestConditionIcon svgComponent={calendarIcon} />
                                <TestConditionIconTitle>{t_dueDate}</TestConditionIconTitle>
                                <TestConditionIconDesc>
                                    {getDateTimeFromMilliseconds(testData.dueDate)} {t_hour}
                                </TestConditionIconDesc>
                            </TestConditionItemWrapper>
                        )}

                        {!!testData.dueDate && !!testData.timeLimit && <TestConditionItemSeparator />}

                        {!!testData.timeLimit && (
                            <TestConditionItemWrapper className={"orange"}>
                                <TestConditionIcon
                                    svgComponent={timeIcon}
                                    color={theme.base.primaryColor}
                                />
                                <TestConditionIconTitle>{t_timeLimit}</TestConditionIconTitle>
                                <TestConditionIconDesc>
                                    {getReadableTimeFromTimeLimit(testData.timeLimit)}
                                </TestConditionIconDesc>
                            </TestConditionItemWrapper>
                        )}
                    </TestConditionsInfoWrapper>
                )}
            </TestConditionsWrapper>
        );
    } else {
        TestConditionsComponent = (
            <TestConditionsWrapper>
                <DatePassedText>
                    <UT
                        _str="Sadly the deadline for this Exercise has already passed, and you cannot take part in it anymore.<br/> Please ask your teacher if he can extend the deadline."
                        _inline
                        _tags="intro,testInfoScreen"
                    />
                </DatePassedText>
            </TestConditionsWrapper>
        );
    }

    let InfoHeaderResponsive;

    if (isMobileView) {
        InfoHeaderResponsive = (
            <InfoHeader>
                {QuestionPopupComponent}
                {TestLanguageComponent}
            </InfoHeader>
        );
    } else {
        InfoHeaderResponsive = (
            <InfoHeader>
                {TestLanguageComponent}
                {QuestionPopupComponent}
            </InfoHeader>
        );
    }

    const updateStudentName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStudentName(event.target.value);
    };

    const wrapperRef = useRef<HTMLDivElement>(null);
    const executeScroll = () => {
        wrapperRef.current!.scrollIntoView();
    };

    const handleEnter = function (event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter" && studentName !== "") {
            openInputModal();
        }
    };

    const openExampleExercise = function () {
        hideInfoModal();
        // the example exercise should be set here and in the .env!
        window.open(cp.cfg.REACT_APP_PREFIX + "/student/zXampGxvJUniLYEn74g8", "_blank");
    };

    const InputModalTitle = (
        <InputModalTitleWrapper>
            {!!testData.timeLimit && (
                <InputModalTestTime>
                    <TestConditionIcon
                        svgComponent={timeIcon}
                        color={theme.base.primaryColor}
                    />
                    <TestConditionIconTitle>{t_timeLimit}</TestConditionIconTitle>
                    <TestConditionIconDesc>{getReadableTimeFromTimeLimit(testData.timeLimit)}</TestConditionIconDesc>
                </InputModalTestTime>
            )}
            {!!testData.timeLimit && (
                <>
                    <UT
                        _str="With the pass of time, the results will be stored and sent to your teacher - <b>keep an eye on the time! </b>"
                        _inline
                        _tags="intro,testInfoScreen"
                    />
                    <br />
                </>
            )}
            {!(getIsLocalTestRun() || getIsTestRun()) && (
                <>
                    <UT
                        _str='<b>Info:</b> by clicking on "Continue" your name and your answers will be saved and sent to your teacher'
                        _inline
                        _tags="intro,testInfoScreen"
                    />
                    <DataDeletionNote>
                        <UT
                            _str="Both will be deleted after 1 year automatically or when your teacher deletes the assignment."
                            _inline
                            _tags="intro,testInfoScreen"
                        />
                    </DataDeletionNote>
                </>
            )}
            {(getIsTestRun() || getIsLocalTestRun()) && (
                <UT
                    _str="This is just a test run, your results will not be saved."
                    _inline
                    _tags="intro,testInfoScreen"
                />
            )}
        </InputModalTitleWrapper>
    );

    return (
        <Wrapper>
            {InfoHeaderResponsive}
            {TestConditionsComponent}
            {isTestWorkable && (
                <StartWrapper ref={wrapperRef}>
                    <StyledInput
                        onChange={updateStudentName}
                        value={studentName}
                        placeholder={t_studentName}
                        onClick={executeScroll}
                        color={"#999"}
                        type={"text"}
                        onKeyUp={(e) => handleEnter(e)}
                    />
                    <ButtonWithIcon
                        iconSvgComponent={NextArrow}
                        text={t_startNow}
                        disabled={studentName === ""}
                        onClick={openInputModal}
                        iconColor={"white"}
                    />
                </StartWrapper>
            )}

            <InfoModal
                isOpen={appInfoModalIsOpen}
                onRequestClose={hideInfoModal}
                modalIcon={helpIcon}
                iconColor={theme.base.primaryColor}
                modalTitle={t_appInfoTitle}
                continueAction={openExampleExercise}
                continueText={t_appInfoCTA}
                includeCloseHeader={true}
            >
                <AppInfoSubtitle>{t_appInfoSubtitle}</AppInfoSubtitle>
            </InfoModal>

            <InfoModal
                isOpen={nameInputModalIsOpen}
                onRequestClose={hideInputModal}
                iconColor={theme.base.primaryColor}
                modalTitle={InputModalTitle}
                cancelAction={hideInputModal}
                cancelText={t_appInfoCTACancel}
                continueAction={goToPractice}
                continueText={t_inputModalContinueCTA}
            />
        </Wrapper>
    );
};

export default Intro;
