import styled, { css } from "styled-components";
import SvgIcon from "../svgIcon/SvgIcon";
import DesignConstants from "../../constants/DesignConstants";

export const BaseArrowCss = css`
    width: 2rem;
    cursor: pointer;
`;

export const LeftArrow = styled(SvgIcon)`
    ${BaseArrowCss};
    transform: rotate(180deg);
`;
export const RightArrow = styled(SvgIcon)`
    ${BaseArrowCss};
`;

export const UpArrow = styled(SvgIcon)`
    ${BaseArrowCss};
    transform: rotate(-90deg);
`;
export const DownArrow = styled(SvgIcon)`
    ${BaseArrowCss};
    transform: rotate(90deg);
`;

export const BaseArrowWrapperCss = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 2rem;
    border-radius: 0.5rem;
    max-height: 100%;
    flex: 1;

    &.disabled {
        border: ${DesignConstants.COLORS.TRANSPARENT_BLACK};
        background: transparent;
    }
`;

export const ArrowButtonWrapper = styled.div`
    ${BaseArrowWrapperCss};
`;
