import styled from "styled-components";
import StyledButton from "../../../basic/styledButton/StyledButton";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import { CoverImage } from "p6-react-shared";

export const Wrapper = styled.div`
    margin-bottom: 15rem;
`;

export const CTASWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
    }
`;

export const MiddleContentWrapper = styled.div`
    flex: 1;
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        flex-direction: column;
    }
`;

export const SelectsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1.5rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 0.5rem;
    }

    input {
        border-radius: 50px;
        color: #666666;
    }
`;

export const SmallSelectWrapper = styled.div`
    flex: 1;

    input {
        color: black;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

export const BookSearchSelectWrapper = styled.div`
    flex: 2;

    input {
        color: black;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

export const SelectedContentWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 80px);
    width: 100%;

    > div {
        width: 100%;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        max-height: 100%;
        height: auto;
    }
`;

export const BookImageWrapper = styled.div`
    width: auto;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const BookNameWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
`;

export const StyledCoverImage = styled(CoverImage)`
    width: unset !important;
    height: 150px !important;
`;

export const TestToText = styled.p`
    font-size: 0.9rem;
`;

export const BookName = styled.h4`
    font-weight: bold;
    margin: 0.5rem 1rem 0 0;
`;

export const BookAvailabilityInfo = styled.div`
    margin: auto auto 0;
    text-align: center;
    padding: 1rem 1.5rem 1rem 1rem;
    background: white;
    border-radius: 1rem;
    border: 1px solid #f5f5f5;
    position: fixed;
    bottom: 5rem;
    max-width: calc(${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px - 2rem);
    width: calc(100% - 2rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &.no-bottom {
        bottom: unset;
    }

    &.bottom-zero {
        bottom: 1rem;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        padding: 0.5rem 0.5rem 1rem 0.5rem;
    }
`;

export const TitleWrapper = styled.div`
    text-align: center;

    &.space {
        margin: 1rem 0;
    }
`;

export const Title = styled.h3``;

export const WarningIconWrapper = styled.div`
    width: 3rem;
    display: flex;
    align-items: center;
`;

export const StyledP6Icon = styled(PhaseSixIcon)`
    font-size: 3rem;
`;

export const BookAvailabilityInfoTitle = styled.p`
    font-size: 1rem;
    margin-bottom: 1rem;
`;

export const BookAvailabilityInfoDescWrapper = styled.div`
    text-align: left;
    flex: 1;
`;

export const BookAvailabilityInfoDesc = styled.p`
    font-size: 0.8rem;
`;

export const StyledSignUpButton = styled(StyledButton)`
    align-self: center;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex: 1;
    }
`;
