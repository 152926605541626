import { TestDetailsWord, UserOwnCard, VerbtrainingSelectedWord } from "p6m-p6u";
import React from "react";
import { useTestCreationContext } from "../../../../../../context/TestCreationContext";
import { ExerciseDirections } from "../../../../../../enums/directions";
import { getExerciseDirection } from "../../../../../../helpers/TestCreation";
import AddVocabularyButton from "../../detailsSubComponents/addVocabularyButton/AddVocabularyButton";

import {
    QuestionAndAnswer,
    StandardWordRow,
    Word,
    WordControl,
    WordIcon,
    WordWrapper,
    StandardWrapper,
} from "./StyledComponents";
import { Wrapper } from "../../StyledComponents";

const _findWordAmongWords = (wordId: string, words: Array<TestDetailsWord | VerbtrainingSelectedWord>) => {
    return words.find((word) => {
        if ("id" in word) {
            return word.id === wordId;
        } else {
            return false;
        }
    });
};

interface DetailsStandardProps {
    onRemoveWord: (cardId?: string) => any;
    isSelectExerciseVocabularyOpen: boolean;
    onClickAddVocabularyButton: () => void;
}

const DetailsStandard: React.FC<DetailsStandardProps> = (props) => {
    const { currentExerciseModel, setCurrentExerciseModel, setIsTestSynced } = useTestCreationContext();

    const words: Array<TestDetailsWord | VerbtrainingSelectedWord | UserOwnCard> =
        currentExerciseModel.wordsInProgress || [];

    function updateWordDirection(wordId: string, direction: ExerciseDirections) {
        const exercise = { ...currentExerciseModel };
        if (exercise.wordsInProgress) {
            const wordToChange: TestDetailsWord | VerbtrainingSelectedWord | undefined = _findWordAmongWords(
                wordId,
                exercise.wordsInProgress
            );

            if (wordToChange && "id" in wordToChange) {
                wordToChange.isQuestionShown = direction === ExerciseDirections.QUESTION_TO_ANSWER;

                // update overall direction
                exercise.direction = getExerciseDirection(exercise);

                setIsTestSynced(false);
            }

            setCurrentExerciseModel(exercise);
        }
    }

    const toggleWordDirection = (word: TestDetailsWord | VerbtrainingSelectedWord | UserOwnCard) => {
        if ("id" in word) {
            const newWordDirection = word.isQuestionShown
                ? ExerciseDirections.ANSWER_TO_QUESTION
                : ExerciseDirections.QUESTION_TO_ANSWER;
            updateWordDirection(word.id ?? "", newWordDirection);
        }
    };

    const renderWord = (word: string, isShown: boolean) => {
        return (
            <WordWrapper>
                <WordIcon name={isShown ? "eye" : "eye-closed"} />
                <Word className={isShown ? "" : "blur"}>{word}</Word>
            </WordWrapper>
        );
    };

    const renderWordRow = (word: TestDetailsWord | UserOwnCard) => {
        return (
            <StandardWordRow key={"wordRow" + word.question}>
                <QuestionAndAnswer onClick={() => toggleWordDirection(word)}>
                    {renderWord(word.question ?? "", word.isQuestionShown ?? true)}
                    {renderWord(word.answer ?? "", !word.isQuestionShown)}
                </QuestionAndAnswer>
                <WordControl>
                    <WordIcon
                        name={"arrows-right-left"}
                        onClick={() => toggleWordDirection(word)}
                    />
                    <WordIcon
                        name={"close"}
                        onClick={() => props.onRemoveWord(word.id)}
                    />
                </WordControl>
            </StandardWordRow>
        );
    };

    return (
        <Wrapper>
            {words.length > 0 && (
                <StandardWrapper>
                    {words.map((word, index) => {
                        if ("id" in word && word.question && word.answer) {
                            return renderWordRow(word);
                        } else {
                            // do not render VerbtrainingSelectedWord fallback
                            return <div key={index} />;
                        }
                    })}
                </StandardWrapper>
            )}
            {!props.isSelectExerciseVocabularyOpen && (
                <AddVocabularyButton onClickAddVocabularyButton={props.onClickAddVocabularyButton} />
            )}
        </Wrapper>
    );
};

export default DetailsStandard;
