import * as React from "react";
import { IndTestRevContext } from "p6m-contexts";
import { useCallback, useEffect, useState } from "react";
import {
    Grade,
    JointTestAnswers,
    ShareTestItem,
    StudentAnswerReviewItem,
    StudentQuestionAnswer,
    StudentTestAnswer,
    StudentTestContent,
} from "p6m-p6u";
import { getPercentagesForStudentIndReview } from "../helpers/ItemProgressCounts";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { hasTestSharingLinkExpired } from "../helpers/DateTime";
import TagManager from "react-gtm-module";
import { useCookies } from "react-cookie";
import { cp } from "../config";
import { getStudentTestById, saveStudentTestResult } from "../networking/tests";
import { getRequest, postRequest } from "../helpers/networkHelper";
import { generateJointData, processNewResultsArray } from "../helpers/TestReview";
import { NetworkErrorCodes } from "../networking/errorCodes";
import { AxiosResponse } from "axios";

const TestRevContext = React.createContext<IndTestRevContext | undefined>(undefined);
function useTestReviewContext() {
    const context = React.useContext(TestRevContext);

    if (!context) {
        throw new Error("useTestReviewContext must be used inside the provider");
    }

    const {
        testIndividualResults,
        setTestIndividualResults,
        jointResultsData,
        setJointResultsData,
        currentTestId,
        setCurrentTestId,
        currentReviewTab,
        setCurrentReviewTab,
        storeGradesInBackend,
        studentTestContent,
        setStudentTestContent,
        testShareData,
        setTestShareData,
        firstEvaluationModeEventSent,
        setFirstEvaluationModeEventSent,
    } = context;

    const getCountOfExercisesInTest = function () {
        return Object.keys(jointResultsData).length;
    };

    const getAmountOfStudentAnswers = function () {
        return testIndividualResults.length;
    };

    const updateIndividualDataAt = function (
        resultId: string,
        exerciseId: string,
        answerId: string,
        grade: Grade,
        teacherComment: string
    ) {
        let studentTestIndex,
            currentExercise = {} as StudentQuestionAnswer,
            currentAnswDataIndex = -1;

        studentTestIndex = testIndividualResults.findIndex((t) => t.id === resultId);
        const currentTestResult = studentTestIndex > -1 ? testIndividualResults[studentTestIndex] : undefined;

        if (currentTestResult && currentTestResult.questionAnswers) {
            currentExercise = currentTestResult!.questionAnswers![exerciseId];

            if (currentExercise.studentAnswers) {
                currentAnswDataIndex = currentExercise.studentAnswers.findIndex((a) => a.id === answerId);
            }
        }

        if (currentAnswDataIndex < 0) {
            setTestIndividualResults([...testIndividualResults]);
        }
        let currentAnsw: StudentAnswerReviewItem = currentExercise.studentAnswers![currentAnswDataIndex];

        currentAnsw.teacherGrade = grade;
        currentAnsw.teacherIndComment = teacherComment;

        let testResultsCopy = [...testIndividualResults];
        testResultsCopy[studentTestIndex]!.questionAnswers![exerciseId].studentAnswers![currentAnswDataIndex] =
            currentAnsw;

        setTestIndividualResults(testResultsCopy);
        setJointResultsData(generateJointData(testResultsCopy));
    };

    const updateStudentIndividualOverallComment = function (resultId: string, comment: string) {
        let dataToUpdateIndex = testIndividualResults.findIndex((t) => t.id === resultId);

        if (dataToUpdateIndex > -1) {
            let testResultsCopy = [...testIndividualResults];
            testResultsCopy[dataToUpdateIndex].teacherComment = comment;
            setTestIndividualResults(testResultsCopy);

            // update individual test comment in DB
            if (testResultsCopy[dataToUpdateIndex] && testResultsCopy[dataToUpdateIndex].id) {
                saveStudentTestResult(
                    currentTestId,
                    testResultsCopy[dataToUpdateIndex].id ?? "",
                    testResultsCopy[dataToUpdateIndex]
                );
            }
        }
    };

    const updateJointDataAt = function (
        exerciseId: string,
        itemId: string,
        itemIndex: number,
        grade: Grade,
        teacherComment: string
    ) {
        // first update in the joint model
        let dataToUpdate = jointResultsData[exerciseId].answers[itemId][itemIndex];
        dataToUpdate.teacherGrade = grade;
        dataToUpdate.teacherComment = teacherComment;

        let answersLength = dataToUpdate.answerOwner?.length || 0;

        dataToUpdate.gradeCounts = {
            "not-graded": 0,
            correct: grade === "correct" ? answersLength : 0,
            incorrect: grade === "incorrect" ? answersLength : 0,
        };

        let dataUpdated = { ...jointResultsData };
        dataUpdated[exerciseId].answers[itemId][itemIndex] = dataToUpdate;
        setJointResultsData(dataUpdated);

        let changedTestResults: Array<string> = [];

        // Then update in every individual model
        let newIndividualResults = [...testIndividualResults];
        dataToUpdate.answerOwner?.forEach((ao, index) => {
            let stdResIndex = newIndividualResults.findIndex((r) => r.id === ao.answerId);
            let stdRes = newIndividualResults[stdResIndex];

            //update answers
            if (!!stdRes && stdRes.questionAnswers && stdRes.questionAnswers[ao.exerciseId]) {
                // Some exercises like jumbledWords and textOnly have no word Id. and the studentAnswer is stored in position 0.
                let stdAnswerItemIndex = 0;
                if (ao.wordId) {
                    if (stdRes.questionAnswers[ao.exerciseId].studentAnswers) {
                        stdAnswerItemIndex = stdRes.questionAnswers[ao.exerciseId].studentAnswers!.findIndex(
                            (sa) => sa.id === ao.wordId
                        );
                    }
                }

                if (stdAnswerItemIndex > -1) {
                    stdRes.questionAnswers[ao.exerciseId]!.studentAnswers![stdAnswerItemIndex].teacherGrade = grade;
                    stdRes.questionAnswers[ao.exerciseId]!.studentAnswers![stdAnswerItemIndex].teacherComment =
                        teacherComment;
                }
            }

            // prepare update of individual test result in DB
            if (stdRes && stdRes.id) {
                changedTestResults.push(stdRes.id);
            }

            newIndividualResults[stdResIndex] = stdRes;
        });

        setTestIndividualResults(newIndividualResults);
        return changedTestResults;
    };

    const generateDataForReviewSharing = useCallback(async () => {
        const shareTestsList: Array<ShareTestItem> = [];

        const testIndividualResultsCopy = [...testIndividualResults];
        let changedTestResults = [];

        for (let index = 0; index < testIndividualResultsCopy.length; index++) {
            const individualResult = testIndividualResultsCopy[index];
            if (!individualResult.id) {
                return;
            }
            const [correctPerc, incorrectPerc, totalComments] = getPercentagesForStudentIndReview(individualResult);
            let totalEvaluated = correctPerc + incorrectPerc;
            let canBeShared = false;
            let shareLink = cp.cfg.REACT_APP_P6_WEBSITE;
            let expirationDate;

            // Some rounding in case it doesnt add up because of prior rounding.
            if (totalEvaluated > 99) {
                totalEvaluated = 100;
                canBeShared = true;
                if (
                    !individualResult.sharingId ||
                    hasTestSharingLinkExpired(individualResult.shareExpirationDate || 0)
                ) {
                    try {
                        expirationDate = moment().add(90, "days").unix() * 1000;
                        const savingGradedTestResult = await postRequest(`aea/feedbacks/`, {
                            testId: currentTestId,
                            resultId: individualResult.id,
                            expirationDate: expirationDate,
                        });

                        const sharingId = savingGradedTestResult.data.id;
                        testIndividualResultsCopy[index].sharingId = sharingId;
                        testIndividualResultsCopy[index].shareExpirationDate = expirationDate;
                        shareLink += "result/" + sharingId;

                        // prepare update of individual test result in DB
                        if (individualResult && individualResult.id) {
                            changedTestResults.push(individualResult);
                        }
                    } catch (e) {
                        console.log("error saving one graded result");
                        continue;
                    }
                } else if (individualResult.sharingId) {
                    shareLink += "result/" + individualResult.sharingId;
                    expirationDate = individualResult.shareExpirationDate;
                }
            }

            const shareItem: ShareTestItem = {
                resultId: individualResult.id,
                name: individualResult.studentName || "",
                reviewPerc: totalEvaluated.toFixed(0),
                correctPerc: correctPerc,
                incorrectPerc: incorrectPerc,
                canBeShared,
                totalComments,
                shareLink,
            };

            if (expirationDate) {
                shareItem.expirationDate = expirationDate;
            }

            shareTestsList.push(shareItem);
        }

        setTestIndividualResults(testIndividualResultsCopy);
        setTestShareData(shareTestsList);

        return changedTestResults;
    }, [setTestShareData, setTestIndividualResults, testIndividualResults, currentTestId]);

    return {
        testIndividualResults,
        setTestIndividualResults,
        jointResultsData,
        setJointResultsData,
        currentTestId,
        setCurrentTestId,
        getCountOfExercisesInTest,
        getAmountOfStudentAnswers,
        updateJointDataAt,
        studentTestContent,
        setStudentTestContent,
        currentReviewTab,
        setCurrentReviewTab,
        updateIndividualDataAt,
        updateStudentIndividualOverallComment,
        generateDataForReviewSharing,
        storeGradesInBackend,
        testShareData,
        setTestShareData,
        firstEvaluationModeEventSent,
        setFirstEvaluationModeEventSent,
    };
}

function TestReviewContextProvider(props: any) {
    const [testIndividualResults, setTestIndividualResults] = useState<Array<StudentTestAnswer>>([]);
    const [jointResultsData, setJointResultsData] = useState<JointTestAnswers>({});
    const [currentTestId, setCurrentTestId] = useState<string>("");
    const [studentTestContent, setStudentTestContent] = useState<StudentTestContent>();
    const [currentReviewTab, setCurrentReviewTab] = useState<"exerciseReview" | "individualReview">();
    const [testShareData, setTestShareData] = useState<Array<ShareTestItem>>([]);
    const [firstEvaluationModeEventSent, setFirstEvaluationModeEventSent] = useState(false);

    const history = useHistory();
    const [cookies] = useCookies(["GDPR"]);

    useEffect(() => {
        if (cookies && cookies.GDPR && cookies.GDPR.includes("analytics") && !window.dataLayer) {
            TagManager.initialize({
                gtmId: `${cp.cfg.REACT_APP_GTM_ID}`,
                auth: `${cp.cfg.REACT_APP_GTM_AUTH}`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies]);

    const getTestData = useCallback(
        async (newTestId: string) => {
            // newTestId can sometimes be passed as a text with undefined as content.
            if (!newTestId || newTestId === "undefined") {
                history.push("/create");
                return;
            }
            try {
                const studentTest = await getStudentTestById(newTestId);
                if (studentTest) {
                    setStudentTestContent({ ...studentTest });
                    const studentTestResults: AxiosResponse<{ [key: string]: StudentTestAnswer }> = await getRequest(
                        `aea/student-tests/${newTestId}/results/`
                    );

                    let testAnswers: Array<StudentTestAnswer> = [];

                    // The results endpoint might return either a graded or a not graded test
                    // We will evaluate here if the test is graded depending on a property (teacherComment)
                    // which is added after processing a not graded test.
                    if (Object.keys(studentTestResults.data).length > 0) {
                        let newResultsArray: Array<StudentTestAnswer> = [];
                        Object.entries(studentTestResults.data).forEach(([id, result]) => {
                            newResultsArray.push({ ...result, id });
                        });
                        testAnswers = testAnswers.concat(processNewResultsArray(newResultsArray));
                    }

                    testAnswers = testAnswers.sort((tA, tB) => {
                        return (tA.sentTime || -1) - (tB.sentTime || 0);
                    });

                    setTestIndividualResults(testAnswers);
                    setJointResultsData(generateJointData(testAnswers));
                } else {
                    setTestIndividualResults([]);
                    setJointResultsData({});
                }
            } catch (e: any) {
                if (e.response?.status === NetworkErrorCodes.FORBIDDEN) {
                    console.log("forbidden");
                    history.push("/unauthorized");
                    return;
                }
                history.push("/create");
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        setTestIndividualResults([]);
        setJointResultsData({});
        setStudentTestContent({});
        if (currentTestId) {
            getTestData(currentTestId);
        }
    }, [currentTestId, getTestData]);

    const value = {
        testIndividualResults,
        setTestIndividualResults,
        jointResultsData,
        setJointResultsData,
        currentTestId,
        setCurrentTestId,
        currentReviewTab,
        setCurrentReviewTab,
        studentTestContent,
        setStudentTestContent,
        testShareData,
        setTestShareData,
        firstEvaluationModeEventSent,
        setFirstEvaluationModeEventSent,
    };
    return (
        <TestRevContext.Provider
            value={value}
            {...props}
        />
    );
}

export { TestReviewContextProvider, useTestReviewContext };
