// LIBRARIES
import React, { useEffect, useState } from "react";
import { useT, UT } from "@transifex/react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useHistory } from "react-router-dom";
import StyledButton from "../../../basic/styledButton/StyledButton";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { UserSubject } from "p6m-p6u";

import { useGeneralContext } from "../../../context/AppGeneralContext";
import UserContentCover from "../../../basic/userContentCover/UserContentCover";
import styled from "styled-components";
import DeleteCardWarningModal from "../../../basic/create/deleteCardWarningModal/DeleteCardWarningModal";
import CreateInfoModal from "../../../basic/create/InfoModal/CreateInfoModal";
import CtasWrapperRT from "../../../wrapper/create/ctasWrapperRT/ctasWrapperRT";

import {
    Wrapper,
    MiddleContentWrapper,
    SmallSelectWrapper,
    SelectedContentWrapper,
    BookAvailabilityInfo,
    WarningIconWrapper,
    StyledP6Icon,
    BookAvailabilityInfoDescWrapper,
    BookAvailabilityInfoDesc,
    TitleWrapper,
    Title,
} from "./StyledComponents";
import UnitAndCardsWrapperRT from "../../../complex/create/unitAndCardsWrapperRT/UnitAndCardsWrapperRT";

export interface SelectOwnContentSourceProps {}

const BookHeaderInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    padding: 0.5rem 0.8rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    background: white;
`;

const BookImageWrapper = styled.div`
    //width: auto;
    margin-right: 1rem;
    height: 100%;
`;

const BookNameWrapper = styled.div`
    flex: 1;
    max-width: max(500px, 50%);
`;

const ExerciseToSpan = styled.span`
    font-size: 0.9rem;
`;

const SelectOwnContent: React.FC<SelectOwnContentSourceProps> = (props) => {
    const {
        selectedUserSubject,
        vocabularyDrawerContent,
        removeContentFromVocabularyDrawer,
        loadUserUnitsForSubject,
        userUnitCards,
        removeCardsFromSelectedWords,
        userOwnSubjects,
        userOwnSubjectUnits,
        shouldSubjectSelectionBeDisabled,
        resetAppStatus,
        openWarningModal,
    } = useTestCreationContext();

    const { creatingMode, userInfo } = useGeneralContext();

    const t = useT();
    const t_selectTitle = t("Please select a Content and Cards", { _tags: "CreateTest,SelectOwnContentSource" });
    const t_back = t("Back", { _tags: "CreateTest,SelectOwnContentSource" });
    const t_noResults = t("No Results", { _tags: "CreateTest,SelectOwnContentSource" });
    const t_exerciseTo = t("Exercise To", { _tags: "CreateTest,SelectOwnContentSource" });
    const t_continue = t("Continue", { _tags: "CreateTest,SelectOwnContentSource" });
    const t_chooseMinVocabulary = t("Please select at least 3 words", {
        _tags: "CreateTest,SelectOwnContentSource",
    });

    const t_selectSubject = t("Select a Subject", { _tags: "CreateTest,SelectVocabularySource" });

    const [selectedSubject, setSelectedSubject] = useState("");
    const [selectedSubjectInfo, setSelectedSubjectInfo] = useState<UserSubject>({});

    const [currentWordForDeletion, setCurrentWordForDeletion] = useState("");
    const [isCurrentWordForDeletionModalOpen, setIsCurrentWordForDeletionModalOpen] = useState(false);

    const [currentUnitCardsForDeletion, setCurrentUnitCardsForDeletion] = useState<Array<string>>([]);
    const [isDeleteAllUnitCardsModalOpen, setIsDeleteAllUnitCardsModalOpen] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (selectedSubject) {
            if (selectedUserSubject.id !== selectedSubject && selectedSubject !== "") {
                removeContentFromVocabularyDrawer(creatingMode === "TEST" ? "OWN" : "ALL");
            }
            loadUserUnitsForSubject(selectedSubject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubject]);

    useEffect(() => {
        if (selectedUserSubject?.id && selectedUserSubject.id !== selectedSubject) {
            setSelectedSubject(selectedUserSubject.id);
            setSelectedSubjectInfo(selectedUserSubject);
        } else if (
            selectedSubject &&
            (userOwnSubjectUnits.length === 0 || userOwnSubjectUnits[0]?.subjectId !== selectedSubject)
        ) {
            loadUserUnitsForSubject(selectedSubject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserSubject]);

    const updateSubject = (selectedValue: any) => {
        setSelectedSubject(selectedValue as string);

        let currentSubject = userOwnSubjects.find((s) => s.id === selectedValue);
        if (currentSubject) {
            setSelectedSubjectInfo(currentSubject);
        }
    };

    function handleContinueButton() {
        if (creatingMode === "RECURRING_TASK") {
            history.push("/create/rt-summary");
        } else {
            if (Object.keys(vocabularyDrawerContent).length >= 3) {
                history.push("/create/summary");
            } else {
                openWarningModal(t_chooseMinVocabulary);
            }
        }
    }

    function handleBackButton() {
        if (creatingMode === "RECURRING_TASK") {
            resetAppStatus();
            history.push("/create/rt-home");
        } else {
            history.goBack();
        }
    }

    function openDeleteModalFn(wordId: string) {
        setCurrentWordForDeletion(wordId);
        setIsCurrentWordForDeletionModalOpen(true);
    }

    function openDeleteUnitCardsFn(cardsToDelete: Array<string>) {
        setCurrentUnitCardsForDeletion(cardsToDelete);
        setIsDeleteAllUnitCardsModalOpen(true);
    }

    function closeDeleteWordWarningModal() {
        setIsCurrentWordForDeletionModalOpen(false);
    }

    function deleteCardsFromUnit() {
        removeCardsFromSelectedWords(currentUnitCardsForDeletion);
        setIsDeleteAllUnitCardsModalOpen(false);
    }

    return (
        <Wrapper>
            {(userInfo.isAnonymousUser || creatingMode === "RECURRING_TASK") && (
                <TitleWrapper className={creatingMode === "RECURRING_TASK" ? "space" : ""}>
                    <Title>{t_selectTitle}</Title>
                </TitleWrapper>
            )}
            <BookHeaderInfo>
                <BookImageWrapper>
                    <UserContentCover
                        primaryLanguage={selectedSubject !== "" ? selectedSubjectInfo.primaryLang! : "other"}
                        secondaryLanguage={selectedSubject !== "" ? selectedSubjectInfo.secondaryLang : ""}
                        small
                    />
                </BookImageWrapper>
                <BookNameWrapper>
                    <ExerciseToSpan>{t_exerciseTo}</ExerciseToSpan>
                    <SmallSelectWrapper>
                        {!shouldSubjectSelectionBeDisabled && (
                            <SelectSearch
                                options={userOwnSubjects.map((s) => {
                                    return { name: s.name!, value: s.id! };
                                })}
                                search
                                filterOptions={fuzzySearch}
                                emptyMessage={t_noResults}
                                placeholder={t_selectSubject}
                                value={selectedSubject}
                                onChange={updateSubject}
                                disabled={shouldSubjectSelectionBeDisabled}
                            />
                        )}
                        {shouldSubjectSelectionBeDisabled && <span>{selectedUserSubject.name}</span>}
                    </SmallSelectWrapper>
                </BookNameWrapper>
            </BookHeaderInfo>
            <MiddleContentWrapper>
                <SelectedContentWrapper>
                    {Object.entries(userUnitCards).map(([id, cards]) => {
                        let hasCards = cards.length;
                        let unit = userOwnSubjectUnits.find((u) => u.id === id);
                        return (
                            <UnitAndCardsWrapperRT
                                unitContent={unit!}
                                key={id}
                                isUserOwnContent
                                openDeleteModalFn={openDeleteModalFn}
                                openDeleteUnitCardsFn={openDeleteUnitCardsFn}
                                cardsList={hasCards ? cards : []}
                                totalUnitCardsCount={hasCards ? cards.length : 0}
                            />
                        );
                    })}
                    {userOwnSubjectUnits.length === 0 && <p>{t_noResults}</p>}
                </SelectedContentWrapper>
            </MiddleContentWrapper>
            {userInfo.isAnonymousUser && (
                <BookAvailabilityInfo className={"no-bottom"}>
                    <WarningIconWrapper>
                        <StyledP6Icon name={"info"} />
                    </WarningIconWrapper>
                    <BookAvailabilityInfoDescWrapper>
                        <BookAvailabilityInfoDesc>
                            <UT
                                _str="Info: You can only use the Book you selected first to create additional assignments. Once we have verified you as a teacher, you will have full access to all books."
                                _inline
                                _tags="CreateTest,CreateHome"
                            />
                        </BookAvailabilityInfoDesc>
                    </BookAvailabilityInfoDescWrapper>
                </BookAvailabilityInfo>
            )}
            {!userInfo.isAnonymousUser && (
                <CtasWrapperRT>
                    <StyledButton
                        onClick={handleBackButton}
                        arrowDirection={"LEFT"}
                        buttonStyle={"BLANK"}
                    >
                        {t_back}
                    </StyledButton>
                    <StyledButton
                        onClick={handleContinueButton}
                        arrowDirection={"NONE"}
                        buttonStyle={"PRIMARY"}
                        disabled={Object.keys(vocabularyDrawerContent).length === 0}
                    >
                        {t_continue}
                    </StyledButton>
                </CtasWrapperRT>
            )}
            <DeleteCardWarningModal
                isModalOpen={isCurrentWordForDeletionModalOpen}
                closeModalFn={closeDeleteWordWarningModal}
                wordId={currentWordForDeletion}
            />
            <CreateInfoModal
                isOpen={isDeleteAllUnitCardsModalOpen}
                onRequestClose={() => {
                    setIsDeleteAllUnitCardsModalOpen(false);
                }}
                continueAction={deleteCardsFromUnit}
                continueText={t_continue}
                cancelAction={() => {
                    setIsDeleteAllUnitCardsModalOpen(false);
                }}
                compact
            >
                <UT
                    _str="Warning: Unchecking this box deletes all words from the basket and exercises for which those words have been used."
                    _inline
                    _tags="CreateTest,SelectBookVocabulary"
                />
            </CreateInfoModal>
        </Wrapper>
    );
};

export default SelectOwnContent;
