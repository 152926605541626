// LIBRARIES
import React from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import ReviewListing from "../views/review/listing/ReviewListing";
import ReviewTest from "../views/review/test/ReviewTest";
import ContentWithHeader from "../wrapper/ContentWithHeader/ContentWithHeader";
import { TestReviewContextProvider } from "../context/TestReviewContext";
import ReviewIndividualExercise from "../views/review/exercise/ReviewIndividualExercise";
import ReviewTestWrapper from "../wrapper/reviewTestWrapper/ReviewTestWrapper";
import Unauthorized from "../views/unauthorized/Unathorized";
import PrivateRoute from "../wrapper/privateRoute/PrivateRoute";
import AuthWrapper from "../wrapper/authWrapper/AuthWrapper";
import ScrollToTop from "./ScrollToTop";
import ReviewIndividualStudent from "../views/review/student/ReviewIndividualStudent";
import ShareTestReviews from "../views/review/share/ShareTestReviews";
import { StudentReviewTestContextProvider } from "../context/StudentReviewTestContext";
import StudentReview from "../views/student-review/StudentReview";
import MainWrapper from "../views/students/MainWrapper/MainWrapper";
import { StudentPracticeProvider } from "../context/StudentPracticeContext";
import { TestCreationContextProvider } from "../context/TestCreationContext";
import { BookContextProvider } from "../context/BookContext";
import { RTContextProvider } from "../context/RTContext";
import CreateHome from "../views/create/createHome/CreateHome";
import SelectVocabularySource from "../views/create/selectVocabularySource/SelectVocabularySource";
import SelectBook from "../views/create/selectBook/SelectBook";
import SelectBookVocabulary from "../views/create/selectBookVocabulary/SelectBookVocabulary";
import Loading from "../basic/loadingAnimation/";
import WarningModal from "../basic/create/warningModal/WarningModal";
import Summary from "../views/create/summary/Summary";
import TestPreview from "../views/create/testPreview/TestPreview";
import TestCreationHeader from "../complex/create/testCreationHeader/TestCreationHeader";
import CreateAnonymousWrapper from "../views/create/createAnonymousWrapper/CreateAnonymousWrapper";
import RecurringTaskSummary from "../views/create/recurringTaskSummary/RecurringTaskSummary";
import CreateRtHome from "../views/create/createRtHome/CreateRtHome";
import SelectOwnContent from "../views/create/selectOwnContent/SelectOwnContent";
import { cp } from "../config";
import SurvicateWrapper from "../wrapper/survicateWrapper/SurvicateWrapper";

import { Background, StudentPracticeBackground } from "./StyledComponents";

export interface LayoutProps {}

const Layout: React.FC<LayoutProps> = (props) => {
    return (
        <Background>
            <BrowserRouter basename={cp.cfg.REACT_APP_PREFIX}>
                <ScrollToTop />
                <Loading />
                <Switch>
                    <Route path={"/student/:testId"}>
                        <StudentPracticeProvider>
                            <StudentPracticeBackground>
                                <MainWrapper />
                            </StudentPracticeBackground>
                        </StudentPracticeProvider>
                    </Route>
                    <SurvicateWrapper>
                        <ContentWithHeader id={"content-with-header"}>
                            <Route
                                exact
                                path="/"
                            >
                                <Redirect to="/create" />
                            </Route>
                            <Route path="/auth/:key?">
                                <AuthWrapper />
                            </Route>
                            <Route path={"/create"}>
                                <TestCreationContextProvider>
                                    <BookContextProvider>
                                        <RTContextProvider>
                                            <TestCreationHeader />
                                            <Switch>
                                                <Route
                                                    path={"/create"}
                                                    exact
                                                >
                                                    <CreateHome />
                                                </Route>
                                                <Route
                                                    path={"/create/rt-home"}
                                                    exact
                                                >
                                                    <CreateRtHome />
                                                </Route>
                                                <Route
                                                    path={"/create/anonymous"}
                                                    exact
                                                >
                                                    <CreateAnonymousWrapper />
                                                </Route>
                                                <PrivateRoute
                                                    path={"/create/choose-source"}
                                                    exact
                                                >
                                                    <SelectVocabularySource />
                                                </PrivateRoute>
                                                <PrivateRoute
                                                    path={"/create/select-book"}
                                                    exact
                                                >
                                                    <SelectBook />
                                                </PrivateRoute>
                                                <PrivateRoute
                                                    path={"/create/select-own-source"}
                                                    exact
                                                >
                                                    <SelectOwnContent />
                                                </PrivateRoute>
                                                <PrivateRoute
                                                    path={"/create/select-vocabulary"}
                                                    exact
                                                >
                                                    <SelectBookVocabulary />
                                                </PrivateRoute>
                                                <PrivateRoute
                                                    path={"/create/summary"}
                                                    exact
                                                >
                                                    <Summary />
                                                </PrivateRoute>
                                                <PrivateRoute
                                                    path={"/create/rt-summary"}
                                                    exact
                                                >
                                                    <RecurringTaskSummary />
                                                </PrivateRoute>
                                                <Route path={"/create/preview/:previewId?"}>
                                                    <StudentPracticeProvider>
                                                        <TestPreview />
                                                    </StudentPracticeProvider>
                                                </Route>
                                            </Switch>
                                            <WarningModal />
                                        </RTContextProvider>
                                    </BookContextProvider>
                                </TestCreationContextProvider>
                            </Route>
                            <Route path={"/review"}>
                                <TestReviewContextProvider>
                                    <Switch>
                                        <PrivateRoute
                                            path={"/review"}
                                            exact
                                        >
                                            <ReviewListing />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            path={"/review/:testId/"}
                                            exact
                                        >
                                            <ReviewTestWrapper>
                                                <ReviewTest />
                                            </ReviewTestWrapper>
                                        </PrivateRoute>
                                        <PrivateRoute path={"/review/:testId/exercise/:exerciseId"}>
                                            <ReviewTestWrapper>
                                                <ReviewIndividualExercise />
                                            </ReviewTestWrapper>
                                        </PrivateRoute>
                                        <PrivateRoute path={"/review/:testId/student/:resultId"}>
                                            <ReviewTestWrapper>
                                                <ReviewIndividualStudent />
                                            </ReviewTestWrapper>
                                        </PrivateRoute>
                                        <PrivateRoute path={"/review/:testId/share"}>
                                            <ReviewTestWrapper>
                                                <ShareTestReviews />
                                            </ReviewTestWrapper>
                                        </PrivateRoute>
                                    </Switch>
                                </TestReviewContextProvider>
                            </Route>
                            <Route path={"/result/:shareId"}>
                                <StudentReviewTestContextProvider>
                                    <StudentReview />
                                </StudentReviewTestContextProvider>
                            </Route>
                            <Route path={"/unauthorized"}>
                                <Unauthorized />
                            </Route>
                        </ContentWithHeader>
                    </SurvicateWrapper>
                </Switch>
            </BrowserRouter>
        </Background>
    );
};

export default Layout;
