import React, { useState } from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useT } from "@transifex/react";

import { TestSyncStatusWrapper, TestSyncStatusIcon, TestSyncStatusText, AnimatedLoadingIcon } from "./StyledComponents";

interface SummarySyncStatusProps {
    isTestBeingSynced: boolean;
}

const SummarySyncStatus: React.FC<SummarySyncStatusProps> = (props) => {
    const { testContent, isTestSynced, isTitleSynced } = useTestCreationContext();

    const t = useT();

    const t_saved = t("Saved", { _tags: "CreateTest,Summary" });
    const t_saving = t("Saving...", { _tags: "CreateTest,Summary" });
    const t_unsavedChanges = t("Unsaved changes", { _tags: "CreateTest,Summary" });
    const t_pleaseAddExercise = t("Please add an exercise to save this test", { _tags: "CreateTest,Summary" });

    const [displayInfoHovering, setDisplayInfoHovering] = useState(false);
    const isSynced = isTestSynced && isTitleSynced;

    const renderSynced = () => {
        return (
            <TestSyncStatusIcon
                onMouseEnter={() => setDisplayInfoHovering(true)}
                onMouseLeave={() => setDisplayInfoHovering(false)}
                name={isSynced ? "cloud" : "alert"}
            />
        );
    };

    const renderSaving = () => {
        return <AnimatedLoadingIcon name={"sync"} />;
    };

    const renderSyncStatusText = () => {
        let syncStatusText = "";
        if (props.isTestBeingSynced) {
            syncStatusText = t_saving;
        } else if (isSynced) {
            syncStatusText = t_saved;
        } else {
            syncStatusText = testContent.length < 1 ? t_pleaseAddExercise : t_unsavedChanges;
        }
        return <TestSyncStatusText>{syncStatusText}</TestSyncStatusText>;
    };

    return (
        <TestSyncStatusWrapper>
            {props.isTestBeingSynced ? renderSaving() : renderSynced()}
            {displayInfoHovering || props.isTestBeingSynced ? renderSyncStatusText() : <div />}
        </TestSyncStatusWrapper>
    );
};

export default SummarySyncStatus;
