import React, { useEffect } from "react";
import { useGeneralContext } from "../../context/AppGeneralContext";

import {
    AccordionTabWrapper,
    AccordionTabSummaryWrapper,
    AccordionTabSummaryContentWrapper,
    AccordionTabSummaryItem,
    AccordionTabSummary,
    AccordionTabText,
    StyledIcon,
    AccordionTabDetailsWrapper,
} from "./StyledComponents";
import { animate } from "framer-motion";

interface AccordionTabProps {
    title: string;
    description: string;
    mainIconName: string;
    counterValue?: string;
    isActive?: boolean;
    showAccordionTabDetails?: boolean;
    onClickEdit?: () => void;
    children?: JSX.Element | JSX.Element[];
    enableTextPulse?: boolean;
}

const AccordionTab: React.FC<AccordionTabProps> = (props) => {
    const { userInfo } = useGeneralContext();

    const onClickEdit = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        props.onClickEdit?.();
    };

    useEffect(() => {
        if (props.enableTextPulse) {
            const controls = animate(
                "#accordion-text-to-animate",
                { scale: [1, 1.5, 1] },
                { duration: 0.4, ease: "easeInOut" }
            );

            return () => {
                controls.stop();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.counterValue]);

    return (
        <AccordionTabWrapper>
            <AccordionTabSummaryWrapper
                onClick={props.isActive ? undefined : onClickEdit}
                isAnonymous={userInfo.isAnonymousUser}
                isActive={props.isActive}
            >
                <AccordionTabSummaryContentWrapper borderBottom={props.showAccordionTabDetails}>
                    <AccordionTabSummary>
                        <AccordionTabSummaryItem>
                            <StyledIcon
                                name={props.mainIconName}
                                $primaryColor={props.isActive}
                            />
                        </AccordionTabSummaryItem>
                        <AccordionTabSummaryItem>
                            <AccordionTabText
                                primaryColor={props.isActive}
                                isHighlighted
                            >
                                {props.title}
                            </AccordionTabText>
                        </AccordionTabSummaryItem>
                        <AccordionTabSummaryItem>
                            <AccordionTabText primaryColor={props.isActive}>{props.description}</AccordionTabText>
                        </AccordionTabSummaryItem>
                        {props.counterValue && (
                            <AccordionTabSummaryItem>
                                <AccordionTabText
                                    // only sets the id when enableTextPulse exists to prevent unintended interactions with other accordions
                                    id={`${props.enableTextPulse ? "accordion-text-to-animate" : ""}`}
                                    primaryColor={props.isActive}
                                >
                                    {props.counterValue}
                                </AccordionTabText>
                            </AccordionTabSummaryItem>
                        )}
                    </AccordionTabSummary>

                    {props.onClickEdit && (
                        <AccordionTabSummaryItem>
                            <StyledIcon
                                name={"edit-avatar"}
                                onClick={onClickEdit}
                            />
                        </AccordionTabSummaryItem>
                    )}
                </AccordionTabSummaryContentWrapper>
            </AccordionTabSummaryWrapper>
            {props.showAccordionTabDetails && <AccordionTabDetailsWrapper>{props.children}</AccordionTabDetailsWrapper>}
        </AccordionTabWrapper>
    );
};

export default AccordionTab;
