import React from "react";
import { useT } from "@transifex/react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { SummaryModes } from "./Summary";
import { TestQuestionModel } from "p6m-p6u";
import { SummaryOpenModals } from "./SummaryModals";

import AccordionTab from "../../../basic/accordionTab/AccordionTab";
import ExerciseItem from "../../../complex/create/exerciseItem/ExerciseItem";
import SelectExerciseType from "../../../complex/create/selectExerciseType/SelectExerciseType";

import { ExercisesContentWrapper, ExerciseItemsWrapper, AddExerciseButton } from "./StyledComponents";

interface SummaryExercisesAccordionProps {
    hasExercises: boolean;
    currentMode: SummaryModes;
    setCurrentMode: React.Dispatch<React.SetStateAction<SummaryModes>>;
    setOpenModal: React.Dispatch<React.SetStateAction<SummaryOpenModals | undefined>>;
    setExerciseContentModalExerciseIndex: React.Dispatch<React.SetStateAction<number>>;
    saveTestDataDebounced: (newTestContent: TestQuestionModel[]) => void;
    exerciseVocabularyOpenId: string;
    setExerciseVocabularyOpenId: React.Dispatch<React.SetStateAction<string>>;
}

const SummaryExercisesAccordion: React.FC<SummaryExercisesAccordionProps> = (props) => {
    const { testContent, currentExerciseModel, setCurrentExerciseModel } = useTestCreationContext();

    const t = useT();

    const t_addExercise = t("Add Exercise", { _tags: "CreateTest,Summary" });
    const t_DesignExerciseUnit = t("Design exercise unit:", { _tags: "CreateTest,Tab" });
    const t_HowToPractice = t("How should be practised?", { _tags: "CreateTest,Tab" });
    const amountExercises = testContent.length;

    const currentExerciseId = currentExerciseModel.exerciseId;

    const isOverviewMode = props.currentMode === SummaryModes.OVERVIEW;
    const isSelectExerciseTypeMode = props.currentMode === SummaryModes.SELECT_EXERCISE_TYPE;
    const isExerciseMode = props.currentMode === SummaryModes.EXERCISE_DETAILS;

    function addExercise() {
        setCurrentExerciseModel({});
        props.setCurrentMode(SummaryModes.SELECT_EXERCISE_TYPE);
    }

    const renderAccordionTabExercisesContent = () => {
        const isCreateNewExercise =
            isExerciseMode && testContent.findIndex((exercise) => exercise.exerciseId === currentExerciseId) === -1;
        const exercises = isCreateNewExercise ? [...testContent, currentExerciseModel] : [...testContent];

        return (
            <ExercisesContentWrapper>
                {(props.hasExercises || (isCreateNewExercise && isExerciseMode)) && (
                    <ExerciseItemsWrapper>
                        {exercises.map((tc, index) => (
                            <ExerciseItem
                                key={tc.exerciseId + "_" + index}
                                index={index}
                                exerciseData={tc}
                                currentExerciseId={currentExerciseId}
                                isCreateNewExercise={isCreateNewExercise}
                                hasWords={!!currentExerciseModel.wordsInProgress}
                                setCurrentMode={props.setCurrentMode}
                                setOpenModal={props.setOpenModal}
                                onOpenExerciseContentModal={() => props.setExerciseContentModalExerciseIndex(index)}
                                saveTestDataDebounced={props.saveTestDataDebounced}
                                exerciseVocabularyOpenId={props.exerciseVocabularyOpenId}
                                setExerciseVocabularyOpenId={props.setExerciseVocabularyOpenId}
                            />
                        ))}
                    </ExerciseItemsWrapper>
                )}

                {isSelectExerciseTypeMode && (
                    <SelectExerciseType
                        isFirstExercise={!props.hasExercises}
                        setCurrentMode={props.setCurrentMode}
                        saveTestDataDebounced={props.saveTestDataDebounced}
                        setExerciseVocabularyOpenId={props.setExerciseVocabularyOpenId}
                    />
                )}

                {isOverviewMode && (
                    <AddExerciseButton
                        buttonStyle={"BLANK"}
                        icon={"add"}
                        iconPosition={"LEFT"}
                        onClick={addExercise}
                    >
                        {t_addExercise}
                    </AddExerciseButton>
                )}
            </ExercisesContentWrapper>
        );
    };

    return (
        <AccordionTab
            mainIconName={"clipboard"}
            title={t_DesignExerciseUnit}
            description={t_HowToPractice}
            counterValue={"(" + amountExercises + ")"}
            showAccordionTabDetails
            isActive
        >
            {renderAccordionTabExercisesContent()}
        </AccordionTab>
    );
};

export default SummaryExercisesAccordion;
