import * as React from "react";
import {
    StudentAnswerItem,
    StudentTestAnswer,
    StudentTestContent,
    StudentQuestionAnswer,
    VerbtrainingAnswerItem,
} from "p6m-p6u";
import { pickBy, identity, shuffle } from "lodash";
import { useEffect, useCallback, useState } from "react";
import { convertTimeLimitToSeconds } from "../helpers/DateTime";
import moment from "moment";
import { getIsLocalTestRun, getIsTestRun } from "../helpers/Url";
import { getIsExampleExercise } from "../helpers/Environment";
import { StorageContextContent } from "p6m-contexts";
import { getRequest, postRequest } from "../helpers/networkHelper";

export enum Views {
    Intro,
    Practice,
    Outro,
    Unauthorized,
}

export enum PracticeViews {
    PRE_EXERCISES_OVERVIEW,
    EXERCISES,
    OVERVIEW,
}

const StudentPracticeContext = React.createContext<StorageContextContent | undefined>(undefined);
function useStudentPracticeContext() {
    const context = React.useContext(StudentPracticeContext);

    if (!context) {
        throw new Error("useStudentPracticeContext must be used inside the provider");
    }

    const {
        studentTestAnswer,
        setStudentTestAnswer,
        teacherTest,
        setTeacherTest,
        currentMainView,
        setCurrentMainView,
        currentExerciseId,
        setCurrentExerciseId,
        testEndTimestamp,
        countdownStarted,
        setCountdownStarted,
        checkCurrentViewsRestart,
        checkCurrentPracticeViewsRestart,
        currentPracticeView,
        setCurrentPracticeView,
        isTimeOver,
        setIsTimeOver,
        mobileScreenResolutions,
        setMobileScreenResolutions,
    } = context;

    const setStudentName = (name: string) => {
        const newSa: StudentTestAnswer = { ...studentTestAnswer };
        newSa.studentName = name;
        setStudentTestAnswer(newSa);
    };

    const refreshData = useCallback(
        async (testId: string) => {
            try {
                const studentTestData = await getRequest(`aea/student-tests/${testId}/`);
                if (studentTestData.data) {
                    const retrievedTestData: StudentTestContent = { ...studentTestData.data, id: testId };
                    updateTeacherTest(retrievedTestData);
                } else {
                    setCurrentMainView(Views.Unauthorized);
                }
            } catch (e) {
                setCurrentMainView(Views.Unauthorized);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentMainView]
    );

    const refreshLocalPreviewData = function () {
        const localTestData = localStorage.getItem("localPreviewData") || "";
        if (localTestData) {
            const parsedData = JSON.parse(localTestData);
            if (parsedData.hasOwnProperty("id") && parsedData.hasOwnProperty("name")) {
                updateTeacherTest(parsedData);
            } else {
                setCurrentMainView(Views.Unauthorized);
            }
        } else {
            setCurrentMainView(Views.Unauthorized);
        }
    };

    const setExerciseAnswer = (exerciseId: string, newAnswer: Array<StudentAnswerItem>) => {
        let newStdAnswer = { ...studentTestAnswer };
        if (
            newStdAnswer.questionAnswers &&
            newStdAnswer.questionAnswers[exerciseId] &&
            newStdAnswer.questionAnswers[exerciseId].studentAnswers
        ) {
            newStdAnswer.questionAnswers[exerciseId].studentAnswers = newAnswer;
        }
    };

    const updateStudentAnswerOnQuestion = function (newStudentAnswerItem: StudentQuestionAnswer) {
        const newStudentAnswerObj = { ...studentTestAnswer };
        newStudentAnswerObj.questionAnswers![currentExerciseId] = newStudentAnswerItem;
        setStudentTestAnswer(newStudentAnswerObj);
    };

    const startTimer = function () {
        setCountdownStarted(true);
    };

    const updateTeacherTest = useCallback(
        (newTest: StudentTestContent) => {
            setTeacherTest(newTest);
            checkCurrentViewsRestart();
        },
        [setTeacherTest, checkCurrentViewsRestart]
    );

    const handleSaveSuccess = function () {
        localStorage.removeItem("@testData:" + teacherTest.id);
    };

    const saveData = useCallback(
        async () => {
            try {
                const sentTime = Date.now();
                const testId = teacherTest.id!;

                const testResultToStore: StudentTestAnswer = {
                    isTimeOver,
                    sentTime,
                    ...studentTestAnswer,
                };
                await postRequest(`aea/student-tests/${testId}/results/`, { ...testResultToStore });
            } catch (e) {
                alert("Error!");
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isTimeOver]
    );

    const finishPractice = function () {
        setCurrentMainView(Views.Outro);
        if (!getIsTestRun() && !getIsExampleExercise() && !getIsLocalTestRun()) {
            saveData();
            handleSaveSuccess();
        }
    };

    return {
        refreshData,
        refreshLocalPreviewData,
        studentTestAnswer,
        setStudentName,
        setExerciseAnswer,
        updateStudentAnswerOnQuestion,
        startTimer,
        countdownStarted,
        testEndTimestamp,
        teacherTest,
        updateTeacherTest,
        currentMainView,
        setCurrentMainView,
        currentExerciseId,
        setCurrentExerciseId,
        finishPractice,
        currentPracticeView,
        setCurrentPracticeView,
        isTimeOver,
        setIsTimeOver,
        checkCurrentPracticeViewsRestart,
        mobileScreenResolutions,
        setMobileScreenResolutions,
    };
}

function StudentPracticeProvider(props: any) {
    const [studentTestAnswer, setStudentTestAnswer] = React.useState<StudentTestAnswer>({ questionAnswers: {} });
    const [teacherTest, setTeacherTest] = React.useState<StudentTestContent>({});
    const [currentMainView, setCurrentMainView] = React.useState<Views | number>(-2);
    const [currentExerciseId, setCurrentExerciseId] = React.useState("e1");
    const [rehydrated, setRehydrated] = React.useState<boolean>(false);
    const [countdownStarted, setCountdownStarted] = React.useState<boolean>(false);
    const [testEndTimestamp, setTestEndTimestamp] = React.useState<number>(0);
    const [currentPracticeView, setCurrentPracticeView] = React.useState<PracticeViews>(0);
    const [isTimeOver, setIsTimeOver] = useState<boolean>(false);
    const [mobileScreenResolutions, setMobileScreenResolutions] = useState<Array<number>>([]);

    const checkCurrentPracticeViewsRestart = useCallback(() => {
        if (currentPracticeView <= 0) {
            setCurrentPracticeView(PracticeViews.PRE_EXERCISES_OVERVIEW);
        }
    }, [currentPracticeView]);

    const checkCurrentViewsRestart = useCallback(() => {
        if (currentMainView < 0 || currentMainView > 1) {
            const isTeacherPreview = getIsTestRun() || getIsLocalTestRun();
            setCurrentMainView(isTeacherPreview ? Views.Practice : Views.Intro);
        } else {
            checkCurrentPracticeViewsRestart();
        }
    }, [currentMainView, checkCurrentPracticeViewsRestart]);

    const value = {
        studentTestAnswer,
        setStudentTestAnswer,
        teacherTest,
        setTeacherTest,
        currentMainView,
        setCurrentMainView,
        currentExerciseId,
        setCurrentExerciseId,
        testEndTimestamp,
        setTestEndTimestamp,
        countdownStarted,
        setCountdownStarted,
        checkCurrentViewsRestart,
        checkCurrentPracticeViewsRestart,
        currentPracticeView,
        setCurrentPracticeView,
        isTimeOver,
        setIsTimeOver,
        mobileScreenResolutions,
        setMobileScreenResolutions,
    };

    /*
     *  Rehydrate state after fetching test
     */
    React.useEffect(() => {
        // TODO check with Juan for good criteria to identify if we have a test
        if (!rehydrated && teacherTest && teacherTest.id) {
            setRehydrated(true);
            const test = localStorage.getItem("@testData:" + teacherTest.id);
            if (test) {
                const parsedTest = JSON.parse(test);
                if (parsedTest.studentTestAnswer) {
                    setStudentTestAnswer(parsedTest.studentTestAnswer);
                }
                if (parsedTest.currentMainView) {
                    setCurrentMainView(parsedTest.currentMainView);
                }
                if (parsedTest.currentExerciseId) {
                    setCurrentExerciseId(parsedTest.currentExerciseId);
                }
                if (parsedTest.testEndTimestamp && parsedTest.testEndTimestamp > 0) {
                    setCountdownStarted(true);
                    setTestEndTimestamp(parsedTest.testEndTimestamp);
                }
                if (parsedTest.currentPracticeView) {
                    setCurrentPracticeView(parsedTest.currentPracticeView);
                }
            }
        }
    }, [teacherTest, rehydrated]);

    /*
     * store progress in local storage for rehydrate
     */
    React.useEffect(() => {
        if (rehydrated && teacherTest && teacherTest.id && !getIsTestRun() && !getIsLocalTestRun()) {
            localStorage.setItem(
                "@testData:" + teacherTest.id,
                JSON.stringify({
                    studentTestAnswer,
                    currentMainView,
                    currentExerciseId,
                    testEndTimestamp,
                    currentPracticeView,
                })
            );
        }
    }, [
        studentTestAnswer,
        currentMainView,
        currentExerciseId,
        teacherTest,
        rehydrated,
        testEndTimestamp,
        currentPracticeView,
    ]);

    const initStudentTestAnswerContent = useCallback(() => {
        if (!rehydrated) {
            return;
        }
        if (Object.keys(studentTestAnswer.questionAnswers || {}).length === 0 && teacherTest.content) {
            let newStudentData: StudentTestAnswer = {
                questionAnswers: {},
                studentName: getIsTestRun() || getIsLocalTestRun() ? teacherTest.teacherName : "",
            };
            // Fill the student test
            teacherTest.content.forEach((td) => {
                if (td.id) {
                    const exerciseType = td.questionMode || "";

                    if (td.questionMode === "textOnly") {
                        newStudentData.questionAnswers![td.id] = {
                            textAnswer: "",
                            exerciseType,
                        };

                        if (td.textFieldStarterText) {
                            newStudentData.questionAnswers![td.id].textFieldStarterText = td.textFieldStarterText;
                        }
                    }

                    if (!td.selectedWords) {
                        return;
                    }

                    let studentAnswers: Array<StudentAnswerItem> = [];
                    if (td.questionMode === "verbTraining") {
                        td.selectedWords!.forEach((sw) => {
                            let { id, requestedConjugations, name } = sw;
                            // If gapMode is columns, put all the known answers at the beginning
                            requestedConjugations.sort((a: VerbtrainingAnswerItem, b: VerbtrainingAnswerItem) =>
                                !!a.id && b.id && a.id < b.id ? -1 : 1
                            );
                            if (td.gapMode && td.gapMode === "columns") {
                                requestedConjugations.sort((a: VerbtrainingAnswerItem, b: VerbtrainingAnswerItem) =>
                                    !!a.answer && b.answer && a.answer !== "" && b.answer === "" ? -1 : 1
                                );
                            }
                            requestedConjugations = requestedConjugations.map((c: VerbtrainingAnswerItem) => {
                                return { studentAnswer: "", ...c };
                            });
                            studentAnswers.push({
                                ...pickBy({ id, verbtrainingAnswers: requestedConjugations, verbName: name }, identity),
                            });
                        });
                    } else {
                        td.selectedWords!.forEach((sw) => {
                            let { question, id, answer, gap_sentence, requestedConjugations, name } = sw;
                            //Removing undefined/null/empty values from the new object, and adding an empty studentAnswer
                            studentAnswers.push({
                                ...pickBy(
                                    { question, id, answer, gap_sentence, requestedConjugations, name },
                                    identity
                                ),
                                studentAnswer: "",
                            });
                        });
                    }

                    // Setting the words to be asked and then shuffled for fill in the gap.
                    if (td.questionMode === "fillInTheGap") {
                        let fillInTheGapWords: Array<string> = td.fillWords || [];
                        fillInTheGapWords = shuffle(fillInTheGapWords);
                        newStudentData.questionAnswers![td.id] = {
                            fillInTheGapWords,
                            studentAnswers,
                            exerciseType,
                        };
                    } else if (td.questionMode === "jumbledWords") {
                        const jumbledWords: Array<string> = shuffle(td.fillWords || []);
                        newStudentData.questionAnswers![td.id] = {
                            jumbledWordsAnswer: "",
                            jumbledWords,
                            exerciseType,
                        };
                        if (td.textFieldStarterText) {
                            newStudentData.questionAnswers![td.id].textFieldStarterText = td.textFieldStarterText;
                        }
                    } else if (td.questionMode === "verbinden") {
                        const connectWordsAnswers = shuffle(td.fillWords || []);
                        let colors: Array<string> = ["#fd6a95", "#040104", "#f5ea20", "#2d9bf0", "#9511ac"];

                        // Removing answers, adding colors
                        let reducedStudentAnswers: Array<StudentAnswerItem> = [];
                        studentAnswers.forEach(({ answer, ...rest }) =>
                            reducedStudentAnswers.push({ ...rest, color: colors.pop() })
                        );
                        newStudentData.questionAnswers![td.id] = {
                            studentAnswers: reducedStudentAnswers,
                            connectWordsAnswers,
                            exerciseType,
                        };
                    } else if (td.questionMode === "verbTraining") {
                        newStudentData.questionAnswers![td.id] = {
                            studentAnswers,
                            exerciseType,
                            gapMode: td.gapMode,
                        };
                    } else {
                        newStudentData.questionAnswers![td.id] = {
                            studentAnswers,
                            exerciseType,
                        };
                    }
                }
            });
            const testHasOnlyOneExercise: boolean = teacherTest.content.length === 1;
            if (testHasOnlyOneExercise) {
                setCurrentPracticeView(PracticeViews.EXERCISES);
            }
            setStudentTestAnswer(newStudentData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teacherTest, studentTestAnswer, rehydrated]);

    React.useEffect(() => {
        initStudentTestAnswerContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teacherTest, rehydrated]);

    useEffect(() => {
        if (rehydrated && countdownStarted && teacherTest.timeLimit) {
            if (testEndTimestamp === 0) {
                setTestEndTimestamp(moment().unix() + convertTimeLimitToSeconds(teacherTest.timeLimit));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rehydrated, countdownStarted, teacherTest]);

    return (
        <StudentPracticeContext.Provider
            value={value}
            {...props}
        />
    );
}

export { StudentPracticeProvider, useStudentPracticeContext };
