import styled, { css } from "styled-components";
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import StyledButtonWithIcon from "../../styledButtonWithIcon/StyledButtonWithIcon";
import StyledButton from "../../styledButton/StyledButton";
import ArrowButton from "../../arrowButton/ArrowButton";
import ExerciseListingSideIcon from "../exerciseListingSideIcon/ExerciseListingSideIcon";
import DesignConstants from "../../../constants/DesignConstants";

export const Wrapper = styled.div`
    width: 100%;
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.S} 0;
    margin: ${DesignConstants.SPACES.S} 0 0;
    user-select: none;
    border-top: ${DesignConstants.BORDERS.THIN} #eeeeee;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.S} 0 0;
    }
`;

export const BookCoverAndCTAWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${DesignConstants.SPACES.XS};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        gap: ${DesignConstants.SPACES.S};
    }
`;

export const BookCoverAndName = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${DesignConstants.SPACES.S};
`;

export const BookName = styled.p`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const BookItemCTAs = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        justify-content: space-around;
        flex: 1;
    }
`;

export const ItemCTAs = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        justify-content: space-around;
        flex: 1;
    }
`;

export const DeleteIconWrapper = styled.div`
    cursor: pointer;
`;

export const ExerciseItemContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${DesignConstants.SPACES.S};
    justify-content: space-around;
    flex: 1;
    font-size: ${DesignConstants.FONT_SIZES.M};
    padding: ${DesignConstants.SPACES.XS} 0 ${DesignConstants.SPACES.XS};
`;

export const StretchExerciseListingSideIcon = styled(ExerciseListingSideIcon)`
    align-self: stretch;
    height: auto !important;
`;

export const ExercisesListWrapper = styled.div`
    padding-left: 30px;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding-left: 0;
        margin-top: ${DesignConstants.SPACES.S};
    }
`;

export const ExerciseItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        gap: ${DesignConstants.SPACES.XS};
    }

    &.highlighted {
        background: ${DesignConstants.COLORS.P6_ORANGE}1a;
    }
`;

export const ExerciseInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${DesignConstants.SPACES.XS};
    flex: 1;
    max-width: 75%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        justify-content: space-between;
        max-width: 100%;
    }
`;

export const ExerciseCardsCountWrapper = styled.div`
    flex: 2;
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const ExerciseDateWrapper = styled.div`
    flex: 5;
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const ExerciseFromText = styled.div`
    flex: 4;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex: 1;
    }
`;

const SmallerStyledButtonsStyle = css`
    min-height: unset;
    min-width: unset;
`;

export const SmallerNormalButton = styled(StyledButton)`
    ${SmallerStyledButtonsStyle};
    font-size: ${DesignConstants.FONT_SIZES.S};
`;

export const SmallerButtonWithIcon = styled(StyledButtonWithIcon)`
    ${SmallerStyledButtonsStyle};
    padding: ${DesignConstants.SPACES.XS} !important;
    font-size: ${DesignConstants.FONT_SIZES.M};
`;

export const MediumIcon = styled(PhaseSixIcon)`
    font-size: 1.7rem;
`;

export const ShowHideAllExercisesWrapper = styled.div`
    width: ${DesignConstants.SPACES.L};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
`;

export const StyledArrowButton = styled(ArrowButton)`
    padding: 0;
`;