import React, { ReactElement } from "react";
import { ExerciseImages, Exercises, ExerciseTypes, TextInputExercises } from "../../../../enums/exercises";
import { UT } from "@transifex/react";
import useExerciseTitle from "../../../../hooks/useExerciseTitle";
import { ExerciseName } from "../StyledComponents";
import createSentenceToggled from "../../../../assets/img/exerciseTypes/CreateSentenceToggled.png";
import createTextToggled from "../../../../assets/img/exerciseTypes/CreateTextToggled.png";
import textInput from "../../../../assets/img/exerciseTypes/TextInput.png";

import {
    ExerciseWrapper,
    ExerciseTitleAndInfoWrapper,
    ExerciseNameQuestionmarkWrapper,
    ExerciseInfo,
    ExerciseQuestionMarkWrapper,
    StyledQuestionMarkIcon,
    ExerciseDescriptionWrapper,
    ExerciseTitleSideWrapper,
    ExerciseImageSideWrapper,
    ExerciseTypeImage,
    ExerciseTypeImageWrapper,
    ToggledImagesWrapper,
} from "./StyledComponents";

const getImageSource = (exerciseType: Exercises) => {
    return ExerciseImages.get(exerciseType);
};

const getDescription = (exerciseType: Exercises): ReactElement | string => {
    switch (exerciseType) {
        case Exercises.STANDARD:
            return (
                <UT
                    _str="In the <b>standard</b> exercise, the words and their sole meaning are going to be asked. The students will fill in the gap with the translation, either on the target language or base language or randomly. "
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        case Exercises.CONNECT_WORDS:
            return (
                <UT
                    _str="In this exercise the students have to <b>connect words</b> that are in two columns, each of five words. Like the Standard exercise, this exercise allows the practice of the definition of the words."
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        case Exercises.FILL_GAP:
            return (
                <UT
                    _str="In this exercise, the example phrases from the book will be shown as <b>gap sentences</b>. The words that belong in the gap, are located in a box on top of the sentences. The students need to translate the words and find the right context. <b>Info:</b> It is possible that in your selected Book, there are not or only a few gap sentences available."
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        case Exercises.WRITE_SENTENCE:
        case Exercises.CREATE_TEXT:
            return (
                <UT
                    _str="In this exercise, you have many opportunities to check the students' knowledge. You can test grammatical <b>(e.g., conjugations)</b> or semantic knowledge <b>(e.g., synonyms)</b> related to the vocabulary, or have them <b>form complete sentences</b>. Alternatively, you can also have the students <b>write entire texts</b> in which their selected vocabulary should appear."
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        case Exercises.FREE_TEXT:
            return (
                <UT
                    _str="With this optional <b>Free text</b> you can add directions, explanations or have place for extra tasks or notes. Additionally this free text is independent from the content of the schoolbook, so you can use it for individual grammar exercises or a free writing exercise."
                    _inline
                    _tags="CreateTest,SelectExerciseType"
                />
            );
        default:
            return "";
    }
};

export interface SelectExerciseTypeItemProps {
    exerciseType: Exercises;
    description?: string;
    infoText?: string;
    isDisabled?: boolean;
    isInfoHidden?: boolean;
    isInfoOpen: boolean;
    onToggleInfo: (event: React.MouseEvent<HTMLDivElement>) => void;
    onSelect: () => void;
}

const SelectExerciseTypeItem: React.FC<SelectExerciseTypeItemProps> = ({
    exerciseType,
    description,
    infoText,
    isDisabled = false,
    isInfoHidden = false,
    isInfoOpen,
    onToggleInfo,
    onSelect,
}) => {
    const { getExerciseNameFromQuestionMode } = useExerciseTitle();

    const renderExerciseDescription = () => {
        return (
            <ExerciseDescriptionWrapper disabled={isDisabled}>
                {description || getDescription(exerciseType)}
            </ExerciseDescriptionWrapper>
        );
    };

    const renderExerciseNameAndQuestionMark = () => {
        const questionModeExerciseType = ExerciseTypes.get(exerciseType);
        return (
            <ExerciseNameQuestionmarkWrapper>
                <ExerciseName className={isInfoOpen ? "infoOpen" : ""}>
                    {getExerciseNameFromQuestionMode(questionModeExerciseType)}
                </ExerciseName>
                {!isInfoHidden && (
                    <ExerciseQuestionMarkWrapper
                        onClick={(event) => onToggleInfo(event)}
                        className={isInfoOpen ? "infoOpen" : ""}
                    >
                        <StyledQuestionMarkIcon className={isInfoOpen ? "infoOpen" : ""}>{"?"}</StyledQuestionMarkIcon>
                    </ExerciseQuestionMarkWrapper>
                )}
            </ExerciseNameQuestionmarkWrapper>
        );
    };

    const renderTitleAndInfo = () => {
        return (
            <ExerciseTitleAndInfoWrapper
                className={isInfoOpen ? "infoOpen" : ""}
                disabled={isDisabled}
            >
                {renderExerciseNameAndQuestionMark()}
                {infoText && <ExerciseInfo>{infoText}</ExerciseInfo>}
            </ExerciseTitleAndInfoWrapper>
        );
    };

    const renderToggledTextinputImages = () => {
        return (
            <ToggledImagesWrapper>
                <ExerciseTypeImage src={createTextToggled} />
                <ExerciseTypeImage src={createSentenceToggled} />
            </ToggledImagesWrapper>
        );
    };

    const renderExerciseImage = () => {
        const questionModeExerciseType = ExerciseTypes.get(exerciseType);
        return (
            <ExerciseTypeImageWrapper
                className={isInfoOpen ? "infoOpen" : ""}
                disabled={isDisabled}
            >
                {isInfoOpen && TextInputExercises.includes(questionModeExerciseType ?? "") ? (
                    renderToggledTextinputImages()
                ) : (
                    <ExerciseTypeImage
                        src={TextInputExercises.includes(questionModeExerciseType ?? "") ? textInput : getImageSource(exerciseType)}
                    />
                )}
            </ExerciseTypeImageWrapper>
        );
    };

    return (
        <ExerciseWrapper
            className={isInfoOpen ? "infoOpen" : ""}
            onClick={onSelect}
            disabled={isDisabled}
        >
            <ExerciseTitleSideWrapper className={isInfoOpen ? "infoOpen" : ""}>
                {renderTitleAndInfo()}
                {isInfoOpen && renderExerciseDescription()}
            </ExerciseTitleSideWrapper>
            <ExerciseImageSideWrapper className={isInfoOpen ? "infoOpen" : ""}>
                {renderExerciseImage()}
            </ExerciseImageSideWrapper>
        </ExerciseWrapper>
    );
};

export default SelectExerciseTypeItem;
