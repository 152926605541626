import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const Wrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: ${DesignConstants.SPACES.S} 0;
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    width: calc(100vw - ${DesignConstants.SPACES.L});
    max-width: calc(${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px - 2rem);
    margin: auto;
    gap: ${DesignConstants.SPACES.S};
`;

export const ScrollToTopButton = styled.div`
    display: flex;
    margin-right: ${DesignConstants.SPACES.S};
    align-self: flex-end;
    z-index: 100;
    width: ${DesignConstants.SIZES.ICON_M};
    height: ${DesignConstants.SIZES.ICON_M};
    background: white;
    border-radius: 50%;
    border: ${DesignConstants.BORDERS.THIN_GREY};
    cursor: pointer;

    align-items: center;
    justify-content: center;
`;

export const StyledP6Icon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
    color: ${DesignConstants.COLORS.MID_GREY};
`;

export const PositioningWrapper = styled.div`
    display: flex;
    position: absolute;
    top: calc(-${DesignConstants.SIZES.ICON_M} / 2 + ${DesignConstants.SPACES.XS});
    width: 100%;
    overflow: visible;
    justify-content: center;
    align-items: center;
`;

export const FullWidthWrapper = styled.div`
    position: absolute;
    display: flex;
    width: 100vw;
    max-width: 1400px;
    justify-content: flex-end;
`;

export const OuterWrapper = styled.div`
    display: flex;
    position: fixed;
    align-items: flex-start;
    bottom: 0;
`;
