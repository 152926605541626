import DesignConstants from "../../../constants/DesignConstants";
import styled from "styled-components";

export const TestCreationHeaderWrapper = styled.div`
    position: sticky;
    z-index: 3;
    top: 60px;
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    margin: -1rem auto 0;
    padding: ${DesignConstants.SPACES.S} 0 ${DesignConstants.SPACES.XS};
    width: 101%;
`;
