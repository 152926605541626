import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useT } from "@transifex/react/dist";
import { StudentQuestionAnswer } from "p6m-p6u";
import { useMediaQuery } from "react-responsive";
import { debounce } from "lodash";
import ExerciseInputField from "../../../basic/exerciseInputField/ExerciseInputField";
import InputControlArea from "../inputControlArea/InputControlArea";
import { isAndroid } from "react-device-detect";

export interface StandardExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
}

const StandardExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
    }
`;

const ExerciseRowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 80%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 1rem;
    }
`;

const ExerciseItemSeparator = styled.div`
    flex: 1;
`;

const QuestionText = styled.p`
    flex: 4;
    text-align: right;

    &.left {
        text-align: left;
    }
`;

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        // console.log("debouncing");
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

// do debounce

const StandardExercise: React.FC<StandardExerciseProps> = (props) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "standardExercise,inputPlaceholder" });

    const { studentAnswerItem, updateStudentQuestionAnswers } = props;
    const [answersContent, setAnswersContent] = useState(
        studentAnswerItem.studentAnswers ? studentAnswerItem.studentAnswers : []
    );
    const [lastEditedIndex, setLastEditedIndex] = useState<number>(-1);

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const updateItemAtPos = function (pos: number, value: string) {
        const newAnswers = [...answersContent];
        newAnswers[pos].studentAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        updateDataToParentDebounced(
            { ...studentAnswerItem, studentAnswers: answersContent },
            updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answersContent]);

    const refs = useRef(new Array(studentAnswerItem.studentAnswers!.length));
    const checkFocus = function (pos: number, event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            if (pos + 1 === refs.current.length) {
                refs.current[pos]?.blur();
            } else {
                refs.current[pos + 1]?.focus();
            }
        }
    };

    const handleFocus = function (pos: number) {
        setLastEditedIndex(pos);
    };

    const restartInputValues = function () {
        setLastEditedIndex(-1);
    };
    const focusExerciseAtIndex = function (index: number) {
        // console.log('focusing at index', index)
        if (index < studentAnswerItem.studentAnswers!.length) {
            refs.current[index]?.focus();
        }
    };

    let exercises = [];

    if (studentAnswerItem.studentAnswers) {
        if (isMobileView) {
            for (let i = 0; i < studentAnswerItem.studentAnswers.length; i++) {
                let item = studentAnswerItem.studentAnswers[i];
                const QuestionElement = (
                    <ExerciseInputField
                        key={i}
                        onChange={(event) => {
                            updateItemAtPos(i, event.target.value);
                        }}
                        onKeyUp={(event) => checkFocus(i, event)}
                        onFocus={() => handleFocus(i)}
                        type="text"
                        placeholder={t_placeholder}
                        ref={(element: any) => (refs.current[i] = element)}
                        value={item.studentAnswer}
                    />
                );

                if (item.question) {
                    exercises.push(
                        <ExerciseRowContainer key={"key_" + item.id}>
                            <QuestionText>{item.question}</QuestionText>
                            {QuestionElement}
                        </ExerciseRowContainer>
                    );
                } else {
                    exercises.push(
                        <ExerciseRowContainer key={"key_" + item.id}>
                            <QuestionText>{item.answer}</QuestionText>
                            {QuestionElement}
                        </ExerciseRowContainer>
                    );
                }
            }
        } else {
            for (let i = 0; i < studentAnswerItem.studentAnswers.length; i++) {
                let item = studentAnswerItem.studentAnswers[i];
                const QuestionElement = (
                    <ExerciseInputField
                        key={i}
                        onChange={(event) => {
                            updateItemAtPos(i, event.target.value);
                        }}
                        onKeyUp={(event) => checkFocus(i, event)}
                        type="text"
                        placeholder={t_placeholder}
                        ref={(element: any) => (refs.current[i] = element)}
                        value={item.studentAnswer}
                    />
                );
                exercises.push(
                    <ExerciseRowContainer key={"key_" + item.id}>
                        {!!item.question ? <QuestionText>{item.question}</QuestionText> : QuestionElement}
                        <ExerciseItemSeparator />
                        {!!item.answer ? <QuestionText className="left">{item.answer}</QuestionText> : QuestionElement}
                    </ExerciseRowContainer>
                );
            }
        }
    }

    return (
        <StandardExerciseWrapper>
            {exercises}
            {isMobileView && isAndroid && lastEditedIndex !== -1 && (
                <InputControlArea
                    currentIndex={lastEditedIndex}
                    endInputCallback={restartInputValues}
                    answersLength={studentAnswerItem.studentAnswers?.length}
                    focusSwitchCallback={focusExerciseAtIndex}
                />
            )}
        </StandardExerciseWrapper>
    );
};

export default StandardExercise;
