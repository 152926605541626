import { View } from "@react-pdf/renderer";
import React from "react";
import { TestDetailsItem } from "p6m-p6u";
import PDFWordBox from "../pdfSubComponents/PDFWordBox";
import PDFGapSentences from "../pdfSubComponents/PDFGapSentences";
import { sanitizePDFText } from "../../../helpers/PDFHelper";
import { ExerciseDirections } from "../../../enums/directions";

interface PDFGapSentenceExerciseProps {
    exercise: TestDetailsItem;
    isSolution?: boolean;
}

const PDFGapSentenceExercise: React.FC<PDFGapSentenceExerciseProps> = (props) => {
    const selectedLearningWords =
        props.exercise.selectedWords?.map((w) =>
            "id" in w && w.id
                ? sanitizePDFText(
                      props.exercise.direction === ExerciseDirections.ANSWER_TO_QUESTION ? w.answer : w.question
                  )
                : ""
        ) ?? [];

    return (
        <View>
            <PDFWordBox words={selectedLearningWords} />
            <PDFGapSentences
                selectedWords={props.exercise.selectedWords ?? []}
                isSolution={props.isSolution}
            />
        </View>
    );
};

export default PDFGapSentenceExercise;
