import React, { useEffect, useMemo, useState } from "react";
import { UnitCard, UserOwnCard } from "p6m-p6u";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useT } from "@transifex/react";
import { sanitizeText } from "../../../helpers/TestCreation";
import { useGeneralContext } from "../../../context/AppGeneralContext";

import {
    CardWrapper,
    CheckboxWrapper,
    QuestionWrapper,
    AnswerWrapper,
    QuestionAnswerWrapper,
    TextSpan,
    Checkbox,
    GapSentenceWrapper,
    SmallP6Icon,
    CheckIcon,
    CardAlreadyInUseIconWrapper,
} from "./StyledComponents";

export interface CardListingCardItemProps {
    card: UnitCard | UserOwnCard;
    isChecked: boolean;
    openModalCallback: () => any;
    hideGapSentences?: boolean;
}
const CardListingCardItemRT: React.FC<CardListingCardItemProps> = (props) => {
    const {
        vocabularyDrawerContent,
        addCardToSelectedWords,
        removeCardFromSelectedWords,
        maxAmountOfCardsSelectable,
        openWarningModal,
        previouslyUsedCardsIds,
    } = useTestCreationContext();
    const { creatingMode } = useGeneralContext();
    const { card, openModalCallback, hideGapSentences } = props;

    const [isChecked, setIsChecked] = useState(false);

    const drawerObjContent = useMemo(
        () => vocabularyDrawerContent[card.id || ""] || {},
        [card.id, vocabularyDrawerContent]
    );

    const t = useT();
    const t_cantAddMoreThan50 = t("Can't add more than {maxAmountOfCardsSelectable} cards", {
        maxAmountOfCardsSelectable,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });
    const t_wordInUseInRT = t("You have already used this word in a previous exercise for this class", {
        maxAmountOfCardsSelectable,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });

    const isRecurringTestMode = creatingMode === "RECURRING_TASK";
    let isCardInPreviouslyUsed;
    if ("uuid" in card) {
        isCardInPreviouslyUsed = previouslyUsedCardsIds.includes(card.uuid!);
    } else {
        isCardInPreviouslyUsed = previouslyUsedCardsIds.includes(card.id!);
    }

    useEffect(() => {
        setIsChecked(!!drawerObjContent.wordContent?.id || false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerObjContent]);

    function openModalCardAlreadyInRecurringTask() {
        openWarningModal(t_wordInUseInRT);
    }

    function toggleCheckedStatus() {
        let action: "DELETE" | "ADD" = isChecked ? "DELETE" : "ADD";

        // let currentVocabulary = {...vocabularyDrawerContent}
        if (action === "DELETE") {
            if (drawerObjContent.exerciseIds?.length) {
                openModalCallback();
            } else {
                removeCardFromSelectedWords(card.id!);
                setIsChecked(!isChecked);
            }
        } else {
            let addResult = addCardToSelectedWords(card);
            if (!addResult) {
                openWarningModal(<p>{t_cantAddMoreThan50}</p>);
            } else {
                setIsChecked(!isChecked);
            }
        }
        // setVocabularyDrawerContent(currentVocabulary);
    }

    return (
        <CardWrapper>
            <CheckboxWrapper onClick={toggleCheckedStatus}>
                {(!drawerObjContent.exerciseIds || drawerObjContent.exerciseIds?.length === 0) && (
                    <Checkbox className={isChecked ? "checked" : ""}>
                        {isChecked ? <CheckIcon name={"exercise-ok"} /> : ""}
                    </Checkbox>
                )}
                {drawerObjContent.exerciseIds && drawerObjContent.exerciseIds?.length > 0 && (
                    <SmallP6Icon
                        className={"orange"}
                        name={"in-use"}
                    />
                )}
            </CheckboxWrapper>
            <QuestionAnswerWrapper
                onClick={toggleCheckedStatus}
                className={isRecurringTestMode && isCardInPreviouslyUsed ? "half-opacity" : ""}
            >
                <QuestionWrapper>
                    <TextSpan className={isChecked ? "checked" : ""}>
                        {"question_text" in card && card.question_text
                            ? sanitizeText(card.question_text)
                            : "question" in card
                            ? card.question
                            : ""}
                    </TextSpan>
                </QuestionWrapper>
                <AnswerWrapper>
                    <TextSpan className={isChecked ? "checked" : ""}>
                        {"answer_text" in card && card.answer_text
                            ? sanitizeText(card.answer_text)
                            : "answer" in card
                            ? card.answer
                            : ""}
                    </TextSpan>
                </AnswerWrapper>
                {!hideGapSentences && "gap_sentence" in card && card.gap_sentence !== "" && (
                    <GapSentenceWrapper>
                        <TextSpan className={isChecked ? "checked" : ""}>
                            {" "}
                            <b>&#8594;</b> <i>{card.gap_sentence ? sanitizeText(card.gap_sentence) : ""}</i>
                        </TextSpan>
                    </GapSentenceWrapper>
                )}
            </QuestionAnswerWrapper>
            {isRecurringTestMode && (
                <CardAlreadyInUseIconWrapper onClick={openModalCardAlreadyInRecurringTask}>
                    {isCardInPreviouslyUsed && <SmallP6Icon name="in-use" />}
                </CardAlreadyInUseIconWrapper>
            )}
        </CardWrapper>
    );
};

export default CardListingCardItemRT;
