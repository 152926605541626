import React from "react";
import { TestDetailsWord } from "p6m-p6u";
import { useTestCreationContext } from "../../../../../../../context/TestCreationContext";
import { Exercises, ExerciseTypes } from "../../../../../../../enums/exercises";
import { sanitizeText } from "../../../../../../../helpers/TestCreation";

import { ListItem, RemoveWord, RemoveWordIcon, TextWrapper, Wrapper } from "./StyledComponents";

export interface GapSentenceListProps {
    onRemoveWord: (wordId?: string) => any;
}

const GapSentenceList: React.FC<GapSentenceListProps> = (props) => {
    const { currentExerciseModel } = useTestCreationContext();

    const renderGapSentence = (word: TestDetailsWord) =>
        currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP) &&
        sanitizeText(word.gap_sentence ?? "");

    const renderDelete = (wordId?: string) => (
        <RemoveWord
            onClick={() => props.onRemoveWord(wordId)}
            className={
                !currentExerciseModel.wordsInProgress || currentExerciseModel.wordsInProgress.length <= 3
                    ? "disabled"
                    : ""
            }
        >
            <RemoveWordIcon name={"close"} />
        </RemoveWord>
    );

    return (
        <Wrapper>
            {currentExerciseModel.wordsInProgress?.map((w) => {
                if ("id" in w) {
                    return (
                        <ListItem key={"wordListing_" + w.id}>
                            <TextWrapper>
                                {currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP) &&
                                    "gap_sentence" in w &&
                                    renderGapSentence(w)}
                            </TextWrapper>
                            {renderDelete(w.id)}
                        </ListItem>
                    );
                } else {
                    return null;
                }
            })}
        </Wrapper>
    );
};

export default GapSentenceList;
