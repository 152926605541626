import { useT } from "@transifex/react";
import { ExerciseImages, Exercises, ExerciseTypes } from "../../../../../enums/exercises";
import { useTestCreationContext } from "../../../../../context/TestCreationContext";

import {
    ExerciseTypeInputWrapper,
    ExerciseTypeWrapper,
    ExerciseTypeTextWrapper,
    ExerciseTypeText,
    ExerciseTypeImageWrapper,
    ExerciseTypeImage,
} from "./StyledComponents";

const SelectInputExerciseType = () => {
    const { currentExerciseModel, updateCurrentExerciseProperties, setIsTestSynced } = useTestCreationContext();
    const t = useT();

    const t_perWord = t("per word", { _tags: "CreateTest, CreateOrEditExercise" });
    const t_bundled = t("bundled", { _tags: "CreateTest, CreateOrEditExercise" });

    function selectExerciseType(exerciseType: Exercises) {
        const questionModeExerciseType = ExerciseTypes.get(exerciseType);
        if (questionModeExerciseType === currentExerciseModel.questionMode) return;

        setIsTestSynced(false);
        updateCurrentExerciseProperties({ questionMode: questionModeExerciseType });
    }

    const renderSingleExerciseType = (isTextRight: boolean, exerciseType: Exercises) => {
        const isSelected = currentExerciseModel.questionMode === ExerciseTypes.get(exerciseType);

        return (
            <ExerciseTypeWrapper
                isSelected={isSelected}
                isTextRight={isTextRight}
                onClick={() => selectExerciseType(exerciseType)}
            >
                <ExerciseTypeTextWrapper isTextRight={isTextRight}>
                    <ExerciseTypeText>
                        {exerciseType === Exercises.WRITE_SENTENCE ? t_perWord : t_bundled}
                    </ExerciseTypeText>
                </ExerciseTypeTextWrapper>

                <ExerciseTypeImageWrapper isSelected={isSelected}>
                    <ExerciseTypeImage
                        src={ExerciseImages.get(exerciseType)}
                        alt={`Exercise type: ${exerciseType}`}
                    />
                </ExerciseTypeImageWrapper>
            </ExerciseTypeWrapper>
        );
    };

    return (
        <ExerciseTypeInputWrapper>
            {renderSingleExerciseType(false, Exercises.WRITE_SENTENCE)}
            {renderSingleExerciseType(true, Exercises.CREATE_TEXT)}
        </ExerciseTypeInputWrapper>
    );
};

export default SelectInputExerciseType;
