import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useT } from "@transifex/react/dist";
import { StudentQuestionAnswer } from "p6m-p6u";
import { useMediaQuery } from "react-responsive";
import { debounce } from "lodash";
import ExerciseTextArea from "../../../basic/exerciseTextArea/ExerciseTextArea";
import { isAndroid } from "react-device-detect";
import InputControlArea from "../inputControlArea/InputControlArea";

export interface FreeTextExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
}

const FreeTextExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 1rem;
`;

const BiggerTextArea = styled(ExerciseTextArea)`
    > textarea {
        height: 150px;
    }
`;

const TextFieldStarterTextWrapper = styled.div`
    width: 100%;
    margin-top: 1rem;
`;

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

const FreeTextExercise: React.FC<FreeTextExerciseProps> = (props) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "FreeTextExercise,inputPlaceholder" });

    const { studentAnswerItem, updateStudentQuestionAnswers } = props;
    const [answerContent, setAnswersContent] = useState(studentAnswerItem);
    const [isEditionMode, setIsEditionMode] = useState<boolean>(false);

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const updateAnswer = function (value: string) {
        const newAnswers = { ...answerContent };
        newAnswers.textAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        updateDataToParentDebounced(
            {
                ...answerContent,
            },
            updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answerContent]);

    const textRef = useRef<HTMLTextAreaElement>(null);

    return (
        <FreeTextExerciseWrapper>
            {studentAnswerItem.textFieldStarterText && (
                <TextFieldStarterTextWrapper>{studentAnswerItem.textFieldStarterText}</TextFieldStarterTextWrapper>
            )}
            <BiggerTextArea
                onChange={(event) => {
                    updateAnswer(event.target.value);
                }}
                type="text"
                placeholder={t_placeholder}
                ref={textRef}
                value={answerContent.textAnswer}
                onFocus={() => setIsEditionMode(true)}
                onBlur={() => setIsEditionMode(false)}
            />
            {isMobileView && isAndroid && isEditionMode && <InputControlArea />}
        </FreeTextExerciseWrapper>
    );
};

export default FreeTextExercise;
