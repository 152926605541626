import React from "react";

import { Wrapper } from "./StyledComponents";

export interface CtasWrapperProps {
    children?: React.ReactNode;
    className?: string;
}

const CtasWrapperRT: React.FC<CtasWrapperProps> = (props) => {
    return <Wrapper className={props.className}>{props.children}</Wrapper>;
};

export default CtasWrapperRT;
