import React from "react";
import { useTestCreationContext } from "../../../../../context/TestCreationContext";
import { ExerciseDirections } from "../../../../../enums/directions";
import { Exercises, ExerciseTypes } from "../../../../../enums/exercises";
import { useT } from "@transifex/react";

import { DirectionSelectorWrapper, StyledFlagIcon, StyledPhase6Icon, DirectionItem } from "./StyledComponents";

export interface DirectionSelectorProps {}

const DirectionSelector: React.FC<DirectionSelectorProps> = (props) => {
    const {
        updateCurrentExerciseProperties,
        currentExerciseModel,
        selectedBookData,
        selectedUserSubject,
        setIsTestSynced,
    } = useTestCreationContext();

    const t = useT();
    const t_random = t("random", { _tags: "CreateTest,DirectionSelector" });
    const t_question = t("Question", { _tags: "CreateTest,DirectionSelector" });
    const t_answer = t("Answer", { _tags: "CreateTest,DirectionSelector" });


    function updateExerciseDirection(direction: ExerciseDirections) {
        updateCurrentExerciseProperties({ direction });
        setIsTestSynced(false);
    }

    const renderQuestion = () => {
        if (selectedBookData.band?.Language?.questionLanguageName && selectedUserSubject.id) {
            return t_question;
        } else if (selectedBookData.band?.Language?.questionLanguageName && !selectedUserSubject.id) {
            return <StyledFlagIcon languageName={selectedBookData.band?.Language?.questionLanguageName || ""} />;
        } else if (selectedUserSubject.id) {
            return <StyledFlagIcon languageName={selectedUserSubject.primaryLang || ""} />;
        } else {
            return <></>;
        }
    };

    const renderAnswer = () => {
        if (selectedBookData.band?.Language?.questionLanguageName && selectedUserSubject.id) {
            return t_answer;
        } else if (selectedBookData.band?.Language?.questionLanguageName && !selectedUserSubject.id) {
            return <StyledFlagIcon languageName={selectedBookData.band?.Language?.answerLanguageName || ""} />;
        } else if (selectedUserSubject.id) {
            return <StyledFlagIcon languageName={selectedUserSubject.secondaryLang || ""} />;
        } else {
            return <></>;
        }
    };

    return (
        <DirectionSelectorWrapper>
            <DirectionItem
                className={(currentExerciseModel.direction ?? 0) === ExerciseDirections.QUESTION_TO_ANSWER ? "selected" : ""}
                onClick={() => updateExerciseDirection(ExerciseDirections.QUESTION_TO_ANSWER)}
            >
                {renderQuestion()}
            </DirectionItem>
            <DirectionItem
                className={(currentExerciseModel.direction ?? 0) === ExerciseDirections.ANSWER_TO_QUESTION ? "selected" : ""}
                onClick={() => updateExerciseDirection(ExerciseDirections.ANSWER_TO_QUESTION)}
            >
                {renderAnswer()}
            </DirectionItem>
            {currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.STANDARD) && (
                <DirectionItem
                    className={(currentExerciseModel.direction ?? 0) === ExerciseDirections.RANDOM ? "selected" : ""}
                    onClick={() => updateExerciseDirection(ExerciseDirections.RANDOM)}
                >
                    <StyledPhase6Icon name={"random"} /> {t_random}
                </DirectionItem>
            )}
        </DirectionSelectorWrapper>
    );
};

export default DirectionSelector;
