import React, { useEffect, useState } from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useHistory } from "react-router-dom";
import { useT, UT } from "@transifex/react";
import StyledButton from "../../../basic/styledButton/StyledButton";
import DeleteCardWarningModal from "../../../basic/create/deleteCardWarningModal/DeleteCardWarningModal";
import CreateInfoModal from "../../../basic/create/InfoModal/CreateInfoModal";
import { CoverImage } from "p6-react-shared";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import {
    Title,
    TitleWrapper,
    GeneralWrapper,
    SelectBookVocabularyWrapper,
    BookHeaderInfo,
    BookImageWrapper,
    BookNameWrapper,
    BookName,
    ExerciseToSpan,
    UnitsWrapper,
    BigButtonWithIcon,
} from "./StyledComponents";
import UnitAndCardsWrapperRT from "../../../complex/create/unitAndCardsWrapperRT/UnitAndCardsWrapperRT";
import CtasWrapperRT from "../../../wrapper/create/ctasWrapperRT/ctasWrapperRT";

export interface SelectBookVocabularyProps {}

const SelectBookVocabularyRT: React.FC<SelectBookVocabularyProps> = (props) => {
    const {
        selectedBookData,
        loadVocabularyForBand,
        selectedBandUnits,
        removeCardsFromSelectedWords,
        availableCards,
        vocabularyDrawerContent,
        openWarningModal,
    } = useTestCreationContext();
    const { creatingMode } = useGeneralContext();

    const history = useHistory();

    const t = useT();
    const t_exerciseTo = t("Exercise to:", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_back = t("Back", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_createExercise = t("Create Exercise", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_chooseMinVocabulary = t("Please select at least 3 words", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_addWords = t("Add more Cards", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_continue = t("Continue", { _tags: "CreateTest,SelectBookVocabulary" });
    const t_selectVocabularyTitle = t("Select Vocabulary", { _tags: "CreateTest,SelectBookVocabulary" });

    const [currentWordForDeletion, setCurrentWordForDeletion] = useState("");
    const [isCurrentWordForDeletionModalOpen, setIsCurrentWordForDeletionModalOpen] = useState(false);

    const [currentUnitCardsForDeletion, setCurrentUnitCardsForDeletion] = useState<Array<string>>([]);
    const [isDeleteAllUnitCardsModalOpen, setIsDeleteAllUnitCardsModalOpen] = useState(false);

    useEffect(() => {
        if (selectedBookData.band && selectedBookData.band.ID) {
            loadVocabularyForBand(selectedBookData.band.ID);
        } else {
            history.push("/create/choose-source");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBookData]);

    function handleCreateExerciseBtnClick() {
        if (Object.keys(vocabularyDrawerContent).length >= 3) {
            history.push("/create/select-exercise");
        } else {
            openWarningModal(t_chooseMinVocabulary);
        }
    }

    function closeDeleteWordWarningModal() {
        setIsCurrentWordForDeletionModalOpen(false);
    }

    function openDeleteModalFn(wordId: string) {
        setCurrentWordForDeletion(wordId);
        setIsCurrentWordForDeletionModalOpen(true);
    }

    function openDeleteUnitCardsFn(cardsToDelete: Array<string>) {
        setCurrentUnitCardsForDeletion(cardsToDelete);
        setIsDeleteAllUnitCardsModalOpen(true);
    }

    function deleteCardsFromUnit() {
        removeCardsFromSelectedWords(currentUnitCardsForDeletion);
        setIsDeleteAllUnitCardsModalOpen(false);
    }

    function handleBackButton() {
        if (creatingMode === "RECURRING_TASK") {
            history.push("/create/select-book");
        } else {
            history.push("/create/choose-source");
        }
    }

    function handleContinueButton() {
        history.push("/create/rt-summary");
    }

    return (
        <GeneralWrapper>
            {creatingMode === "RECURRING_TASK" && (
                <TitleWrapper className={"space"}>
                    <Title>{t_selectVocabularyTitle}</Title>
                </TitleWrapper>
            )}
            <SelectBookVocabularyWrapper>
                <BookHeaderInfo>
                    <BookImageWrapper>
                        {selectedBookData.band?.Image && (
                            <CoverImage
                                imageId={"https://www.phase-6.de" + selectedBookData.band?.Image}
                                size={"small"}
                            />
                        )}
                    </BookImageWrapper>
                    <BookNameWrapper>
                        <ExerciseToSpan>{t_exerciseTo}</ExerciseToSpan>
                        <BookName>
                            {selectedBookData.publisherBook?.BookName} ({selectedBookData.publisherBook?.PublisherName})
                            ({selectedBookData.band?.ShortName})
                        </BookName>
                    </BookNameWrapper>
                </BookHeaderInfo>
                <UnitsWrapper>
                    {selectedBandUnits.map((u) => {
                        if (u.id) {
                            let hasCards = availableCards.hasOwnProperty(u.id) && availableCards[u.id].items;
                            return (
                                <UnitAndCardsWrapperRT
                                    unitContent={u}
                                    key={u.id}
                                    openDeleteModalFn={openDeleteModalFn}
                                    openDeleteUnitCardsFn={openDeleteUnitCardsFn}
                                    cardsList={hasCards ? availableCards[u.id].items : []}
                                    totalUnitCardsCount={hasCards ? availableCards[u.id].count : 0}
                                />
                            );
                        }
                        return false;
                    })}
                </UnitsWrapper>
            </SelectBookVocabularyWrapper>
            <CtasWrapperRT>
                <StyledButton
                    onClick={handleBackButton}
                    arrowDirection={"LEFT"}
                    buttonStyle={"BLANK"}
                >
                    {t_back}
                </StyledButton>
                {creatingMode !== "RECURRING_TASK" && (
                    <BigButtonWithIcon
                        buttonStyle={"BLANK"}
                        icon={"vocabulary-list-unset"}
                        iconPosition={"LEFT"}
                        onClick={() => {
                            history.push("/create/choose-source");
                        }}
                    >
                        {t_addWords}
                    </BigButtonWithIcon>
                )}
                {creatingMode === "TEST" && (
                    <StyledButton
                        onClick={handleCreateExerciseBtnClick}
                        arrowDirection={"NONE"}
                        buttonStyle={"PRIMARY"}
                        disabled={Object.keys(vocabularyDrawerContent).length === 0}
                    >
                        {t_createExercise}
                    </StyledButton>
                )}
                {creatingMode === "RECURRING_TASK" && (
                    <StyledButton
                        onClick={handleContinueButton}
                        arrowDirection={"NONE"}
                        buttonStyle={"PRIMARY"}
                        disabled={Object.keys(vocabularyDrawerContent).length === 0}
                    >
                        {t_continue}
                    </StyledButton>
                )}
            </CtasWrapperRT>
            <DeleteCardWarningModal
                isModalOpen={isCurrentWordForDeletionModalOpen}
                closeModalFn={closeDeleteWordWarningModal}
                wordId={currentWordForDeletion}
            />
            <CreateInfoModal
                isOpen={isDeleteAllUnitCardsModalOpen}
                onRequestClose={() => {
                    setIsDeleteAllUnitCardsModalOpen(false);
                }}
                continueAction={deleteCardsFromUnit}
                continueText={t_continue}
                cancelAction={() => {
                    setIsDeleteAllUnitCardsModalOpen(false);
                }}
                compact
            >
                <UT
                    _str="Warning: Unchecking this box deletes all words from the basket and exercises for which those words have been used."
                    _inline
                    _tags="CreateTest,SelectBookVocabulary"
                />
            </CreateInfoModal>
        </GeneralWrapper>
    );
};

export default SelectBookVocabularyRT;
