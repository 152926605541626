// LIBRARIES
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useGeneralContext } from "../../context/AppGeneralContext";

const PrivateRoute: React.FunctionComponent<any> = ({ children, ...rest }) => {
    const { successfulAuth, userId, userInfo } = useGeneralContext();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                (successfulAuth && !!userId) || userInfo.isAnonymousUser ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/unauthorized",
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
