import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../constants/DesignConstants";

export const Arrow = styled(PhaseSixIcon)`
    width: 1.8rem;
    font-size: 1.8rem;
`;

export const Button = styled.button`
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    width: unset;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
    background: ${(props) => props.theme.base.primaryColor};
    border: solid 1px ${(props) => props.theme.base.primaryColor};
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    min-width: 180px;
    cursor: pointer;
    color: white;
    user-select: none;
    text-transform: none;

    &.CAPITALIZE {
        text-transform: capitalize !important;
    }

    &.NO_TRANSFORMATION {
        text-transform: none !important;
    }

    &.NONE {
        padding: 0.6rem 0.6rem;
    }

    &.LEFT {
        flex-direction: row-reverse;
    }

    &.RIGHT {
        flex-direction: row;
    }

    &.BLANK {
        background: white;
        border: solid 1px ${DesignConstants.COLORS.MID_GREY};
        color: ${DesignConstants.COLORS.DARK_GREY};
    }

    &.LIGHT_BORDER {
        background: white;
        border: solid 1px ${DesignConstants.COLORS.LIGHTEST_GREY};
        color: ${DesignConstants.COLORS.DARK_GREY};
    }

    &.ORANGE_BLANK {
        background: white;
        border: solid 1px ${DesignConstants.COLORS.P6_ORANGE};
        color: ${DesignConstants.COLORS.P6_ORANGE};
    }

    &:disabled {
        cursor: not-allowed;
        opacity: ${DesignConstants.OPACITY.THREE};
    }
`;

export const ChildrenWrapper = styled.div`
    flex: 4;
    white-space: nowrap;
`;

export const ArrowWrapper = styled.div`
    flex: 1;
    padding: 0;
    display: flex;
    align-items: center;
`;

export const CenteringDiv = styled.div`
    flex: 1;
`;
