import * as React from "react";
import DesignConstants from "../../constants/DesignConstants";
import { Arrow, ArrowIconWrapper } from "./StyledComponents";

export interface ArrowIconProps {
    className?: string;
    onClick: () => any;
    disabled?: boolean;
    direction: "LEFT" | "RIGHT" | "TOP" | "BOTTOM";
    color?: string;
}

const ArrowIcon: React.FC<ArrowIconProps> = (props) => {
    const { direction } = props;

    let color = props.disabled ? DesignConstants.COLORS.TRANSPARENT_BLACK : props.color ? props.color : undefined;

    return (
        <ArrowIconWrapper
            onClick={!!props.disabled ? () => {} : props.onClick}
            className={!!props.className ? props.className : ""}
        >
            {
                {
                    LEFT: (
                        <Arrow
                            name={"chevron-left"}
                            color={color}
                        />
                    ),
                    RIGHT: (
                        <Arrow
                            name={"chevron-right"}
                            color={color}
                        />
                    ),
                    TOP: (
                        <Arrow
                            name={"chevron-up"}
                            color={color}
                        />
                    ),
                    BOTTOM: (
                        <Arrow
                            name={"chevron-down"}
                            color={color}
                        />
                    ),
                }[direction]
            }
        </ArrowIconWrapper>
    );
};

export default ArrowIcon;
