import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import StyledButtonWithIcon from "../../../../../../basic/styledButtonWithIcon/StyledButtonWithIcon";

const xxs = parseFloat(DesignConstants.SPACES.XXS);
const xs = parseFloat(DesignConstants.SPACES.XS);
const result = xxs + xs + "rem";

export const InputWrapper = styled.div`
    padding: ${result} ${DesignConstants.SPACES.M};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    background: white;
`;

export const ButtonWithIcon = styled(StyledButtonWithIcon)`
    display: inline-block;
    align-self: start;
`;
