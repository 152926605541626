import styled, { css } from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

export const ConnectWordsExerciseWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding-top: ${DesignConstants.SPACES.S};
    margin-bottom: ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        gap: ${DesignConstants.SPACES.XS};
    }
`;

const basicColumnCss = css`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.XXS};
        width: 50%;
    }
`;
export const ConnectWordsLeftWrapper = styled.div`
    gap: ${DesignConstants.SPACES.S};
    ${basicColumnCss};
`;
export const ConnectWordsRightWrapper = styled.div`
    gap: ${DesignConstants.SPACES.S};
    ${basicColumnCss};
`;
