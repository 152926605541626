import { useT } from "@transifex/react";
import { useEffect, useState } from "react";
import { SidebarSelectedWord, TestDetailsWord, VerbtrainingSelectedWord } from "p6m-p6u";
import { shuffle } from "lodash";
import { useTestCreationContext } from "../../../../../context/TestCreationContext";
import { ExerciseDirections } from "../../../../../enums/directions";
import { translateWordFromModelToQuestion } from "../../../../../helpers/TestCreation";
import { ExercisesWithDirection } from "../../../../../enums/exercises";

import {
    SelectExerciseVocabularyHeaderWrapper,
    RandomCardNumberSelectorWrapper,
    RandomSelectionAndDeleteWrapper,
    RandomSelectionWrapper,
    SelectExerciseVocabularyHeaderTitle,
    StyledExerciseInputField,
    StyledIcon,
    StyledRandomSelectionButton,
    ToggleSelectVocabularyButton,
} from "./StyledComponent";

interface SelectExerciseVocabularyHeaderProps {
    onClose: () => void;
    availableVocabulary: SidebarSelectedWord[];
    disabled?: boolean;
    amountOfRemainingCards: number;
    processQueue: boolean;
    setProcessQueue: (value: boolean) => void;
    queue: string[];
    setQueue: (value: string[]) => void;
}

const defaultRandom = 5;

const SelectExerciseVocabularyHeader: React.FC<SelectExerciseVocabularyHeaderProps> = (props) => {
    const { vocabularyDrawerContent, currentExerciseModel, setCurrentExerciseModel, setIsTestSynced } =
        useTestCreationContext();

    const t = useT();
    const t_addWords = t("Add words", { _tags: "CreateTest,SelectExerciseVocabulary" });
    const t_randomSelection = t("Random selection", { _tags: "CreateTest,SelectExerciseVocabulary" });

    const defaultRandomCardsInputVal = Math.min(props.amountOfRemainingCards, defaultRandom);
    const [randomCardsInputVal, setRandomCardsInputVal] = useState(defaultRandomCardsInputVal);

    useEffect(() => {
        if (randomCardsInputVal > props.amountOfRemainingCards) {
            setRandomCardsInputVal(props.amountOfRemainingCards);
        }
    }, [randomCardsInputVal, props.amountOfRemainingCards]);

    useEffect(() => {
        if (props.processQueue && props.queue.length !== 0) {
            addWordsToExercise([props.queue[0]]);

            const filteredQueue = props.queue.filter((wordId) => wordId !== props.queue[0]);
            props.setQueue(filteredQueue);
            props.setProcessQueue(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.processQueue]);

    function addWordsToExercise(wordIds: string[]) {
        if (!wordIds || wordIds.length < 1) return;
        const exercise = { ...currentExerciseModel };
        const newExerciseWords: Array<TestDetailsWord | VerbtrainingSelectedWord> = [];

        wordIds.forEach((w) => {
            if (vocabularyDrawerContent[w] && vocabularyDrawerContent[w].wordContent) {
                newExerciseWords.push(translateWordFromModelToQuestion(vocabularyDrawerContent[w].wordContent!));
            }
        });

        // set correct direction for each word for all exercises with direction
        if (ExercisesWithDirection.includes(exercise.questionMode ?? "")) {
            newExerciseWords.map((word) => {
                if ("id" in word) {
                    if (currentExerciseModel.direction === ExerciseDirections.RANDOM) {
                        const randomDirection = Math.random();
                        word.isQuestionShown = randomDirection <= 0.5;
                    } else {
                        word.isQuestionShown =
                            currentExerciseModel.direction === undefined ||
                            currentExerciseModel.direction === ExerciseDirections.QUESTION_TO_ANSWER;
                    }
                }
                return word;
            });
        }

        exercise.wordsInProgress = [...(exercise.wordsInProgress ? exercise.wordsInProgress : []), ...newExerciseWords];

        setCurrentExerciseModel(exercise);
        setIsTestSynced(false);
    }

    function prepareRandomWordsForExercise() {
        const availableWords = props.availableVocabulary.filter(
            (word) => !props.queue.includes(word.wordContent?.id || "")
        );
        const randomCardsToAdd = shuffle(availableWords).slice(0, randomCardsInputVal);
        const ids = randomCardsToAdd.map((w) => w.wordContent?.id || "");
        return ids;
    }

    const renderToggleAddWordsButton = () => {
        return (
            <ToggleSelectVocabularyButton onClick={props.onClose}>
                <StyledIcon name={"chevron-up"} />
                <SelectExerciseVocabularyHeaderTitle>{t_addWords}</SelectExerciseVocabularyHeaderTitle>
            </ToggleSelectVocabularyButton>
        );
    };

    const renderRandomSelector = () => (
        <RandomSelectionAndDeleteWrapper>
            <RandomSelectionWrapper>
                <RandomCardNumberSelectorWrapper
                    className={props.amountOfRemainingCards <= 0 || props.disabled ? "disabled" : ""}
                >
                    <StyledExerciseInputField
                        type={"number"}
                        max={props.amountOfRemainingCards}
                        min={0}
                        value={randomCardsInputVal}
                        onChange={(e) => {
                            setRandomCardsInputVal(Math.min(props.amountOfRemainingCards, Number(e.target.value)));
                        }}
                        className={props.amountOfRemainingCards <= 0 || props.disabled ? "disabled" : ""}
                    />
                </RandomCardNumberSelectorWrapper>
                <StyledRandomSelectionButton
                    onClick={() => addWordsToExercise(prepareRandomWordsForExercise())}
                    arrowDirection={"NONE"}
                    buttonStyle={"BLANK"}
                    textStyle={"NO_TRANSFORMATION"}
                    disabled={props.amountOfRemainingCards <= 0 || props.disabled}
                >
                    {t_randomSelection}
                </StyledRandomSelectionButton>
            </RandomSelectionWrapper>
        </RandomSelectionAndDeleteWrapper>
    );

    return (
        <SelectExerciseVocabularyHeaderWrapper>
            {renderToggleAddWordsButton()}
            {renderRandomSelector()}
        </SelectExerciseVocabularyHeaderWrapper>
    );
};

export default SelectExerciseVocabularyHeader;
