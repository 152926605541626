import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import DesignConstants from "../../../constants/DesignConstants";
import { TestDetailsWord, VerbtrainingSelectedWord } from "p6m-p6u";

const alphabet = [...Array(26)].map((e, i) => (i + 10).toString(36));
/**remove quotation marks "\""*/
const _sanitize = (sentence: string) => {
    return sentence.replaceAll('"', "");
};

interface PDFGapSentencesProps {
    selectedWords: Array<TestDetailsWord | VerbtrainingSelectedWord>;
    isSolution?: boolean;
}

const PDFGapSentences: React.FC<PDFGapSentencesProps> = (props) => {
    const renderAlphabetChar = (i: number) => <Text style={styles.text}>{`${alphabet[i]})  `}</Text>;

    const renderGapSentence = (sentence: string | undefined, i: number) => {
        return (
            sentence && (
                <View
                    key={`${alphabet[i]}_${i}`}
                    style={styles.sentence}
                >
                    {renderAlphabetChar(i)}
                    <Text style={styles.text}>{_sanitize(sentence)} </Text>
                </View>
            )
        );
    };

    const renderSolutionSentence = (selectedWord: TestDetailsWord, i: number) => {
        const splitGapSentence = selectedWord.gap_sentence?.split(/_+/) ?? [];
        const solutionSentence = (
            <View
                key={`${alphabet[i]}_${i}`}
                style={styles.sentence}
            >
                {renderAlphabetChar(i)}
                <Text style={styles.text}>{_sanitize(splitGapSentence[0])}</Text>
                <Text style={[styles.text, styles.bold]}>{selectedWord.answer}</Text>
                {_sanitize(splitGapSentence[1]).trim().substring(0, 1) === "-" && <Text style={styles.text}> </Text>}
                <Text style={styles.text}>{_sanitize(splitGapSentence[1])}</Text>
            </View>
        );
        return solutionSentence;
    };

    const renderSentences = () => {
        return props.selectedWords.map((word, i) => {
            return (
                "id" in word &&
                word.id &&
                (props.isSolution ? renderSolutionSentence(word, i) : renderGapSentence(word.gap_sentence, i))
            );
        });
    };

    return <View style={styles.container}>{renderSentences()}</View>;
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        marginTop: DesignConstants.PDF.GAP_XS,
        paddingHorizontal: DesignConstants.PDF.GAP_S,
        gap: DesignConstants.PDF.GAP_XS,
    },
    sentence: {
        flexDirection: "row",
        paddingTop: DesignConstants.PDF.GAP_XXS,
    },
    text: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
    },
    bold: {
        fontWeight: DesignConstants.PDF.SEMIBOLD,
    },
});

export default PDFGapSentences;
