// LIBRARIES
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useT, UT } from "@transifex/react";
import ArrowIcon from "../../../basic/arrowIcon/ArrowIcon";
import TestReviewExerciseListing from "../../../complex/testReviewExerciseListing/TestReviewExerciseListing";
import TestReviewIndividualListing from "../../../complex/testReviewIndividualListing/TestReviewIndividualListing";
import { useTestReviewContext } from "../../../context/TestReviewContext";
import TestReviewInfoHeader from "../../../basic/testReviewInfoHeader/TestReviewInfoHeader";
import { useHistory, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { getCorrectPathname } from "../../../helpers/Url";
import StyledButton from "../../../basic/styledButton/StyledButton";

export interface ReviewTestProps {}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const EvaluationInfoBox = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    margin: 1rem 0 2rem 0;
    background: #f5f5f5;
    border-radius: 0.5rem;
`;

const EvaluationInfoBoxHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
`;

const EvaluationInfoBoxHeaderTitle = styled.h3`
    margin-bottom: 0.5rem;
    font-weight: 500;
`;

const EvaluationInfoBoxBody = styled.div`
    padding: 0.5rem 1rem;
    width: 100%;
`;

const EvaluationInfoBoxParagraph = styled.p`
    margin-bottom: 0.5rem;
    line-height: 1.3;
`;

const CurrentTabContentWrapper = styled.div`
    margin: 1rem 0 5rem;
`;

const TabsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ddd;
`;

const ReviewTab = styled.div`
    padding: 1rem 1.5rem;
    border-style: solid;
    border-width: 0 0 2px 0;
    border-color: #ddd;
    border-bottom: none;
    background: #fff;
    font-weight: 500;
    cursor: pointer;
    color: #999;

    &.active {
        border-width: 2px 2px 0 2px;
        border-radius: 0.5rem 0.5rem 0 0;
        margin: 0 0 -2px 0;
        color: #666;
    }
`;

const AnchoredCTAsRow = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    box-shadow: 0 -3px 3px rgb(0 0 0 / 10%);
    padding: 1rem 2rem;
`;

const StyledBlankButton = styled(StyledButton)`
    font-size: 1.2rem;
    width: auto;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 40%;
        margin-bottom: 1rem;
    }
`;

const ReviewTest: React.FC<ReviewTestProps> = (props) => {
    const t = useT();
    const t_info = t("Important", { _tags: "reviewTest,testInfo" });
    const t_reviewPerExercise = t("Review by Exercise", { _tags: "reviewTest,testInfo" });
    const t_individualReview = t("Individual Review", { _tags: "reviewTest,testInfo" });
    const t_shareGrades = t("Share Grades", { _tags: "reviewTest,testInfo" });
    const t_back = t("Back", { _tags: "reviewTest,testInfo" });

    const { getCountOfExercisesInTest, getAmountOfStudentAnswers, currentReviewTab, setCurrentReviewTab } =
        useTestReviewContext();
    const history = useHistory();
    const location = useLocation();

    const [showEvaluationBodyInfo, setShowEvaluationBodyInfo] = useState(true);

    // Rebuilding tooltips on change of current tab, per documentation https://www.npmjs.com/package/react-tooltip
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [currentReviewTab]);

    // Setting the exerciseReview as the default one on init, and rebuilding the tooltips.
    useEffect(() => {
        if (!currentReviewTab) {
            setCurrentReviewTab("exerciseReview");
        }
        ReactTooltip.rebuild();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function redirectUser() {
        history.push("/create");
    }

    function navigateToShare() {
        history.push(getCorrectPathname(location.pathname) + "share");
    }

    return (
        <Wrapper>
            <StyledBlankButton
                onClick={redirectUser}
                buttonStyle={"BLANK"}
                arrowDirection={"LEFT"}
            >
                {t_back}
            </StyledBlankButton>
            <TestReviewInfoHeader />
            <EvaluationInfoBox>
                <EvaluationInfoBoxHeader
                    onClick={() => {
                        setShowEvaluationBodyInfo(!showEvaluationBodyInfo);
                    }}
                >
                    <EvaluationInfoBoxHeaderTitle>{t_info}</EvaluationInfoBoxHeaderTitle>
                    <ArrowIcon
                        onClick={() => {}}
                        direction={showEvaluationBodyInfo ? "TOP" : "BOTTOM"}
                    />
                </EvaluationInfoBoxHeader>
                {showEvaluationBodyInfo && (
                    <EvaluationInfoBoxBody>
                        <EvaluationInfoBoxParagraph>
                            <UT
                                _str="There are two options for the evaluation:"
                                _inline
                                _tags="reviewTest,testInfo"
                            />
                        </EvaluationInfoBoxParagraph>
                        <EvaluationInfoBoxParagraph>
                            <UT
                                _str="(1) In the <b>Evaluation by Exercise</b> you can see the totality of the student answers per exercise and can be evaluated (right/wrong) and commented for all the students with the same answer. "
                                _inline
                                _tags="reviewTest,testInfo"
                            />
                        </EvaluationInfoBoxParagraph>
                        <EvaluationInfoBoxParagraph>
                            <UT
                                _str="(2) In the <b>Individual Evaluation</b> you can check the evaluations again. In this mode, you can include an extra comment for the specified student."
                                _inline
                                _tags="reviewTest,testInfo"
                            />
                        </EvaluationInfoBoxParagraph>
                        <EvaluationInfoBoxParagraph>
                            <UT
                                _str="<b>We recommend to go through the evaluation in a device with a big screen.</b>"
                                _inline
                                _tags="reviewTest,testInfo"
                            />
                        </EvaluationInfoBoxParagraph>
                    </EvaluationInfoBoxBody>
                )}
            </EvaluationInfoBox>

            <CurrentTabContentWrapper>
                <TabsWrapper>
                    <ReviewTab
                        className={currentReviewTab === "exerciseReview" ? "active" : ""}
                        onClick={() => {
                            setCurrentReviewTab("exerciseReview");
                        }}
                    >
                        {t_reviewPerExercise} (
                        <UT
                            _str="{exerciseCount} Exercises"
                            exerciseCount={getCountOfExercisesInTest()}
                            _inline
                            _tags="reviewTest,testInfo"
                        />
                        )
                    </ReviewTab>
                    <ReviewTab
                        className={currentReviewTab === "individualReview" ? "active" : ""}
                        onClick={() => {
                            setCurrentReviewTab("individualReview");
                        }}
                    >
                        {t_individualReview} (
                        <UT
                            _str="{resultsCount} Submissions"
                            resultsCount={getAmountOfStudentAnswers()}
                            _inline
                            _tags="reviewTest,testInfo"
                        />
                        )
                    </ReviewTab>
                </TabsWrapper>

                {currentReviewTab === "exerciseReview" && <TestReviewExerciseListing />}
                {currentReviewTab === "individualReview" && <TestReviewIndividualListing />}
            </CurrentTabContentWrapper>

            <AnchoredCTAsRow>
                <StyledButton
                    onClick={navigateToShare}
                    buttonStyle={"PRIMARY"}
                >
                    {t_shareGrades}
                </StyledButton>
            </AnchoredCTAsRow>
            <ReactTooltip
                effect="solid"
                id={"tooltipReviewTest"}
            />
        </Wrapper>
    );
};

export default ReviewTest;
