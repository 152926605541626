import React, { useState } from "react";
import { useT, UT } from "@transifex/react";
import { StudentTestSharingData, TeacherTestDetails } from "p6m-p6u";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import ExerciseInputField from "../../../basic/exerciseInputField/ExerciseInputField";
import CreateInfoModal from "../../../basic/create/InfoModal/CreateInfoModal";
import { logEventWithProps } from "../../../logging/Logger";
import {
    DuplicateInputWrapper,
    TaskName,
    DuplicateConfirmationWrapper,
    DuplicateConfirmation,
    DeleteConfirmationWrapper,
    DeleteConfirmation,
    DeleteConfirmationInvalidLink,
    ShareConfirmationWrapper,
    ShareConfirmation,
    ShareConfirmationDataNotice,
} from "./StyledComponents";

export interface TestListElementInfoModalProps {
    isDuplicateModalOpen: boolean;
    setIsDuplicateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    teacherTestDetails: TeacherTestDetails;
    isDeleteModalOpen: boolean;
    setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isShareModalOpen: boolean;
    setIsShareModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    copiedLinkModalOpen: boolean;
    setCopiedLinkModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    copyTestLink: () => void;
    localSharingData: StudentTestSharingData;
}

const TestListElementInfoModal: React.FC<TestListElementInfoModalProps> = ({
    isDuplicateModalOpen,
    teacherTestDetails,
    setIsDuplicateModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isShareModalOpen,
    setIsShareModalOpen,
    copiedLinkModalOpen,
    setCopiedLinkModalOpen,
    copyTestLink,
    localSharingData,
}) => {
    const t = useT();
    const t_duplicateConfirmBtn = t("Make a copy", { _tags: "CreateTest,TestListElement" });
    const t_taskName = t("Name of the test:", { _tags: "CreateTest,TestListElement" });
    const t_yes = t("Yes", { _tags: "CreateTest,TestListElement" });
    const t_generateNow = t("Generate Now", { _tags: "CreateTest,TestListElement" });
    const t_cancel = t("Cancel", { _tags: "CreateTest,TestListElement" });
    const t_close = t("Close", { _tags: "CreateTest,TestListElement" });
    const t_duplicate = t("Duplicate", { _tags: "CreateTest,TestListElement" });
    const t_duplicateConfirm = t("Please confirm that you would like to make a copy", {
        _tags: "CreateTest,TestListElement",
    });
    const t_delete = t("Delete?", { _tags: "CreateTest,TestListElement" });
    const t_attention = t("Attention!", { _tags: "CreateTest,TestListElement" });
    const t_deleteConfirm = t("Do you want to delete this Test?", { _tags: "CreateTest,TestListElement" });
    const t_linkBecomesInvalid = t("The link becomes invalid for your students.", {
        _tags: "CreateTest,TestListElement",
    });
    const t_copyOf = t("Copy of", { _tags: "CreateTest,TestListElement" });
    const t_sharingError = t("There was an unexpected error while sharing the test.", {
        _tags: "CreateTest,TestListElement",
    });
    const t_sharingErroConnectWords = t(
        "Connect Words: please use at least 3 and no more than 5 words for this exercise. Please check your exercises and add or remove words to solve this problem.",
        {
            _tags: "CreateTest,TestListElement",
        }
    );
    const [duplicationInProgress, setDuplicationInProgress] = useState(false);
    const [duplicatedTestName, setDuplicatedTestName] = useState(`${t_copyOf} ${teacherTestDetails.name}`);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [shareInProgress, setShareInProgress] = useState(false);

    const { duplicateTest, deleteTest, shareTest, openWarningModal, canLogEvent } = useTestCreationContext();

    const defaultDuplicateTestName = `${t_copyOf} ${teacherTestDetails.name}`;

    function duplicateTestRequest() {
        if (teacherTestDetails.id) {
            setDuplicationInProgress(true);
            duplicateTest(
                teacherTestDetails.id,
                duplicatedTestName !== "" ? duplicatedTestName : defaultDuplicateTestName
            ).then(
                (message) => {
                    setTimeout(() => {
                        setIsDuplicateModalOpen(false);
                        setDuplicationInProgress(false);
                    }, 500);
                },
                (error) => {
                    alert("Error " + error);
                    setDuplicationInProgress(false);
                }
            );
        }
    }

    function deleteTestRequest() {
        if (teacherTestDetails.id) {
            setDeleteInProgress(true);
            deleteTest(teacherTestDetails.id).then(
                (message) => {
                    setTimeout(() => {
                        setIsDeleteModalOpen(false);
                        setDeleteInProgress(false);
                    }, 500);
                },
                (error) => {
                    setDeleteInProgress(false);
                }
            );
        }
    }

    function shareTestRequest() {
        if (teacherTestDetails.id) {
            setShareInProgress(true);
            shareTest(teacherTestDetails.id, localSharingData).then(
                () => {
                    if (canLogEvent()) {
                        logEventWithProps("VTG - Assignment Link Generated", {
                            testId: teacherTestDetails.id,
                            assignmentDeadline: localSharingData.dueDate,
                            assignmentTime: localSharingData.timeLimit,
                        });
                    }
                    copyTestLink();
                    setShareInProgress(false);
                    setIsShareModalOpen(false);
                },
                (error) => {
                    if (
                        error?.contentErrors.includes(
                            "Wörter verbinden: Wählen Sie mindestens 3 und maximal 5 Vokabeln aus."
                        )
                    ) {
                        openWarningModal(t_sharingErroConnectWords);
                    } else {
                        openWarningModal(t_sharingError);
                    }
                    setShareInProgress(false);
                }
            );
        } else {
            alert("Cant share");
        }
    }

    return (
        <>
            <CreateInfoModal
                isOpen={isDuplicateModalOpen}
                onRequestClose={() => {
                    setIsDuplicateModalOpen(false);
                }}
                cancelAction={() => {
                    setIsDuplicateModalOpen(false);
                }}
                continueAction={duplicateTestRequest}
                modalTitle={t_duplicate}
                continueActionDisabled={duplicatedTestName === "" || duplicationInProgress}
                continueText={t_duplicateConfirmBtn}
            >
                <DuplicateInputWrapper>
                    <TaskName>{t_taskName}</TaskName>
                    <ExerciseInputField
                        value={duplicatedTestName}
                        onChange={(ev) => {
                            setDuplicatedTestName(ev.target.value);
                        }}
                    />
                </DuplicateInputWrapper>
                <DuplicateConfirmationWrapper>
                    <DuplicateConfirmation>{t_duplicateConfirm}</DuplicateConfirmation>
                </DuplicateConfirmationWrapper>
            </CreateInfoModal>

            <CreateInfoModal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => {
                    setIsDeleteModalOpen(false);
                }}
                cancelAction={() => {
                    setIsDeleteModalOpen(false);
                }}
                continueAction={deleteTestRequest}
                modalTitle={t_delete}
                continueActionDisabled={deleteInProgress}
                continueText={t_yes}
                cancelText={t_cancel}
            >
                <DeleteConfirmationWrapper>
                    <DeleteConfirmation>{t_deleteConfirm}</DeleteConfirmation>
                    <DeleteConfirmationInvalidLink>{t_linkBecomesInvalid}</DeleteConfirmationInvalidLink>
                </DeleteConfirmationWrapper>
            </CreateInfoModal>

            <CreateInfoModal
                isOpen={isShareModalOpen}
                onRequestClose={() => {
                    setIsShareModalOpen(false);
                }}
                cancelAction={() => {
                    setIsShareModalOpen(false);
                }}
                continueAction={() => {
                    shareTestRequest();
                }}
                modalTitle={t_attention}
                continueActionDisabled={shareInProgress}
                continueText={t_generateNow}
                cancelText={t_cancel}
            >
                <ShareConfirmationWrapper>
                    <ShareConfirmation>
                        <UT
                            _str="As soon as the link for this exercise is Generated, it cannot be edited anymore. The Deadline can be updated after.<br/><br/>For further editing, you can duplicate the test and edit the new version."
                            _inline
                            _tags="CreateTest,TestListElement"
                        />
                    </ShareConfirmation>
                </ShareConfirmationWrapper>
            </CreateInfoModal>

            <CreateInfoModal
                isOpen={copiedLinkModalOpen}
                onRequestClose={() => {
                    setCopiedLinkModalOpen(false);
                }}
                continueAction={() => {
                    setCopiedLinkModalOpen(false);
                }}
                continueText={t_close}
            >
                <ShareConfirmationWrapper>
                    <ShareConfirmation>
                        <UT
                            _str="The link was copied to the clipboard.<br/>In the link, the students can answer to the Test online. They dont need to be registered."
                            _inline
                            _tags="CreateTest,TestListElement"
                        />
                        <ShareConfirmationDataNotice>
                            <UT
                                _str="To make an evaluation of the results possible, we save the answers from the students and their names (the one that they wrote themselves). Your students' assignments will be deleted automatically after 1 year or when you delete the assignment yourself."
                                _inline
                                _tags="CreateTest,TestListElement"
                            />
                        </ShareConfirmationDataNotice>
                    </ShareConfirmation>
                </ShareConfirmationWrapper>
            </CreateInfoModal>
        </>
    );
};

export default TestListElementInfoModal;
