import { StyleSheet, View } from "@react-pdf/renderer";
import React from "react";
import DesignConstants from "../../../constants/DesignConstants";

interface PDFEmptyLinesProps {
    linesAmount?: number;
}

const PDFEmptyLines: React.FC<PDFEmptyLinesProps> = (props) => {
    const renderLines = () => {
        const lines = [];
        for (let i = 0; i < (props.linesAmount ?? 4); i++) {
            lines.push(
                <View
                    key={`line_${i}`}
                    style={styles.line}
                />
            );
        }
        return lines;
    };

    return <View style={styles.container}>{renderLines()}</View>;
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        paddingHorizontal: DesignConstants.PDF.GAP_S,
    },
    line: {
        width: "100%",
        height: DesignConstants.PDF.GAP_M,
        borderBottomWidth: DesignConstants.PDF.BORDER_WIDTH_S,
        borderColor: DesignConstants.PDF.GREY,
    },
});

export default PDFEmptyLines;
