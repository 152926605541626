import * as React from "react";
import { BulletPointIcon, BulletPointWrapper } from "./StyledComponents";

export interface BulletPointProps {
    children?: React.ReactElement;
    bulletPointIcon?: string;
}

// rendering checkmarks by default
const BulletPoint: React.FC<BulletPointProps> = (props) => {
    return (
        <BulletPointWrapper>
            <BulletPointIcon name={props.bulletPointIcon ?? "exercise-ok"} />
            {props.children}
        </BulletPointWrapper>
    );
};

export default BulletPoint;
