import { AeaExerciseModel, AeaGroupedExercisesContent, UserOwnCard } from "p6m-p6u";
import moment from "moment";
import { sanitizeText } from "./TestCreation";

export function processUserUnitCardsResponse(responseData: any, subjectId: string, unitId: string): Array<UserOwnCard> {
    let returnData: Array<UserOwnCard> = [];

    if (responseData.length) {
        responseData.forEach(([cId, cardContent]: any) => {
            let newCard: UserOwnCard = { ...cId, ...cardContent };
            if (newCard.answer && newCard.answer.indexOf("[") >= 0) {
                newCard.answer = newCard.answer.split("[")[0];
            }
            newCard.answer = sanitizeText(newCard.answer || "");

            if (newCard.question && newCard.question.indexOf("[")) {
                newCard.question = newCard.question.split("[")[0];
            }
            newCard.question = sanitizeText(newCard.question || "");

            if (newCard.answer.indexOf(":image~}") >= 0 || newCard.question.indexOf(":image~}") >= 0) {
                return;
            }

            if (newCard.answer.trim().length === 0 || newCard.question.trim().length === 0) {
                return;
            }
            newCard.isUserCard = true;
            newCard.subjectId = subjectId;
            newCard.unitId = unitId;

            returnData.push(newCard);
        });
    }

    return returnData;
}

export function processExercisesListResponse(responseData: any, userId: string): AeaGroupedExercisesContent {
    let mergedExercises: Array<AeaExerciseModel> = [];
    let exercisesInGroup: { [key: string]: Array<AeaExerciseModel> } = {};
    let groupedExercises: AeaGroupedExercisesContent = {};

    if (responseData.length > 0) {
        responseData.forEach((i: Array<any>) => mergedExercises.push({ ...i[0], ...i[1] }));
    }

    mergedExercises.forEach((e) => {
        if (!e.groupId || !e.subjectContentId || !e.subjectContentId.id) {
            return;
        }
        if (exercisesInGroup.hasOwnProperty(e.groupId)) {
            exercisesInGroup[e.groupId].push(e);
        } else {
            exercisesInGroup[e.groupId] = [e];
        }
    });

    let lastCreatedExerciseId = localStorage.getItem("lsRt");

    for (const [key, exercisesArray] of Object.entries(exercisesInGroup)) {
        let bookedGroups: { [key: string]: Array<AeaExerciseModel> } = {};

        exercisesArray.forEach((exercise) => {
            if (!exercise.groupId || !exercise.subjectContentId || !exercise.subjectContentId.id) {
                return;
            }

            exercise.wasCreatedLast = !!lastCreatedExerciseId && lastCreatedExerciseId === exercise.id;
            exercise.isOwnContentTask = exercise.subjectContentId.ownerId === userId;

            if (bookedGroups.hasOwnProperty(exercise.subjectContentId.id)) {
                bookedGroups[exercise.subjectContentId.id].push(exercise);
            } else {
                bookedGroups[exercise.subjectContentId.id] = [exercise];
            }
        });

        Object.keys(bookedGroups).forEach((bookId) => {
            bookedGroups[bookId].sort((e1, e2) => moment(e2.createdDate).unix() - moment(e1.createdDate).unix());
        });

        groupedExercises = { ...groupedExercises, [key]: bookedGroups };
    }

    localStorage.removeItem("lsRt");

    return groupedExercises;
}
