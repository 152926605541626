import React, { ReactNode } from "react";
import { Page, Text, View, Document, StyleSheet, Image, Link } from "@react-pdf/renderer";
import { GetBandInfoResponse } from "p6m-p6u";
import logo from "../../assets/img/p6-logo.png";
import DesignConstants from "../../constants/DesignConstants";
import PDFClassNameDate from "./PDFClassNameDate";
import PDFFooterSourceInfo from "./PDFFooterSourceInfo";

interface PDFBasicWrapperProps {
    title?: string;
    bookArticleId?: string;
    loadInitArticleIdData: (
        articleId: string,
        isUuid?: boolean,
        shouldSetSelectedBookData?: boolean
    ) => Promise<GetBandInfoResponse>;
    ownSubjectInfoName?: string;
    isSolution?: boolean;
    children?: ReactNode;
}

// Create Document Component
const PDFBasicWrapper: React.FC<PDFBasicWrapperProps> = (props: PDFBasicWrapperProps) => {
    const logoWidth = 4;
    const logoHeight = (logoWidth / 1000) * 402;

    const watermarkWidth = 20;
    const watermarkHeight = (watermarkWidth / 1000) * 402;

    return (
        <Document>
            <Page
                size="A4"
                style={styles.page}
            >
                <View
                    style={styles.paddingTop} // needed to add top & bottom padding to all pages
                    fixed
                />
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => totalPages > 1 && `${pageNumber} / ${totalPages}`}
                    fixed
                />
                {props.isSolution && (
                    <View
                        style={styles.watermarkWrapper}
                        fixed
                    >
                        <Image
                            src={logo}
                            style={{
                                height: watermarkHeight + "cm",
                                width: watermarkWidth + "cm",
                            }}
                        />
                    </View>
                )}
                <View
                    style={styles.logoWrapper}
                    fixed
                >
                    <Image
                        src={logo}
                        style={{
                            height: logoHeight + "cm",
                            width: logoWidth + "cm",
                        }}
                    />
                </View>
                <View style={styles.contentWrapper}>
                    {!props.isSolution && (
                        <View style={styles.schoolClassContainer}>
                            <PDFClassNameDate />
                        </View>
                    )}
                    {props.title && (
                        <View style={props.isSolution ? styles.headingSolutionContainer : styles.headingContainer}>
                            <Text style={styles.exerciseSheetTitle}>{props.title}</Text>
                        </View>
                    )}
                    <View style={styles.exercisesWrapper}>{props.children ?? <View />}</View>
                </View>
                <View
                    style={styles.footerContainer}
                    fixed
                >
                    {(props.bookArticleId || props.ownSubjectInfoName) && (
                        <PDFFooterSourceInfo
                            articleId={props.bookArticleId}
                            loadInitArticleIdData={props.loadInitArticleIdData}
                            ownSubjectInfoName={props.ownSubjectInfoName}
                            styles={{ footerText: styles.footerText }}
                        />
                    )}
                    <View style={styles.phase6Container}>
                        <Link
                            src={"https://www.phase6.de/"}
                            style={styles.footerText}
                        >
                            {"www.phase6.de"}
                        </Link>
                    </View>
                </View>
                <View
                    style={[
                        styles.paddingBottom,
                        {
                            height:
                                props.bookArticleId && props.ownSubjectInfoName
                                    ? DesignConstants.PDF.GAP_XXL
                                    : DesignConstants.PDF.GAP_XL,
                        },
                    ]} // needed to add top & bottom padding to all pages
                    fixed
                />
            </Page>
        </Document>
    );
};

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "white",
        paddingHorizontal: DesignConstants.PDF.GAP_M,
        fontFamily: DesignConstants.PDF.PROXIMA_FONT,
        fontWeight: DesignConstants.PDF.REGULAR,
    },
    paddingTop: {
        height: "0.3cm",
    },
    paddingBottom: {
        bottom: 0,
    },
    schoolClassContainer: {
        marginBottom: DesignConstants.PDF.GAP_L,
    },
    headingContainer: {
        alignItems: "center",
        marginBottom: DesignConstants.PDF.GAP_M,
    },
    headingSolutionContainer: {
        alignItems: "center",
        marginVertical: DesignConstants.PDF.GAP_M,
    },
    pageNumber: {
        position: "absolute",
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        marginBottom: 20,
        textAlign: "center",
        color: DesignConstants.COLORS.MID_GREY,
        top: DesignConstants.PDF.GAP_S,
        left: DesignConstants.PDF.GAP_S,
    },
    logoWrapper: {
        width: "100%",
        alignItems: "flex-end",
    },
    watermarkWrapper: {
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: 0.2,
        alignItems: "center",
        justifyContent: "center",
        transform: "rotate(-45deg)",
        left: DesignConstants.PDF.GAP_M,
    },
    exerciseSheetTitle: {
        fontSize: DesignConstants.PDF.FONTSIZE_PAGE_HEADER,
        fontWeight: DesignConstants.PDF.SEMIBOLD,
    },
    contentWrapper: {
        flexDirection: "column",
        justifyContent: "flex-start",
        flex: 1,
    },
    exercisesWrapper: {
        gap: DesignConstants.PDF.GAP_L,
    },
    footerContainer: {
        flexDirection: "row",
        position: "absolute",
        bottom: DesignConstants.PDF.GAP_S,
        width: "100%",
        left: DesignConstants.PDF.GAP_M,
        marginTop: DesignConstants.PDF.GAP_XXS,
    },
    phase6Container: {
        flex: 1,
        alignItems: "flex-end",
        justifyContent: "flex-end",
    },
    footerText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        color: DesignConstants.COLORS.MID_GREY,
    },
});

export default PDFBasicWrapper;
