// LIBRARIES
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useStudentPracticeContext, Views } from "../../../context/StudentPracticeContext";
import { isMobileOnly } from "react-device-detect";
import { useT } from "@transifex/react";
import MainBackgroundSquircle from "../../../basic/mainBackgroundSquircle/MainBackgroundSquircle";
import InfoModal from "../../../complex/students/infoModal/InfoModal";
import Intro from "../intro/Intro";
import Practice from "../practice/Practice";
import Outro from "../outro/Outro";
import Error from "../error/Error";
import { useParams } from "react-router-dom";
import { getIsLocalTestRun } from "../../../helpers/Url";

export interface MainWrapperProps {}

const Background = styled.div`
    height: 100vh;
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px;
    margin: auto;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 5px 0.8rem;
        height: unset;

        &.mobFullScreen {
            height: 100vh;
        }
    }

    &.landscape {
        align-items: center;
        justify-content: center;
        height: 100%;
    }
`;

const MainBodyContainer = styled.div`
    overflow: auto;
    width: 100%;
    height: 100%;
`;
const DeviceOrientationAlertWrapper = styled(MainBackgroundSquircle)`
    height: 60vh;
    max-height: 60vh;
    max-width: 60vh;
    width: auto;
    border-radius: 1rem;
    padding: 2rem;
`;

const DeviceOrientationAlert = styled.h2`
    text-align: center;
`;

const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
`;

const FooterItemWrapper = styled.div`
    flex: 1;
`;

const FooterItem = styled.p`
    margin: 0.5rem 0.5rem 1rem;
    font-size: 0.9rem;
    cursor: pointer;
`;

const FooterModals = styled(InfoModal)`
    height: 80vh;
    max-width: calc(800px - 2rem);
    width: 50vw;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: unset;
    }

    > button {
        padding: 0.5rem 0;
        height: 3rem;
    }
`;

const FooterIframe = styled.iframe`
    height: 100%;
    width: 100%;
    border: none;
    margin-bottom: 0;
`;

let initialDataFetched = false;

const MainWrapper: React.FC<MainWrapperProps> = (props) => {
    const { testId } = useParams<{ testId: string }>();
    const isLocalTestRun = getIsLocalTestRun();

    const t = useT();
    const t_pleaseRotate = t("Please turn your phone back to portrait mode.", { _tags: "mainWrapper,alert" });
    const t_agb = t("AGB", { _tags: "MainWrapper,Footer" });
    const t_impressum = t("Impressum", { _tags: "MainWrapper,Footer" });
    const t_privacy = t("Privacy", { _tags: "MainWrapper,Footer" });
    const t_closeModal = t("Close", { _tags: "MainWrapper,Footer" });

    const { setStudentName, teacherTest, refreshData, refreshLocalPreviewData, currentMainView, setCurrentMainView } =
        useStudentPracticeContext();

    const [isFooterModalOpen, setIsFooterModalOpen] = useState(false);
    const [currentFooterLink, setCurrentFooterLink] = useState("");
    const [deviceOrientation, setDeviceOrientation] = useState<"portrait" | "landscape">();
    const backgroundRef = useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (isMobileOnly) {
            window.addEventListener("orientationchange", handleOrientationChange);
            handleOrientationChange();
            // cleanup this component
            return () => {
                window.removeEventListener("orientationchange", handleOrientationChange);
            };
        }
    }, []);

    useEffect(() => {
        if (!testId || testId === "test") {
            if (isLocalTestRun) {
                if (!initialDataFetched) {
                    initialDataFetched = true;
                    refreshLocalPreviewData();
                }
            } else {
                setCurrentMainView(Views.Unauthorized);
            }
        } else if (!initialDataFetched) {
            initialDataFetched = true;
            refreshData(testId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testId, refreshData]);

    function goToPractice(inputStudentName: string) {
        setCurrentMainView(Views.Practice);
        setStudentName(inputStudentName);
    }

    function openModal(modalType: "agb" | "impressum" | "privacy") {
        let modalLink = "";
        if (modalType === "agb") {
            modalLink = "https://www.phase-6.de/agb_text.html";
        } else if (modalType === "impressum") {
            modalLink = "https://www.phase-6.de/impressum_text.html";
        } else {
            modalLink = "https://www.phase-6.de/datenschutz_text.html";
        }
        setCurrentFooterLink(modalLink);
        setIsFooterModalOpen(true);
    }

    function closeModal() {
        setIsFooterModalOpen(false);
    }

    function renderSwitch() {
        switch (currentMainView) {
            case Views.Intro:
                return (
                    <Intro
                        goToPracticeAction={goToPractice}
                        testData={teacherTest}
                    />
                );
            case Views.Practice:
                return <Practice testData={teacherTest} />;
            case Views.Outro:
                return <Outro />;
            case Views.Unauthorized:
                return <Error />;
            default:
                return <div>Loading</div>;
        }
    }

    // Handle the device orientation lock.
    const handleOrientationChange = (event?: any) => {
        const eventTarget = event?.target || window;
        // console.log('orientationChange', eventTarget, eventTarget.screen?.orientation?.angle);
        const orientationAngle = eventTarget.screen?.orientation?.angle || eventTarget.orientation;
        if (orientationAngle) {
            if (orientationAngle === 0 || orientationAngle % 180 === 0) {
                setDeviceOrientation("portrait");
            } else {
                setDeviceOrientation("landscape");
            }
        } else {
            setDeviceOrientation("portrait");
        }
        backgroundRef.current?.blur();
    };

    return (
        <Background
            className={`${deviceOrientation ? deviceOrientation : ""} ${
                currentMainView === Views.Outro || currentMainView === Views.Intro ? "mobFullScreen" : ""
            }`}
            ref={backgroundRef}
        >
            {deviceOrientation !== "landscape" && <MainBodyContainer>{renderSwitch()}</MainBodyContainer>}
            {deviceOrientation === "landscape" && (
                <DeviceOrientationAlertWrapper>
                    <DeviceOrientationAlert>{t_pleaseRotate}</DeviceOrientationAlert>
                </DeviceOrientationAlertWrapper>
            )}

            {(currentMainView === Views.Intro || currentMainView === Views.Outro) && (
                <Footer>
                    <FooterItemWrapper>
                        <FooterItem
                            onClick={() => {
                                openModal("agb");
                            }}
                        >
                            {t_agb}
                        </FooterItem>
                    </FooterItemWrapper>
                    <FooterItemWrapper>
                        <FooterItem
                            onClick={() => {
                                openModal("privacy");
                            }}
                        >
                            {t_privacy}
                        </FooterItem>
                    </FooterItemWrapper>
                    {/*<FooterItemWrapper>*/}
                    {/*    <FooterItem>{t_cookies}</FooterItem>*/}
                    {/*</FooterItemWrapper>*/}
                    <FooterItemWrapper>
                        <FooterItem
                            onClick={() => {
                                openModal("impressum");
                            }}
                        >
                            {t_impressum}
                        </FooterItem>
                    </FooterItemWrapper>
                </Footer>
            )}

            <FooterModals
                isOpen={isFooterModalOpen}
                onRequestClose={closeModal}
                cancelText={t_closeModal}
                cancelAction={closeModal}
            >
                <FooterIframe src={currentFooterLink} />
            </FooterModals>
        </Background>
    );
};

export default MainWrapper;
