// LIBRARIES
import React, { useEffect, useState } from "react";
import { useT, UT } from "@transifex/react";
import { useHistory } from "react-router-dom";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useRTContext } from "../../../context/RTContext";
import StyledButton from "../../../basic/styledButton/StyledButton";
import { translateWordFromModelToQuestion } from "../../../helpers/TestCreation";
import ExerciseVocabularyListingRT from "../../../complex/create/exerciseVocabularyListingRT/ExerciseVocabularyListingRT";
import CreateInfoModal from "../../../basic/create/InfoModal/CreateInfoModal";
import { useMediaQuery } from "react-responsive";

import {
    Title,
    TitleWrapper,
    Wrapper,
    MiddleContentWrapper,
    BookLanguageWrapper,
    BookLanguageNameWrapperLeft,
    BookLanguageNameWrapperRight,
    StyledFlagIcon,
    ExerciseVocabularyWrapper,
    BookInfoAndCTA,
    DeleteAllCTAWrapper,
    SmallIcon,
    CardsCountMobileWrapper,
} from "./StyledComponents";
import CtasWrapperRT from "../../../wrapper/create/ctasWrapperRT/ctasWrapperRT";

export interface RecurringTaskSummaryProps {}

const RecurringTaskSummary: React.FC<RecurringTaskSummaryProps> = (props) => {
    const {
        vocabularyDrawerContent,
        selectedBookData,
        removeContentFromVocabularyDrawer,
        removeCardFromSelectedWords,
        selectedUserSubject,
    } = useTestCreationContext();
    const { aeaClasses, currentExerciseGroupId, saveUserExercise } = useRTContext();
    const history = useHistory();

    const t = useT();
    const t_back = t("More Vocabulary", { _tags: "CreateTest,RecurringTaskSummary" });
    const t_summary = t("Summary", { _tags: "CreateTest,RecurringTaskSummary" });
    const t_done = t("Completed", { _tags: "CreateTest,RecurringTaskSummary" });
    const t_deleteAllWords = t("Are you sure you want to delete all cards?", {
        _tags: "CreateTest,RecurringTaskSummary",
    });
    const t_yes = t("Yes", { _tags: "CreateTest,RecurringTaskSummary" });
    const t_summaryDesc = t("You can share the following vocabulary via link with your students in the next step.", {
        _tags: "CreateTest,RecurringTaskSummary",
    });

    const [isDeleteAllWordsModalOpen, setIsDeleteAllWordsModalOpen] = useState(false);
    const [currentClassName, setCurrentClassName] = useState("");
    const [disableDoneButton, setDisableDoneButton] = useState(false);

    function getVocabularyList() {
        return Object.values(vocabularyDrawerContent)
            .filter((w) => w.wordContent)
            .map((w) =>
                "answer_text" in w.wordContent! ? translateWordFromModelToQuestion(w.wordContent!) : w.wordContent!
            );
    }

    function deleteAllCardsCallback() {
        removeContentFromVocabularyDrawer("ALL");
        setIsDeleteAllWordsModalOpen(false);
    }
    const vocabularyCount = Object.keys(vocabularyDrawerContent).length;

    useEffect(() => {
        if (vocabularyCount === 0) {
            handleBackButton();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vocabularyCount]);

    useEffect(() => {
        if (currentExerciseGroupId.length > 0 && aeaClasses.length > 0) {
            let aeaClass = aeaClasses.find((c) => c.token && c.token === currentExerciseGroupId);
            setCurrentClassName(aeaClass && aeaClass.name ? aeaClass.name : "");
        } else {
            setCurrentClassName("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentExerciseGroupId]);

    function handleSaveExerciseRequest() {
        setDisableDoneButton(true);
        saveUserExercise()
            .then(() => {
                console.log("success");
                setDisableDoneButton(false);
                history.push("/create/rt-home");
            })
            .catch((e) => {
                console.log(e);
                setDisableDoneButton(false);
                alert("error while saving the exercise.");
            });
    }

    function handleBackButton() {
        if (selectedBookData.band && selectedBookData.band.ID) {
            history.push("/create/select-vocabulary");
        } else {
            history.push("/create/select-own-source");
        }
    }

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const CardsCount = (
        <UT
            _str="Total: {totalCount}"
            totalCount={vocabularyCount}
            _inline
            _tags="CreateTest,RecurringTaskSummary"
        />
    );

    return (
        <Wrapper>
            <TitleWrapper className={"space"}>
                <Title>{t_summary}</Title>
                {currentClassName.length > 0 && (
                    <UT
                        _str={"for Class {className}"}
                        className={currentClassName}
                        _tags="CreateTest,RecurringTaskSummary"
                    />
                )}
                <p>{t_summaryDesc}</p>
            </TitleWrapper>
            {isMobileView && <CardsCountMobileWrapper>{CardsCount}</CardsCountMobileWrapper>}
            <MiddleContentWrapper>
                <BookLanguageWrapper>
                    {selectedBookData.band && selectedBookData.band.ID && (
                        <>
                            <BookLanguageNameWrapperLeft>
                                <StyledFlagIcon
                                    languageName={selectedBookData.band?.Language?.questionLanguageName || ""}
                                />
                            </BookLanguageNameWrapperLeft>
                            <BookLanguageNameWrapperRight>
                                <StyledFlagIcon
                                    languageName={selectedBookData.band?.Language?.answerLanguageName || ""}
                                />
                            </BookLanguageNameWrapperRight>
                        </>
                    )}
                    {selectedUserSubject.id && (
                        <>
                            <BookLanguageNameWrapperLeft>
                                <StyledFlagIcon languageName={selectedUserSubject.primaryLang || ""} />
                            </BookLanguageNameWrapperLeft>
                            <BookLanguageNameWrapperRight>
                                <StyledFlagIcon languageName={selectedUserSubject.secondaryLang || ""} />
                            </BookLanguageNameWrapperRight>
                        </>
                    )}
                    <BookInfoAndCTA>
                        {!isMobileView && CardsCount}
                        <DeleteAllCTAWrapper
                            className={vocabularyCount === 0 ? "disabled" : ""}
                            onClick={() => {
                                setIsDeleteAllWordsModalOpen(true);
                            }}
                        >
                            <SmallIcon name={"trash"} />
                        </DeleteAllCTAWrapper>
                    </BookInfoAndCTA>
                </BookLanguageWrapper>
                <ExerciseVocabularyWrapper>
                    <ExerciseVocabularyListingRT
                        vocabularyList={getVocabularyList()}
                        onRemove={(cardId) => {
                            removeCardFromSelectedWords(cardId!);
                        }}
                        listType={"DOUBLE"}
                        removeCardCountRestriction={true}
                        isConnectWordsExercise={true}
                    />
                </ExerciseVocabularyWrapper>
            </MiddleContentWrapper>
            <CtasWrapperRT>
                <StyledButton
                    onClick={handleBackButton}
                    arrowDirection={"LEFT"}
                    buttonStyle={"BLANK"}
                >
                    {t_back}
                </StyledButton>
                <StyledButton
                    onClick={handleSaveExerciseRequest}
                    arrowDirection={"NONE"}
                    buttonStyle={"PRIMARY"}
                    disabled={disableDoneButton}
                >
                    {t_done}
                </StyledButton>
            </CtasWrapperRT>
            <CreateInfoModal
                isOpen={isDeleteAllWordsModalOpen}
                onRequestClose={() => {
                    setIsDeleteAllWordsModalOpen(false);
                }}
                continueAction={deleteAllCardsCallback}
                continueText={t_yes}
                cancelAction={() => {
                    setIsDeleteAllWordsModalOpen(false);
                }}
                compact
            >
                {t_deleteAllWords}
            </CreateInfoModal>
        </Wrapper>
    );
};

export default RecurringTaskSummary;
