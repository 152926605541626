// LIBRARIES
import React, { useState } from "react";
import { useT } from "@transifex/react";
import { useParams } from "react-router-dom";
import CtasWrapper from "../../../wrapper/create/ctasWrapper/CtasWrapper";
import { cp } from "../../../config";
import CloseButton from "../../../basic/closeButton/CloseButton";

import {
    Wrapper,
    TestPreviewHeader,
    MiddleContentWrapper,
    PreviewModeSwitchWrapper,
    PreviewModeSwitchItem,
    PreviewModeSwitchText,
    TestPreviewInfo,
    IframeWrapper,
    StyledIframe,
    PDFPreviewWrapper,
    PDFDownloadButtonWrapper,
} from "./StyledComponents";
import PDFPreview from "../../../basic/pdfBasic/PDFPreview/PDFPreview";
import PDFDownloadButton from "../../../basic/pdfBasic/PDFDownloadButton/PDFDownloadButton";

export interface TestPreviewProps {}

const TestPreview: React.FC<TestPreviewProps> = (props) => {
    const { previewId } = useParams<{ previewId?: string }>();

    const storedPreviewTestDetails =
        localStorage.getItem("localFullPreviewData") && JSON.parse(localStorage.getItem("localFullPreviewData") || "");

    const t = useT();
    const t_desktop = t("Desktop", { _tags: "CreateTest,TestPreview" });
    const t_mobile = t("Mobile", { _tags: "CreateTest,TestPreview" });
    const t_pdf = t("PDF", { _tags: "CreateTest,TestPreview" });
    const t_closePreview = t("close preview", { _tags: "CreateTest,TestPreview" });
    const t_previewHint = t(
        "This page shows a preview of your task, i.e. the edits are not saved. To test the task yourself, use the generated link. You will see all exercises as your students would see them.",
        { _tags: "CreateTest,TestPreview" }
    );
    const t_exercisePDF = t("Exercise Sheet", { _tags: "CreateTest,TestPreview" });
    const t_testDownload = t("Download Test as PDF", { _tags: "CreateTest,TestPreview" });

    const [currentPreviewMode, setCurrentPreviewMode] = useState<"DESKTOP" | "MOBILE" | "PDF">("MOBILE");

    return (
        <Wrapper>
            <CloseButton
                onClick={() => {
                    window.close();
                }}
                hintText={t_closePreview}
            />
            <TestPreviewInfo>{t_previewHint}</TestPreviewInfo>
            <TestPreviewHeader>
                <PreviewModeSwitchWrapper>
                    <PreviewModeSwitchItem
                        onClick={() => setCurrentPreviewMode("MOBILE")}
                        currentMode={currentPreviewMode}
                        mode="MOBILE"
                        position="left"
                    >
                        <PreviewModeSwitchText>{t_mobile}</PreviewModeSwitchText>
                    </PreviewModeSwitchItem>

                    <PreviewModeSwitchItem
                        onClick={() => setCurrentPreviewMode("DESKTOP")}
                        currentMode={currentPreviewMode}
                        mode="DESKTOP"
                        position="center"
                    >
                        <PreviewModeSwitchText>{t_desktop}</PreviewModeSwitchText>
                    </PreviewModeSwitchItem>

                    <PreviewModeSwitchItem
                        onClick={() => setCurrentPreviewMode("PDF")}
                        currentMode={currentPreviewMode}
                        mode="PDF"
                        position="right"
                    >
                        <PreviewModeSwitchText>{t_pdf}</PreviewModeSwitchText>
                    </PreviewModeSwitchItem>
                </PreviewModeSwitchWrapper>
            </TestPreviewHeader>
            <MiddleContentWrapper isPDF={currentPreviewMode === "PDF"}>
                {currentPreviewMode === "PDF" ? (
                    storedPreviewTestDetails && (
                        <PDFPreviewWrapper>
                            <PDFDownloadButtonWrapper>
                                <PDFDownloadButton
                                    buttonText={t_testDownload}
                                    fileName={(storedPreviewTestDetails.name ?? t_exercisePDF) + ".pdf"}
                                    testDetails={storedPreviewTestDetails}
                                />
                            </PDFDownloadButtonWrapper>
                            <PDFPreview testDetails={storedPreviewTestDetails} />
                        </PDFPreviewWrapper>
                    )
                ) : (
                    <IframeWrapper className={`${currentPreviewMode}`}>
                        <StyledIframe
                            src={`${cp.cfg.REACT_APP_PREFIX}/student/${
                                previewId ? previewId + "?testRun=true" : "test?localTestRun=true"
                            }`}
                        ></StyledIframe>
                    </IframeWrapper>
                )}
            </MiddleContentWrapper>

            <CtasWrapper></CtasWrapper>
        </Wrapper>
    );
};

export default TestPreview;
