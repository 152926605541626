import styled from "styled-components";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const TopCTAWrapper = styled.div`
    text-align: center;
    color: ${(props) => props.theme.base.primaryColor};
    margin-bottom: 1rem;
    font-weight: bold;

    > span {
        color: inherit;
    }
`;

export const BottomCTAWrapper = styled.div`
    padding: 0.5rem;
    cursor: pointer;
`;

export const BottomText = styled.p`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    &:hover {
        color: ${(props) => props.theme.base.primaryColor};
        fill: ${(props) => props.theme.base.primaryColor};
    }
`;

export const StyledArrowIcon = styled(SvgIcon)`
    height: 2rem;

    &:hover {
        fill: ${(props) => props.theme.base.primaryColor};
    }
`;
