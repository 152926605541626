import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import PDFEmptyLines from "../pdfSubComponents/PDFEmptyLines";
import { TestDetailsItem } from "p6m-p6u";
import DesignConstants from "../../../constants/DesignConstants";
import PDFTable from "../pdfSubComponents/PDFTable";
import { sanitizePDFText } from "../../../helpers/PDFHelper";
import { ExerciseDirections } from "../../../enums/directions";

interface PDFWriteSentenceExerciseProps {
    exercise: TestDetailsItem;
    isSolution?: boolean;
}

const PDFWriteSentenceExercise: React.FC<PDFWriteSentenceExerciseProps> = (props) => {
    const renderWordWithEmptyLines = () => {
        const selectedWordsQuestions =
            props.exercise.selectedWords?.map((w) =>
                "id" in w && w.id
                    ? sanitizePDFText(
                          props.exercise.direction === ExerciseDirections.ANSWER_TO_QUESTION ? w.answer : w.question
                      )
                    : ""
            ) ?? [];

        return selectedWordsQuestions.map((word, i) => (
            <View
                style={i === 0 ? {} : { paddingTop: DesignConstants.PDF.GAP_M }}
                key={"sentence" + word}
            >
                <Text style={styles.text}>{word}</Text>
                <PDFEmptyLines linesAmount={3} />
            </View>
        ));
    };

    return !props.isSolution ? (
        <View>{renderWordWithEmptyLines()}</View>
    ) : (
        <PDFTable
            selectedWords={props.exercise?.selectedWords ?? []}
            showAllQuestions
            showAllAnswers
        />
    );
};

const styles = StyleSheet.create({
    text: {
        paddingHorizontal: DesignConstants.PDF.GAP_S,
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
    },
});

export default PDFWriteSentenceExercise;
