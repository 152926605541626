//LIBRARIES
import React, { useState } from "react";
import { useT } from "@transifex/react";
import { useTestCreationContext } from "../../../../../../context/TestCreationContext";
import InputWithActions from "../../../../../../basic/inputWithActions/InputWithActions";

import { ButtonWithIcon, InputWrapper } from "./StyledComponents";

export interface ExerciseStarterTextProps {}

const ExerciseStarterText: React.FC<ExerciseStarterTextProps> = (props) => {
    const { currentExerciseModel, updateCurrentExerciseProperties, setIsTestSynced } = useTestCreationContext();
    const starterText = currentExerciseModel.textFieldStarterText || "";

    const t = useT();
    const t_placeholder = t(
        "Leave the text field empty or click here to add Suggestions, sentence starters or similar.",
        { _tags: "CreateTest,CreateOrEditExercise" }
    );
    const t_starterTextButton = t("Support, starting text, etc.", { _tags: "CreateTest,ExerciseDetails" });

    const [isEditing, setIsEditing] = useState(false);
    const [currentText, setCurrentText] = useState(starterText);

    function saveChanges() {
        if (currentText !== starterText) {
            updateCurrentExerciseProperties({ textFieldStarterText: currentText });
            setIsTestSynced(false);
        }
        setIsEditing(false);
    }

    function discardChanges() {
        setCurrentText(starterText);
        setIsEditing(false);
    }

    return isEditing || starterText ? (
        <InputWrapper>
            <InputWithActions
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                currentText={currentText}
                onChange={(e) => setCurrentText(e.target.value)}
                onSave={saveChanges}
                onDiscard={discardChanges}
                placeholder={t_placeholder}
            />
        </InputWrapper>
    ) : (
        <ButtonWithIcon
            buttonStyle={"LIGHT_BORDER"}
            icon={"add"}
            iconPosition={"LEFT"}
            onClick={() => setIsEditing(true)}
        >
            {t_starterTextButton}
        </ButtonWithIcon>
    );
};

export default ExerciseStarterText;
