import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";

export const SelectExerciseVocabularyRowWrapper = styled.div<{ isRowAnimating: boolean }>`
    display: flex;
    align-items: center;
    border-bottom: ${DesignConstants.BORDERS.MEDIUM};
    :last-child {
        border-bottom: none;
    }
    border-color: ${DesignConstants.COLORS.LIGHTEST_GREY};
    padding: ${DesignConstants.SPACES.XXS};
    background: ${({ isRowAnimating }) => (isRowAnimating ? DesignConstants.COLORS.BACKGROUND_LIGHT : "white")};
    position: relative;

    &:hover {
        cursor: ${({ isRowAnimating }) => (isRowAnimating ? "default" : "pointer")};
        background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    }

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        cursor: ${({ isRowAnimating }) => (isRowAnimating ? "default" : "not-allowed")};
    }
`;

export const SelectExerciseVocabularyRowWordContainer = styled.div`
    flex: 1;
`;

export const SelectExerciseVocabularyRowWord = styled.span`
    display: block;
    font-size: ${DesignConstants.FONT_SIZES.M};
    padding-right: ${DesignConstants.SPACES.S_OLD};
`;

export const ContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        gap: ${DesignConstants.SPACES.XS};
        flex-direction: column;
        align-items: start;
    }
`;

export const WordContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
`;

export const AddIconWrapper = styled.div`
    padding: ${DesignConstants.SPACES.XS};
    display: flex;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
    }
`;

export const StyledAddIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.MID_GREY};
`;

export const WordContainer = styled.span`
    display: inline-block;
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.S};
    margin: 0 ${DesignConstants.SPACES.XS};
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.LIGHTEST_GREY};
`;

export const Word = styled.span`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;
