import { View } from "@react-pdf/renderer";
import React from "react";
import PDFEmptyLines from "../pdfSubComponents/PDFEmptyLines";

interface PDFFreeTextExerciseProps {
    isSolution?: boolean;
}

const PDFFreeTextExercise: React.FC<PDFFreeTextExerciseProps> = (props) => {
    return <View>{!props.isSolution && <PDFEmptyLines />}</View>;
};

export default PDFFreeTextExercise;
