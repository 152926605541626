import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../constants/DesignConstants";

export const Wrapper = styled.div<{ clickable?: boolean }>`
    cursor: ${(props) => (props.clickable ? "pointer" : "default")};
    flex: 1;
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
`;

export const ContentWrapper = styled.div<{ isFocused?: boolean; highlighted?: boolean }>`
    flex: 1;
    display: flex;
    align-items: center;
    border: ${DesignConstants.BORDERS.MEDIUM};
    border-color: ${({ isFocused, highlighted }) => {
        if (highlighted) return DesignConstants.COLORS.P6_ORANGE;
        if (isFocused) return DesignConstants.COLORS.MID_GREY;
        return "transparent";
    }};
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    padding: 0 ${DesignConstants.SPACES.S};
    gap: ${DesignConstants.SPACES.S};
    transition: color 500ms ease-in, background 500ms ease-in;

    &.triggerFlash {
        color: ${DesignConstants.COLORS.P6_ORANGE};
        background: ${DesignConstants.COLORS.P6_ORANGE_LIGHT_PASTEL};
    }
`;

export const InputField = styled.input`
    flex: 1;
    display: flex;
    padding: ${DesignConstants.SPACES.XXS} 0;
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-family: inherit;
    color: inherit;
    outline: none;
    border: none;
    background: transparent;
`;

export const AlertWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
`;

export const AlertIcon = styled(PhaseSixIcon)`
    height: ${DesignConstants.SPACES.L};
    width: ${DesignConstants.SPACES.L};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.P6_ORANGE};
`;

export const Text = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-family: inherit;
    padding: ${DesignConstants.SPACES.XXS} 0;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
`;

export const StyledIcon = styled(PhaseSixIcon)<{ disabled: boolean }>`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM};
    opacity: ${({ disabled }) => disabled && DesignConstants.OPACITY.HALF};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
