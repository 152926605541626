import { useT } from "@transifex/react";
import React from "react";

import {
    ExplanationText,
    EyeExplanationWrapper,
    EyeInfoWrapper,
    IconAndTextWrapper,
    Label,
    InfoIcon,
} from "./StyledComponents";

interface EyeExplanationProps {
    setShowExpandedEyeExplanation: (visible: boolean) => void;
    showExpandedEyeExplanation?: boolean;
}

const EyeExplanation: React.FC<EyeExplanationProps> = (props) => {
    const t = useT();
    const t_explanation = t("Explanation", { _tags: "CreateTest,CreateOrEditExercise" });
    const t_visible_for_students = t("Visible for students", { _tags: "CreateTest,CreateOrEditExercise" });
    const t_not_visible_for_students = t("Not visible for students", { _tags: "CreateTest,CreateOrEditExercise" });

    const changeEyeVisibilityExplanation = (expanded: boolean) => {
        localStorage.setItem("eyeExplanation", expanded ? "expanded" : "small");
        props.setShowExpandedEyeExplanation(expanded);
    };

    const renderSmallEyeInfo = () => {
        return (
            <EyeInfoWrapper onClick={() => changeEyeVisibilityExplanation(true)}>
                <InfoIcon name={"eye"} />
                <InfoIcon name={"info"} />
            </EyeInfoWrapper>
        );
    };

    const renderEyeInfo = () => {
        return (
            <EyeExplanationWrapper>
                <IconAndTextWrapper>
                    <InfoIcon name={"info"} />
                    <Label>{t_explanation + ":"}</Label>
                </IconAndTextWrapper>
                <IconAndTextWrapper>
                    <InfoIcon name={"eye"} />
                    <ExplanationText>{t_visible_for_students}</ExplanationText>
                </IconAndTextWrapper>
                <IconAndTextWrapper>
                    <InfoIcon name={"eye-closed"} />
                    <ExplanationText>{t_not_visible_for_students}</ExplanationText>
                </IconAndTextWrapper>
                <InfoIcon
                    name={"close"}
                    onClick={() => changeEyeVisibilityExplanation(false)}
                    className={"clickable"}
                />
            </EyeExplanationWrapper>
        );
    };

    if (props.showExpandedEyeExplanation) {
        return renderEyeInfo();
    } else {
        return renderSmallEyeInfo();
    }
};

export default EyeExplanation;
