import * as React from "react";
import styled from "styled-components";
import SvgIcon from "../svgIcon/SvgIcon";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert.svg";
import { useT } from "@transifex/react";

export interface InconsistentGradeOnExerciseIconProps {
    correct?: number;
    incorrect?: number;
    tooltipId?: string;
}

const WarningReviewIconWrapper = styled.div`
    width: 2rem;
    height: 2rem;
    border: none;
    background: transparent;
    text-align: center;
    margin-left: 5px;
    color: white;
`;

const InconsistentGradeOnExerciseIcon: React.FC<InconsistentGradeOnExerciseIconProps> = (props) => {
    const { correct, incorrect, tooltipId } = props;
    const t = useT();
    const t_tooltip = t(
        "You graded this as correct for {correctStds} students and as incorrect for {incorrectStds} students",
        { correctStds: correct, incorrectStds: incorrect, _tags: "ReviewIndividualExercise" }
    );

    return (
        <WarningReviewIconWrapper
            data-tip={t_tooltip}
            data-for={tooltipId ? tooltipId : "tooltipReviewIndividualExercise"}
        >
            <SvgIcon
                svgComponent={AlertIcon}
                color={"grey"}
            />
        </WarningReviewIconWrapper>
    );
};

export default InconsistentGradeOnExerciseIcon;
