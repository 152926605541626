//LIBRARIES
import React from "react";

import { Wrapper, Line } from "./StyledComponents";

export interface ExerciseEmptyLinesProps {
    linesAmount?: number;
}

const ExerciseEmptyLines: React.FC<ExerciseEmptyLinesProps> = (props) => {
    const renderLines = () => {
        const lines = [];
        for (let i = 0; i < (props.linesAmount ?? 3); i++) {
            lines.push(<Line key={`line_${i}`} />);
        }
        return lines;
    };

    return <Wrapper>{renderLines()}</Wrapper>;
};

export default ExerciseEmptyLines;
