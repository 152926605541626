import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

import {
    Wrapper,
    ScrollToTopButton,
    StyledP6Icon,
    PositioningWrapper,
    FullWidthWrapper,
    OuterWrapper,
} from "./StyledComponents";

export interface CtasWrapperProps {
    children?: React.ReactNode;
    className?: string;
    showScrollToTop?: boolean;
}

export const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: ${DesignConstants.SPACES.S};
    width: 100%;
`;

const CtasWrapper: React.FC<CtasWrapperProps> = (props) => {
    function scrollToTop() {
        window?.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const [isEnoughScrollToShowButton, setIsEnoughScrollToShowButton] = useState<boolean>(false);

    useEffect(() => {
        if (props.showScrollToTop) {
            const onScroll = () => {
                if (window.scrollY > 100) {
                    setIsEnoughScrollToShowButton(true);
                } else {
                    setIsEnoughScrollToShowButton(false);
                }
            };

            window.addEventListener("scroll", onScroll);

            return () => {
                window.removeEventListener("scroll", onScroll);
            };
        }
    }, [props.showScrollToTop]);

    return (
        <OuterWrapper>
            {props.showScrollToTop && isEnoughScrollToShowButton && (
                <PositioningWrapper>
                    <FullWidthWrapper>
                        <ScrollToTopButton onClick={scrollToTop}>
                            <StyledP6Icon name={"chevron-up"} />
                        </ScrollToTopButton>
                    </FullWidthWrapper>
                </PositioningWrapper>
            )}
            <Wrapper className={props.className}>{props.children}</Wrapper>
        </OuterWrapper>
    );
};

export default CtasWrapper;
