import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useT } from "@transifex/react/dist";
import { StudentQuestionAnswer } from "p6m-p6u";
import { useMediaQuery } from "react-responsive";
import { debounce } from "lodash";
import ExerciseTextArea from "../../../basic/exerciseTextArea/ExerciseTextArea";
import { isAndroid } from "react-device-detect";
import InputControlArea from "../inputControlArea/InputControlArea";

export interface WriteSentenceExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
}

const WriteSentenceExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 1rem;
`;

const ExerciseRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 80%;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        margin-bottom: 1rem;
    }
`;

const QuestionText = styled.p`
    flex: 4;
    text-align: left;
`;

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

// do debounce

const WriteSentenceExercise: React.FC<WriteSentenceExerciseProps> = (props) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "writeSentenceExercise,inputPlaceholder" });

    const { studentAnswerItem, updateStudentQuestionAnswers } = props;
    const [answersContent, setAnswersContent] = useState(
        studentAnswerItem.studentAnswers ? studentAnswerItem.studentAnswers : []
    );
    const [lastEditedIndex, setLastEditedIndex] = useState<number>(-1);

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const updateItemAtPos = function (pos: number, value: string) {
        const newAnswers = [...answersContent];
        newAnswers[pos].studentAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        // console.log("using effect to call parent function");
        updateDataToParentDebounced(
            {
                ...studentAnswerItem,
                studentAnswers: answersContent,
            },
            updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answersContent]);

    const refs = useRef(new Array(studentAnswerItem.studentAnswers!.length));

    const handleFocus = function (pos: number) {
        setLastEditedIndex(pos);
    };

    const restartInputValues = function () {
        setLastEditedIndex(-1);
    };

    const focusExerciseAtIndex = function (index: number) {
        // console.log('focusing at index', index)
        if (index < studentAnswerItem.studentAnswers!.length) {
            refs.current[index]?.focus();
        }
    };

    let exercises = [];

    if (studentAnswerItem.studentAnswers) {
        for (let i = 0; i < studentAnswerItem.studentAnswers.length; i++) {
            let item = studentAnswerItem.studentAnswers[i];
            const QuestionElement = (
                <ExerciseTextArea
                    key={i}
                    onChange={(event) => {
                        updateItemAtPos(i, event.target.value);
                    }}
                    type="text"
                    placeholder={t_placeholder}
                    ref={(element: any) => (refs.current[i] = element)}
                    value={item.studentAnswer}
                    onFocus={() => handleFocus(i)}
                />
            );

            exercises.push(
                <ExerciseRowContainer key={"key_" + item.id}>
                    <QuestionText>{item.question ? item.question : item.answer}</QuestionText>
                    {QuestionElement}
                </ExerciseRowContainer>
            );
        }
    }

    return (
        <WriteSentenceExerciseWrapper>
            {exercises}
            {isMobileView && isAndroid && lastEditedIndex !== -1 && (
                <InputControlArea
                    currentIndex={lastEditedIndex}
                    endInputCallback={restartInputValues}
                    answersLength={studentAnswerItem.studentAnswers?.length}
                    focusSwitchCallback={focusExerciseAtIndex}
                />
            )}
        </WriteSentenceExerciseWrapper>
    );
};

export default WriteSentenceExercise;
