import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../constants/DesignConstants";

export const BulletPointIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
    color: ${DesignConstants.COLORS.DARK_GREY};
    margin-right: ${DesignConstants.SPACES.S};
`;

export const BulletPointWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: ${DesignConstants.FONT_SIZES.L};
`;
