import * as React from "react";
import styled from "styled-components";
import { Grade } from "p6m-p6u";

export interface HighlightedAnswerProps {
    className?: string;
    grade?: Grade;
    children?: JSX.Element | string;
}

const HighlightedAnswerWrapper = styled.span`
    color: black;
    padding: 2px 5px;
    line-height: 1.5;
    border-radius: 0.3rem;
    align-self: flex-start;

    &.correct {
        background: rgba(39, 220, 39, 0.3);
    }

    &.incorrect {
        background: rgba(255, 0, 0, 0.3);
    }
`;

const HighlightedAnswer: React.FC<HighlightedAnswerProps> = (props) => {
    const { grade } = props;

    return (
        <HighlightedAnswerWrapper className={`${props.className || ""} ${grade || ""}`}>
            {props.children}
        </HighlightedAnswerWrapper>
    );
};

export default HighlightedAnswer;
