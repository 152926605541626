import React from "react";
import { useT } from "@transifex/react";
import { useTestCreationContext } from "../../../../context/TestCreationContext";
import { Exercises, ExerciseTypes } from "../../../../enums/exercises";
import { getExerciseDirection } from "../../../../helpers/TestCreation";
import AddVocabularyButton from "./detailsSubComponents/addVocabularyButton/AddVocabularyButton";
import DetailsConnectWords from "./details/detailsConnectWords/DetailsConnectWords";
import DetailsFreeText from "./details/detailsFreeText/DetailsFreeText";
import DetailsStandard from "./details/detailsStandard/DetailsStandard";
import DetailsGapSentence from "./details/detailsGapSentence/DetailsGapSentence";
import DetailsTextInput from "./details/detailsTextInput/DetailsTextInput";

import { InfoIconWrapper, NoWordsWrapper, StyledInfoIcon, Wrapper } from "./StyledComponents";

export interface ExerciseDetailsProps {
    hasWords?: boolean;
    isSelectExerciseVocabularyOpen: boolean;
    onClickAddVocabularyButton: () => void;
}

const ExerciseDetails: React.FC<ExerciseDetailsProps> = (props) => {
    const { currentExerciseModel, setCurrentExerciseModel, openWarningModal, setIsTestSynced } =
        useTestCreationContext();

    const t = useT();

    const t_cantDeleteCardMin = t("The word can't be deleted, as the minimum amount of cards per exercise is 3.", {
        _tags: "CreateTest,CreateOrEditExercise",
    });
    const t_noWordsYet = t("This exercise does not have any words yet.", {
        _tags: "CreateTest,CreateOrEditExercise",
    });

    function removeWordFromExerciseWordsInProgress(wordId: string) {
        const exercise = { ...currentExerciseModel };

        if (exercise.wordsInProgress?.length) {
            exercise.wordsInProgress = exercise.wordsInProgress.filter((w) => {
                if ("id" in w) {
                    return w.id !== wordId;
                } else if ("ID" in w) {
                    return w.ID !== wordId;
                }
                return false;
            });
        }

        if (currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.STANDARD) && exercise.wordsInProgress) {
            // adapt direction accordingly
            exercise.direction = getExerciseDirection(exercise);
        }

        setCurrentExerciseModel(exercise);
    }

    function handleRemoveWord(wordId?: string) {
        if (!wordId) return;
        if (currentExerciseModel.wordsInProgress && currentExerciseModel.wordsInProgress?.length > 3) {
            removeWordFromExerciseWordsInProgress(wordId);
            setIsTestSynced(false);
        } else {
            openWarningModal(t_cantDeleteCardMin);
        }
    }

    const renderDetails = () => {
        switch (currentExerciseModel.questionMode) {
            case ExerciseTypes.get(Exercises.CONNECT_WORDS):
                return (
                    <DetailsConnectWords
                        words={currentExerciseModel.wordsInProgress || []}
                        onRemoveWord={handleRemoveWord}
                        isSelectExerciseVocabularyOpen={props.isSelectExerciseVocabularyOpen}
                        onClickAddVocabularyButton={props.onClickAddVocabularyButton}
                    />
                );
            case ExerciseTypes.get(Exercises.FILL_GAP):
                return (
                    <DetailsGapSentence
                        hasWords={!!props.hasWords}
                        onRemoveWord={handleRemoveWord}
                        isSelectExerciseVocabularyOpen={props.isSelectExerciseVocabularyOpen}
                        onClickAddVocabularyButton={props.onClickAddVocabularyButton}
                    />
                );
            case ExerciseTypes.get(Exercises.WRITE_SENTENCE):
            case ExerciseTypes.get(Exercises.CREATE_TEXT):
                return (
                    <DetailsTextInput
                        hasWords={!!props.hasWords}
                        onRemoveWord={handleRemoveWord}
                        isSelectExerciseVocabularyOpen={props.isSelectExerciseVocabularyOpen}
                        onClickAddVocabularyButton={props.onClickAddVocabularyButton}
                    />
                );
            case ExerciseTypes.get(Exercises.FREE_TEXT):
                return <DetailsFreeText />;
            default:
                return (
                    <DetailsStandard
                        onRemoveWord={handleRemoveWord}
                        isSelectExerciseVocabularyOpen={props.isSelectExerciseVocabularyOpen}
                        onClickAddVocabularyButton={props.onClickAddVocabularyButton}
                    />
                );
        }
    };

    const renderNoWordsYet = () => {
        return (
            <Wrapper>
                <NoWordsWrapper>
                    <InfoIconWrapper>
                        <StyledInfoIcon name={"info"} />
                    </InfoIconWrapper>
                    {t_noWordsYet}
                </NoWordsWrapper>
                {!props.isSelectExerciseVocabularyOpen && (
                    <AddVocabularyButton onClickAddVocabularyButton={props.onClickAddVocabularyButton} />
                )}
            </Wrapper>
        );
    };

    if (!props.hasWords && currentExerciseModel.questionMode !== ExerciseTypes.get(Exercises.FREE_TEXT)) {
        return renderNoWordsYet();
    } else {
        return renderDetails();
    }
};

export default ExerciseDetails;
