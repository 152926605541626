import React, { useEffect, useMemo, useState } from "react";
import { UnitCard, UserOwnCard } from "p6m-p6u";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useT } from "@transifex/react";
import { sanitizeText } from "../../../helpers/TestCreation";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import UsedInExerciseNumber from "../../usedInExerciseNumber/UsedInExerciseNumber";
import useExerciseIdFinder from "../../../hooks/useExerciseIdFinder";

import {
    CardWrapper,
    CheckboxWrapper,
    QuestionAnswerWrapper,
    QuestionWrapper,
    AnswerWrapper,
    RowContent,
    TextSpan,
    Checkbox,
    GapSentenceWrapper,
    CheckIcon,
    ContentWrapper,
    UsedInExerciseNumberPlaceholder,
} from "./StyledComponents";

export interface CardListingCardItemProps {
    card: UnitCard | UserOwnCard;
    isChecked: boolean;
    openModalCallback: () => any;
    hideGapSentences?: boolean;
}

const CardListingCardItem: React.FC<CardListingCardItemProps> = (props) => {
    const {
        vocabularyDrawerContent,
        addCardToSelectedWords,
        removeCardFromSelectedWords,
        maxAmountOfCardsSelectable,
        openWarningModal,
        previouslyUsedCardsIds,
    } = useTestCreationContext();
    const { creatingMode } = useGeneralContext();
    const { mapExerciseIdsToExercises } = useExerciseIdFinder();

    const { card, openModalCallback, hideGapSentences } = props;

    const [isChecked, setIsChecked] = useState(false);
    const [isHoveringRow, setIsHoveringRow] = useState(false);

    const drawerObjContent = useMemo(
        () => vocabularyDrawerContent[card.id || ""] || {},
        [card.id, vocabularyDrawerContent]
    );

    const t = useT();
    const t_cantAddMoreThan50 = t("Can't add more than {maxAmountOfCardsSelectable} cards", {
        maxAmountOfCardsSelectable,
        _tags: "CreateTest,UnitAndCardsWrapper",
    });

    const exerciseIds = vocabularyDrawerContent[card.id ?? 0]?.exerciseIds;

    const isRecurringTestMode = creatingMode === "RECURRING_TASK";
    let isCardInPreviouslyUsed;
    if ("uuid" in card) {
        isCardInPreviouslyUsed = previouslyUsedCardsIds.includes(card.uuid!);
    } else {
        isCardInPreviouslyUsed = previouslyUsedCardsIds.includes(card.id!);
    }

    useEffect(() => {
        setIsChecked(!!drawerObjContent.wordContent?.id || false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerObjContent]);

    function toggleCheckedStatus() {
        let action: "DELETE" | "ADD" = isChecked ? "DELETE" : "ADD";
        // let currentVocabulary = {...vocabularyDrawerContent}
        if (action === "DELETE") {
            if (drawerObjContent.exerciseIds?.length) {
                openModalCallback();
            } else {
                removeCardFromSelectedWords(card.id!);
                setIsChecked(!isChecked);
            }
        } else {
            let addResult = addCardToSelectedWords(card);
            if (!addResult) {
                openWarningModal(<p>{t_cantAddMoreThan50}</p>);
            } else {
                setIsChecked(!isChecked);
            }
        }
    }

    return (
        <CardWrapper
            onMouseEnter={() => setIsHoveringRow(true)}
            onMouseLeave={() => setIsHoveringRow(false)}
            onClick={toggleCheckedStatus}
        >
            <CheckboxWrapper onClick={toggleCheckedStatus}>
                <Checkbox className={isChecked ? "checked" : ""}>
                    {isChecked ? <CheckIcon name={"exercise-ok"} /> : ""}
                </Checkbox>
            </CheckboxWrapper>

            <ContentWrapper>
                <RowContent
                    className={isRecurringTestMode && isCardInPreviouslyUsed ? "half-opacity" : ""}
                >
                    <QuestionAnswerWrapper>
                        <QuestionWrapper>
                            <TextSpan className={isChecked ? "checked" : ""}>
                                {"question_text" in card && card.question_text
                                    ? sanitizeText(card.question_text)
                                    : "question" in card
                                    ? card.question
                                    : ""}
                            </TextSpan>
                        </QuestionWrapper>
                        <AnswerWrapper>
                            <TextSpan className={isChecked ? "checked" : ""}>
                                {"answer_text" in card && card.answer_text
                                    ? sanitizeText(card.answer_text)
                                    : "answer" in card
                                    ? card.answer
                                    : ""}
                            </TextSpan>
                        </AnswerWrapper>
                    </QuestionAnswerWrapper>

                    {!hideGapSentences && "gap_sentence" in card && card.gap_sentence !== "" && (
                        <GapSentenceWrapper>
                            <TextSpan className={isChecked ? "checked" : ""}>
                                {" "}
                                <b>&#8594;</b> <i>{card.gap_sentence ? sanitizeText(card.gap_sentence) : ""}</i>
                            </TextSpan>
                        </GapSentenceWrapper>
                    )}
                </RowContent>

                {exerciseIds && exerciseIds?.length !== 0 ? (
                    <UsedInExerciseNumber
                        isHoveringRow={isHoveringRow}
                        numsOfUsedExercises={mapExerciseIdsToExercises(exerciseIds)}
                        isLimitedToTwo
                    />
                ) : (
                    <UsedInExerciseNumberPlaceholder />
                )}
            </ContentWrapper>
        </CardWrapper>
    );
};

export default CardListingCardItem;
