// LIBRARIES
import React from "react";
import styled, { css } from "styled-components";
import { ReviewItemType, StudentQuestionAnswer } from "p6m-p6u";
import { useT } from "@transifex/react";
import { useLocation, useHistory } from "react-router-dom";
import {
    getCountsForJointExerciseItem,
    getPercentagesForIndividualExerciseReview,
    getPercentagesForStudentIndReview,
} from "../../helpers/ItemProgressCounts";
import { getCorrectPathname } from "../../helpers/Url";
import { useTestReviewContext } from "../../context/TestReviewContext";
import { logEventWithProps } from "../../logging/Logger";
import StyledButton from "../styledButton/StyledButton";

export interface TestReviewProgressItemProps {
    itemName: string;
    itemContent: ReviewItemType;
    type: "exerciseItem" | "studentItem";
    exerciseId?: string;
}

const Wrapper = styled.div`
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #ddd;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 1rem 0.5rem;
    }
`;

const NameWrapper = styled.div`
    flex: 2;

    &.exerciseItem {
        flex: 1;
        font-size: 1.2rem;
    }

    &.studentItem {
        flex: 1;
        font-size: 1.2rem;
    }
`;
const BarAndCTAWrapper = styled.div`
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const BarWrapper = styled.div`
    flex: 2;
    margin-right: 10px;
`;

const StartReviewButton = styled(StyledButton)`
    //height: 40px;
    //flex: 1;
    //margin-top: 0;
    //font-size: 1rem;
    //border: solid 1px #fc7c00;
    //padding: 0.2rem 0.5rem;
    max-width: 180px;
    font-size: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        min-width: 120px;
        max-width: 140px;
    }
`;

const ProgressBarBaseCss = css`
    height: 10px;
    border-radius: 10px;
`;

const ProgressBarWrapper = styled.div`
    ${ProgressBarBaseCss};
    height: 10px;
    width: 100%;
    background: #eee;
    max-width: 70%;
    margin: 0 auto;
    position: relative;
`;

const ProgressBarSystem = styled.div`
    ${ProgressBarBaseCss};
    background: #666666aa;
    position: absolute;
`;

const ProgressBarTeacher = styled.div`
    ${ProgressBarBaseCss};
    background: #fc7c00;
    position: absolute;
`;

const ProgressItemWrappers = styled.div``;

const TestReviewProgressItem: React.FC<TestReviewProgressItemProps> = (props) => {
    const t = useT();
    const t_startReview = t("Start Review", { _tags: "TestReviewProgressItem,testInfo" });
    const t_correct = t("Correct", { _tags: "TestReviewProgressItem,tooltip" });
    const t_incorrect = t("Incorrect", { _tags: "TestReviewProgressItem,tooltip" });
    const t_comments = t("Comments", { _tags: "TestReviewProgressItem,tooltip" });

    const { itemName, itemContent, type } = props;
    const location = useLocation();
    const history = useHistory();

    const { firstEvaluationModeEventSent, setFirstEvaluationModeEventSent, studentTestContent } =
        useTestReviewContext();

    const generateProgressBar = () => {
        let totalItems = 0;
        let reviewedItems = 0;
        let systemSuggestedItems = 0;

        let correctItems = "";
        let incorrectItems = "";
        let comments = 0;

        let progressBar;
        // if its an exerciseItem, meaning per exercise evaluation
        if (type === "exerciseItem" && "answers" in itemContent) {
            const [sS, rI, tI] = getCountsForJointExerciseItem(itemContent);
            systemSuggestedItems += sS;
            reviewedItems += rI;
            totalItems += tI;

            const [pC, pI, c] = getPercentagesForIndividualExerciseReview(itemContent);
            correctItems = pC + "%";
            incorrectItems = pI + "%";
            comments = c;
        } else if ("questionAnswers" in itemContent && itemContent["questionAnswers"]) {
            // if its an studentItem, meaning its an evaluation per student
            let answers: Array<StudentQuestionAnswer> = Object.values(itemContent["questionAnswers"]);
            answers.forEach((a) => {
                a.studentAnswers?.forEach((sa) => {
                    systemSuggestedItems += sa.systemSuggestedGrade !== "not-graded" ? 1 : 0;
                    reviewedItems += sa.teacherGrade !== "not-graded" ? 1 : 0;
                    totalItems++;
                });
            });

            const [pC, pI, c] = getPercentagesForStudentIndReview(itemContent);
            correctItems = pC + "%";
            incorrectItems = pI + "%";
            comments = c;
        }

        //
        let teacherProgress = reviewedItems > 0 ? (reviewedItems * 100) / totalItems : 0;
        let systemProgress = systemSuggestedItems > 0 ? (systemSuggestedItems * 100) / totalItems : 0;

        progressBar = (
            <ProgressItemWrappers
                data-tip={`${correctItems} ${t_correct}, ${incorrectItems} ${t_incorrect}, ${comments} ${t_comments}`}
                data-for={"tooltipReviewTest"}
            >
                <ProgressBarWrapper>
                    <ProgressBarSystem style={{ width: systemProgress + "%" }} />
                    <ProgressBarTeacher style={{ width: teacherProgress + "%" }} />
                </ProgressBarWrapper>
            </ProgressItemWrappers>
        );

        return progressBar;
    };

    const navigateToReview = function () {
        let navigateTo = getCorrectPathname(location.pathname);

        if (type === "exerciseItem") {
            navigateTo += "exercise/" + props.exerciseId;
        } else if ("questionAnswers" in itemContent) {
            navigateTo += "student/" + itemContent.id;
        }

        if (!firstEvaluationModeEventSent) {
            logEventWithProps("Start Evaluation", {
                mode: type === "exerciseItem" ? "Nach Übung" : "Individuelle",
                testId: studentTestContent.id,
            });
            // console.log("Start Evaluation", {mode: type === "exerciseItem" ?  "Nach Übung" : "Individuelle"})
            setFirstEvaluationModeEventSent(true);
        }
        history.push(navigateTo);
        // console.log("navigateTo", navigateTo);
    };

    return (
        <Wrapper>
            <NameWrapper className={type}>{itemName}</NameWrapper>
            <BarAndCTAWrapper>
                <BarWrapper>{generateProgressBar()}</BarWrapper>
                <StartReviewButton
                    onClick={navigateToReview}
                    buttonStyle={"ORANGE_BLANK"}
                    arrowDirection={"NONE"}
                >
                    {t_startReview}
                </StartReviewButton>
            </BarAndCTAWrapper>
        </Wrapper>
    );
};

export default TestReviewProgressItem;
