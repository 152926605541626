import { AxiosRequestConfig } from "axios";
import { cp } from "../config";
import { BandUnit, UnitCard } from "p6m-p6u";
import { generateGapSentence, sanitizeText } from "./TestCreation";

export const getRequest = (endpoint: string, params?: {}) => {
    return cp.openCMSAxios.get(endpoint, { params });
};

export const postRequest = (endpoint: string, params?: {}) => {
    return cp.openCMSAxios.post(endpoint, { ...params });
};

export const putRequest = (endpoint: string, params?: {}) => {
    return cp.openCMSAxios.put(endpoint, { ...params });
};

export const deleteRequest = (endpoint: string, params?: {}) => {
    return cp.openCMSAxios.delete(endpoint, { params });
};

export const pxpGetRequest = (endpoint: string, headers?: {}, params?: {}, config?: AxiosRequestConfig) => {
    return cp.pxpAxios.get(endpoint, { params, headers, ...config });
};
export const pxpPostRequest = (endpoint: string, headers?: {}, params?: {}) => {
    return cp.pxpAxios.post(endpoint, { ...params }, { headers });
};
export const pxpDeleteRequest = (endpoint: string, headers?: {}) => {
    return cp.pxpAxios.delete(endpoint, { headers });
};

export const sortAndFilterUnitsForBandResponse = (response?: any): BandUnit[] => {
    return response
        .filter((unit: BandUnit) => !unit.hidden)
        .sort((unit1: BandUnit, unit2: BandUnit) => (unit1.order_id || 0) - (unit2.order_id || 0));
};

export const processCardsForUnitResponse = (unitId: string, response?: any): Array<UnitCard> => {
    const newCardsList: Array<UnitCard> = [];
    response.items
        .filter((card: UnitCard) => !card.hidden)
        .forEach((card: UnitCard) => {
            const processedCard = { ...card };
            processedCard.id = String(card.id);
            processedCard.unitId = String(unitId);
            processedCard.answer_text = sanitizeText(card.answer_text || "");
            processedCard.question_text = sanitizeText(card.question_text || "");
            processedCard.gap_sentence = generateGapSentence(card);

            newCardsList.push(processedCard);
        });

    return newCardsList;
};

export const generateUuid = () => {
    var d = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        // eslint-disable-next-line
        return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
    });
};
