import React from "react";
import CreateInfoModal from "../InfoModal/CreateInfoModal";
import { useT, UT } from "@transifex/react/dist";
import styled from "styled-components";

interface NoOwnContentModalProps {
    isOpen: boolean;
    closeModalCallback: () => void;
    continueCallback: () => void;
    continueButtonName?: string;
}

const NoOwnContentTextWrapper = styled.div`
    width: 70%;
    margin-bottom: 0;
`;

const NoOwnContentText = styled.p`
    text-align: center;
    line-height: 1.5rem;
`;

const NoOwnContentModal: React.FC<NoOwnContentModalProps> = (props) => {
    const { isOpen, closeModalCallback, continueCallback, continueButtonName } = props;

    const t = useT();
    const t_continue = t("Continue", { _tags: "CreateTest,CreateRtHome" });
    const t_openWebApp = t("Go to Web App", { _tags: "CreateTest,CreateRtHome" });

    const continueButtonNameT = continueButtonName || t_continue;

    return (
        <CreateInfoModal
            isOpen={isOpen}
            onRequestClose={closeModalCallback}
            continueAction={continueCallback}
            continueText={t_openWebApp}
        >
            <NoOwnContentTextWrapper>
                <NoOwnContentText>
                    <UT
                        _str="You do not have any self-created vocabulary yet. Clicking on the following button opens the web app in a separate tab. Here you can create your own content. Then click on “{continueButtonNameT}” again in this tab."
                        continueButtonNameT={continueButtonNameT}
                        _inline
                        _tags="CreateTest,CreateRtHome"
                    />
                </NoOwnContentText>
            </NoOwnContentTextWrapper>
        </CreateInfoModal>
    );
};

export default NoOwnContentModal;
