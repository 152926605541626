import React from "react";
import { SidebarSelectedWord } from "p6m-p6u";
import { useTestCreationContext } from "../../../../../context/TestCreationContext";
import SelectExerciseVocabularyRow from "./selectExerciseVocabularyRow/SelectExerciseVocabularyRow";

import { MainContentWrapper } from "./StyledComponents";

export interface SelectExerciseVocabularyContentProps {
    availableVocabulary: SidebarSelectedWord[];
    disabled?: boolean;
    queue: string[];
    addToQueue: (rowId: string, wordId: string) => void;
}

const SelectExerciseVocabularyContent: React.FC<SelectExerciseVocabularyContentProps> = (props) => {
    const { currentExerciseModel, testContent } = useTestCreationContext();

    return (
        <MainContentWrapper>
            {props.availableVocabulary.map((v) => {
                if (!v.wordContent || !v.wordContent.id) {
                    return null;
                }

                const rowId = `row_${v.wordContent.id}`;
                const queueContainsWord = props.queue.includes(v.wordContent?.id || "");
                const usedInExercisesNumbers: number[] = [];
                v.exerciseIds?.forEach((id) => {
                    const testContentIndex = testContent.findIndex(
                        (tc) => tc.exerciseId === id && tc.exerciseId !== currentExerciseModel.exerciseId
                    );
                    if (testContentIndex > -1) {
                        usedInExercisesNumbers.push(testContentIndex);
                    }
                });

                return (
                    <SelectExerciseVocabularyRow
                        key={"list_" + v.wordContent?.id}
                        word={v}
                        addToQueue={() => !queueContainsWord && props.addToQueue(rowId, v.wordContent?.id || "")}
                        disabled={props.disabled}
                        rowId={rowId}
                        isRowAnimating={queueContainsWord}
                    />
                );
            })}
        </MainContentWrapper>
    );
};

export default SelectExerciseVocabularyContent;
