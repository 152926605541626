// LIBRARIES
import React from "react";
import styled from "styled-components/macro";
import { useTestReviewContext } from "../../context/TestReviewContext";
import { useT, UT } from "@transifex/react";
import {
    getPercentagesForIndividualExerciseReview,
    getPercentagesForStudentIndReview,
} from "../../helpers/ItemProgressCounts";
import { StudentTestAnswer } from "p6m-p6u";

export interface TestReviewSummaryBoxProps {
    exerciseId?: string;
    resultId?: string;
    extraBottomSpace?: boolean;
}

const ExerciseReviewSummaryWrapper = styled.div`
    &.extraBottomSpace {
        margin-bottom: 6rem;
    }
`;

const ExerciseSummary = styled.div`
    background: #f5f5f5;
    padding: 1rem;
    width: 50%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

const ExerciseSummaryItem = styled.div`
    display: flex;
    margin-bottom: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const ExerciseSummaryTextItem = styled.span`
    flex: 2;
    line-height: 1.5;
`;

const ExerciseSummaryBottomWarning = styled.span`
    line-height: 1.5;
    margin-bottom: 1rem;
    display: block;
`;

const ExerciseSummaryValueItem = styled.span`
    flex: 1;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        text-align: center;
    }
`;

const ExerciseSummaryTitle = styled.p`
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 500;
`;

const TestReviewSummaryBox: React.FC<TestReviewSummaryBoxProps> = (props) => {
    const { jointResultsData, testIndividualResults } = useTestReviewContext();
    const { exerciseId, resultId } = props;

    const t = useT();
    const t_asCorrect = t("Marked as Correct.", { _tags: "TestReviewSummaryBox" });
    const t_asIncorrect = t("Marked as Incorrect.", { _tags: "TestReviewSummaryBox" });
    const t_comments = t("Added Comments", { _tags: "TestReviewSummaryBox" });
    const t_summaryTitle = t("Summary of your Grading", { _tags: "TestReviewSummaryBox" });

    let correctPerc = 0,
        incorrectPerc = 0,
        totalComments = 0;

    let BottomWarning = null;
    if (exerciseId) {
        [correctPerc, incorrectPerc, totalComments] = getPercentagesForIndividualExerciseReview(
            jointResultsData[exerciseId] || { answers: [] }
        );
        BottomWarning = (
            <UT
                _inline
                _tags="SummaryBoxReviewExercise"
                _str="Diese Bewertungen und Kommentare wurden für alle Schüler:innen übernommen. <br/>
                            Über die Individuelle Bewertung können Sie für die einzelnen Schüler:innen Änderungen vornehmen und eine zusätzliche Evaluation hinzufügen."
            />
        );
    } else if (resultId) {
        let evaluation = testIndividualResults.find((r: StudentTestAnswer) => r.id === resultId) || {};
        [correctPerc, incorrectPerc, totalComments] = getPercentagesForStudentIndReview(evaluation);
        BottomWarning = (
            <UT
                _inline
                _tags="SummaryBoxReviewExercise"
                studentName={evaluation?.studentName}
                _str="These evaluations and the final comment shown in the current window are <b>only valid for {studentName}.</b>"
            />
        );
    }

    return (
        <ExerciseReviewSummaryWrapper className={props.extraBottomSpace ? "extraBottomSpace" : ""}>
            <ExerciseSummaryTitle>{t_summaryTitle}</ExerciseSummaryTitle>
            <ExerciseSummary>
                <ExerciseSummaryItem>
                    <ExerciseSummaryTextItem>{t_asCorrect}</ExerciseSummaryTextItem>
                    <ExerciseSummaryValueItem>{correctPerc}%</ExerciseSummaryValueItem>
                </ExerciseSummaryItem>
                <ExerciseSummaryItem>
                    <ExerciseSummaryTextItem>{t_asIncorrect}</ExerciseSummaryTextItem>
                    <ExerciseSummaryValueItem>{incorrectPerc}%</ExerciseSummaryValueItem>
                </ExerciseSummaryItem>
                <ExerciseSummaryItem>
                    <ExerciseSummaryTextItem>{t_comments}</ExerciseSummaryTextItem>
                    <ExerciseSummaryValueItem>{totalComments}</ExerciseSummaryValueItem>
                </ExerciseSummaryItem>
            </ExerciseSummary>
            <ExerciseSummaryBottomWarning>{BottomWarning}</ExerciseSummaryBottomWarning>
        </ExerciseReviewSummaryWrapper>
    );
};

export default TestReviewSummaryBox;
