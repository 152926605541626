import styled from "styled-components";
import FlagIcon from "../../../basic/flagIcon/FlagIcon";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../constants/DesignConstants";

//TODO: when we redesign the recurring task, we will have to use the design constants here.

export const TitleWrapper = styled.div`
    text-align: center;

    &.space {
        margin: 1rem 0;
    }
`;

export const Title = styled.h3``;

export const Wrapper = styled.div`
    padding-bottom: 6rem;
`;
export const MiddleContentWrapper = styled.div`
    background: white;
    border-radius: 0.5rem;
`;
export const BookLanguageWrapper = styled.div`
    width: 100%;
    padding: 0.5rem;
    display: flex;
    border-bottom: solid 2px ${DesignConstants.COLORS.BACKGROUND_LIGHT};
`;
export const BookLanguageNameWrapperLeft = styled.div`
    width: calc(50% - 1.5rem);
`;
export const BookLanguageNameWrapperRight = styled.div`
    flex: 1;
`;
export const StyledFlagIcon = styled(FlagIcon)`
    color: #666666;
    font-weight: 600;

    svg {
        width: 1.5rem;
        margin-right: 8px;
        border-radius: 1rem;
    }
`;
export const ExerciseVocabularyWrapper = styled.div``;
export const BookInfoAndCTA = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
`;
export const DeleteAllCTAWrapper = styled.div`
    color: #666;
    padding-top: 3px;
    cursor: pointer;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
`;
export const SmallIcon = styled(PhaseSixIcon)`
    font-size: 1.5rem;
`;
export const CardsCountMobileWrapper = styled.div`
    width: 100%;
    text-align: right;
    margin-bottom: 0.5rem;
    padding-right: 0.5rem;
`;
