import * as React from "react";
import styled from "styled-components/macro";

export interface MainBackgroundSquircleProps {
    className?: string;
    children?: React.ReactNode;
}

const Squircle = styled.div`
    width: 90%;
    background: white;
    border-radius: 1rem;
    padding: 2rem 2rem;
    flex: 1;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        border-radius: 1rem;
        padding: 2rem 1rem 1rem;
        margin: 0.5rem 0 1rem;
    }
`;

const MainBackgroundSquircle = React.forwardRef<HTMLDivElement, MainBackgroundSquircleProps>((props, ref) => {
    return (
        <Squircle
            className={props.className}
            ref={ref}
        >
            {props.children}
        </Squircle>
    );
});

export default MainBackgroundSquircle;
