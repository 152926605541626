import React from "react";
import { PDFViewer } from "@react-pdf/renderer";

import { TeacherTestDetails } from "p6m-p6u";
import PDFFullTest from "../../../complex/pdfCreation/PDFFullTest";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { PDFViewWrapper } from "./StyledComponents";

interface PDFPreviewProps {
    testDetails?: TeacherTestDetails;
    isSolution?: boolean;
}

// change this comment to update the preview while developing:
//

const PDFPreview: React.FC<PDFPreviewProps> = (props) => {
    const { loadInitArticleIdData } = useTestCreationContext();

    return (
        <div>
            <PDFViewWrapper>
                <PDFViewer style={{ width: "100%", height: "100%" }}>
                    <PDFFullTest
                        testDetails={props.testDetails}
                        bookArticleId={props.testDetails?.articleId}
                        loadInitArticleIdData={loadInitArticleIdData}
                        isSolution={props.isSolution}
                    />
                </PDFViewer>
            </PDFViewWrapper>
        </div>
    );
};

export default PDFPreview;
