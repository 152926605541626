//LIBRARIES
import React, { ChangeEvent, useEffect, useRef } from "react";

import {
    ActionsWrapper,
    AlertIcon,
    AlertWrapper,
    InputField,
    StyledIcon,
    ContentWrapper,
    Text,
    Wrapper,
} from "./StyledComponents";

export interface InputWithActionsProps {
    isEditing: boolean;
    setIsEditing: (value: boolean) => void;
    currentText: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onSave: () => void;
    onDiscard: () => void;
    placeholder?: string;
    showWarning?: boolean;
    isSaveButtonDisabled?: boolean;
    triggerFlash?: boolean;
}

const InputWithActions: React.FC<InputWithActionsProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.isEditing) {
            inputRef.current?.focus();
        }
    }, [props.isEditing]);

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === "Enter") {
            e.preventDefault();
            props.onSave();
        } else if (e.key === "Escape") {
            e.preventDefault();
            props.onDiscard();
        }
    };

    function handleEdit(e: React.MouseEvent) {
        if (!props.isEditing) {
            e.preventDefault();
            props.setIsEditing(true);
        }
    };

    function handleSave(e: React.MouseEvent) {
        props.isSaveButtonDisabled ? e.preventDefault() : props.onSave();
    };

    const renderContent = () => {
        if (props.isEditing) {
            return (
                <InputField
                    type={"text"}
                    value={props.currentText ?? ""}
                    onChange={props.onChange}
                    onKeyDown={handleKeyDown}
                    onBlur={props.onSave}
                    ref={inputRef}
                    placeholder={props.placeholder}
                />
            );
        } else {
            return (
                <AlertWrapper>
                    {props.showWarning && !props.currentText && <AlertIcon name="alert" />}
                    <Text>{props.currentText || props.placeholder}</Text>
                </AlertWrapper>
            );
        }
    };

    const renderClickableIcons = () => {
        return (
            <ActionsWrapper>
                {props.isEditing ? (
                    <>
                        <StyledIcon
                            name={"exercise-ok"}
                            onMouseDown={handleSave}
                            disabled={props.isSaveButtonDisabled}
                        />
                        <StyledIcon
                            name={"close"}
                            onMouseDown={props.onDiscard}
                        />
                    </>
                ) : (
                    <StyledIcon
                        name={"edit-avatar"}
                        onClick={() => {}}
                    />
                )}
            </ActionsWrapper>
        );
    };

    return (
        <Wrapper
            onMouseDown={handleEdit}
            clickable={!props.isEditing}
        >
            <ContentWrapper
                isFocused={props.isEditing}
                highlighted={props.showWarning}
                className={props.triggerFlash ? "triggerFlash" : ""}
            >
                {renderContent()}
            </ContentWrapper>

            {renderClickableIcons()}
        </Wrapper>
    );
};

export default InputWithActions;
