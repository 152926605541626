import React from "react";
import { useT } from "@transifex/react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { SummaryModes } from "./Summary";
import { cp } from "../../../config";
import CtasWrapper, { ButtonGroup } from "../../../wrapper/create/ctasWrapper/CtasWrapper";
import StyledButton from "../../../basic/styledButton/StyledButton";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";

import { ButtonWrapper, StyledButtonWithoutIcon } from "./StyledComponents";

interface SummaryFooterProps {
    currentMode: SummaryModes;
    isSaveExerciseDisabled: boolean;
    isTestBeingSynced: boolean;
    onClickButtonBack: () => void;
    onClickButtonContinue: () => void;
}

const SummaryFooter: React.FC<SummaryFooterProps> = (props) => {
    const { testContent, createLocalPreviewData } = useTestCreationContext();

    const t = useT();

    const t_saveTest = t("Complete test creation", { _tags: "CreateTest,Summary" });
    const t_saving = t("Saving...", { _tags: "CreateTest,Summary" });
    const t_saveExercise = t("Save exercise", { _tags: "CreateTest,Summary" });
    const t_preview = t("Preview", { _tags: "CreateTest,Summary" });
    const t_back = t("Back", { _tags: "CreateTest,Summary" });

    const isOverviewMode = props.currentMode === SummaryModes.OVERVIEW;
    const isSelectExerciseTypeMode = props.currentMode === SummaryModes.SELECT_EXERCISE_TYPE;
    const isExerciseMode = props.currentMode === SummaryModes.EXERCISE_DETAILS;

    const canSaveExercise = !props.isSaveExerciseDisabled && !props.isTestBeingSynced;

    function openPreview() {
        try {
            createLocalPreviewData();
            window.open(cp.cfg.REACT_APP_PREFIX + "/create/preview", "_blank");
        } catch (e) {
            alert(e);
        }
    }

    return (
        <CtasWrapper showScrollToTop>
            <ButtonWrapper>
                <StyledButton
                    onClick={props.onClickButtonBack}
                    arrowDirection={"LEFT"}
                    buttonStyle={"BLANK"}
                >
                    {t_back}
                </StyledButton>
            </ButtonWrapper>
            <ButtonGroup>
                {(isOverviewMode || isSelectExerciseTypeMode) && (
                    <ButtonWrapper>
                        <StyledButtonWithIcon
                            icon={"preview"}
                            iconPosition={"LEFT"}
                            onClick={openPreview}
                            arrowDirection={"NONE"}
                            buttonStyle={"ORANGE_BLANK"}
                            disabled={testContent.length < 1}
                        >
                            {t_preview}
                        </StyledButtonWithIcon>
                    </ButtonWrapper>
                )}
                {(isOverviewMode || isSelectExerciseTypeMode) && (
                    <StyledButtonWithIcon
                        onClick={props.onClickButtonContinue}
                        icon={"exercise-ok"}
                        iconPosition={"LEFT"}
                        arrowDirection={"NONE"}
                        buttonStyle={"PRIMARY"}
                        disabled={testContent.length < 1 || props.isTestBeingSynced}
                    >
                        {props.isTestBeingSynced ? t_saving : t_saveTest}
                    </StyledButtonWithIcon>
                )}
                {isExerciseMode && (
                    <StyledButtonWithoutIcon
                        arrowDirection="NONE"
                        onClick={props.onClickButtonContinue}
                        disabled={!canSaveExercise}
                    >
                        {t_saveExercise}
                    </StyledButtonWithoutIcon>
                )}
            </ButtonGroup>
        </CtasWrapper>
    );
};

export default SummaryFooter;
