import React from "react";
import CreateInfoModal from "../create/InfoModal/CreateInfoModal";
import { useT, UT } from "@transifex/react/dist";
import { InnerTextWrapper, CancelText } from "./StyledComponents";

interface CancelCreateExerciseModalProps {
    isOpen: boolean;
    closeModalCallback: () => void;
    continueCallback: () => void;
}

const CancelCreateExerciseModal: React.FC<CancelCreateExerciseModalProps> = (props) => {
    const { isOpen, closeModalCallback, continueCallback } = props;

    const t = useT();
    const t_cancel = t("Cancel", { _tags: "CreateTest,CancelCreateExercise" });
    const t_agree = t("Yes", { _tags: "CreateTest,CancelCreateExercise" });
    const t_cancelCreateExerciseText = t(
        "The changes to this exercise sheet have not been saved yet. Do you really want to discard them?",
        {
            _tags: "CreateTest,CancelCreateExercise",
        }
    );

    function continueAction() {
        continueCallback();
        closeModalCallback();
    }

    return (
        <CreateInfoModal
            isOpen={isOpen}
            onRequestClose={closeModalCallback}
            continueAction={continueAction}
            continueText={t_agree}
            cancelText={t_cancel}
            cancelAction={closeModalCallback}
        >
            <InnerTextWrapper>
                <CancelText>
                    <UT
                        _str={t_cancelCreateExerciseText}
                        _inline
                        _tags="CreateTest,CancelCreateExercise"
                    />
                </CancelText>
            </InnerTextWrapper>
        </CreateInfoModal>
    );
};

export default CancelCreateExerciseModal;
