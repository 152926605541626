// LIBRARIES
import React from "react";
import styled from "styled-components/macro";
import { useTestReviewContext } from "../../context/TestReviewContext";
import { UT } from "@transifex/react";
import { getDateTimeFromMilliseconds, getReadableTimeFromTimeLimit } from "../../helpers/DateTime";

export interface TextReviewInfoHeaderProps {}

const TestInfo = styled.div`
    padding: 1rem;
`;

const TestDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const TestTitle = styled.h2`
    color: #666;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    font-weight: 400;
`;

const TestDetailsInfoItem = styled.div`
    font-size: 1.1rem;
`;

const TestDetailsInfoItemSeparator = styled.div`
    margin: 0 1rem;
`;

const TextReviewInfoHeader: React.FC<TextReviewInfoHeaderProps> = (props) => {
    const { studentTestContent } = useTestReviewContext();

    return (
        <TestInfo>
            <TestTitle>{studentTestContent.name}</TestTitle>
            <TestDetails>
                <TestDetailsInfoItem>
                    {studentTestContent.dueDate ? (
                        <UT
                            _str="Deadline: {readableDateTime}"
                            readableDateTime={getDateTimeFromMilliseconds(studentTestContent.dueDate)}
                            _inline
                            _tags="reviewTest,testInfo"
                        />
                    ) : (
                        <UT
                            _str="No deadline set"
                            _inline
                            _tags="reviewTest,testInfo"
                        />
                    )}
                </TestDetailsInfoItem>
                <TestDetailsInfoItemSeparator>|</TestDetailsInfoItemSeparator>

                <TestDetailsInfoItem>
                    {studentTestContent.timeLimit ? (
                        <UT
                            _str="Time: {timeLimit}"
                            timeLimit={getReadableTimeFromTimeLimit(studentTestContent.timeLimit)}
                            _inline
                            _tags="reviewTest,testInfo"
                        />
                    ) : (
                        <UT
                            _str="No time limit set"
                            _inline
                            _tags="reviewTest,testInfo"
                        />
                    )}
                </TestDetailsInfoItem>
            </TestDetails>
        </TestInfo>
    );
};

export default TextReviewInfoHeader;
