// LIBRARIES
import React from "react";
import styled from "styled-components/macro";
import { useT } from "@transifex/react";
import { getPercentagesForStudentIndReview } from "../../helpers/ItemProgressCounts";
import { StudentTestAnswer } from "p6m-p6u";

export interface StudentReviewSummaryBoxProps {
    resultData: StudentTestAnswer;
}

const ExerciseReviewSummaryWrapper = styled.div``;

const ExerciseSummary = styled.div`
    background: #f5f5f5;
    padding: 1rem;
    width: 50%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
`;

const ExerciseSummaryItem = styled.div`
    display: flex;
    margin-bottom: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const ExerciseSummaryTextItem = styled.span`
    flex: 2;
    line-height: 1.5;
`;

const ExerciseSummaryValueItem = styled.span`
    flex: 1;
`;

const ExerciseSummaryTitle = styled.p`
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 500;
`;

const StudentReviewSummaryBox: React.FC<StudentReviewSummaryBoxProps> = (props) => {
    const { resultData } = props;

    const t = useT();
    const t_asCorrect = t("Marked as Correct.", { _tags: "StudentReviewSummaryBox" });
    const t_asIncorrect = t("Marked as Incorrect.", { _tags: "StudentReviewSummaryBox" });
    const t_comments = t("Added Comments", { _tags: "StudentReviewSummaryBox" });
    const t_summaryTitle = t("Summary of your Grading", { _tags: "StudentReviewSummaryBox" });

    let [correctPerc, incorrectPerc, totalComments] = getPercentagesForStudentIndReview(resultData);

    return (
        <ExerciseReviewSummaryWrapper>
            <ExerciseSummaryTitle>{t_summaryTitle}</ExerciseSummaryTitle>
            <ExerciseSummary>
                <ExerciseSummaryItem>
                    <ExerciseSummaryTextItem>{t_asCorrect}</ExerciseSummaryTextItem>
                    <ExerciseSummaryValueItem>{correctPerc}%</ExerciseSummaryValueItem>
                </ExerciseSummaryItem>
                <ExerciseSummaryItem>
                    <ExerciseSummaryTextItem>{t_asIncorrect}</ExerciseSummaryTextItem>
                    <ExerciseSummaryValueItem>{incorrectPerc}%</ExerciseSummaryValueItem>
                </ExerciseSummaryItem>
                <ExerciseSummaryItem>
                    <ExerciseSummaryTextItem>{t_comments}</ExerciseSummaryTextItem>
                    <ExerciseSummaryValueItem>{totalComments}</ExerciseSummaryValueItem>
                </ExerciseSummaryItem>
            </ExerciseSummary>
        </ExerciseReviewSummaryWrapper>
    );
};

export default StudentReviewSummaryBox;
