// LIBRARIES
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGeneralContext } from "../../context/AppGeneralContext";
import { useT } from "@transifex/react";
import queryString from "query-string";
import { Wrapper } from "./StyledComponents";

export interface AuthWrapperProps {}

const AuthWrapper: React.FC<AuthWrapperProps> = () => {
    const { successfulAuth, loginUser, isIframeMode, setIsIframeMode, setShowRedirectOldVTGPopup } =
        useGeneralContext();

    const { search } = useLocation();
    const urlParams = queryString.parse(search.replace("?", ""));

    const t = useT();
    const t_loadingText = t("Loading", { _tags: "AuthWrapper" });
    const [isLoginInProgress, setIsLoginInProgress] = useState(true);

    const history = useHistory();

    useEffect(() => {
        if (isIframeMode) {
            history.push("/create/anonymous");
        } else {
            let redirect = "";
            if (urlParams.redirectTo) {
                redirect = urlParams.redirectTo as string;
            }
            loginUser(redirect).then(() => {
                setIsLoginInProgress(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (urlParams.iframeMode) {
            setIsIframeMode(true);
        }
        if (urlParams.articleId) {
            const aId: string = urlParams.articleId as string;
            localStorage.setItem("initAID", aId);
        }
        if (urlParams.articleUuid) {
            localStorage.setItem("initUAID", urlParams.articleUuid as string);
            if (urlParams.unitUuid || urlParams.unitFromUuid) {
                const unitFromVal = (urlParams.unitUuid || urlParams.unitFromUuid) as string;
                localStorage.setItem("initFromUUID", unitFromVal);
            }
            if (urlParams.unitToUuid) {
                localStorage.setItem("initToUUID", urlParams.unitToUuid as string);
            }
        }
        if (urlParams.oldVTGRedirect) {
            setShowRedirectOldVTGPopup(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    const renderPage = function () {
        if (!isLoginInProgress && !successfulAuth) {
            history.push("/unauthorized");
        } else {
            return <p> .... {t_loadingText}</p>;
        }
    };

    return <Wrapper>{renderPage()}</Wrapper>;
};

export default AuthWrapper;
