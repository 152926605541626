// LIBRARIES
import React, { useState } from "react";
import { useT } from "@transifex/react/dist";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";
import Lottie from "lottie-react";

import { ReactComponent as Logo } from "../../../assets/img/p6-logo.svg";
import Slide from "@mui/material/Slide";

import {
    Wrapper,
    InfoWrapper,
    OutroMainScreenWrapper,
    OutroSlidingItemWrapper,
    OutroTitleWrapper,
    OutroSubtitle,
    OutroTitle,
    OutroTextContent,
    OutroCTA,
    happyMonsterAnimationOptions,
    thumbsUpMonsterAnimationOptions,
    NavigationDotsWrapper,
    NavigationDot,
    StyledLogo,
    Divider,
} from "./StyledComponents";

export interface OutroProps {}

const Outro: React.FC<OutroProps> = (props) => {
    const t = useT();
    const t_titleSimple = t("Super, you're done!", { _tags: "Outro" });
    const t_titleTimeOver = t("Your time is up.", { _tags: "Outro" });
    const t_answersSent = t("Your answers have been sent to your Teacher.", { _tags: "Outro" });
    const t_byTheWay = t(
        "By the way: With phase6 you can learn the Vocabulary from your schoolbook fast, easy and successfully - and you're always prepared",
        { _tags: "Outro" }
    );
    const t_endDesc = t("You will get your results from your teacher as soon as the Grading is complete", {
        _tags: "Outro",
    });
    const t_cta = t("Know more", { _tags: "Outro" });

    const { isTimeOver } = useStudentPracticeContext();
    const [currentTab, setCurrentTab] = useState<"animation" | "conversion">("animation");
    const [hasAnimationTimerRunOut, setHasAnimationTimerRunOut] = useState(false);

    const finishAnimationCallback = function () {
        if (!hasAnimationTimerRunOut) {
            setCurrentTab("conversion");
            setHasAnimationTimerRunOut(true);
        }
    };

    const renderAnimationSlide = () => (
        <Slide
            direction={"right"}
            in={currentTab === "animation"}
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 1000, exit: 0 }}
        >
            <OutroSlidingItemWrapper>
                {isTimeOver ? (
                    <Lottie
                        {...thumbsUpMonsterAnimationOptions}
                        style={{ height: 400, width: 400 }}
                        onLoopComplete={finishAnimationCallback}
                    />
                ) : (
                    <Lottie
                        {...happyMonsterAnimationOptions}
                        style={{ height: 400, width: 400 }}
                        onLoopComplete={finishAnimationCallback}
                    />
                )}
                <OutroTitleWrapper>
                    <OutroTitle>{isTimeOver ? t_titleTimeOver : t_titleSimple}</OutroTitle>
                    <br />
                    <OutroSubtitle>{t_answersSent}</OutroSubtitle>
                </OutroTitleWrapper>
            </OutroSlidingItemWrapper>
        </Slide>
    );

    const renderInfoSlide = () => (
        <Slide
            direction={"left"}
            in={currentTab === "conversion"}
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 1000, exit: 0 }}
        >
            <OutroSlidingItemWrapper>
                <OutroTextContent>{t_endDesc}</OutroTextContent>
                <Divider />
                <OutroTextContent>{t_byTheWay}</OutroTextContent>
                <OutroCTA onClick={() => window.open("https://www.phase-6.de/?utm_source=phase6-vtg2", "_self")}>
                    {t_cta}
                </OutroCTA>
                <StyledLogo svgComponent={Logo} />
            </OutroSlidingItemWrapper>
        </Slide>
    );

    const renderContent = () => (
        <InfoWrapper>
            <OutroMainScreenWrapper>
                {renderAnimationSlide()}
                {renderInfoSlide()}
            </OutroMainScreenWrapper>
        </InfoWrapper>
    );

    const renderNavigationDots = () => {
        return (
            <NavigationDotsWrapper>
                <NavigationDot
                    className={currentTab === "animation" ? "selected" : ""}
                    onClick={() => {
                        setCurrentTab("animation");
                    }}
                />
                <NavigationDot
                    className={currentTab === "conversion" ? "selected" : ""}
                    onClick={() => {
                        setCurrentTab("conversion");
                    }}
                />
            </NavigationDotsWrapper>
        );
    };

    return (
        <Wrapper>
            {renderContent()}
            {renderNavigationDots()}
        </Wrapper>
    );
};

export default Outro;
