import { cp } from "../config";

export const redirectAnonymousUser = (isIframeMode: boolean, openInNewTab?: boolean) => {
    let redirectUrl = cp.cfg.REACT_APP_VTG_SITE + "act_redirect-to-register.jsp?isNewVTG=true";

    if (isIframeMode) {
        redirectUrl += "&iframeMode=true";
    }

    const originator = localStorage.getItem("originator") || "";
    if (originator !== "") {
        redirectUrl += (redirectUrl.indexOf("?") > -1 ? "&" : "?") + "originator=" + originator;
    }

    if (openInNewTab) {
        (window.top ? window.top : window.parent).open(redirectUrl, "_blank")?.focus();
    } else {
        (window.top ? window.top : window.parent).location.replace(redirectUrl);
    }
};
