import styled, { css } from "styled-components";
import DesignConstants from "../../constants/DesignConstants";

const baseProgressStyles = css`
    width: 100%;
    height: 1rem;
    background: ${(props) => props.theme.base.highlightAreaColor};
    max-width: 40%;
    border-radius: 0.5rem;
    position: relative;
`;

export const CompletedProgressBar = styled.div`
    ${baseProgressStyles};
    background: ${(props) => props.theme.base.primaryColor};
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
        color: ${(props) => props.theme.base.primaryColor};
    }
`;

export const EmptyProgressBar = styled.div`
    ${baseProgressStyles};
`;

export const InnerProgressBar = styled.div`
    background: ${DesignConstants.COLORS.DARK_GREY};
    border-radius: 0.5rem;
    height: 100%;
`;

export const ExerciseName = styled.p`
    margin-right: 1rem;
`;
