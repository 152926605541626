import styled from "styled-components/macro";
import StyledButton from "../../styledButton/StyledButton";
import DesignConstants from "../../../constants/DesignConstants";
import { Styles } from "react-modal";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";

export const ModalScrollableContent = styled.div<{ noVerticalPadding?: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S} 0 ${DesignConstants.SPACES.XS};

    > * {
        margin-bottom: ${(props) => (props.noVerticalPadding ? 0 : DesignConstants.SPACES.S)} 

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ModalIcon = styled(SvgIcon)`
    height: ${DesignConstants.SPACES.XL};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        height: ${DesignConstants.SPACES.XXL};
    }
`;

export const ModalTitleWrapper = styled.div`
    text-align: center;
    padding: 0 ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        color: inherit;
        font-size: ${DesignConstants.FONT_SIZES.XL};
    }
`;

export const ModalTitle = styled.h2`
    font-weight: normal;
`;

export const CTASWrapper = styled.div<{ alignLeft?: boolean; marginToButtons?: number | string }>`
    display: flex;
    width: 100%;
    align-items: ${(props) => (props.alignLeft ? "flex-start" : "center")};
    justify-content: space-between;
    margin-top: ${(props) => props.marginToButtons ?? DesignConstants.SPACES.M};
    gap: ${DesignConstants.SPACES.S};

    > :only-child {
        margin-left: ${(props) => (props.alignLeft ? 0 : "auto")};
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
        gap: ${DesignConstants.SPACES.S};
    }
`;

export const CtaButton = styled(StyledButton)``;

export const CloseButton = styled.div`
    display: innline-block;
    align-self: end;
    height: ${DesignConstants.FONT_SIZES.XL};
    width: ${DesignConstants.FONT_SIZES.XL};
    cursor: pointer;
    margin-top: -${DesignConstants.SPACES.XXS};
    margin-right: -${DesignConstants.SPACES.XXS};
`;

export const modalStyles: Styles = {
    overlay: {
        backgroundColor: DesignConstants.COLORS.TRANSPARENT_WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10,
    },
    content: {
        position: "unset",
        margin: DesignConstants.SPACES.S,
        border: `${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.DARK_GREY}`,
        borderRadius: DesignConstants.BORDER_RADIUS.SMALL_CONTAINER,
        maxWidth: "90%",
        minWidth: "60vw",
        display: "flex",
        flexDirection: "column",
        padding: DesignConstants.SPACES.S,
    },
};

export const modalStylesCompact: Styles = {
    overlay: {
        backgroundColor: DesignConstants.COLORS.TRANSPARENT_WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 11,
    },
    content: {
        maxHeight: "80vh",
        position: "unset",
        margin: DesignConstants.SPACES.S,
        border: `${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.DARK_GREY}`,
        borderRadius: DesignConstants.BORDER_RADIUS.SMALL_CONTAINER,
        display: "flex",
        flexDirection: "column",
        gap: DesignConstants.SPACES.XXS,
        padding: DesignConstants.SPACES.S,
    },
};
