import styled from "styled-components";
import DesignConstants from "../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

export const Wrapper = styled.div`
    padding: ${DesignConstants.SPACES.S};
    border-top: ${DesignConstants.BORDERS.THIN_GREY};
    border-color: ${DesignConstants.COLORS.LIGHTEST_GREY};
`;

export const InfoIconWrapper = styled.div`
    display: flex;
    padding-bottom: ${DesignConstants.SPACES.M};
`;

export const StyledInfoIcon = styled(PhaseSixIcon)`
    display: flex;
    justify-content: center;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    width: ${DesignConstants.FONT_SIZES.XL};
    white-space: nowrap;
`;

export const NoMoreVocabularyWrapper = styled.div`
    padding: ${DesignConstants.SPACES.XS};
`;

export const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
`;

export const NoMoreVocabularyInfo = styled.span`
    padding-bottom: ${DesignConstants.SPACES.M};
`;

export const NoMoreVocabularyHint = styled.span`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const NoMoreVocabularyHintText = styled.span``;

export const NoMoreConnectWordsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    color: ${DesignConstants.COLORS.P6_ORANGE};
    gap: ${DesignConstants.SPACES.XS};
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S}
        ${DesignConstants.SPACES.XS};
`;

export const ConnectInfoWrapper = styled.div`
    display: flex;
    padding-left: ${DesignConstants.SPACES.XXS};
`;

export const ConnectInfoText = styled.p`
    display: flex;
    align-items: center;
    font-size: ${DesignConstants.FONT_SIZES.L};
    line-height: ${DesignConstants.FONT_SIZES.XL};
`;
