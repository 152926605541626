import styled from "styled-components";
import ExerciseInputField from "../../../../../basic/exerciseInputField/ExerciseInputField";
import DesignConstants from "../../../../../constants/DesignConstants";
import StyledButton from "../../../../../basic/styledButton/StyledButton";
import PhaseSixIcon from "../../../../../basic/phaseSixIcon/PhaseSixIcon";

export const SelectExerciseVocabularyHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ${DesignConstants.SPACES.XS};
`;

export const ToggleSelectVocabularyButton = styled.button`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
    padding: ${DesignConstants.SPACES.XS};
    cursor: pointer;
    border: none;
    background: none;
    color: ${DesignConstants.COLORS.DARK_GREY};
`;

export const StyledIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
`;

export const SelectExerciseVocabularyHeaderTitle = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const RandomSelectionAndDeleteWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const RandomSelectionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;

    &.hide {
        opacity: 0;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
`;

export const RandomCardNumberSelectorWrapper = styled.div`
    width: 70px;
    margin-right: 5px;

    > div {
        padding: 0;
    }
`;

export const StyledExerciseInputField = styled(ExerciseInputField)`
    width: 70px;
    height: 40px;
    flex: unset;
    margin: 0;
    background: white;
    border-radius: 100px 0 0 100px;
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
    overflow: hidden;

    > div {
        padding: 0;
        background-color: ${DesignConstants.COLORS.P6_ORANGE};
    }

    > input {
        padding: 0 ${DesignConstants.SPACES.XS} 0 ${DesignConstants.SPACES.S};
        border: none;
    }

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        pointer-events: none;
        cursor: not-allowed;
    }
`;

export const StyledRandomSelectionButton = styled(StyledButton)`
    text-transform: capitalize !important;
    font-size: ${DesignConstants.FONT_SIZES.S};
    min-width: unset;
    min-height: 40px !important;
    padding: 0 ${DesignConstants.SPACES.S} !important;
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY} !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;
