import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { InputHTMLAttributes, useImperativeHandle, useRef } from "react";

export interface ExerciseTextAreaProps {
    className?: string;
}

const InputWrapper = styled.div`
    flex: 4;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
    background: white;
    width: 100%;
    margin: 10px 0;
    border: solid 1px #a6a6a6;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #999;
    transition: all 0.15s ease-in-out 0s;
    padding: 0.5rem 0.5rem 0.5rem 0rem;

    // &:hover{
    //   background:${(props) => props.theme.base.highlightAreaColor}
    // }
`;

const TextArea = styled.textarea`
    width: 100%;
    border: medium none;
    margin: 0.5rem 0;
    padding: 0 0 0 10px;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    box-shadow: none;
    display: inline;
    height: 80px;
    font-size: 1rem;
    font-family: ${(props) => props.theme.base.fontFamily};
    color: rgba(0, 0, 0, 0.75);
    transition: all 0.15s ease-in-out 0s;
    border-radius: 0.5rem;
    overflow: auto;
    resize: none;

    &:focus {
        outline: none;
    }
`;

const ExerciseTextArea = React.forwardRef<
    HTMLTextAreaElement,
    ExerciseTextAreaProps & InputHTMLAttributes<HTMLTextAreaElement>
>((props, ref) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => textAreaRef.current!, []);

    const fieldProps: InputHTMLAttributes<HTMLTextAreaElement> = props;

    const handleInnerChange = function () {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "inherit";
            textAreaRef.current.style.height = Math.max(80, textAreaRef.current.scrollHeight) + "px";
        }
    };

    useEffect(() => {
        handleInnerChange();
    }, []);

    return (
        <InputWrapper className={props.className ? props.className : ""}>
            <TextArea
                {...fieldProps}
                ref={textAreaRef}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                onBlur={handleInnerChange}
                draggable="false"
            />
        </InputWrapper>
    );
});

export default ExerciseTextArea;
