import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
`;

export const TestNameWrapper = styled.div`
    margin: 1rem 0;
    padding: 1rem 1.5rem;
    width: 100%;
    background: #f5f5f5;
    border-radius: 0.5rem;
    text-align: left;
`;

export const ExamContentWrapper = styled.div`
    width: 100%;
`;

export const ExamContentRow = styled.div`
    padding: 1.5rem;
    /* border: 2px solid #ddd; */
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
    border-radius: 1rem;
    margin: 0 0 1rem 0;

    &:nth-child(even) {
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 1rem;
    }
`;

export const ExerciseTitleWrapper = styled.h3`
    font-weight: 500;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin-bottom: 0.5rem;
    }
`;

export const TestName = styled.h3``;

export const StudentsCTAsWrapper = styled.div`
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    left: 0;
    padding: 2rem;
    bottom: 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    background: #fff;
    height: 7rem;
    justify-content: center;
`;

export const TextAnswerTitles = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 0;
`;

export const BasicAnswerTitles = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 0;
`;

export const ExerciseRowContent = styled.div``;

export const AnswerTitle = styled.div`
    flex: 3;
    text-align: left;

    &.big {
        flex: 5;
    }
`;

export const QuestionTitle = styled.div`
    flex: 2;
    text-align: left;
`;

export const ActionTitle = styled.div`
    flex: 1;
    text-align: center;
`;

export const TextAnswerRowWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 0.5rem 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 0.5rem 0;
    }
`;

export const BaseAnswerRowWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 0.5rem 0;
    border-bottom: dashed 1px #ccc;

    :last-child {
        border: none;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 0.5rem 0;
    }
`;

export const BaseQuestionContent = styled.div`
    flex: 2;
    line-height: 1.5;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding-right: 10px;
        word-break: break-word;
    }
`;

export const BaseAnswerContent = styled.div`
    flex: 3;
    display: flex;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        word-break: break-word;
    }
`;

export const TextAnswerContent = styled.div`
    flex: 5;
`;

export const ActionsWrapper = styled.div`
    flex: 1;
    text-align: center;
`;

export const ExerciseDescription = styled.p`
    font-size: 1rem;
    margin: 1rem 0 1.5rem;
    line-height: 1.5;
`;

export const CommentTextArea = styled.textarea`
    width: 80%;
    height: 4rem;
    border-radius: 5px;
    margin-bottom: 1rem;
`;

export const StudentOverallCommentText = styled.p`
    margin-bottom: 1rem;
`;

export const StudentOverallCommentWrapper = styled.div``;
