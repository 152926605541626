// LIBRARIES
import React from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";
import { Page, Image, Text, View, Document, StyleSheet, BlobProvider, Font } from "@react-pdf/renderer";
import correctPng from "../../assets/img/pdf/correct.png";
import incorrectPng from "../../assets/img/pdf/incorrect.png";
import logo from "../../assets/img/pdf/logo-png.png";
import { getPercentagesForStudentIndReview } from "../../helpers/ItemProgressCounts";
import { StudentTestAnswer, StudentTestContent } from "p6m-p6u";
import moment from "moment";

export interface StudentResultPDFProps {
    resultData: StudentTestAnswer;
    teacherTestData: StudentTestContent;
}

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
`;

const StyledDownloadLink = styled.a`
    text-transform: none;
    height: auto;
    font-weight: 600;
    width: 100%;
    font-family: "proxima-nova", sans-serif;
    background-color: transparent;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 1rem;
    font-size: 1.2rem;
    color: #666666;
    border: solid 1px #666666;
    margin: 0;
    text-decoration: none;
    text-align: center;

    > span {
        color: #666666;
        font-size: 1.2rem;
    }
`;

const Styles = StyleSheet.create({
    examContentRow: {
        padding: "12px 12px 7px",
        borderRadius: 15,
        margin: "0 0 20px 0",
        display: "flex",
        border: "1px solid #ddd",
        color: "#666",
    },
    basicAnswerTitles: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        marginBottom: 5,
        fontWeight: 600,
        flexDirection: "row",
    },
    textAnswerTitles: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        marginBottom: 15,
        fontWeight: 600,
        flexDirection: "row",
    },
    exerciseQuestionTitle: {
        fontSize: 13,
        marginBottom: 10,
        fontWeight: 600,
    },
    exerciseQuestionText: {
        fontSize: 12,
        marginBottom: 15,
    },
    questionTitle: {
        flex: 3,
        textAlign: "left",
        fontSize: 12,
        fontWeight: 600,
    },
    actionTitle: {
        flex: 1,
        textAlign: "center",
        fontSize: 12,
        fontWeight: 600,
    },
    answerTitle: {
        flex: 3,
        textAlign: "left",
        fontSize: 12,
        fontWeight: 600,
    },
    answerTitleBig: {
        flex: 5,
        textAlign: "left",
        fontSize: 12,
        fontWeight: 600,
    },
    textAnswerRowWrapper: {
        display: "flex",
        padding: "5px 0",
        flexDirection: "row",
    },
    textAnswerContent: {
        flex: 5,
        fontSize: 12,
        padding: 3,
    },
    baseQuestionContent: {
        flex: 3,
        lineHeight: 1.5,
        fontSize: 12,
        padding: "0 5px 0 0",
    },
    baseAnswerRowWrapper: {
        display: "flex",
        padding: "5px 0",
        borderBottom: "dashed 1px #ccc",
        flexDirection: "row",
    },
    baseAnswerContent: {
        flex: 3,
        display: "flex",
        flexDirection: "row",
    },
    actionsWrapper: {
        flex: 1,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    correctHighlightedAnswer: {
        backgroundColor: "rgba(39, 220, 39, 0.3)",
        fontSize: 12,
        color: "black",
        padding: "0 3px",
    },
    incorrectHighlightedAnswer: {
        backgroundColor: "rgba(255, 0, 0, 0.3)",
        fontSize: 12,
        color: "black",
        padding: "0 3px",
    },
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: "Open Sans",
    },
    header: {
        height: 30,
        alignItems: "flex-end",
    },
    headerImage: {
        height: "80%",
        objectFit: "contain",
    },
    gradeImage: {
        width: 15,
        height: 15,
        margin: "0 auto",
    },
    teacherComment: {
        fontSize: 11,
        lineHeight: 1.5,
        textAlign: "center",
    },
    teacherCommentWrapper: {
        width: "100%",
        margin: "15px 0",
    },
    testNameWrapper: {
        margin: "15px 0",
        padding: "15px 20px",
        width: "100%",
        backgroundColor: "#f5f5f5",
        borderRadius: "7px",
        fontSize: 14,
        fontWeight: "bold",
    },
    overallCommentWrapper: {
        fontSize: 13,
        lineHeight: 1.5,
        color: "#666",
        marginBottom: 10,
    },
    boldCommentTitle: {
        fontWeight: "bold",
        marginBottom: 10,
    },
    summaryReviewWrapper: {
        backgroundColor: "#f5f5f5",
        padding: "15px",
        width: "50%",
        marginBottom: "15px",
        borderRadius: "7px",
        color: "#666",
        fontSize: 12,
    },
    summaryTitleItem: {
        flex: 2,
    },
    summaryValueItem: {
        flex: 1,
    },
    summaryTitle: {
        fontSize: 14,
        marginBottom: 15,
        fontWeight: 600,
        color: "#666",
    },
    summaryItem: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 15,
    },
    lastSummaryItem: {
        display: "flex",
        flexDirection: "row",
    },
});

Font.register({
    family: "Open Sans",
    fonts: [
        { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf" },
        { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf", fontWeight: 600 },
    ],
});

const StudentResultPDF: React.FC<StudentResultPDFProps> = (props) => {
    const { resultData, teacherTestData } = props;

    const t = useT();
    const t_question = t("Question", { _tags: "StudentResultPDF" });
    const t_answer = t("Answer", { _tags: "StudentResultPDF" });
    const t_exerciseNo = t("Exercise", { _tags: "StudentResultPDF" });
    const t_action = t("Actions", { _tags: "StudentResultPDF" });
    const t_noQuestion = t("<< no question >>", { _tags: "StudentResultPDF" });
    const t_noAnswer = t("<< empty answer >>", { _tags: "StudentResultPDF" });
    const t_words = t("Words:", { _tags: "StudentResultPDF" });
    const t_download = t("Download Results (PDF)", { _tags: "StudentResultPDF" });
    const t_resultsFor = t("Results for:", { _tags: "StudentResultPDF" });

    const t_studentOverallComment = t("Additional Note from your Teacher", { _tags: "StudentResultPDF" });
    const t_asCorrect = t("Marked as Correct.", { _tags: "StudentResultPDF" });
    const t_asIncorrect = t("Marked as Incorrect.", { _tags: "StudentResultPDF" });
    const t_comments = t("Added Comments", { _tags: "StudentResultPDF" });
    const t_summaryTitle = t("Summary of your Grading", { _tags: "StudentResultPDF" });

    function getTextAnswerTitle(key: string) {
        return (
            <View
                style={Styles.textAnswerTitles}
                key={key}
                minPresenceAhead={1}
            >
                <Text style={Styles.answerTitleBig}>{t_answer}</Text>
                <Text style={Styles.actionTitle}>{t_action}</Text>
            </View>
        );
    }

    function getBasicTitle(key: string) {
        return (
            <View
                style={Styles.basicAnswerTitles}
                key={key}
                minPresenceAhead={3}
            >
                <Text style={Styles.questionTitle}>{t_question}</Text>
                <Text style={Styles.answerTitle}>{t_answer}</Text>
                <Text style={Styles.actionTitle}>{t_action}</Text>
            </View>
        );
    }

    let renderPDFData = Object.keys(resultData.questionAnswers || [])
        .sort()
        .map((eId, i) => {
            const exerciseAnswer = resultData.questionAnswers![eId];

            let rowContent: Array<JSX.Element> = [];

            switch (exerciseAnswer.exerciseType) {
                case "textOnly":
                    let stdAnsws = exerciseAnswer.studentAnswers;
                    if (stdAnsws && stdAnsws[0]) {
                        let stdAnsw = stdAnsws[0];
                        rowContent.push(getTextAnswerTitle("title_" + eId));
                        rowContent.push(
                            <View
                                style={Styles.textAnswerRowWrapper}
                                key={"only_" + stdAnsw.id}
                                wrap={false}
                            >
                                <View style={Styles.textAnswerContent}>
                                    <Text>
                                        {stdAnsw.teacherGrade === "correct" ? (
                                            <Text style={Styles.correctHighlightedAnswer}>
                                                {stdAnsw.studentAnswer || t_noAnswer}
                                            </Text>
                                        ) : (
                                            <Text style={Styles.incorrectHighlightedAnswer}>
                                                {stdAnsw.studentAnswer || t_noAnswer}
                                            </Text>
                                        )}
                                    </Text>
                                </View>
                                <View style={Styles.actionsWrapper}>
                                    {stdAnsw.teacherGrade === "correct" ? (
                                        <Image
                                            style={Styles.gradeImage}
                                            src={correctPng}
                                        />
                                    ) : (
                                        <Image
                                            style={Styles.gradeImage}
                                            src={incorrectPng}
                                        />
                                    )}
                                    {(stdAnsw.teacherComment !== "" || stdAnsw.teacherIndComment !== "") && (
                                        <View style={Styles.teacherCommentWrapper}>
                                            {stdAnsw.teacherIndComment !== "" && (
                                                <Text style={Styles.teacherComment}>{stdAnsw.teacherIndComment}</Text>
                                            )}
                                            {stdAnsw.teacherComment !== "" && (
                                                <Text style={Styles.teacherComment}>{stdAnsw.teacherComment}</Text>
                                            )}
                                        </View>
                                    )}
                                </View>
                            </View>
                        );
                    }
                    break;
                case "jumbledWords":
                    let jumbledAnswers = exerciseAnswer.studentAnswers;
                    if (jumbledAnswers && jumbledAnswers[0]) {
                        let jumbledAnswer = jumbledAnswers[0];
                        rowContent.push(getTextAnswerTitle("title_" + eId));
                        rowContent.push(
                            <View
                                style={Styles.textAnswerRowWrapper}
                                key={"jumbl_" + jumbledAnswer.id}
                                wrap={false}
                            >
                                <View style={Styles.textAnswerContent}>
                                    <Text>
                                        {jumbledAnswer.teacherGrade === "correct" ? (
                                            <Text style={Styles.correctHighlightedAnswer}>
                                                {jumbledAnswer.studentAnswer || t_noAnswer}
                                            </Text>
                                        ) : (
                                            <Text style={Styles.incorrectHighlightedAnswer}>
                                                {jumbledAnswer.studentAnswer || t_noAnswer}
                                            </Text>
                                        )}
                                    </Text>
                                </View>
                                <View style={Styles.actionsWrapper}>
                                    {jumbledAnswer.teacherGrade === "correct" ? (
                                        <Image
                                            style={Styles.gradeImage}
                                            src={correctPng}
                                        />
                                    ) : (
                                        <Image
                                            style={Styles.gradeImage}
                                            src={incorrectPng}
                                        />
                                    )}
                                    {(jumbledAnswer.teacherComment !== "" ||
                                        jumbledAnswer.teacherIndComment !== "") && (
                                        <View style={Styles.teacherCommentWrapper}>
                                            {jumbledAnswer.teacherIndComment !== "" && (
                                                <Text style={Styles.teacherComment}>
                                                    {jumbledAnswer.teacherIndComment}
                                                </Text>
                                            )}
                                            {jumbledAnswer.teacherComment !== "" && (
                                                <Text style={Styles.teacherComment}>
                                                    {jumbledAnswer.teacherComment}
                                                </Text>
                                            )}
                                        </View>
                                    )}
                                </View>
                            </View>
                        );
                    }
                    break;
                default:
                    rowContent.push(getBasicTitle("title_" + eId));
                    exerciseAnswer.studentAnswers?.forEach((sa, index) => {
                        let QuestionTextComponent;
                        switch (exerciseAnswer.exerciseType) {
                            case "verbTraining":
                                QuestionTextComponent = (
                                    <Text style={Styles.baseQuestionContent}>
                                        {sa.verbName} ({sa.conjugationName})
                                    </Text>
                                );
                                break;
                            case "fillInTheGap":
                                QuestionTextComponent = (
                                    <Text style={Styles.baseQuestionContent}>
                                        [{sa.question}]{"\n"}
                                        {sa.gap_sentence}
                                    </Text>
                                );
                                break;
                            default:
                                QuestionTextComponent = (
                                    <Text style={Styles.baseQuestionContent}>
                                        {sa.answer ? sa.answer : sa.question || t_noQuestion}
                                    </Text>
                                );
                        }
                        rowContent.push(
                            <View
                                style={Styles.baseAnswerRowWrapper}
                                key={"row_" + sa.id + "_" + index}
                                wrap={false}
                            >
                                {QuestionTextComponent}
                                <View style={Styles.baseAnswerContent}>
                                    <Text>
                                        {sa.teacherGrade === "correct" ? (
                                            <Text style={Styles.correctHighlightedAnswer}>
                                                {sa.studentAnswer || t_noAnswer}
                                            </Text>
                                        ) : (
                                            <Text style={Styles.incorrectHighlightedAnswer}>
                                                {sa.studentAnswer || t_noAnswer}
                                            </Text>
                                        )}
                                    </Text>
                                </View>
                                <View style={Styles.actionsWrapper}>
                                    {sa.teacherGrade === "correct" ? (
                                        <Image
                                            style={Styles.gradeImage}
                                            src={correctPng}
                                        />
                                    ) : (
                                        <Image
                                            style={Styles.gradeImage}
                                            src={incorrectPng}
                                        />
                                    )}
                                    {(sa.teacherComment !== "" || sa.teacherIndComment !== "") && (
                                        <View style={Styles.teacherCommentWrapper}>
                                            {sa.teacherIndComment !== "" && (
                                                <Text style={Styles.teacherComment}>{sa.teacherIndComment}</Text>
                                            )}
                                            {sa.teacherComment !== "" && (
                                                <Text style={Styles.teacherComment}>{sa.teacherComment}</Text>
                                            )}
                                        </View>
                                    )}
                                </View>
                            </View>
                        );
                    });
            }

            let exerciseDesc = "";
            let exerciseStarterText = "";
            let exerciseExtraInfo: string = "";
            let exerciseNum: number = Number(eId.replace("e", ""));
            if (teacherTestData && teacherTestData.content && teacherTestData.content[exerciseNum - 1]) {
                exerciseDesc = teacherTestData.content[exerciseNum - 1].extraComments || "";

                if (
                    teacherTestData.content[exerciseNum - 1].questionMode === "jumbledWords" &&
                    teacherTestData.content[exerciseNum - 1].fillWords
                ) {
                    exerciseExtraInfo +=
                        t_words + " " + (teacherTestData.content[exerciseNum - 1].fillWords?.join(" || ") || "");
                }

                if (teacherTestData.content[exerciseNum - 1].textFieldStarterText) {
                    exerciseStarterText = teacherTestData.content[exerciseNum - 1].textFieldStarterText || "";
                }
            }

            return (
                <View
                    style={Styles.examContentRow}
                    key={eId}
                >
                    <View wrap={false}>
                        <Text style={Styles.exerciseQuestionTitle}>
                            {t_exerciseNo} {exerciseNum}
                        </Text>
                        <Text style={Styles.exerciseQuestionText}>
                            {exerciseDesc}
                            {exerciseExtraInfo && "\n"}
                            {exerciseExtraInfo && exerciseExtraInfo}
                            {exerciseStarterText && "\n"}
                            {exerciseStarterText && exerciseStarterText}
                        </Text>
                    </View>
                    <View>{rowContent}</View>
                </View>
            );
        });

    let [correctPerc, incorrectPerc, totalComments] = getPercentagesForStudentIndReview(resultData);

    return (
        <Wrapper>
            {resultData && resultData.questionAnswers && renderPDFData && (
                <BlobProvider
                    document={
                        <Document title={`ergebnis_${teacherTestData.name}_${moment().format("DD.MM.YYYY - HH:mm")}`}>
                            <Page style={Styles.page}>
                                <View
                                    style={Styles.header}
                                    fixed
                                >
                                    <Image
                                        style={Styles.headerImage}
                                        src={logo}
                                    />
                                </View>
                                <View style={Styles.testNameWrapper}>
                                    <Text>
                                        {t_resultsFor} {teacherTestData.name}
                                    </Text>
                                </View>
                                {renderPDFData}
                                <View wrap={false}>
                                    <Text style={Styles.summaryTitle}>{t_summaryTitle}</Text>
                                    <View
                                        style={Styles.summaryReviewWrapper}
                                        wrap={false}
                                    >
                                        <View style={Styles.summaryItem}>
                                            <Text style={Styles.summaryTitleItem}>{t_asCorrect}</Text>
                                            <Text style={Styles.summaryValueItem}>{correctPerc}%</Text>
                                        </View>
                                        <View style={Styles.summaryItem}>
                                            <Text style={Styles.summaryTitleItem}>{t_asIncorrect}</Text>
                                            <Text style={Styles.summaryValueItem}>{incorrectPerc}%</Text>
                                        </View>
                                        <View style={Styles.lastSummaryItem}>
                                            <Text style={Styles.summaryTitleItem}>{t_comments}</Text>
                                            <Text style={Styles.summaryValueItem}>{totalComments}</Text>
                                        </View>
                                    </View>
                                    {resultData.teacherComment !== "" && (
                                        <View style={Styles.overallCommentWrapper}>
                                            <Text style={Styles.boldCommentTitle}>{t_studentOverallComment}</Text>
                                            <Text>{resultData.teacherComment}</Text>
                                        </View>
                                    )}
                                </View>
                            </Page>
                        </Document>
                    }
                >
                    {({ url }) => (
                        <StyledDownloadLink
                            href={url || ""}
                            title={`ergebnis_${teacherTestData.name}_${moment().format("DD.MM.YYYY - HH:mm")}`}
                            target={"_blank"}
                        >
                            {t_download}
                        </StyledDownloadLink>
                    )}
                </BlobProvider>
            )}
        </Wrapper>
    );
};

export default StudentResultPDF;
