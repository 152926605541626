import styled from "styled-components/macro";
import ExerciseInputField from "../../../basic/exerciseInputField/ExerciseInputField";
import StyledButton from "../../../basic/styledButton/StyledButton";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import { keyframes } from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

export const CreateHomeWrapper = styled.div``;

export const CreateHomeHeader = styled.div`
    margin-bottom: ${DesignConstants.SPACES.L};
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const HomeHeaderCTAS = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        justify-content: space-around;
    }

    > button:only-child {
        margin-left: auto;
    }
`;

export const CreateHomeTitle = styled.h3`
    width: 100%;
    text-align: center;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    margin-bottom: 1rem;
`;

export const CreateTestBtn = styled(StyledButton)`
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.L} !important;
    align-self: flex-end;
`;

export const SortAndFilterWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
        align-items: flex-start;
    }

    input {
        border-radius: 50px;
    }
`;

export const FiltersWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: ${DesignConstants.SPACES.S};
    flex: 1;

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        pointer-events: none;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-bottom: ${DesignConstants.SPACES.M};
        width: 100%;
    }
`;
export const SortingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: ${DesignConstants.SPACES.S};
    flex: 1;

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        pointer-events: none;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 0.5rem;
        width: 100%;
    }
`;

const spinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`;

export const AnimatedNormalSizeIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    animation: ${spinAnimation} 1.5s infinite;
`;

export const FilterSortingSpan = styled.span`
    margin-right: ${DesignConstants.SPACES.XS};
`;

export const SelectSearchWrapper = styled.div`
    min-width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const NoTestsToShowText = styled.p`
    text-align: center;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    margin-top: ${DesignConstants.SPACES.XXXL};
`;

export const NotATeacherWrapper = styled.div`
    margin: ${DesignConstants.SPACES.S} auto;
    text-align: center;
    width: 60%;
`;

export const ContentWrapper = styled.div``;

export const NotATeacherDesc = styled.p`
    line-height: 1.5;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    font-weight: normal;
`;

export const BackButtonWrapper = styled.div`
    margin-bottom: ${DesignConstants.SPACES.S};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TestNameModalTextStrong = styled.p`
    font-weight: bold;
    margin-bottom: ${DesignConstants.SPACES.XS};
`;

export const TestNameModalNameDesc = styled.p``;

export const TestNameLabel = styled.label`
    cursor: pointer;
    margin-bottom: ${DesignConstants.SPACES.S};
    display: block;
`;

export const TestNameModalWrapper = styled.div`
    width: 100%;
    margin-bottom: 0;
`;

export const TestNameRequiredWarning = styled.span`
    color: ${DesignConstants.COLORS.RED};
    font-size: ${DesignConstants.FONT_SIZES.XS};
`;

export const StyledExerciseInputField = styled(ExerciseInputField)`
    margin-bottom: 0;

    > input {
        margin-bottom: ${DesignConstants.SPACES.XS};
    }
`;

export const V1TestWrapper = styled.div`
    display: flex;
    gap: 1rem;
    background: white;
    padding: ${DesignConstants.SPACES.S};
    border-radius: 0.5rem;
    margin-bottom: ${DesignConstants.SPACES.S};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.XS};
    }
`;

export const V1TestsWrapper = styled.div`
    margin: ${DesignConstants.SPACES.XL} auto 0;
    width: 95%;
`;

export const V1TestName = styled.p`
    flex: 1;
    word-break: break-word;
`;

export const V1TestsTitle = styled.h4`
    margin: 0 0 ${DesignConstants.SPACES.S};
`;

export const V1TestCTAs = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        max-width: 40%;
        justify-content: space-around;
        flex-direction: column;
        text-align: center;
    }
`;

export const V1TestCTAWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 0 ${DesignConstants.SPACES.XXXL} 0;
`;

export const SmallIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;

export const ActionName = styled.span`
    font-size: ${DesignConstants.FONT_SIZES.M};
`;

export const BackLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
`;
