// LIBRARIES
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { useT } from "@transifex/react";
import { cp } from "../../config";

export interface UnauthorizedProps {}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const WarningWrapper = styled.div`
    height: 50vw;
    width: 50vw;
    padding: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
`;

const WarningTextTitle = styled.h2``;
const WarningText = styled.h3``;

const Unauthorized: React.FC<UnauthorizedProps> = (props) => {
    const t = useT();
    const t_unauthorizedTitle = t("Auth Error", { _tags: "Unauthorized" });
    const t_unauthorizedDesc = t("Your session time has run out or you're trying to access a test that is not yours.", {
        _tags: "Unauthorized",
    });
    const t_unauthorized = t("We are gonna redirect you to the p6 website so you can login again and come back!", {
        _tags: "Unauthorized",
    });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            window.location.href = `${cp.cfg.REACT_APP_AUTH_REDIRECT}`;
        }, 8000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <Wrapper>
            <WarningWrapper>
                <WarningTextTitle>{t_unauthorizedTitle}</WarningTextTitle>
                <WarningText>{t_unauthorizedDesc}</WarningText>
                <WarningText>{t_unauthorized}</WarningText>
            </WarningWrapper>
        </Wrapper>
    );
};

export default Unauthorized;
