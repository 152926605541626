import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import { StyledPhase6Icon } from "../../directionSelector/StyledComponents";

export const TaskSuggestionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
    margin-bottom: ${DesignConstants.SPACES.S};
`;

export const MoreSuggestionsWrapper = styled.div`
    display: flex;
    align-self: start;
    white-space: nowrap;
    padding: ${DesignConstants.SPACES.XXS} 0;
    border: ${DesignConstants.BORDERS.THIN} transparent; // used to have the same height as the TaskSuggestionSentence text
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const TaskSuggestionSentenceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
    flex-wrap: wrap;
`;

export const TaskSuggestionSentence = styled.div`
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.S};
    cursor: pointer;
    font-size: ${DesignConstants.FONT_SIZES.L};

    &:hover {
        background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    }
`;

export const EditSuggestionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
    border: ${DesignConstants.BORDERS.THIN} ${DesignConstants.COLORS.LIGHT_GREY};
    border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.S};
    cursor: pointer;

    &:hover {
        background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    }
`;

export const EllipsisText = styled.div``;

export const StyledIcon = styled(StyledPhase6Icon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;
