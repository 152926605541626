import React, { useEffect, useState } from "react";
import { StudentReviewContext, StudentReviewDataLoadedStatus } from "p6m-contexts";
import { StudentTestAnswer, StudentTestContent, TestResultShareData } from "p6m-p6u";
import { getRequest } from "../helpers/networkHelper";

const StudentReviewTestContext = React.createContext<StudentReviewContext | undefined>(undefined);

function useStudentReviewContext() {
    const context = React.useContext(StudentReviewTestContext);

    if (!context) {
        throw new Error("useTestReviewContext must be used inside the provider");
    }

    return {
        ...context,
    };
}

function StudentReviewTestContextProvider(props: any) {
    // If dataLoaded is false and shareId exist, it means the code doesnt exists.
    const [shareId, setShareId] = useState("");
    const [testId, setTestId] = useState("");
    const [resultId, setResultId] = useState("");
    const [linkExpDate, setLinkExpDate] = useState<number>();
    const [dataLoaded, setDataLoaded] = useState<StudentReviewDataLoadedStatus>("START");
    const [resultData, setResultData] = useState<StudentTestAnswer>({});
    const [teacherTestData, setTeacherTestData] = useState<StudentTestContent>({});

    useEffect(() => {
        async function loadResultData(resultId: string, testId: string) {
            try {
                const gradesData = await getRequest(`aea/student-tests/${testId}/results/${resultId}/`);
                if (gradesData.data) {
                    setResultData({ ...gradesData.data });

                    const teacherTestContent = await getRequest(`aea/student-tests/${testId}/`);
                    if (teacherTestContent.data) {
                        let retrievedTestData: StudentTestContent = { ...teacherTestContent.data, id: testId };
                        setTeacherTestData(retrievedTestData);
                    }
                }
            } catch (e) {
                console.log("Error loading data ", e);
            }
        }
        if (resultId && testId) {
            loadResultData(resultId, testId);
        }
    }, [resultId, testId]);

    useEffect(() => {
        async function loadFeedbackData(shareId: string) {
            try {
                const feedbackData = await getRequest(`aea/feedbacks/${shareId}/`);
                if (feedbackData.data) {
                    const sharedIdData: TestResultShareData = { ...feedbackData.data };
                    setTestId(sharedIdData.testId || "");
                    setResultId(sharedIdData.resultId || "");
                    setDataLoaded("LOADED");
                    setLinkExpDate(sharedIdData.expirationDate || 0);
                } else {
                    setDataLoaded("NOT_FOUND");
                }
            } catch (e) {
                setDataLoaded("NOT_FOUND");
                console.log("Error loading feedbackdata", e);
            }
        }
        if (shareId) {
            loadFeedbackData(shareId);
        }
    }, [shareId]);

    const value = {
        shareId,
        setShareId,
        testId,
        setTestId,
        resultId,
        setResultId,
        dataLoaded,
        setDataLoaded,
        resultData,
        setResultData,
        teacherTestData,
        setTeacherTestData,
        linkExpDate,
        setLinkExpDate,
    };

    return (
        <StudentReviewTestContext.Provider
            value={value}
            {...props}
        />
    );
}

export { StudentReviewTestContextProvider, useStudentReviewContext };
