import axios, { AxiosInstance } from "axios";
import openCmsApiAuthTokenInterceptor from "./networking/interceptors/apiAuthInterceptor";

class ConfigProvider {
    private _cfg: any = undefined;
    private _openCMSAxios?: AxiosInstance;
    private _pxpAxios?: AxiosInstance;

    get cfg() {
        return this._cfg;
    }

    set cfg(v: any) {
        this._cfg = v;

        if (!this._cfg) {
            throw new Error("No configuration provided!");
        }

        this._openCMSAxios = axios.create({
            baseURL: this._cfg.REACT_APP_API_BASE_PATH,
            withCredentials: true,
        });

        this._openCMSAxios.interceptors.request.use(openCmsApiAuthTokenInterceptor);

        this._pxpAxios = axios.create({
            baseURL: this._cfg.REACT_APP_BACKEND_API_URL,
        });
    }

    get openCMSAxios(): AxiosInstance {
        if (!this._openCMSAxios) {
            throw new Error("openCMSAxios is not initialized!");
        }

        return this._openCMSAxios;
    }

    get pxpAxios(): AxiosInstance {
        if (!this._pxpAxios) {
            throw new Error("pxpAxios is not initialized!");
        }

        return this._pxpAxios;
    }
}

/**
 * @returns Config provider, including externalized configuration, configured  axios instances.
 */
export const cp = new ConfigProvider();
