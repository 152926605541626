import styled from "styled-components";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../constants/DesignConstants";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.XS};
    padding: 0 ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.S};
`;

export const DirectionSelectorWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
`;

export const DirectionSelectorHeader = styled.p`
    pointer-events: none;
    user-select: none;
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    flex-shrink: 0;
    text-align: bottom;
`;

export const DirectionAndEyeButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ExerciseWarningWrapper = styled.div`
    display: flex;
    gap: ${DesignConstants.SPACES.XS};
    color: ${DesignConstants.COLORS.DARK_GREY};
`;

export const ExerciseInfoText = styled.p`
    display: flex;
    align-items: center;
    font-size: ${DesignConstants.FONT_SIZES.L};
    line-height: ${DesignConstants.FONT_SIZES.XL};
`;

export const InfoIcon = styled(PhaseSixIcon)`
    display: flex;
    white-space: nowrap;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    line-height: ${DesignConstants.FONT_SIZES.XL};
`;
