// LIBRARIES
import React from "react";

import {
    WarningIconWrapper,
    StyledP6Icon,
    BookAvailabilityInfoTitle,
    BookAvailabilityInfoDescWrapper,
    BookAvailabilityInfoDesc,
    AdditionalInfoItem,
} from "./StyledComponents";
import StyledButton from "../styledButton/StyledButton";

export interface InformationBannerProps {
    iconName: string;
    title?: string;
    isAnonymous: boolean;
    anonymousText: string;
    registeredText: string;
    showButton: boolean;
    onButtonPress: () => any;
    buttonText: string;
}

const InformationBanner: React.FC<InformationBannerProps> = (props: InformationBannerProps) => {
    return (
        <AdditionalInfoItem>
            <WarningIconWrapper>
                <StyledP6Icon name={props.iconName} />
            </WarningIconWrapper>
            <BookAvailabilityInfoDescWrapper>
                {props.title ? <BookAvailabilityInfoTitle>{props.title}</BookAvailabilityInfoTitle> : <div />}
                <BookAvailabilityInfoDesc className={"bigger-font"}>
                    {props.isAnonymous ? props.anonymousText : props.registeredText}
                </BookAvailabilityInfoDesc>
            </BookAvailabilityInfoDescWrapper>
            {props.showButton && (
                <StyledButton
                    arrowDirection={"NONE"}
                    onClick={props.onButtonPress}
                >
                    {props.buttonText}
                </StyledButton>
            )}
        </AdditionalInfoItem>
    );
};

export default InformationBanner;
