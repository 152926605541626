import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";

export const Wrapper = styled.div`
    padding: ${DesignConstants.SPACES.XXS} ${DesignConstants.SPACES.M} ${DesignConstants.SPACES.M}
        ${DesignConstants.SPACES.M};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    background-color: white;
`;

export const Line = styled.div`
    height: ${DesignConstants.SPACES.M};
    border-bottom: ${DesignConstants.BORDERS.THIN};
    border-color: ${DesignConstants.COLORS.MID_GREY};
`;
