import { useT } from "@transifex/react";
import React, { useState } from "react";
import { useTestCreationContext } from "../../../../context/TestCreationContext";
import { Exercises, ExerciseTypes, TextInputExercises } from "../../../../enums/exercises";
import EyeExplanation from "../exerciseInfo/eyeExplanation/EyeExplanation";
import DirectionSelector from "./directionSelector/DirectionSelector";
import ExerciseTask from "./exerciseTask/ExerciseTask";
import SelectInputExerciseType from "./selectInputExerciseType/SelectInputExerciseType";

import {
    DirectionAndEyeButtonWrapper,
    DirectionSelectorHeader,
    DirectionSelectorWrapper,
    ExerciseInfoText,
    ExerciseWarningWrapper,
    InfoIcon,
    Wrapper,
} from "./StyledComponents";

export interface ExerciseInfoProps {
    hasWords?: boolean;
}

const ExerciseInfo: React.FC<ExerciseInfoProps> = (props) => {
    const { currentExerciseModel } = useTestCreationContext();

    const t = useT();
    const t_question_direction = t("Question Direction", {
        _tags: "CreateTest,CreateOrEditExercise",
    });

    const t_connectWordsWarning = t(
        "The word pairs are positioned randomly and will appear differently for each user. (See preview)",
        { _tags: "CreateTest,CreateOrEditExercise" }
    );

    const [showExpandedEyeExplanation, setShowExpandedEyeExplanation] = useState(
        localStorage.getItem("eyeExplanation") !== "small"
    );

    const showDirectionSelector =
        props.hasWords &&
        (currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.STANDARD) ||
            currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.WRITE_SENTENCE) ||
            currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.CREATE_TEXT) ||
            currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP));

    const showSelectExerciseType = TextInputExercises.includes(currentExerciseModel.questionMode ?? "");

    const renderConnectWordsInfo = () => (
        <ExerciseWarningWrapper>
            <InfoIcon name={"info"} />
            <ExerciseInfoText>{t_connectWordsWarning}</ExerciseInfoText>
        </ExerciseWarningWrapper>
    );

    const renderDirectionSelector = () => (
        <DirectionSelectorWrapper>
            <DirectionSelectorHeader>{t_question_direction + ":"}</DirectionSelectorHeader>
            <DirectionSelector />
        </DirectionSelectorWrapper>
    );

    return (
        <Wrapper>
            {showSelectExerciseType && <SelectInputExerciseType />}
            <ExerciseTask showSelectExerciseType={showSelectExerciseType} />
            <DirectionAndEyeButtonWrapper>
                {showDirectionSelector && renderDirectionSelector()}
                {!showExpandedEyeExplanation &&
                    props.hasWords &&
                    currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.STANDARD) && (
                        <EyeExplanation
                            showExpandedEyeExplanation={showExpandedEyeExplanation}
                            setShowExpandedEyeExplanation={setShowExpandedEyeExplanation}
                        />
                    )}
            </DirectionAndEyeButtonWrapper>
            {showExpandedEyeExplanation &&
                props.hasWords &&
                currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.STANDARD) && (
                    <EyeExplanation
                        showExpandedEyeExplanation={showExpandedEyeExplanation}
                        setShowExpandedEyeExplanation={setShowExpandedEyeExplanation}
                    />
                )}
            {currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.CONNECT_WORDS) &&
                renderConnectWordsInfo()}
        </Wrapper>
    );
};

export default ExerciseInfo;
