import styled from "styled-components";
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import StyledButtonWithIcon from "../../styledButtonWithIcon/StyledButtonWithIcon";
import StyledButton from "../../styledButton/StyledButton";
import { Styles } from "react-modal";
import DesignConstants from "../../../constants/DesignConstants";

export const ModalHeaderActions = styled.div`
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${DesignConstants.SPACES.S} 0;
`;

export const StyledHeaderActionsIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
`;

export const CloseIconWrapper = styled.div`
    cursor: pointer;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
`;

export const CTASWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContinueBtn = styled(StyledButtonWithIcon)`
    font-weight: ${DesignConstants.FONT_WEIGHTS.BOLD};
    width: 100%;
`;

export const CancelBtn = styled(StyledButton)`
    width: 100%;
`;

export const ModalTitle = styled.h4`
    margin: 0 0 0 ${DesignConstants.SPACES.L};
    text-align: center;
    flex: 1;
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const ExercisesNameContainer = styled.div`
    min-height: 8rem;
    background: white;
    margin: ${DesignConstants.SPACES.S} 0 ${DesignConstants.SPACES.S};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    padding: ${DesignConstants.SPACES.S};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const DeleteCardWarningText = styled.div`
    width: 90%;
    text-align: center;
    margin: ${DesignConstants.SPACES.S} 0;
    font-size: ${DesignConstants.FONT_SIZES.M};
`;

export const modalStylesCompact: Styles = {
    overlay: {
        backgroundColor: DesignConstants.COLORS.TRANSPARENT_WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 11,
    },
    content: {
        position: "unset",
        margin: DesignConstants.SPACES.S,
        border: "none",
        borderRadius: DesignConstants.BORDER_RADIUS.SMALL_CONTAINER,
        padding: DesignConstants.SPACES.S,
        maxWidth: "600px",
        width: "350px",
        background: DesignConstants.COLORS.BACKGROUND_LIGHT,
    },
};
