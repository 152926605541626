import styled from "styled-components";
import DesignConstants from "../../../constants/DesignConstants";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${DesignConstants.SPACES.S} 0;
    position: fixed;
    bottom: 0;
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    width: calc(100vw - ${DesignConstants.SPACES.L});
    max-width: calc(${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px - ${DesignConstants.SPACES.L});
    margin: auto;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        gap: ${DesignConstants.SPACES.S};
        flex-wrap: wrap;
        width: calc(100% - ${DesignConstants.SPACES.L});

        > button {
            width: 100%;
        }
    }
`;
