// LIBRARIES
import React from "react";
import styled, { css } from "styled-components/macro";
import { Grade } from "p6m-p6u";
import { ReactComponent as CorrectIcon } from "../../assets/icons/exercise-ok.svg";
import { ReactComponent as IncorrectIcon } from "../../assets/icons/exercise-failed.svg";
import SvgIcon from "../../basic/svgIcon/SvgIcon";

export interface StudentReviewGradeDisplayProps {
    grade?: Grade;
    teacherIndComment?: string;
    teacherComment?: string;
}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
`;

const AnswerIconBaseCss = css`
    border-radius: 5px;
    text-align: center;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 3px;
`;

const CorrectAnswerIconWrapper = styled.div`
    ${AnswerIconBaseCss};
    border: solid 1px green;
    color: green;

    &.checked {
        background: green;
        color: white;
    }
`;
const IncorrectAnswerIconWrapper = styled.div`
    ${AnswerIconBaseCss};
    border: solid 1px red;
    color: red;

    &.checked {
        background: red;
        color: white;
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
`;

const CommentWrapper = styled.div`
    margin-top: 1rem;
    width: 100%;
`;

const CommentTextArea = styled.textarea`
    width: 100%;
    height: 4rem;
    border-radius: 5px;

    &[readonly] {
        background: #cccc;
    }
`;

const StudentReviewGradeDisplay: React.FC<StudentReviewGradeDisplayProps> = (props) => {
    const { grade, teacherComment, teacherIndComment } = props;

    return (
        <Wrapper>
            <ActionsWrapper>
                {grade === "correct" && (
                    <CorrectAnswerIconWrapper className={"checked"}>
                        <SvgIcon
                            svgComponent={CorrectIcon}
                            color={"white"}
                        />
                    </CorrectAnswerIconWrapper>
                )}
                {grade === "incorrect" && (
                    <IncorrectAnswerIconWrapper className={"checked"}>
                        <SvgIcon
                            svgComponent={IncorrectIcon}
                            color={"white"}
                        />
                    </IncorrectAnswerIconWrapper>
                )}
            </ActionsWrapper>
            {teacherIndComment && (
                <CommentWrapper>
                    <CommentTextArea
                        defaultValue={teacherIndComment}
                        readOnly
                    />
                </CommentWrapper>
            )}
            {teacherComment && (
                <CommentWrapper>
                    <CommentTextArea
                        defaultValue={teacherComment}
                        readOnly
                    />
                </CommentWrapper>
            )}
        </Wrapper>
    );
};

export default StudentReviewGradeDisplay;
