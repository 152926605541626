import styled from "styled-components";
import DesignConstants from "../../../../../../constants/DesignConstants";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";

export const WriteSentenceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${DesignConstants.SPACES.S};
`;

export const ItemWrapper = styled.div`
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.M} ${DesignConstants.SPACES.M}
        ${DesignConstants.SPACES.M};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    background-color: white;
`;

export const LinesContainer = styled.div`
    > div {
        padding: 0;
        border-radius: 0;
    }
`;

export const WordAndIconWrapper = styled.div`
    height: ${DesignConstants.SPACES.M};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${DesignConstants.FONT_SIZES.L};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const Word = styled.p``;

export const RemoveIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    cursor: pointer;
`;
