import * as React from "react";
import styled from "styled-components/macro";
import { InputHTMLAttributes } from "react";

export interface ExerciseInputFieldProps {
    className?: string;
    type?: "text" | "number";
}

const InputWrapper = styled.div`
    flex: 4;
    background: white;
    width: 100%;
    margin: 10px 0;
    border: solid 1px #a6a6a6;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #a6a6a6;
    transition: all 0.15s ease-in-out 0s;
    padding: 0.5rem 0.5rem 0.5rem 0rem;

    // &:hover{
    //   background:${(props) => props.theme.base.highlightAreaColor}
    // }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 5px 5px 5px 0;
        margin: 5px 0;
    }
`;

const InputField = styled.input`
    width: 100%;
    border: medium none;
    margin: 0.5rem 0;
    padding: 0 0 0 10px;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    box-shadow: none;
    display: inline;
    height: auto;
    font-size: 1rem;
    color: #555555;
    transition: all 0.15s ease-in-out 0s;
    border-radius: 0.5rem;

    &:focus {
        outline: none;
    }
`;

const ExerciseInputField = React.forwardRef<
    HTMLInputElement,
    ExerciseInputFieldProps & InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
    const fieldProps: InputHTMLAttributes<HTMLInputElement> = props;

    return (
        <InputWrapper className={props.className ? props.className : ""}>
            <InputField
                {...fieldProps}
                type={props.type || "text"}
                ref={ref}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
            />
        </InputWrapper>
    );
});

export default ExerciseInputField;
