import { Styles } from "react-modal";
import styled from "styled-components/macro";
import { PrimaryButton } from "p6-react-shared";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";
import FlagIcon from "../../../basic/flagIcon/FlagIcon";
import MainBackgroundSquircle from "../../../basic/mainBackgroundSquircle/MainBackgroundSquircle";
import DesignConstants from "../../../constants/DesignConstants";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
`;

export const InfoHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    height: 2.5rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        top: 0;
        position: fixed;
        background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
        padding: 0 0.5rem;
    }
`;

export const MiddleContentWrapper = styled.div`
    width: 90%;
    display: flex;
    flex: 1;
    overflow: hidden;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        justify-content: center;
        margin-top: 1.6rem;
    }
`;

export const StyledFlagIcon = styled(FlagIcon)``;

export const AppInfoModalContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 70vh;
    padding: 1rem;

    > * {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ExerciseIndicatorContainer = styled.div`
    cursor: pointer;
    padding-left: 24px;
    flex: 1;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    > p {
        width: auto;
        margin: 0 0.5rem;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        text-align: left;
        padding: 0;
    }
`;

export const CurrentExercise = styled.span`
    font-size: 1.5rem;
`;

export const TotalExercises = styled.span``;

export const OpenModalIcon = styled(SvgIcon)`
    width: 1.5rem;
    transform: rotate(90deg);
    margin: 0;
    vertical-align: middle;
`;

export const OverviewIcon = styled(SvgIcon)`
    width: 1.6rem;
    vertical-align: middle;
`;

export const OverviewCTAContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.5rem;
    width: 90%;
    justify-content: center;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        justify-content: center;
    }
`;

export const OverviewButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: auto;
    margin: 0 2rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin: 0 1.5rem;
    }
`;

export const OverviewText = styled.p`
    text-transform: uppercase;
`;

export const SendNowButton = styled(PrimaryButton)`
    width: 100%;
    box-shadow: 0 5px 0 rgb(0 0 0 / 10%);
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: normal;
    padding: 0.5rem 0;

    > span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
`;

export const SendNowIcon = styled(SvgIcon)`
    width: 2rem;
    margin-left: 0.5rem;
`;

export const NoTimerDiv = styled.div`
    flex: 1;
`;

export const OverviewTitle = styled.p`
    font-size: 1.2rem;
`;

export const InfoSubtitle = styled.p`
    text-align: center;
    font-size: 1.1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        color: inherit;
        font-size: 1.3rem;
    }
`;

export const modalStyles: Styles = {
    overlay: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        paddingTop: "3rem",
        backgroundColor: "transparent",
    },
    content: {
        position: "unset",
        borderRadius: "1rem",
        maxWidth: "800px",
        minWidth: "250px",
        boxShadow: "rgb(0 0 0 / 10%) 1px 4px 0px",
        padding: 0,
    },
};

export const ScrollWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding-right: 1rem;
    min-height: 70px;
    position: absolute;
    bottom: 0;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 1.3rem;
`;

export const Squircle = styled(MainBackgroundSquircle)`
    position: relative;
`;

export const EmptyButtonPlaceholder = styled.div`
    width: 2rem;
`;
