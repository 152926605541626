import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import { useHistory } from "react-router-dom";
import { useT } from "@transifex/react/dist";
import CancelCreateExerciseModal from "../../../basic/CancelCreateExerciseModal/CancelCreateExerciseModal";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import CloseButton from "../../../basic/closeButton/CloseButton";

import { TestCreationHeaderWrapper } from "./StyledComponents";

export interface TestCreationHeaderProps {}

const TestCreationHeader: React.FC<TestCreationHeaderProps> = (props) => {
    const [CancelCreateExerciseModalIsOpen, setCancelCreateExerciseModalIsOpen] = useState(false);
    const history = useHistory();
    const { resetAppStatus, isTestSynced, loadUserTests } = useTestCreationContext();
    const location = useLocation();
    const { userInfo } = useGeneralContext();

    const { creatingMode } = useGeneralContext();
    const t = useT();
    const t_cancelText = t("Cancel", { _tags: "CreateTest,CreateInfoModal" });

    if (
        creatingMode === "RECURRING_TASK" ||
        location.pathname === "/create" ||
        location.pathname.includes("/create/preview") ||
        location.pathname.includes("/create/rt-")
    ) {
        if (creatingMode === "RECURRING_TASK" || location.pathname.includes("/create/rt-")) {
            document.title = "Vokabeln aufgeben";
        }

        return null;
    }

    function openCancelModal() {
        setCancelCreateExerciseModalIsOpen(true);
    }
    function navigateToTestsOverview() {
        loadUserTests(true);
        history.push("/create");
        resetAppStatus();
    }

    function closeExerciseSheet() {
        if (isTestSynced) {
            navigateToTestsOverview();
        } else {
            openCancelModal();
        }
    }

    return !userInfo.isAnonymousUser ? (
        <TestCreationHeaderWrapper>
            <CloseButton
                onClick={closeExerciseSheet}
                hintText={t_cancelText}
            />
            <CancelCreateExerciseModal
                isOpen={CancelCreateExerciseModalIsOpen}
                closeModalCallback={() => {
                    setCancelCreateExerciseModalIsOpen(false);
                }}
                continueCallback={navigateToTestsOverview}
            />
        </TestCreationHeaderWrapper>
    ) : (
        <></>
    );
};

export default TestCreationHeader;
