import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import DesignConstants from "../../../constants/DesignConstants";

interface PDFExerciseStarterTextProps {
    starterText: string;
    smallerMarginBottom?: boolean;
}

const PDFExerciseStarterText: React.FC<PDFExerciseStarterTextProps> = (props) => {
    return (
        <View
            style={[
                styles.container,
                { marginBottom: props.smallerMarginBottom ? DesignConstants.PDF.GAP_XXS : DesignConstants.PDF.GAP_XS },
            ]}
        >
            <Text style={styles.text}>{props.starterText}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        paddingHorizontal: DesignConstants.PDF.GAP_S,
    },
    text: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
    },
});

export default PDFExerciseStarterText;
