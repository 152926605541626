import * as React from "react";
import styled from "styled-components/macro";

import Flag_ag from "../../assets/subject-covers/uc_ag.jpg";
import Flag_ar from "../../assets/subject-covers/uc_ar.jpg";
import Flag_bg from "../../assets/subject-covers/uc_bg.jpg";
import Flag_bp from "../../assets/subject-covers/uc_bp.jpg";
import Flag_cs from "../../assets/subject-covers/uc_cs.jpg";
import Flag_da from "../../assets/subject-covers/uc_da.jpg";
import Flag_de from "../../assets/subject-covers/uc_de.jpg";
import Flag_el from "../../assets/subject-covers/uc_el.jpg";
import Flag_en from "../../assets/subject-covers/uc_en.jpg";
import Flag_es from "../../assets/subject-covers/uc_es.jpg";
import Flag_et from "../../assets/subject-covers/uc_et.jpg";
import Flag_fa from "../../assets/subject-covers/uc_fa.jpg";
import Flag_fi from "../../assets/subject-covers/uc_fi.jpg";
import Flag_fr from "../../assets/subject-covers/uc_fr.jpg";
import Flag_ga from "../../assets/subject-covers/uc_ga.jpg";
import Flag_hr from "../../assets/subject-covers/uc_hr.jpg";
import Flag_hu from "../../assets/subject-covers/uc_hu.jpg";
import Flag_it from "../../assets/subject-covers/uc_it.jpg";
import Flag_ja from "../../assets/subject-covers/uc_ja.jpg";
import Flag_ko from "../../assets/subject-covers/uc_ko.jpg";
import Flag_la from "../../assets/subject-covers/uc_la.jpg";
import Flag_lt from "../../assets/subject-covers/uc_lt.jpg";
import Flag_lv from "../../assets/subject-covers/uc_lv.jpg";
import Flag_mt from "../../assets/subject-covers/uc_mt.jpg";
import Flag_nl from "../../assets/subject-covers/uc_nl.jpg";
import Flag_no from "../../assets/subject-covers/uc_no.jpg";
import Flag_pl from "../../assets/subject-covers/uc_pl.jpg";
import Flag_ps from "../../assets/subject-covers/uc_ps.jpg";
import Flag_pt from "../../assets/subject-covers/uc_pt.jpg";
import Flag_ro from "../../assets/subject-covers/uc_ro.jpg";
import Flag_others from "../../assets/subject-covers/uc_others.jpg";
import Flag_ru from "../../assets/subject-covers/uc_ru.jpg";
import Flag_sk from "../../assets/subject-covers/uc_sk.jpg";
import Flag_sl from "../../assets/subject-covers/uc_sl.jpg";
import Flag_sv from "../../assets/subject-covers/uc_sv.jpg";
import Flag_ti from "../../assets/subject-covers/uc_ti.jpg";
import Flag_tr from "../../assets/subject-covers/uc_tr.jpg";
import Flag_uk from "../../assets/subject-covers/uc_uk.jpg";
import Flag_zh from "../../assets/subject-covers/uc_zh.jpg";
import { AvailableCovers } from "../../helpers/LanguageCountryName";

export interface UserContentCoverProps {
    primaryLanguage: string;
    secondaryLanguage?: string;
    className?: string;
    small?: boolean;
}

const StyledCoverWrapper = styled.div`
    &.small {
        height: 100px;
        width: 75px;
    }
`;

const StyledCover = styled.img`
    max-width: 100%;
    border-radius: 0.8rem;
`;

const UserContentCover: React.FC<UserContentCoverProps> = (props) => {
    let langName = props.primaryLanguage;
    let secLang = props.secondaryLanguage;
    let nameToUse = props.primaryLanguage;

    if (langName === "") {
        return null;
    }

    if (langName === "de" && secLang) {
        nameToUse = secLang;
    }

    if (!AvailableCovers.includes(nameToUse)) {
        nameToUse = "others";
    }

    let file;

    switch (nameToUse) {
        case "ag":
            file = Flag_ag;
            break;
        case "ar":
            file = Flag_ar;
            break;
        case "bg":
            file = Flag_bg;
            break;
        case "bp":
            file = Flag_bp;
            break;
        case "cs":
            file = Flag_cs;
            break;
        case "da":
            file = Flag_da;
            break;
        case "de":
            file = Flag_de;
            break;
        case "el":
            file = Flag_el;
            break;
        case "en":
            file = Flag_en;
            break;
        case "es":
            file = Flag_es;
            break;
        case "et":
            file = Flag_et;
            break;
        case "fa":
            file = Flag_fa;
            break;
        case "fi":
            file = Flag_fi;
            break;
        case "fr":
            file = Flag_fr;
            break;
        case "ga":
            file = Flag_ga;
            break;
        case "hr":
            file = Flag_hr;
            break;
        case "hu":
            file = Flag_hu;
            break;
        case "it":
            file = Flag_it;
            break;
        case "ja":
            file = Flag_ja;
            break;
        case "ko":
            file = Flag_ko;
            break;
        case "la":
            file = Flag_la;
            break;
        case "lt":
            file = Flag_lt;
            break;
        case "lv":
            file = Flag_lv;
            break;
        case "mt":
            file = Flag_mt;
            break;
        case "nl":
            file = Flag_nl;
            break;
        case "no":
            file = Flag_no;
            break;
        case "pl":
            file = Flag_pl;
            break;
        case "ps":
            file = Flag_ps;
            break;
        case "pt":
            file = Flag_pt;
            break;
        case "ro":
            file = Flag_ro;
            break;
        case "others":
            file = Flag_others;
            break;
        case "ru":
            file = Flag_ru;
            break;
        case "sk":
            file = Flag_sk;
            break;
        case "sl":
            file = Flag_sl;
            break;
        case "sv":
            file = Flag_sv;
            break;
        case "ti":
            file = Flag_ti;
            break;
        case "tr":
            file = Flag_tr;
            break;
        case "uk":
            file = Flag_uk;
            break;
        case "zh":
            file = Flag_zh;
            break;
    }

    return (
        <StyledCoverWrapper className={props.small ? "small" : ""}>
            <StyledCover src={file} />
        </StyledCoverWrapper>
    );
};

export default UserContentCover;
