import React, { useState } from "react";
import { SidebarSelectedWord, UnitCard, UserOwnCard } from "p6m-p6u";
import { sanitizeText } from "../../../../../../helpers/TestCreation";
import { useTestCreationContext } from "../../../../../../context/TestCreationContext";
import { Exercises, ExerciseTypes } from "../../../../../../enums/exercises";
import UsedInExerciseNumber from "../../../../../../basic/usedInExerciseNumber/UsedInExerciseNumber";
import useExerciseIdFinder from "../../../../../../hooks/useExerciseIdFinder";

import {
    AddIconWrapper,
    SelectExerciseVocabularyRowWord,
    SelectExerciseVocabularyRowWordContainer,
    SelectExerciseVocabularyRowWrapper,
    StyledAddIcon,
    Word,
    WordContainer,
    ContentWrapper,
    WordContentWrapper,
} from "./StyledComponents";

export interface SelectExerciseVocabularyRowProps {
    word: SidebarSelectedWord;
    addToQueue: () => void;
    disabled?: boolean;
    rowId: string;
    isRowAnimating: boolean;
}

const SelectExerciseVocabularyRow: React.FC<SelectExerciseVocabularyRowProps> = (props) => {
    const { currentExerciseModel, vocabularyDrawerContent } = useTestCreationContext();
    const { mapExerciseIdsToExercises } = useExerciseIdFinder();

    const [isHoveringRow, setIsHoveringRow] = useState(false);

    const cardId = props.word.wordContent?.id;
    const exerciseIds = vocabularyDrawerContent[cardId ?? 0]?.exerciseIds;

    const splitSentenceIntoWords = () => {
        const wordContent = props.word.wordContent as UnitCard | UserOwnCard;
        if (!wordContent || !("gap_sentence" in wordContent) || !wordContent.gap_sentence) return null;

        const wordToUnderline = ("answer_text" in wordContent && wordContent.answer_text) || "";
        const parts = wordContent.gap_sentence.split(/_{2,}/);

        return { wordToUnderline, parts };
    };

    const renderGapSentence = () => {
        const result = splitSentenceIntoWords();

        if (!result) return "";

        const { wordToUnderline, parts } = result;

        return parts.map((part, index) => (
            <React.Fragment key={index}>
                {part}

                {index < parts.length - 1 && (
                    <WordContainer>
                        <Word>{wordToUnderline}</Word>
                    </WordContainer>
                )}
            </React.Fragment>
        ));
    };

    const renderDefaultWordText = () => {
        if (!props.word.wordContent) return "";

        if ("answer_text" in props.word.wordContent) {
            return sanitizeText(props.word.wordContent.answer_text as string);
        }

        if ("answer" in props.word.wordContent) {
            return sanitizeText(props.word.wordContent.answer as string);
        }

        return "";
    };

    const renderWordContent = () => {
        if (currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP)) {
            return renderGapSentence();
        } else {
            return renderDefaultWordText();
        }
    };

    return (
        <SelectExerciseVocabularyRowWrapper
            id={props.rowId}
            onClick={() => !props.disabled && props.addToQueue()}
            className={props.disabled ? "disabled" : ""}
            isRowAnimating={props.isRowAnimating}
            onMouseEnter={() => setIsHoveringRow(true)}
            onMouseLeave={() => setIsHoveringRow(false)}
        >
            <AddIconWrapper className={props.disabled ? "disabled" : ""}>
                <StyledAddIcon name={"plus"} />
            </AddIconWrapper>

            <ContentWrapper>
                <WordContentWrapper>
                    <SelectExerciseVocabularyRowWordContainer>
                        <SelectExerciseVocabularyRowWord>{renderWordContent()}</SelectExerciseVocabularyRowWord>
                    </SelectExerciseVocabularyRowWordContainer>
                </WordContentWrapper>

                {exerciseIds && exerciseIds?.length !== 0 && (
                    <UsedInExerciseNumber
                        isHoveringRow={isHoveringRow}
                        numsOfUsedExercises={mapExerciseIdsToExercises(exerciseIds)}
                    />
                )}
            </ContentWrapper>
        </SelectExerciseVocabularyRowWrapper>
    );
};

export default SelectExerciseVocabularyRow;
