import React, { useState } from "react";
import { GeneralContext } from "p6m-contexts";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { generateUuid, getRequest, pxpDeleteRequest, pxpGetRequest, pxpPostRequest } from "../helpers/networkHelper";
import moment from "moment";
import axios, { AxiosResponse } from "axios";
import { UserInfo } from "p6m-p6u";
import { PXPJossoInfoResponse } from "p6m-aea-networking";
import { CookieSetOptions } from "universal-cookie/cjs/types";

const AppGeneralContext = React.createContext<GeneralContext | undefined>(undefined);

function useGeneralContext() {
    const context = React.useContext(AppGeneralContext);

    if (!context) {
        throw new Error("useGeneralContext must be used inside the provider");
    }

    let history = useHistory();
    const [cookies, setCookie] = useCookies(["JOSSO_SESSIONID"]);

    const {
        userId,
        setUserId,
        successfulAuth,
        setSuccessfulAuth,
        pxpAuthToken,
        clientId,
        setUserEmail,
        userEmail,
        setPxpAuthToken,
        setClientId,
        setJossoSessionId,
        setUserInfo,
    } = context;

    const setStateDataFromPXPJossoResponse = (pxpResponse: PXPJossoInfoResponse) => {
        const sessionToken = pxpResponse.replyContent?.p6pSessionToken;
        const userEmail = (pxpResponse.replyContent?.email || "").toLowerCase();
        const userId = pxpResponse.replyContent?.userDnsId;
        const jossoSessionId = pxpResponse.replyContent?.jossoSessionId;

        if (sessionToken && userEmail && userId && jossoSessionId) {
            setPxpAuthToken(sessionToken);
            setUserEmail(userEmail);
            setUserId(userId);
            setJossoSessionCookie(jossoSessionId);
        } else if (!sessionToken || !userId || !jossoSessionId) {
            throw new Error("No session nor user id info.");
        }
    };

    async function connectToPxp(params: { jossoSessionIdToken?: string; username?: string }) {
        if (cookies?.JOSSO_SESSIONID || params.jossoSessionIdToken) {
            setClientId(generateUuid());

            let reqHeaders: any = {
                "X-JAUTHTOKEN": params.jossoSessionIdToken || cookies.JOSSO_SESSIONID,
            };

            if (params.username) {
                reqHeaders["X-LBTOKEN"] = params.username;
            }

            const source1 = axios.CancelToken.source();
            const source2 = axios.CancelToken.source();
            try {
                const timeout = setTimeout(() => {
                    source1.cancel();
                    console.log("First request cancelled");
                    // Timeout Logic
                }, 5000);
                const pxpLoginResult: AxiosResponse<PXPJossoInfoResponse> = await pxpGetRequest(
                    "jossoInfo",
                    reqHeaders,
                    {},
                    { cancelToken: source1.token }
                );
                clearTimeout(timeout);
                setStateDataFromPXPJossoResponse(pxpLoginResult.data);
                return Promise.resolve(true);
            } catch (e) {
                // if (axios.isCancel(e)) {
                try {
                    const timeout2 = setTimeout(() => {
                        source2.cancel();
                        console.log("Second request cancelled");
                        // Timeout Logic
                    }, 5000);
                    const pxpLoginResult2 = await pxpGetRequest(
                        "jossoInfo",
                        reqHeaders,
                        {},
                        { cancelToken: source2.token }
                    );
                    clearTimeout(timeout2);
                    setStateDataFromPXPJossoResponse(pxpLoginResult2.data);
                    return Promise.resolve(true);
                } catch (e) {
                    return Promise.reject("Promise failed. It was either cancelled or did not have the correct data.");
                }
            }
        } else {
            return Promise.reject("no jossoinfo");
        }
    }

    const checkIsUserLoggedInInP6 = async () => {
        try {
            const getUserInfoResponse: AxiosResponse<UserInfo> = await getRequest("aea/user/user-info/");
            setUserInfo(getUserInfoResponse.data);
            getUserInfoResponse.data.userEmail && setUserEmail(getUserInfoResponse.data.userEmail);
            return Promise.resolve(getUserInfoResponse.data);
        } catch (e: any) {
            return Promise.reject(e.response.status);
        }
    };

    const loginUser = async (redirectTo?: string) => {
        try {
            const userInfo: UserInfo = await checkIsUserLoggedInInP6();
            await connectToPxp({ username: userInfo.userEmail });
            setSuccessfulAuth(true);
            history.push("/create" + (redirectTo ? "/" + redirectTo : ""));
        } catch (e) {
            history.push("/unauthorized");
        }
    };

    const setJossoSessionCookie = (jossoSessionId: string) => {
        const jossoSessionCookieVal = cookies.JOSSO_SESSIONID || "";
        if (jossoSessionId && jossoSessionId !== jossoSessionCookieVal) {
            setJossoSessionId(jossoSessionId);
            const cookieOptions: CookieSetOptions = {
                expires: moment().add(3, "months").toDate(),
            };
            setCookie("JOSSO_SESSIONID", jossoSessionId, cookieOptions);
        }
    };

    async function makePxpRequest(method: "GET" | "POST" | "DELETE", endpoint: string, params?: {}) {
        if (!userEmail || !pxpAuthToken) {
            return Promise.reject("Auth data needs to contain user email and auth token.");
        }
        const headers = {
            "X-CLIENTID": clientId || generateUuid(),
            "X-JAUTHTOKEN": pxpAuthToken,
            "X-LBTOKEN": userEmail,
        };

        if (method === "DELETE") {
            return pxpDeleteRequest(endpoint, headers);
        } else if (method === "GET") {
            return pxpGetRequest(endpoint, headers, params);
        } else {
            return pxpPostRequest(endpoint, headers, params);
        }
    }

    return {
        ...context,
        userId,
        setUserId,
        successfulAuth,
        setSuccessfulAuth,
        loginUser,
        makePxpRequest,
    };
}

function AppGeneralContextProvider(props: any) {
    const [userInfo, setUserInfo] = useState<UserInfo>({});

    const [userId, setUserId] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [successfulAuth, setSuccessfulAuth] = useState(false);
    const [pxpAuthToken, setPxpAuthToken] = useState("");

    const [isIframeMode, setIsIframeMode] = useState(false);

    const [clientId, setClientId] = useState("");
    const [jossoSessionId, setJossoSessionId] = useState("");
    const [isDataBeingLoaded, setIsDataBeingLoaded] = useState(false);
    const [showRedirectOldVTGPopup, setShowRedirectOldVTGPopup] = useState(false);
    const [creatingMode, setCreatingMode] = useState<"TEST" | "RECURRING_TASK" | "">("");

    const value = {
        userInfo,
        setUserInfo,
        userId,
        setUserId,
        successfulAuth,
        setSuccessfulAuth,
        clientId,
        setClientId,
        jossoSessionId,
        setJossoSessionId,
        isDataBeingLoaded,
        setIsDataBeingLoaded,
        isIframeMode,
        setIsIframeMode,
        showRedirectOldVTGPopup,
        setShowRedirectOldVTGPopup,
        creatingMode,
        setCreatingMode,
        userEmail,
        setUserEmail,
        pxpAuthToken,
        setPxpAuthToken,
    };

    return (
        <AppGeneralContext.Provider
            value={value}
            {...props}
        />
    );
}

export { AppGeneralContextProvider, useGeneralContext };
