import DesignConstants from "../../constants/DesignConstants";
import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const CancelButtonWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
`;

export const CancelTextWrapper = styled.div`
    font-size: ${DesignConstants.FONT_SIZES.L};
`;

export const CloseIconWrapper = styled.div`
    height: ${DesignConstants.FONT_SIZES.XXL};
    cursor: pointer;
`;

export const StyledHeaderActionsIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
    color: ${DesignConstants.COLORS.MID_GREY};
`;
