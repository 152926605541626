import * as React from "react";
import styled from "styled-components";

export interface SvgIconProps {
    svgComponent: React.FunctionComponent;
    color?: string;
    className?: string;
}

// The fill property is present twice, as there's some svgs from the project that have this class, thus
// overriding the initial fill value, making it necessary to have it in both places.
let ColoredIcon = styled(({ component, ...props }) => React.cloneElement(component, props))`
    fill: ${(props) => props.color};

    .cls-1,
    .skipCardColor {
        fill: ${(props) => props.color};
    }

    > * {
        fill: ${(props) => props.color};
    }
`;

const SvgIcon: React.FC<SvgIconProps> = (props) => {
    const Element = props.svgComponent;

    // if (props.hasOwnProperty('color')) {
    return (
        <ColoredIcon
            component={<Element />}
            color={props.color}
            className={props.className}
        />
    );
    // }

    // return <Element/>
};

export default SvgIcon;
