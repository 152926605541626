// LIBRARIES
import React from "react";
import styled from "styled-components";

export interface ExerciseDescriptionProps {
    exerciseTitle?: string;
}

const Wrapper = styled.div`
    width: 100%;
    text-align: center;
`;

const ExerciseTitle = styled.h3`
    text-align: center;
`;

// Creating this component as we are gonna add more stuff near the title in the future
const ExerciseDescription: React.FC<ExerciseDescriptionProps> = (props) => {
    return (
        <Wrapper>
            <ExerciseTitle>{props.exerciseTitle}</ExerciseTitle>
        </Wrapper>
    );
};

export default ExerciseDescription;
