import styled from "styled-components";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import { CoverImage } from "p6-react-shared";
import DesignConstants from "../../../constants/DesignConstants";

export const Wrapper = styled.div`
    margin-bottom: ${DesignConstants.SPACES.XXXL};
`;

export const MiddleContentWrapper = styled.div`
    flex: 1;
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        flex-direction: column;
    }
`;

export const SelectsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: ${DesignConstants.SPACES.XS};
    margin-top: ${DesignConstants.SPACES.XS};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        flex-direction: column;
        gap: ${DesignConstants.SPACES.XXS};
    }

    input {
        border-radius: ${DesignConstants.BORDER_RADIUS.BUTTON};
        color: ${DesignConstants.COLORS.LIGHTEST_GREY};
    }
`;

export const SmallSelectWrapper = styled.div`
    flex: 1;

    input {
        color: black;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

export const BookSearchSelectWrapper = styled.div`
    flex: 2;

    input {
        color: black;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

export const SelectedContentWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 80px);

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        max-height: 100%;
        height: auto;
    }
`;

export const BookInfoWrapper = styled.div`
    display: flex;
    background: white;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-height: 100%;
    height: fit-content;
    margin-top: ${DesignConstants.SPACES.S};

    > button:last-child {
        min-width: 30%;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        gap: ${DesignConstants.SPACES.S};
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
`;

export const SelectBookBtn = styled(StyledButtonWithIcon)`
    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex: 1;
    }
`;

export const BookImageWrapper = styled.div`
    width: auto;
    margin-right: ${DesignConstants.SPACES.S};
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const BookNameWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
`;

export const StyledCoverImage = styled(CoverImage)`
    width: unset !important;
    height: 150px !important;
`;

export const TestToText = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.M};
`;

export const BookName = styled.h4`
    font-weight: bold;
    margin: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S} 0 0;
`;

export const AdditionalInfoWrapper = styled.div`
    margin-top: ${DesignConstants.SPACES.M};
    display: flex;
    flex-direction: column;
    text-align: center;
    bottom: ${DesignConstants.SPACES.XXXL};
    max-width: calc(${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px - ${DesignConstants.SPACES.L});
    width: 100%;
    gap: ${DesignConstants.SPACES.XS};
    z-index: 1;

    &.no-bottom {
        bottom: unset;
    }

    &.bottom-zero {
        bottom: ${DesignConstants.SPACES.S};
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S}
            ${DesignConstants.SPACES.XS};
    }
`;
