// LIBRARIES
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useT, UT } from "@transifex/react";
import ArrowIcon from "../../../basic/arrowIcon/ArrowIcon";
import { useTestReviewContext } from "../../../context/TestReviewContext";
import TestReviewInfoHeader from "../../../basic/testReviewInfoHeader/TestReviewInfoHeader";
import { css } from "styled-components";
import { useHistory } from "react-router-dom";
import exportFromJSON from "export-from-json";
import SvgIcon from "../../../basic/svgIcon/SvgIcon";
import { ReactComponent as CorrectIcon } from "../../../assets/icons/exercise-ok.svg";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import ReactTooltip from "react-tooltip";
import copy from "copy-to-clipboard";
import InfoModal from "../../../basic/infoModal/InfoModal";
import { utils, writeFile } from "xlsx";
import { logEventWithProps } from "../../../logging/Logger";
import StyledButton from "../../../basic/styledButton/StyledButton";
import { StudentTestAnswer } from "p6m-p6u";
import { saveStudentTestResult } from "../../../networking/tests";

export interface ReviewTestProps {}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    padding-bottom: 5rem;
`;

const ShareTestInfoBox = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    margin: 1rem 0 2rem 0;
    background: #f5f5f5;
    border-radius: 0.5rem;
`;

const ShareTestInfoBoxHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
`;

const ShareTestInfoBoxHeaderTitle = styled.h3`
    margin-bottom: 0.5rem;
    font-weight: 500;
`;

const ShareTestInfoBoxBody = styled.div`
    background: #f6f6f6;
    padding: 0.5rem 1rem;
    width: 100%;
`;

const ShareTestInfoBoxParagraph = styled.p`
    margin-bottom: 0.5rem;
    line-height: 1.3;
`;

const TableContents = styled.div`
    margin: 1rem 0;
    width: 100%;
`;
const TableHeaders = styled.div`
    margin: 1rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-weight: bold;
`;

const TableHeaderName = styled.span`
    flex: 1;
`;

const TableHeaderProgress = styled.span`
    flex: 3;
    padding-left: 0.5rem;
`;

const TableHeaderLink = styled.div`
    flex: 2;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
`;

const CTAsRow = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    box-shadow: 0 -3px 3px rgb(0 0 0 / 10%);
    padding: 2rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 0.5rem;
    }
`;

const BackButtonWrapper = styled.div`
    flex: 1;
`;

const CTASCenterWrapper = styled.div`
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
        gap: 0.5rem;

        > div {
            width: unset;
            margin: 0 auto;
        }
    }
`;

const DownloadButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    flex: 1;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;
    }
`;

const ShareLinkContainer = styled.div`
    border: solid 1px #666666;
    background: white;
    font-size: 0.8rem;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
`;

const ShareLinkContent = styled.span`
    width: calc(100% - 1px);
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;

const ShareRow = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 1rem 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
    }
`;

const ShareName = styled.div`
    flex: 1;
    padding: 0.5rem 0 0.5rem 1rem;
    font-size: 1.2rem;
`;

const ShareReviewInfo = styled.div`
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-right: solid 4px #ccc;
    padding: 0.5rem 0;
    align-self: stretch;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        border: none;
    }
`;

const ShareLinkWrapper = styled.div`
    flex: 2;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const ReviewInfoItem = styled.span`
    padding: 0 5px;
    text-align: center;
`;

const TooltipWrapper = styled.span`
    display: inherit;
`;

const CompleteReviewPercWrapper = styled.div`
    color: green;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IncompleteReviewPercWrapper = styled.div`
    color: red;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FinishReviewSpanCTA = styled.span`
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
`;

const StatusIconBaseCss = css`
    border-radius: 50%;
    text-align: center;
    margin-right: 5px;
    color: white;
`;

const AllReviewedIconWrapper = styled.div`
    ${StatusIconBaseCss};
    border: solid 1px green;
    background: green;
    width: 1.1rem;
    height: 1.1rem;
    display: flex;
    align-items: center;
`;
const WarningReviewIconWrapper = styled.div`
    ${StatusIconBaseCss};
    width: 2rem;
    height: 2rem;
    border: none;
    background: transparent;
`;

const StyledInfoIcon = styled(SvgIcon)`
    width: 1.8rem;
    height: 1.8rem;
    text-align: center;
`;

const StyledInfoModal = styled(InfoModal)`
    text-align: center;
    font-size: 1.1rem;

    button {
        width: unset;
        padding: 0.5rem 2rem;
        height: unset;
        align-self: flex-end;
    }
`;

const ShareTestReviews: React.FC<ReviewTestProps> = (props) => {
    const t = useT();
    const t_info = t("Important", { _tags: "ShareTestReviews" });
    const t_downloadCSV = t("Download as CSV", { _tags: "ShareTestReviews" });
    const t_downloadXls = t("Download as XLSX", { _tags: "ShareTestReviews" });
    const t_backToOverview = t("Back to Overview", { _tags: "ShareTestReviews" });

    const t_exportName = t("Name", { _tags: "ShareTestReviews" });
    const t_exportCorrectPer = t("Percentage correct answers", { _tags: "ShareTestReviews" });
    const t_exportIncorrectPer = t("Percentage incorrect answers", { _tags: "ShareTestReviews" });
    const t_exportLink = t("Unique result access link", { _tags: "ShareTestReviews" });
    const t_noLink = t("No results link available", { _tags: "ShareTestReviews" });
    const t_finishReview = t("Finish review", { _tags: "ShareTestReviews" });

    const t_nameHeader = t("Name", { _tags: "ShareTestReviews" });
    const t_progressHeader = t("Evaluation Progress", { _tags: "ShareTestReviews" });
    const t_linkHeader = t("Unique results link for students", { _tags: "ShareTestReviews" });

    const t_linkTooltip = t("Share link with your students to make results available to them", {
        _tags: "ShareTestReviews",
    });
    const t_close = t("Close", { _tags: "ShareTestReviews" });

    const [showResultsBodyInfo, setShowResultsBodyInfo] = useState(true);
    // const [sharingData, setSharingData] = useState<Array<ShareTestItem>>([]);
    const [isCopiedModalShown, setIsCopiedModalShown] = useState(false);
    const [lastCopiedStudentName, setLastCopiedStudentName] = useState("");
    const [visitEventSent, setVisitEventSent] = useState(false);

    const [updatedStudentTestResults, setUpdatedStudentTestResults] = useState<Array<StudentTestAnswer>>([]);

    const { generateDataForReviewSharing, testShareData, currentTestId, studentTestContent } = useTestReviewContext();

    const history = useHistory();

    useEffect(() => {
        const prepareReviewSharing = async () => {
            const changed = await generateDataForReviewSharing();
            setUpdatedStudentTestResults(changed ?? []);
        };

        prepareReviewSharing();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // deal with updated test results in a separate useEffect in case the above fires twice
    useEffect(() => {
        const saveUpdatedTestResults = async () => {
            if (updatedStudentTestResults && updatedStudentTestResults?.length > 0) {
                updatedStudentTestResults.forEach(async (test) => {
                    if (test.id) {
                        await saveStudentTestResult(currentTestId, test.id, test);
                    }
                });
                setUpdatedStudentTestResults([]);
            }
        };

        saveUpdatedTestResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedStudentTestResults]);

    useEffect(() => {
        if (testShareData.length > 0 && !visitEventSent) {
            let fullyRatedPersons = testShareData.filter((s) => s.canBeShared).length;
            let totalPersonsInTest = testShareData.length;
            let fullyRatedPersonsPercent = (fullyRatedPersons * 100) / totalPersonsInTest || 0;

            logEventWithProps("Visited Share Results Page", {
                totalPersonsInTest,
                fullyRatedPersons,
                testId: studentTestContent.id,
                fullyRatedPersonsPercent,
            });

            setVisitEventSent(true);
        }
    }, [testShareData, visitEventSent, studentTestContent.id]);

    const exportAs = function (type: "csv" | "xls") {
        let dataToExport: any = [];
        testShareData.forEach((d) => {
            let newObj = [];
            newObj[t_exportName] = d.name;
            newObj[t_exportCorrectPer] = d.correctPerc + "%";
            newObj[t_exportIncorrectPer] = d.incorrectPerc + "%";
            newObj[t_exportLink] = d.canBeShared ? d.shareLink : t_noLink;

            dataToExport.push(newObj);
        });

        if (type === "csv") {
            exportFromJSON({
                data: dataToExport,
                fileName: studentTestContent.name,
                exportType: type,
                withBOM: true,
            });
        } else {
            let worksheet = utils.json_to_sheet(dataToExport);
            let workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "students");
            writeFile(workbook, (studentTestContent.name || "export") + ".xlsx");
        }

        logEventWithProps("Downloaded Results " + type, { testId: studentTestContent.id });
    };

    const copyLinkToClipboard = function (link: string, stdName: string) {
        copy(link);
        setLastCopiedStudentName(stdName);
        setIsCopiedModalShown(true);
    };

    const generateReviewTable = function () {
        let grid: Array<JSX.Element> = [];
        testShareData.forEach((item, i) => {
            let rowContent: Array<JSX.Element> = [];

            rowContent.push(<ShareName key={"name_" + i}>{item.name}</ShareName>);

            let reviewPercentageWrapper = null;

            if (item.canBeShared) {
                reviewPercentageWrapper = (
                    <CompleteReviewPercWrapper>
                        <AllReviewedIconWrapper>
                            <SvgIcon
                                svgComponent={CorrectIcon}
                                color={"white"}
                            />
                        </AllReviewedIconWrapper>
                        <UT
                            _str="{reviewedPerc}% reviewed"
                            reviewedPerc={item.reviewPerc}
                            _inline
                            _tags="ShareTestReviews"
                        />
                    </CompleteReviewPercWrapper>
                );
            } else {
                reviewPercentageWrapper = (
                    <IncompleteReviewPercWrapper>
                        <WarningReviewIconWrapper>
                            <SvgIcon
                                svgComponent={AlertIcon}
                                color={"red"}
                            />
                        </WarningReviewIconWrapper>
                        <UT
                            _str="{reviewedPerc}% not yet reviewed"
                            reviewedPerc={100 - Number(item.reviewPerc)}
                            _inline
                            _tags="ShareTestReviews"
                        />
                    </IncompleteReviewPercWrapper>
                );
            }

            rowContent.push(
                <ShareReviewInfo key={"info_" + i}>
                    {reviewPercentageWrapper}|
                    <ReviewInfoItem>
                        <UT
                            _str="{correctPerc}% correct"
                            correctPerc={item.correctPerc}
                            _inline
                            _tags="ShareTestReviews"
                        />
                    </ReviewInfoItem>
                    |
                    <ReviewInfoItem>
                        <UT
                            _str="{incorrectPerc}% incorrect"
                            incorrectPerc={item.incorrectPerc}
                            _inline
                            _tags="ShareTestReviews"
                        />
                    </ReviewInfoItem>
                    |
                    <ReviewInfoItem>
                        <UT
                            _str="{totalComments} comments"
                            totalComments={item.totalComments}
                            _inline
                            _tags="ShareTestReviews"
                        />
                    </ReviewInfoItem>
                </ShareReviewInfo>
            );

            if (item.canBeShared) {
                rowContent.push(
                    <ShareLinkWrapper key={"share"}>
                        <ShareLinkContainer>
                            <ShareLinkContent
                                onClick={() => {
                                    copyLinkToClipboard(item.shareLink, item.name);
                                }}
                            >
                                {item.shareLink}
                            </ShareLinkContent>
                        </ShareLinkContainer>
                    </ShareLinkWrapper>
                );
            } else {
                rowContent.push(
                    <ShareLinkWrapper key={"share"}>
                        <UT
                            _str="Not available yet. Please finish evaluation first"
                            _inline
                            _tags="ShareTestReviews"
                        />
                        <FinishReviewSpanCTA
                            onClick={() => {
                                history.push(`/review/${currentTestId}/student/${item.resultId}`);
                            }}
                        >
                            {t_finishReview}
                        </FinishReviewSpanCTA>
                    </ShareLinkWrapper>
                );
            }
            grid.push(<ShareRow key={"row_" + i}>{rowContent}</ShareRow>);
        });

        return grid;
    };

    return (
        <Wrapper>
            <TestReviewInfoHeader />
            <ShareTestInfoBox>
                <ShareTestInfoBoxHeader
                    onClick={() => {
                        setShowResultsBodyInfo(!showResultsBodyInfo);
                    }}
                >
                    <ShareTestInfoBoxHeaderTitle>{t_info}</ShareTestInfoBoxHeaderTitle>
                    <ArrowIcon
                        onClick={() => {}}
                        direction={showResultsBodyInfo ? "TOP" : "BOTTOM"}
                    />
                </ShareTestInfoBoxHeader>
                {showResultsBodyInfo && (
                    <ShareTestInfoBoxBody>
                        <ShareTestInfoBoxParagraph>
                            <UT
                                _str="This page shows you an overview of the results. Share the unique links with the individual students to enable them to access their individual result. The link is unique, i.e. each student may only use their respective unique result link."
                                _inline
                                _tags="ShareTestReviews"
                            />
                        </ShareTestInfoBoxParagraph>
                        <ShareTestInfoBoxParagraph>
                            <UT
                                _str="You can export the summary of all results to a CSV file (which can be opened with different software, e.g. Excel or Numbers). The list will contain the names and individual results of the students, as well as their unique results link."
                                _inline
                                _tags="ShareTestReviews"
                            />
                        </ShareTestInfoBoxParagraph>
                        <ShareTestInfoBoxParagraph>
                            <UT
                                _str="Please note: You can only share results with your students when 100% of the assignment has been reviewed."
                                _inline
                                _tags="ShareTestReviews"
                            />
                        </ShareTestInfoBoxParagraph>
                    </ShareTestInfoBoxBody>
                )}
            </ShareTestInfoBox>

            <TableHeaders>
                <TableHeaderName>{t_nameHeader}</TableHeaderName>
                <TableHeaderProgress>{t_progressHeader}</TableHeaderProgress>
                <TableHeaderLink>
                    {t_linkHeader}{" "}
                    <TooltipWrapper data-tip={t_linkTooltip}>
                        <StyledInfoIcon svgComponent={InfoIcon} />
                    </TooltipWrapper>
                </TableHeaderLink>
                <ReactTooltip effect="solid" />
            </TableHeaders>

            <TableContents>{generateReviewTable()}</TableContents>

            <CTAsRow>
                <CTASCenterWrapper>
                    <BackButtonWrapper>
                        <StyledButton
                            buttonStyle={"BLANK"}
                            onClick={() => {
                                history.push("/review/" + currentTestId);
                            }}
                        >
                            {t_backToOverview}
                        </StyledButton>
                    </BackButtonWrapper>
                    <DownloadButtonsWrapper>
                        <StyledButton
                            onClick={() => {
                                exportAs("csv");
                            }}
                            buttonStyle={"PRIMARY"}
                        >
                            {t_downloadCSV}
                        </StyledButton>
                        <StyledButton
                            onClick={() => {
                                exportAs("xls");
                            }}
                            buttonStyle={"PRIMARY"}
                        >
                            {t_downloadXls}
                        </StyledButton>
                    </DownloadButtonsWrapper>
                </CTASCenterWrapper>
            </CTAsRow>

            <StyledInfoModal
                isOpen={isCopiedModalShown}
                onRequestClose={() => {
                    setIsCopiedModalShown(false);
                }}
                cancelAction={() => {
                    setIsCopiedModalShown(false);
                }}
                cancelText={t_close}
            >
                <UT
                    _str="Link has been copied to your clipboard. You can share it with <b>{studentName}</b> now to enable them to see their answers and your evaluation."
                    studentName={lastCopiedStudentName}
                    _inline
                    _tags="ShareTestReviews"
                />
                <UT
                    _str="Everyone with this link can access the results. Therefore, please make sure to share the link only with the respective student."
                    _inline
                    _tags="ShareTestReviews"
                />
            </StyledInfoModal>
        </Wrapper>
    );
};

export default ShareTestReviews;
