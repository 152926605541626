// LIBRARIES
import React from "react";
import styled from "styled-components";
import { useT } from "@transifex/react/dist";
import { ReactComponent as Background } from "../../../assets/img/404.svg";

const Wrapper = styled.div`
    height: 100%;
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }
`;

const BackgroundContainer = styled.div`
    width: 100%;
    height: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        height: unset;
    }
`;

const TextContainer = styled.div`
    position: absolute;
    left: 15%;
    top: 20%;
    max-width: 45%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        position: unset;
        max-width: unset;
        flex: 1;
    }
`;

const Title404 = styled.p`
    font-size: 6rem;
`;

const Content404 = styled.p`
    font-size: 2rem;
`;

const AskTeacherText = styled.p`
    font-size: 2rem;
`;

const Error: React.FC = () => {
    const t = useT();
    const t_siteNotFound = t("Ups, the site was not found!", { _tags: "errorScreen" });
    const t_askTeacher = t("Please ask your teacher, if she can help you.", { _tags: "errorScreen" });

    return (
        <Wrapper>
            <TextContainer>
                <Title404>404</Title404>
                <Content404>{t_siteNotFound}</Content404>
                <AskTeacherText>{t_askTeacher}</AskTeacherText>
            </TextContainer>
            <BackgroundContainer>
                <Background />
            </BackgroundContainer>
        </Wrapper>
    );
};

export default Error;
