// LIBRARIES
import React from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";
import TestReviewProgressItem from "../../basic/testReviewProgressItem/TestReviewProgressItem";
import { useTestReviewContext } from "../../context/TestReviewContext";
import { getDateFromMilliseconds } from "../../helpers/DateTime";

export interface TestReviewIndividualListingProps {}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const NoExercisesText = styled.p`

;`;

const TestReviewIndividualListing: React.FC<TestReviewIndividualListingProps> = (props) => {
    const t = useT();
    const t_noResults = t("There are not results to be evaluated yet.", { _tags: "TestReviewExerciseListing" });

    const { testIndividualResults } = useTestReviewContext();

    return (
        <Wrapper>
            {testIndividualResults.map((r, i) => (
                <TestReviewProgressItem
                    key={"std_" + i}
                    itemName={r.studentName + (r.sentTime ? ` (${getDateFromMilliseconds(r.sentTime)})` : "")}
                    type={"studentItem"}
                    itemContent={r}
                />
            ))}
            {testIndividualResults.length === 0 && <NoExercisesText>{t_noResults}</NoExercisesText>}
        </Wrapper>
    );
};

export default TestReviewIndividualListing;
