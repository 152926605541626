import React, { ReactElement } from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { tx } from "@transifex/native";

import { ThemeProvider } from "styled-components";
import { createMainTheme } from "p6-react-shared";
import Layout from "./layout/Layout";
import { AppGeneralContextProvider } from "./context/AppGeneralContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/de";
import "./assets/css/selectSearch.css";

import "./assets/proximaNovaFont/proximaNova.css";
import "./assets/phase6-icon-font/phase6icons.css";
import "./assets/phase6-icon-font/p6-icon-font.css";

// Axios
import axios from "axios";
import { cp } from "./config";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { createRoot } from "react-dom/client";

tx.init({
    // Public project token, defaults to empty string
    token: "1/2523cf8a33104482b1fed06c9ca31901303654cc",
    filterTags: "p6u",
});
const lastLanguage = localStorage.getItem("lastTxLang");
tx.setCurrentLocale(!!lastLanguage ? lastLanguage : "de");

axios
    .get(window.location.href.includes("localhost") ? "/config.json" : "config.json")
    .then((response) => {
        cp.cfg = response.data;

        return (
            <React.StrictMode>
                <ThemeProvider theme={createMainTheme}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <AppGeneralContextProvider>
                            <Layout />
                        </AppGeneralContextProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </React.StrictMode>
        );
    })
    .catch(() => {
        return <p style={{ color: "red", textAlign: "center" }}>Error while fetching global config</p>;
    })
    .then((reactElement: ReactElement) => {
        const rootElement = document.getElementById("root");
        if (rootElement) {
            createRoot(rootElement).render(reactElement);
        }
    });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
