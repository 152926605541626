import styled from "styled-components";
import PhaseSixIcon from "../../../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../../../constants/DesignConstants";

export const Box = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${DesignConstants.SPACES.XS};
`;

export const Item = styled.div`
    padding: 0 ${DesignConstants.SPACES.S};
    min-height: ${DesignConstants.SPACES.L};
    border-radius: 3rem;
    border: ${DesignConstants.BORDERS.THIN} #ccc;
    display: flex;
    align-items: center;
    font-size: ${DesignConstants.FONT_SIZES.M};
    user-select: none;
    gap: ${DesignConstants.SPACES.XS};
    background-color: white;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S};
        border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    }
`;

export const Word = styled.span``;

export const RemoveIconWrapper = styled.div`
    width: ${DesignConstants.SPACES.M};
    cursor: pointer;
    margin-top: 1px;
    height: ${DesignConstants.FONT_SIZES.XL};

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        cursor: not-allowed;
    }
`;

export const RemoveIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;
