import * as React from "react";
import styled from "styled-components";
import { ReactComponent as TimeIcon } from "../../assets/icons/time.svg";
import SvgIcon from "../svgIcon/SvgIcon";
import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as IncorrectIcon } from "../../assets/icons/exercise-failed.svg";
import { useT } from "@transifex/react";

export interface DurationPickerProps {
    className?: string;
    onChange?: (newVal: string) => any;
    value?: string;
    disabled?: boolean;
}

const DurationPickerWrapper = styled.div`
    border: solid 1px #ccc;
    border-radius: 0.5rem;
    padding: 0.5rem 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.disabled {
        cursor: not-allowed;
        &:hover {
            border: solid 1px #ccc;
        }
    }

    &:hover {
        border: solid 1px #000;
    }
`;

const DurationPickerInnerWrapper = styled.div`
    width: 100%;
`;
const InputSvgIcon = styled(SvgIcon)`
    width: 2rem;
    height: 2rem;
    text-align: center;
`;

const DisabledSpan = styled.span`
    color: rgba(0, 0, 0, 0.38);
    letter-spacing: 2px;
`;

const StyledSelect = styled(Select)`
    width: 45%;
    margin: 0 5px;
    color: #666666 !important;
    border: none;
`;

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SelectsWrapper = styled.div`
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
const ResetWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
    margin: 0 3px;
    background: #ccc;

    > svg {
        width: 1rem;
        height: 1rem;
    }
`;

const DurationPicker: React.FC<DurationPickerProps> = (props) => {
    const { value, onChange, disabled = false } = props;

    const [hoursValue, setHoursValue] = useState("");
    const [minutesValue, setMinutesValue] = useState("");

    const t = useT();
    const t_hrs = t("(H)", { _tags: "CreateTest,DurationPicker" });
    const t_min = t("(Min)", { _tags: "CreateTest,DurationPicker" });

    useEffect(() => {
        if (value && value.includes(":")) {
            let valueArray = value.split(":");

            // if (disabled) {
            setHoursValue(valueArray[0]);
            setMinutesValue(valueArray[1]);
            // } else {
            //     try {
            //         let hoursVal = Number(valueArray[0]);
            //         if (hoursVal > 5) {
            //             hoursVal = 5;
            //         }
            //
            //     } catch (e) {
            //         setHoursValue("");
            //         setMinutesValue("")
            //     }
            // }

            // value?.indexOf(":") ? value.split(":")[0] : ""
        } else {
            setHoursValue("");
            setMinutesValue("");
        }
    }, [value]);

    function onHourChange(value: string) {
        setHoursValue(value);
        let minsVal = minutesValue;
        if (minutesValue === "") {
            minsVal = "00";
            setMinutesValue("00");
        }
        if (onChange) {
            onChange(value + ":" + minsVal);
        }
    }
    function onMinuteChange(value: string) {
        setMinutesValue(value);
        if (hoursValue === "") {
            setHoursValue("00");
        }
        if (onChange) {
            onChange(hoursValue + ":" + value);
        }
    }

    function resetTimer() {
        if (onChange) {
            onChange("");
        }
    }

    return (
        <DurationPickerWrapper className={`${props.className || ""} ${disabled ? "disabled" : ""} `}>
            <DurationPickerInnerWrapper>
                {disabled && (
                    <>
                        <DisabledSpan>
                            {hoursValue === "" ? "__" : hoursValue} : {minutesValue === "" ? "__" : minutesValue}
                        </DisabledSpan>
                    </>
                )}
                {!disabled && (
                    <ContentWrapper>
                        <SelectsWrapper>
                            <StyledSelect
                                value={hoursValue}
                                onChange={(ev) => {
                                    onHourChange(ev.target.value as string);
                                }}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                variant={"standard"}
                            >
                                <MenuItem value="">-- {t_hrs}</MenuItem>
                                <MenuItem value={"00"}>0 {t_hrs}</MenuItem>
                                <MenuItem value={"01"}>1 {t_hrs}</MenuItem>
                                <MenuItem value={"02"}>2 {t_hrs}</MenuItem>
                                <MenuItem value={"03"}>3 {t_hrs}</MenuItem>
                                <MenuItem value={"04"}>4 {t_hrs}</MenuItem>
                                <MenuItem value={"05"}>5 {t_hrs}</MenuItem>
                            </StyledSelect>
                            :
                            <StyledSelect
                                value={minutesValue}
                                onChange={(ev) => {
                                    onMinuteChange(ev.target.value as string);
                                }}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                variant={"standard"}
                            >
                                <MenuItem value="">-- {t_min}</MenuItem>
                                <MenuItem value={"00"}>00 {t_min}</MenuItem>
                                <MenuItem value={"05"}>05 {t_min}</MenuItem>
                                <MenuItem value={"10"}>10 {t_min}</MenuItem>
                                <MenuItem value={"15"}>15 {t_min}</MenuItem>
                                <MenuItem value={"20"}>20 {t_min}</MenuItem>
                                <MenuItem value={"25"}>25 {t_min}</MenuItem>
                                <MenuItem value={"30"}>30 {t_min}</MenuItem>
                                <MenuItem value={"35"}>35 {t_min}</MenuItem>
                                <MenuItem value={"40"}>40 {t_min}</MenuItem>
                                <MenuItem value={"45"}>45 {t_min}</MenuItem>
                                <MenuItem value={"50"}>50 {t_min}</MenuItem>
                                <MenuItem value={"55"}>55 {t_min}</MenuItem>
                            </StyledSelect>
                        </SelectsWrapper>
                        {(hoursValue !== "" || minutesValue !== "") && (
                            <ResetWrapper onClick={resetTimer}>
                                <SvgIcon
                                    svgComponent={IncorrectIcon}
                                    color={"white"}
                                />
                            </ResetWrapper>
                        )}
                    </ContentWrapper>
                )}
            </DurationPickerInnerWrapper>
            <InputSvgIcon
                svgComponent={TimeIcon}
                color={disabled ? "rgba(0,0,0,0.38)" : undefined}
            />
        </DurationPickerWrapper>
    );
};

export default DurationPicker;
