// LIBRARIES
import React, { useEffect, useRef, useState } from "react";
import { StudentQuestionAnswer, TestQuestionItem } from "p6m-p6u";
import ExerciseDescription from "../../../basic/exerciseDescription/ExerciseDescription";
import StandardExercise from "../standardExercise/StandardExercise";
import WriteSentenceExercise from "../writeSentenceExercise/WriteSentenceExercise";
import FillInTheGapExercise from "../fillInTheGapExercise/FillInTheGapExercise";
import JumbledWordsExercise from "../jumbledWordsExercise/JumbledWordsExercise";
import { useStudentPracticeContext } from "../../../context/StudentPracticeContext";
import ConnectWordsExercise from "../connectWordsExercise/ConnectWordsExercise";
import FreeTextExercise from "../freeTextExercise/FreeTextExercise";
import VerbtrainingExercise from "../verbtrainingExercise/VerbtrainingExercise";
import { debounce } from "lodash";
import { ExerciseTypes, Exercises } from "../../../enums/exercises";
import { Wrapper, ExerciseTypeContainer } from "./StyledComponents";

export interface ExerciseProps {
    testQuestion: TestQuestionItem;
    setContentIsOverflowingCallback?: (val: boolean) => any;
}

const updateOverflowDebounced = debounce(
    (value, functionToCall) => {
        functionToCall(value);
    },
    100,
    {
        leading: false,
        trailing: true,
    }
);

const Exercise: React.FC<ExerciseProps> = (props) => {
    let { testQuestion } = props;
    const { currentExerciseId, studentTestAnswer, updateStudentAnswerOnQuestion } = useStudentPracticeContext();

    let studentAnswerItem = {} as StudentQuestionAnswer;
    if (studentTestAnswer.questionAnswers) {
        studentAnswerItem = studentTestAnswer.questionAnswers[currentExerciseId];
    }

    const [studentAnswer, setStudentAnswer] = useState(studentAnswerItem);
    const [contentIsOverflowing, setContentIsOverflowing] = useState(false);

    useEffect(() => {
        if (props.setContentIsOverflowingCallback) {
            updateOverflowDebounced(contentIsOverflowing, props.setContentIsOverflowingCallback);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentIsOverflowing, updateOverflowDebounced]);

    useEffect(() => {
        if (updateStudentAnswerOnQuestion) {
            updateStudentAnswerOnQuestion(studentAnswer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentAnswer]);

    const updateStudentQuestionAnswers = function (newItem: StudentQuestionAnswer) {
        setStudentAnswer(newItem);
    };

    let exerciseContent;
    let exerciseProps = { studentAnswerItem, updateStudentQuestionAnswers };

    switch (testQuestion.questionMode) {
        case ExerciseTypes.get(Exercises.STANDARD):
            exerciseContent = <StandardExercise {...exerciseProps} />;
            break;
        case ExerciseTypes.get(Exercises.WRITE_SENTENCE):
            exerciseContent = <WriteSentenceExercise {...exerciseProps} />;
            break;
        case ExerciseTypes.get(Exercises.CREATE_TEXT):
            exerciseContent = <JumbledWordsExercise {...exerciseProps} />;
            break;
        case ExerciseTypes.get(Exercises.FILL_GAP):
            exerciseContent = <FillInTheGapExercise {...exerciseProps} />;
            break;
        case ExerciseTypes.get(Exercises.CONNECT_WORDS):
            exerciseContent = <ConnectWordsExercise {...exerciseProps} />;
            break;
        case ExerciseTypes.get(Exercises.VERBTRAINING):
            exerciseContent = <VerbtrainingExercise {...exerciseProps} />;
            break;
        case ExerciseTypes.get(Exercises.FREE_TEXT):
            exerciseContent = <FreeTextExercise {...exerciseProps} />;
            break;
        default:
            exerciseContent = <p>Default</p>;
    }

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (
            props.setContentIsOverflowingCallback &&
            ref.current &&
            ref.current?.scrollHeight > ref.current?.clientHeight
        ) {
            setContentIsOverflowing(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    const scrollListener = () => {
        if (!props.setContentIsOverflowingCallback) {
            return;
        }

        let scrolledHeight = Number(ref.current?.scrollTop) || 0;
        let realHeight = Number(ref.current?.scrollHeight) || 0;
        let clientHeight = ref.current?.clientHeight || 0;

        if (scrolledHeight + clientHeight >= realHeight - 50) {
            setContentIsOverflowing(false);
        } else if (!contentIsOverflowing) {
            setContentIsOverflowing(true);
        }
    };

    useEffect(() => {
        ref.current?.addEventListener("scroll", scrollListener);
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            ref.current?.removeEventListener("scroll", scrollListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper ref={ref}>
            <ExerciseDescription exerciseTitle={testQuestion.extraComments} />
            <ExerciseTypeContainer>{exerciseContent}</ExerciseTypeContainer>
        </Wrapper>
    );
};

export default Exercise;
