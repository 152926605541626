import styled from "styled-components";
import DesignConstants from "../../constants/DesignConstants";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const WarningIconWrapper = styled.div`
    width: ${DesignConstants.SIZES.ICON_M};
    display: flex;
    align-items: center;
`;

export const StyledP6Icon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.SIZES.ICON_M};
`;

export const BookAvailabilityInfoDescWrapper = styled.div`
    text-align: left;
    flex: 1;
`;

export const BookAvailabilityInfoTitle = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.M};
    margin-bottom: ${DesignConstants.SPACES.XS};
`;

export const BookAvailabilityInfoDesc = styled.p`
    font-size: ${DesignConstants.FONT_SIZES.S};

    &.bigger-font {
        font-size: ${DesignConstants.FONT_SIZES.M};
    }
`;

export const AdditionalInfoItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${DesignConstants.SPACES.S};
    background: white;
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    border: ${DesignConstants.BORDERS.THIN_GREY};
    flex-wrap: wrap;
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.M} ${DesignConstants.SPACES.S}
        ${DesignConstants.SPACES.S};
`;
