export const AvailableCovers = [
    "ag",
    "ar",
    "bg",
    "bp",
    "cs",
    "da",
    "de",
    "el",
    "en",
    "es",
    "et",
    "fa",
    "fi",
    "fr",
    "ga",
    "hr",
    "hu",
    "it",
    "ja",
    "ko",
    "la",
    "lt",
    "lv",
    "mt",
    "nl",
    "no",
    "pl",
    "ps",
    "pt",
    "ro",
    "others",
    "ru",
    "sk",
    "sl",
    "sv",
    "ti",
    "tr",
    "uk",
    "zh",
];

export const NameToCode = new Map<string, string>([
    ["Dänisch", "da"],
    ["Deutsch", "de"],
    ["Englisch", "en"],
    ["Finnisch", "fi"],
    ["Französisch", "fr"],
    ["Griechisch", "el"],
    ["Italienisch", "it"],
    ["Lateinisch", "la"],
    ["Niederländisch", "nl"],
    ["Norwegisch", "no"],
    ["Polnisch", "pl"],
    ["Portugiesisch", "pt"],
    ["Russisch", "ru"],
    ["Schwedisch", "sv"],
    ["Spanisch", "es"],
    ["Türkisch", "tr"],
    ["Ukrainisch", "uk"],
    ["Brasilianisches Portugiesisch", "br"],
    ["Arabisch", "ar"],
    ["Alt-Griechisch", "ag"],
    ["Chinesisch", "zh"],
]);

export const CodeToName = new Map<string, string>([
    ["da", "Dänisch"],
    ["de", "Deutsch"],
    ["en", "Englisch"],
    ["fi", "Finnisch"],
    ["fr", "Französisch"],
    ["el", "Griechisch"],
    ["it", "Italienisch"],
    ["la", "Lateinisch"],
    ["nl", "Niederländisch"],
    ["no", "Norwegisch"],
    ["pl", "Polnisch"],
    ["pt", "Portugiesisch"],
    ["ru", "Russisch"],
    ["sv", "Schwedisch"],
    ["es", "Spanisch"],
    ["tr", "Türkisch"],
    ["uk", "Ukrainisch"],
    ["br", "Brasilianisches Portugiesisch"],
    ["ar", "Arabisch"],
    ["ag", "Alt-Griechisch"],
    ["zh", "Chinesisch"],
]);

export function getLanguageNameFromCode(code?: string) {
    code = code?.trim() || "";
    if (code.length > 0 && CodeToName.has(code)) {
        return CodeToName.get(code);
    } else {
        return "Other";
    }
}

export function getCodeFromLanguageName(langName?: string) {
    langName = langName?.trim() || "";
    if (langName.length > 0 && NameToCode.has(langName)) {
        return NameToCode.get(langName);
    } else {
        return "others";
    }
}

export function getLanguageName(lang?: string) {
    lang = lang?.trim() || "";
    if (lang.length > 0 && AvailableCovers.includes(lang)) {
        return getLanguageNameFromCode(lang);
    }
    return lang;
}
