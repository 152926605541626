import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { StudentQuestionAnswer } from "p6m-p6u";
import { useMediaQuery } from "react-responsive";
import { debounce } from "lodash";
import ExerciseInputField from "../../../basic/exerciseInputField/ExerciseInputField";
import InputControlArea from "../inputControlArea/InputControlArea";
import { isAndroid } from "react-device-detect";

export interface VerbtrainingExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
}

const VerbtrainingExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
    }
`;

const ExerciseRowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem 0;
    width: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 1rem;
    }

    &:nth-child(odd) {
        background: #f6f6f6;
    }

    > * {
        flex: 1;
        margin: 5px;
        padding: 0.3rem 0.3rem 0.3rem 0;
    }
`;

const QuestionText = styled.p`
    text-align: center;
    font-weight: bold;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding-left: 10px;
        width: calc(100% - 10px);
    }
`;

const StyledExerciseInputField = styled(ExerciseInputField)`
    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 95%;
    }
`;

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

// do debounce

const VerbtrainingExercise: React.FC<VerbtrainingExerciseProps> = (props) => {
    const { studentAnswerItem, updateStudentQuestionAnswers } = props;
    const [answersContent, setAnswersContent] = useState(
        studentAnswerItem.studentAnswers ? studentAnswerItem.studentAnswers : []
    );
    const [lastEditedIndex, setLastEditedIndex] = useState<number>(-1);

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    let availableInputFieldsCount = 0;
    studentAnswerItem.studentAnswers?.forEach((sa, index) => {
        availableInputFieldsCount += sa.verbtrainingAnswers?.filter((va) => va.answer === "").length || 0;
    });

    const updateItemAtPos = function (verbPosition: number, conjugationPosition: number, value: string) {
        const newAnswers = [...answersContent];
        if (
            newAnswers[verbPosition].verbtrainingAnswers &&
            newAnswers[verbPosition].verbtrainingAnswers![conjugationPosition]
        ) {
            newAnswers[verbPosition].verbtrainingAnswers![conjugationPosition].studentAnswer = value;
            setAnswersContent(newAnswers);
        }
    };

    useEffect(() => {
        updateDataToParentDebounced(
            { ...studentAnswerItem, studentAnswers: answersContent },
            updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answersContent]);

    const refs = useRef(new Array(availableInputFieldsCount));
    const checkFocus = function (pos: number, event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            if (pos + 1 === refs.current.length) {
                refs.current[pos]?.blur();
            } else {
                refs.current[pos + 1]?.focus();
            }
        }
    };

    const handleFocus = function (pos: number) {
        setLastEditedIndex(pos);
    };

    const restartInputValues = function () {
        setLastEditedIndex(-1);
    };
    const focusExerciseAtIndex = function (index: number) {
        if (index < availableInputFieldsCount) {
            refs.current[index]?.focus();
        }
    };

    let exercises = [];
    let itemRefIndex = 0;

    if (studentAnswerItem.studentAnswers) {
        for (let i = 0; i < studentAnswerItem.studentAnswers.length; i++) {
            let item = studentAnswerItem.studentAnswers[i];
            let fieldsGroup = [];

            if (item.verbtrainingAnswers) {
                for (let j = 0; j < item.verbtrainingAnswers.length; j++) {
                    let verbtrainingConjugation = item.verbtrainingAnswers[j];
                    let itemKey = verbtrainingConjugation.conjName?.replace(" ", "") + "_" + verbtrainingConjugation.id;

                    if (verbtrainingConjugation.answer) {
                        if (studentAnswerItem.gapMode && studentAnswerItem.gapMode === "columns") {
                            fieldsGroup.unshift(
                                <QuestionText key={itemKey}>{verbtrainingConjugation.answer}</QuestionText>
                            );
                        } else {
                            fieldsGroup.push(
                                <QuestionText key={itemKey}>{verbtrainingConjugation.answer}</QuestionText>
                            );
                        }
                    } else {
                        let currIndex = itemRefIndex;

                        fieldsGroup.push(
                            <StyledExerciseInputField
                                key={itemKey}
                                type={"text"}
                                value={verbtrainingConjugation.studentAnswer}
                                placeholder={verbtrainingConjugation.conjName}
                                onChange={(event) => {
                                    updateItemAtPos(i, j, event.target.value);
                                }}
                                ref={(element: any) => (refs.current[currIndex] = element)}
                                onFocus={() => handleFocus(currIndex)}
                                onKeyUp={(event) => checkFocus(currIndex, event)}
                            />
                        );
                        itemRefIndex++;
                    }
                }

                exercises.push(<ExerciseRowContainer key={"key_" + item.id}>{fieldsGroup}</ExerciseRowContainer>);
            }
        }
    }

    return (
        <VerbtrainingExerciseWrapper>
            {exercises}
            {isMobileView && isAndroid && lastEditedIndex !== -1 && (
                <InputControlArea
                    currentIndex={lastEditedIndex}
                    endInputCallback={restartInputValues}
                    answersLength={availableInputFieldsCount}
                    focusSwitchCallback={focusExerciseAtIndex}
                />
            )}
        </VerbtrainingExerciseWrapper>
    );
};

export default VerbtrainingExercise;
