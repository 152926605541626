// LIBRARIES
import React from "react";
import { useT } from "@transifex/react";
import { useTestCreationContext } from "../../../../context/TestCreationContext";
import { TestQuestionModel } from "p6m-p6u";
import { Exercises, ExerciseTypes } from "../../../../enums/exercises";
import { sanitizeText } from "../../../../helpers/TestCreation";
import { SummaryModes } from "../../../../views/create/summary/Summary";
import { SummaryOpenModals } from "../../../../views/create/summary/SummaryModals";
import ArrowIcon from "../../../../basic/arrowIcon/ArrowIcon";
import useExerciseTitle from "../../../../hooks/useExerciseTitle";

import {
    HeaderWrapper,
    NameWrapper,
    ExerciseName,
    VocabularyWrapper,
    ActionsWrapper,
    StyledIcon,
    ArrowActionsWrapper,
    ExerciseWordsCount,
    ExerciseNumber,
    ContentWrapper,
    Action,
    ExerciseWords,
    ExerciseWord,
    VocabularySeparator,
    Separator,
} from "./StyledComponents";

export interface ExerciseItemHeaderProps {
    isNewExercise?: boolean;
    isEditMode?: boolean;
    exerciseData: TestQuestionModel;
    index: number;
    setCurrentMode: React.Dispatch<React.SetStateAction<SummaryModes>>;
    setOpenModal: React.Dispatch<React.SetStateAction<SummaryOpenModals | undefined>>;
    onOpenExerciseContentModal: () => void;
    saveTestDataDebounced: (newTestContent: TestQuestionModel[]) => void;
    onAction: () => void;
}

const ExerciseItemHeader: React.FC<ExerciseItemHeaderProps> = (props) => {
    const {
        testContent,
        setTestContent,
        openWarningModal,
        setCurrentExerciseModel,
        setIsTestSynced,
        vocabularyDrawerContent,
        setVocabularyDrawerContent,
    } = useTestCreationContext();

    const { getExerciseNameFromQuestionMode, ExerciseTypesExerciseName } = useExerciseTitle();

    const t = useT();
    const t_words = t("{wordCount} Words", {
        wordCount: props.exerciseData.selectedWords?.length,
        _tags: "CreateTest,SummaryExerciseItem",
    });
    const t_noVerbtraining = t("You cannot edit this exercise ({verbtrainingName}) in the current version.", {
        verbtrainingName: ExerciseTypesExerciseName.get(Exercises.VERBTRAINING),
        _tags: "CreateTest,SummaryExerciseItem",
    });
    const t_NewExercise = t("New exercise:", { _tags: "CreateTest,ExerciseDetails" });

    const exerciseName = getExerciseNameFromQuestionMode(props.exerciseData.questionMode);

    function loadExercise(exerciseId: string) {
        const exercise = testContent.find((t) => t.exerciseId === exerciseId);
        if (exercise) {
            setCurrentExerciseModel({ ...exercise, status: "EDIT", wordsInProgress: exercise.selectedWords });
        }
    }

    function deleteExerciseFromContent(exerciseId: string): TestQuestionModel[] {
        const newTestContent = testContent.filter((t) => t.exerciseId !== exerciseId);
        const drawerContentCopy = { ...vocabularyDrawerContent };

        for (const word of Object.keys(drawerContentCopy)) {
            const wordData = drawerContentCopy[word];
            const newExercisesIdArray = wordData.exerciseIds?.filter((eId) => eId !== exerciseId);
            if (!newExercisesIdArray || newExercisesIdArray.length === 0) {
                wordData.exerciseIds = [];
                wordData.isInMoreThanOneExercise = false;
            } else {
                wordData.exerciseIds = newExercisesIdArray;
                wordData.isInMoreThanOneExercise = newExercisesIdArray.length > 1;
            }
        }

        setTestContent(newTestContent);
        setVocabularyDrawerContent(drawerContentCopy);

        return newTestContent;
    }

    function moveExercise(index: number, direction: "UP" | "DOWN") {
        if (direction === "UP" ? index < 1 : index === testContent.length - 1) return;

        const shift = direction === "UP" ? -1 : +1;
        const newTestContent = [...testContent];
        [newTestContent[index], newTestContent[index + shift]] = [newTestContent[index + shift], newTestContent[index]];

        setTestContent(newTestContent);
        setIsTestSynced(false);
        props.saveTestDataDebounced(newTestContent);
        props.onAction();
        props.setCurrentMode(SummaryModes.OVERVIEW);
    }

    function editExercise() {
        if (props.exerciseData.questionMode === ExerciseTypes.get(Exercises.VERBTRAINING)) {
            openWarningModal(t_noVerbtraining);
            return;
        }

        props.onAction();
        loadExercise(props.exerciseData.exerciseId!);
        props.setCurrentMode(SummaryModes.EXERCISE_DETAILS);
    }

    function deleteExercise() {
        if (testContent.findIndex((e) => e.exerciseId === props.exerciseData.exerciseId) === -1) {
            setIsTestSynced(true);
            props.onAction();
            props.setCurrentMode(SummaryModes.OVERVIEW);
        } else if (testContent.length > 1) {
            const newTestContent = deleteExerciseFromContent(props.exerciseData.exerciseId!);
            setIsTestSynced(false);
            props.saveTestDataDebounced(newTestContent);
            props.onAction();
            props.setCurrentMode(SummaryModes.OVERVIEW);
        } else {
            props.setOpenModal(SummaryOpenModals.DELETION_FORBIDDEN);
        }
    }

    const renderTitle = () => (
        <NameWrapper>
            {props.isNewExercise && <ExerciseName>{t_NewExercise}</ExerciseName>}
            <ExerciseName>{exerciseName}</ExerciseName>
        </NameWrapper>
    );
    const renderWords = () => {
        const words =
            props.exerciseData.selectedWords?.map((w) => ("answer" in w ? sanitizeText(w.answer || "") : "")) ?? [];
        const wordsWithSeparatorDots = words.flatMap((w, i) =>
            i === 0 ? (
                <ExerciseWord key={`word_${i}`}>{w}</ExerciseWord>
            ) : (
                [
                    <VocabularySeparator key={`word_separator_${i}`}>·</VocabularySeparator>,
                    <ExerciseWord key={`word_${i}`}>{w}</ExerciseWord>,
                ]
            )
        );
        return wordsWithSeparatorDots;
    };

    const renderDescription = () => (
        <VocabularyWrapper onClick={props.exerciseData.extraComments ? props.onOpenExerciseContentModal : () => {}}>
            <ExerciseWords>
                <ExerciseWord>{props.exerciseData.extraComments}</ExerciseWord>
            </ExerciseWords>
        </VocabularyWrapper>
    );

    const renderVocabularyOverview = () => (
        <VocabularyWrapper onClick={props.onOpenExerciseContentModal}>
            <ExerciseWordsCount>{t_words}</ExerciseWordsCount>
            <Separator> | </Separator>
            <ExerciseWords>{renderWords()}</ExerciseWords>
        </VocabularyWrapper>
    );

    const renderActions = () => (
        <ActionsWrapper>
            {!props.isEditMode && (
                <Action onClick={editExercise}>
                    <StyledIcon name={"edit-avatar"} />
                </Action>
            )}
            <Action onClick={deleteExercise}>
                <StyledIcon name={"trash"} />
            </Action>
            {!props.isEditMode && (
                <ArrowActionsWrapper>
                    <ArrowIcon
                        onClick={() => moveExercise(props.index, "UP")}
                        disabled={props.index === 0}
                        direction={"TOP"}
                    />
                    <ArrowIcon
                        onClick={() => moveExercise(props.index, "DOWN")}
                        disabled={props.index === testContent.length - 1}
                        direction={"BOTTOM"}
                    />
                </ArrowActionsWrapper>
            )}
        </ActionsWrapper>
    );

    return (
        <HeaderWrapper
            className={props.exerciseData.questionMode === ExerciseTypes.get(Exercises.VERBTRAINING) ? "blur" : ""}
        >
            <ExerciseNumber>{props.index + 1}</ExerciseNumber>
            <ContentWrapper>
                {renderTitle()}
                {!props.isEditMode &&
                    (props.exerciseData.questionMode === ExerciseTypes.get(Exercises.FREE_TEXT)
                        ? renderDescription()
                        : !props.isEditMode && renderVocabularyOverview())}
                {renderActions()}
            </ContentWrapper>
        </HeaderWrapper>
    );
};

export default ExerciseItemHeader;
