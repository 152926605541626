import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useT } from "@transifex/react/dist";
import { StudentQuestionAnswer } from "p6m-p6u";
import { useMediaQuery } from "react-responsive";
import { debounce } from "lodash";
import ExerciseTextArea from "../../../basic/exerciseTextArea/ExerciseTextArea";
import { isAndroid } from "react-device-detect";
import InputControlArea from "../inputControlArea/InputControlArea";

export interface jumbledWordsExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
}

const JumbledWordsExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 1rem;
`;

const ExerciseQuestionWordsContainer = styled.div`
    border-radius: 5px;
    border: solid 1px #cccccc;
    background: #f6f6f6;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 80%;
    min-height: 3rem;
    padding: 0.5rem;
    margin-bottom: 2rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        padding: 0.3rem;
        margin-bottom: 1rem;
    }
`;

const QuestionWordSpan = styled.span`
    margin: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin: 5px;
    }
`;

const BiggerTextArea = styled(ExerciseTextArea)`
    > textarea {
        height: 150px;
    }
`;

const TextFieldStarterTextWrapper = styled.div`
    width: 100%;
    margin-top: 1rem;
`;

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

const JumbledWordsExercise: React.FC<jumbledWordsExerciseProps> = (props) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "jumbledWordsExercise,inputPlaceholder" });

    const { studentAnswerItem, updateStudentQuestionAnswers } = props;
    const [answerContent, setAnswersContent] = useState(studentAnswerItem);
    const [isEditionMode, setIsEditionMode] = useState<boolean>(false);

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const updateAnswer = function (value: string) {
        const newAnswers = { ...answerContent };
        newAnswers.jumbledWordsAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        updateDataToParentDebounced(
            {
                ...answerContent,
            },
            updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answerContent]);

    const textRef = useRef<HTMLTextAreaElement>(null);

    let jumbledWords;

    if (studentAnswerItem.jumbledWords) {
        let questionsWords: Array<React.ReactElement> = [];
        studentAnswerItem.jumbledWords.forEach((w) => {
            questionsWords.push(<QuestionWordSpan key={"key_q_" + w.replace(" ", "")}>{w}</QuestionWordSpan>);
        });
        jumbledWords = <ExerciseQuestionWordsContainer>{questionsWords}</ExerciseQuestionWordsContainer>;
    }

    return (
        <JumbledWordsExerciseWrapper>
            {jumbledWords}
            {studentAnswerItem.textFieldStarterText && (
                <TextFieldStarterTextWrapper>{studentAnswerItem.textFieldStarterText}</TextFieldStarterTextWrapper>
            )}
            <BiggerTextArea
                onChange={(event) => {
                    updateAnswer(event.target.value);
                }}
                type="text"
                placeholder={t_placeholder}
                ref={textRef}
                value={answerContent.jumbledWordsAnswer}
                onFocus={() => setIsEditionMode(true)}
                onBlur={() => setIsEditionMode(false)}
            />
            {isMobileView && isAndroid && isEditionMode && <InputControlArea />}
        </JumbledWordsExerciseWrapper>
    );
};

export default JumbledWordsExercise;
