import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useT } from "@transifex/react/dist";
import { StudentQuestionAnswer } from "p6m-p6u";
import { debounce, replace } from "lodash";
import ExerciseInputField from "../../../basic/exerciseInputField/ExerciseInputField";
import { useMediaQuery } from "react-responsive";
import { isAndroid } from "react-device-detect";
import InputControlArea from "../inputControlArea/InputControlArea";

export interface FillInTheGapExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
}

const FillInTheGapExerciseWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding-top: 1rem;
`;

const ExerciseRowContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 80%;
    justify-content: unset;
    flex-wrap: wrap;
    line-height: 1.4;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        margin-bottom: 1.5rem;
        line-height: 1.6;
    }
`;

const ExerciseQuestionWordsContainer = styled.div`
    border-radius: 5px;
    border: solid 1px #cccccc;
    background: #f6f6f6;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 80%;
    min-height: 3rem;
    padding: 0.5rem;
    margin-bottom: 2rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        padding: 0.3rem;
        margin-bottom: 1rem;
    }
`;

const QuestionWordSpan = styled.span`
    margin: 1rem;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin: 5px;
    }
`;

const InlineQuestionSpan = styled.span`
    display: inline-block;
    white-space: pre-line;
    width: 100%;
`;

const StyledInlineInput = styled(ExerciseInputField)`
    display: inline-block;
    margin: 0;
    padding: 5px 5px 5px 0;
    width: 250px;

    > input {
        padding: 0 0 0 5px !important;
    }

    &.s {
        width: 135px;
    }

    &.m {
        width: 190px;
    }
`;

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        // console.log("debouncing");
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

// do debounce

const FillInTheGapExercise: React.FC<FillInTheGapExerciseProps> = (props) => {
    const t = useT();
    const t_placeholder = t("Type answer", { _tags: "fillInTheGapExercise,inputPlaceholder" });

    const { studentAnswerItem, updateStudentQuestionAnswers } = props;
    const [answersContent, setAnswersContent] = useState(
        studentAnswerItem.studentAnswers ? studentAnswerItem.studentAnswers : []
    );
    const [lastEditedIndex, setLastEditedIndex] = useState<number>(-1);

    const updateItemAtPos = function (pos: number, value: string) {
        const newAnswers = [...answersContent];
        newAnswers[pos].studentAnswer = value;
        setAnswersContent(newAnswers);
    };

    useEffect(() => {
        updateDataToParentDebounced(
            { ...studentAnswerItem, studentAnswers: answersContent },
            updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answersContent]);

    const refs = useRef(new Array(studentAnswerItem.studentAnswers!.length));

    const checkFocus = function (pos: number, event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            if (pos + 1 === refs.current.length) {
                refs.current[pos]?.blur();
            } else {
                refs.current[pos + 1]?.focus();
            }
        }
    };

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const handleFocus = function (pos: number) {
        setLastEditedIndex(pos);
    };

    const restartInputValues = function () {
        setLastEditedIndex(-1);
    };
    const focusExerciseAtIndex = function (index: number) {
        // console.log('focusing at index', index)
        if (index < studentAnswerItem.studentAnswers!.length) {
            refs.current[index]?.focus();
        }
    };

    let exercises = [];

    if (studentAnswerItem.studentAnswers) {
        for (let i = 0; i < studentAnswerItem.studentAnswers.length; i++) {
            let item = studentAnswerItem.studentAnswers[i];

            if (item.gap_sentence) {
                let questionText = item.gap_sentence;
                questionText = replace(questionText, /_+/g, "^");
                // This should split the phrase in two, one before the gap, the other one after.
                let questionTextArray = questionText.split("^");
                let gapLength = item.gap_sentence.length - questionText.length;

                exercises.push(
                    <ExerciseRowContainer key={"key_" + item.id}>
                        <InlineQuestionSpan>
                            {questionTextArray[0]}

                            <StyledInlineInput
                                key={i}
                                onChange={(event) => {
                                    updateItemAtPos(i, event.target.value);
                                }}
                                onKeyUp={(event) => checkFocus(i, event)}
                                type="text"
                                placeholder={t_placeholder}
                                ref={(element: any) => (refs.current[i] = element)}
                                value={item.studentAnswer}
                                onFocus={() => handleFocus(i)}
                                className={`${gapLength > 12 * 2 ? "l" : gapLength > 8 * 2 ? "m" : "s"}`}
                            />

                            {!!questionTextArray[1] ? <span>{questionTextArray[1]}</span> : null}
                        </InlineQuestionSpan>
                    </ExerciseRowContainer>
                );
            }
        }
    }

    let gapWords;

    if (studentAnswerItem.fillInTheGapWords) {
        let questionsWords: Array<React.ReactElement> = [];
        studentAnswerItem.fillInTheGapWords.forEach((w) => {
            questionsWords.push(<QuestionWordSpan key={"key_q_" + w.replace(" ", "")}>{w}</QuestionWordSpan>);
        });
        gapWords = <ExerciseQuestionWordsContainer>{questionsWords}</ExerciseQuestionWordsContainer>;
    }

    return (
        <FillInTheGapExerciseWrapper>
            {gapWords}
            {exercises}
            {isMobileView && isAndroid && lastEditedIndex !== -1 && (
                <InputControlArea
                    currentIndex={lastEditedIndex}
                    endInputCallback={restartInputValues}
                    answersLength={studentAnswerItem.studentAnswers?.length}
                    focusSwitchCallback={focusExerciseAtIndex}
                />
            )}
        </FillInTheGapExerciseWrapper>
    );
};

export default FillInTheGapExercise;
