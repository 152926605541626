// LIBRARIES
import React, { useEffect, useState } from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useBookContext } from "../../../context/BookContext";
import { useRTContext } from "../../../context/RTContext";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { useT, UT } from "@transifex/react";
import ArrowIcon from "../../../basic/arrowIcon/ArrowIcon";
import CreateInfoModal from "../../../basic/create/InfoModal/CreateInfoModal";
import ExerciseInputField from "../../../basic/exerciseInputField/ExerciseInputField";
import queryString from "query-string";
import StyledButtonWithIcon from "../../../basic/styledButtonWithIcon/StyledButtonWithIcon";
import RtClassItem from "../../../complex/create/rtClassItem/RtClassItem";
import { AeaClass, DeleteExercisesOrGroupProperties } from "p6m-p6u";
import { cp } from "../../../config";
import NoOwnContentModal from "../../../basic/create/noOwnContentModal/NoOwnContentModal";

export interface CreateRtHomeProps {}

const CreateRtHomeWrapper = styled.div``;

const CreateRtHomeHeader = styled.div`
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        margin-bottom: 1rem;
    }
`;

const CreateRtHomeTitle = styled.h3`
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
`;

const ContentWrapper = styled.div``;

const BackButtonWrapper = styled.div`
    margin-bottom: 1rem;
`;

const ClassNameModalTextStrong = styled.p`
    font-weight: bold;
    margin-bottom: 0.6rem;
`;

const ClassNameModalNameDesc = styled.p``;

const TestNameLabel = styled.label`
    cursor: pointer;
    margin-bottom: 1rem;
    display: block;
`;

const TestNameModalWrapper = styled.div`
    width: 100%;
    margin-bottom: 0;
`;
const DeleteItemModalWrapper = styled.div`
    width: 100%;
    margin-bottom: 0;
    max-width: 600px;
`;

const TestNameRequiredWarning = styled.span`
    color: red;
    font-size: 0.8rem;
`;

const StyledExerciseInputField = styled(ExerciseInputField)`
    margin-bottom: 0;

    > input {
        margin-bottom: 0.5rem;
    }
`;

const BackLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
`;
const NoClassesText = styled.p`
    font-size: 1.2rem;
    margin: 2rem 0;
    text-align: center;
`;

const WiderCreateModal = styled(CreateInfoModal)`
    min-width: 500px;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        min-width: 80vw;
    }
`;

const ShareConfirmationWrapper = styled.div`
    width: 70%;
    margin-bottom: 0;
`;

const ShareConfirmation = styled.p`
    text-align: center;
    line-height: 1.5rem;
`;

const NewClassRow = styled.div`
    margin-bottom: 1rem;

    > button {
        margin-left: auto;
    }
`;

interface AeaHomeClass extends AeaClass {
    isOpen?: boolean;
}

interface AeaClassesOpen {
    [key: string]: AeaHomeClass;
}

const CreateRtHome: React.FC<CreateRtHomeProps> = (props) => {
    const {
        loadUserOwnSubjectUUIDsInfoIntoState,
        userUsedOwnSubjectsInfo,
        canLogEvent,
        userOwnSubjects,
        reloadUserSubjects,
        openWebAppInAddCardsMode,
    } = useTestCreationContext();

    const { userUsedBooksInfo, loadBooksInfoFromUUIDsIntoState } = useBookContext();

    const {
        aeaClasses,
        createAeaClass,
        loadClassesAndExercisesForUser,
        updateAeaClass,
        deleteUserExerciseClassOrBook,
        startNewRecurringExerciseForGroup,
        addNewVocabularyToBookInGroup,
        userExercisesGroupedData,
    } = useRTContext();

    const { userInfo, userId, setIsIframeMode } = useGeneralContext();

    const { search } = useLocation();
    const urlParams = queryString.parse(search);

    const t = useT();
    const t_myAccount = t("Mein Konto", { _tags: "CreateTest,CreateRtHome" });
    const t_title = t("Exercises for the phase6 Vocabulary trainer", { _tags: "CreateTest,CreateRtHome" });
    const t_newClass = t("New Class", { _tags: "CreateTest,CreateRtHome" });
    const t_newClassName = t("Give the class a name", { _tags: "CreateTest,CreateRtHome" });
    const t_classNameRequired = t("The class needs to have a name.", { _tags: "CreateTest,CreateRtHome" });
    const t_create = t("Create", { _tags: "CreateTest,CreateRtHome" });
    const t_addClass = t("Add Class", { _tags: "CreateTest,CreateRtHome" });
    const t_newExercise = t("New Exercise", { _tags: "CreateTest,CreateRtHome", _key: "newExerciseCreateRtHome" });
    const t_noClasses = t(
        'You currently have no exercises. You can create one by clicking on the "{t_newExercise}" button',
        { t_newExercise, _tags: "CreateTest,CreateRtHome" }
    );
    const t_confirm = t("Confirm", { _tags: "CreateTest,CreateRtHome" });
    const t_updateClass = t("Update Class name", { _tags: "CreateTest,CreateRtHome" });
    const t_updateClassName = t("Please update the class name", { _tags: "CreateTest,CreateRtHome" });
    const t_deleteConfirmation = t("Confirm Deletion", { _tags: "CreateTest,CreateRtHome" });
    const t_close = t("Close", { _tags: "CreateTest,CreateRtHome" });
    const t_selfContent = t("New Task with Own Content", { _tags: "CreateTest,CreateRtHome" });

    const [isNewClassModalOpen, setIsNewClassModalOpen] = useState(false);
    const [newClassName, setNewClassName] = useState("");
    const [isClassNameInputTouched, setIsClassNameInputTouched] = useState(false);
    const [classesOpenStatus, setClassesOpenStatus] = useState<AeaClassesOpen>({});
    const [classIdToEdit, setClassIdToEdit] = useState("");
    const [isEditClassNameModalOpen, setIsEditClassNameModalOpen] = useState(false);

    const [copiedLinkModalOpen, setCopiedLinkModalOpen] = useState(false);
    const [noOwnContentModalOpen, setNoOwnContentModalOpen] = useState(false);
    const [needsOwnContentRefresh, setNeedsOwnContentRefresh] = useState(false);

    // Delete Modal
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteModalProperties, setDeleteModalProperties] = useState<DeleteExercisesOrGroupProperties>({
        itemType: "",
    });

    useEffect(() => {
        if (urlParams.iframeMode) {
            setIsIframeMode(true);
        }
        // if (urlParams.articleId) {
        // let aId: string = urlParams.articleId as string;
        // localStorage.setItem("initAID", aId);
        // checkForInitArticleId(t_giveADescriptionPlaceholder);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    useEffect(() => {
        let newClassesObj: AeaClassesOpen = {};
        let bookIdsToLoad: Array<string> = [];
        let userSubjectIdsToLoad: Array<string> = [];
        aeaClasses
            .sort((a, b) => a.name!.localeCompare(b.name || ""))
            .forEach((c, i) => {
                if (c.token && c.name) {
                    newClassesObj[c.token] = c;

                    let classData = userExercisesGroupedData[c.token];
                    let userExercisesArray = Object.values(classData || {}).flat();

                    // debugger;
                    let hasLastCreatedExercise = false;
                    if (userExercisesArray.length > 0) {
                        hasLastCreatedExercise = !!userExercisesArray.find((c) => c.wasCreatedLast);
                    }

                    if (hasLastCreatedExercise) {
                        newClassesObj[c.token].isOpen = true;
                        if (classData && Object.keys(classData)) {
                            // debugger;
                            Object.entries(classData).forEach(([key, exercises]) => {
                                let hasOwnSubjects = exercises.find((e) => e.isOwnContentTask);
                                if (hasOwnSubjects) {
                                    userSubjectIdsToLoad.push(key);
                                } else {
                                    bookIdsToLoad.push(key);
                                }
                            });
                        }
                    }
                }
            });

        if (bookIdsToLoad.length > 0) {
            loadBooksInfoFromUUIDsIntoState(bookIdsToLoad);
        }
        if (userSubjectIdsToLoad.length > 0) {
            loadUserOwnSubjectUUIDsInfoIntoState(userSubjectIdsToLoad);
        }

        setClassesOpenStatus(newClassesObj);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aeaClasses, userExercisesGroupedData]);

    function toggleAeaClassOpenStatus(classId: string, status: boolean) {
        let classesStatus = classesOpenStatus;
        classesStatus[classId].isOpen = status;
        setClassesOpenStatus(classesStatus);
    }

    useEffect(() => {
        loadClassesAndExercisesForUser(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const history = useHistory();

    if (!userId) {
        history.push({
            pathname: "/auth",
            search: "?redirectTo=rt-home",
        });
    }

    function redirectUser() {
        let redirectionUrl = `${cp.cfg.REACT_APP_P6_BASE_WEBSITE}`;
        if (userInfo && userInfo.visitorOrUserRole === "teacher") {
            redirectionUrl = `${cp.cfg.REACT_APP_MEIN_KONTO}`;
        } else if (userInfo.visitorOrUserRole === "parent") {
            redirectionUrl = `${cp.cfg.REACT_APP_PARENT_MEIN_KONTO}`;
        }
        window.location.href = redirectionUrl;
    }

    function startNewRecurringTask(groupId: string, withOwnContent?: boolean) {
        if (withOwnContent && userOwnSubjects.length === 0) {
            if (needsOwnContentRefresh) {
                reloadUserSubjects()
                    .then((res) => {
                        if (res && res.length) {
                            startNewRecurringExerciseForGroup(groupId, withOwnContent);
                        } else {
                            setNoOwnContentModalOpen(true);
                            setNeedsOwnContentRefresh(true);
                        }
                    })
                    .catch((e) => {
                        setNoOwnContentModalOpen(true);
                        setNeedsOwnContentRefresh(true);
                    });
            } else {
                setNoOwnContentModalOpen(true);
                setNeedsOwnContentRefresh(true);
            }
        } else {
            startNewRecurringExerciseForGroup(groupId, !!withOwnContent);
        }
    }

    function updateClassName(newName: string) {
        setNewClassName(newName);
        setIsClassNameInputTouched(true);
    }

    function createClassCallback() {
        createAeaClass(newClassName).then(() => {
            setIsClassNameInputTouched(false);
            setNewClassName("");
            setIsNewClassModalOpen(false);
        });
    }

    function editClassFn(classId: string) {
        setClassIdToEdit(classId);
        setNewClassName(classesOpenStatus[classId].name || "");
        setIsEditClassNameModalOpen(true);
    }

    function editClassCallback() {
        updateAeaClass(classIdToEdit, newClassName).then(() => {
            setIsEditClassNameModalOpen(false);
            setNewClassName("");
            setClassIdToEdit("");
        });
    }

    function openDeleteModal(properties: DeleteExercisesOrGroupProperties) {
        setDeleteModalProperties(properties);
        setIsDeleteModalOpen(true);
    }

    function closeDeleteModal() {
        setDeleteModalProperties({ itemType: "" });
        setIsDeleteModalOpen(false);
    }

    function deleteCurrentSelectedItem() {
        deleteUserExerciseClassOrBook(deleteModalProperties).then(() => {
            setIsDeleteModalOpen(false);
        });
    }

    function toggleCopyModal(val: boolean) {
        setCopiedLinkModalOpen(val);
    }

    return (
        <CreateRtHomeWrapper>
            <BackButtonWrapper>
                <BackLink onClick={redirectUser}>
                    <ArrowIcon
                        onClick={() => {}}
                        direction={"LEFT"}
                        color={"inherit"}
                    />{" "}
                    {t_myAccount}
                </BackLink>
            </BackButtonWrapper>
            <CreateRtHomeHeader>
                <CreateRtHomeTitle>{t_title}</CreateRtHomeTitle>
            </CreateRtHomeHeader>
            {userInfo.visitorOrUserRole &&
                (userInfo.visitorOrUserRole === "teacher" || userInfo.visitorOrUserRole === "parent") && (
                    <ContentWrapper>
                        {aeaClasses.length > 0 && (
                            <NewClassRow>
                                <StyledButtonWithIcon
                                    icon={"add"}
                                    iconPosition={"LEFT"}
                                    buttonStyle={"BLANK"}
                                    onClick={() => {
                                        setIsNewClassModalOpen(true);
                                    }}
                                >
                                    {t_addClass}
                                </StyledButtonWithIcon>
                            </NewClassRow>
                        )}

                        {Object.values(classesOpenStatus).map((t, i) => {
                            // debugger;
                            if (t.token) {
                                return (
                                    <RtClassItem
                                        key={"class_" + i}
                                        classData={t}
                                        isClassOpen={classesOpenStatus[t.token].isOpen || false}
                                        startNewTaskCreationFn={startNewRecurringTask}
                                        editClassNameFn={editClassFn}
                                        toggleClassOpenStatusCallback={toggleAeaClassOpenStatus}
                                        canLogEvent={canLogEvent}
                                        addVocabularyToExerciseFn={addNewVocabularyToBookInGroup}
                                        openDeleteModalFn={openDeleteModal}
                                        toggleCopyModalFn={toggleCopyModal}
                                        bookInfoData={userUsedBooksInfo}
                                        getBookInfoFn={(bookUuid) => {
                                            loadBooksInfoFromUUIDsIntoState(bookUuid);
                                        }}
                                        userUsedOwnSubjectsInfo={userUsedOwnSubjectsInfo}
                                        getSubjectInfoFn={(subjectUuids) => {
                                            loadUserOwnSubjectUUIDsInfoIntoState(subjectUuids);
                                        }}
                                        classExercisesContent={userExercisesGroupedData[t.token] || {}}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                        {aeaClasses.length === 0 && <NoClassesText>{t_noClasses}</NoClassesText>}
                        {aeaClasses.length === 0 && (
                            <StyledButtonWithIcon
                                icon={"add"}
                                iconPosition={"LEFT"}
                                buttonStyle={"PRIMARY"}
                                onClick={() => {
                                    startNewRecurringTask("");
                                }}
                            >
                                {t_newExercise}
                            </StyledButtonWithIcon>
                        )}
                        <WiderCreateModal
                            isOpen={isNewClassModalOpen}
                            onRequestClose={() => {
                                setIsNewClassModalOpen(false);
                            }}
                            continueAction={createClassCallback}
                            continueText={t_create}
                            compact
                        >
                            <TestNameModalWrapper>
                                <TestNameLabel>
                                    <ClassNameModalTextStrong>{t_newClass}</ClassNameModalTextStrong>
                                    <ClassNameModalNameDesc>{t_newClassName}</ClassNameModalNameDesc>
                                    <StyledExerciseInputField
                                        value={newClassName}
                                        onChange={(ev) => {
                                            updateClassName(ev.target.value);
                                        }}
                                    />
                                    {newClassName.trim().length === 0 && isClassNameInputTouched && (
                                        <TestNameRequiredWarning>{t_classNameRequired}</TestNameRequiredWarning>
                                    )}
                                </TestNameLabel>
                            </TestNameModalWrapper>
                        </WiderCreateModal>
                        <WiderCreateModal
                            isOpen={isEditClassNameModalOpen}
                            onRequestClose={() => {
                                setIsEditClassNameModalOpen(false);
                            }}
                            continueAction={editClassCallback}
                            continueText={t_confirm}
                            compact
                        >
                            <TestNameModalWrapper>
                                <TestNameLabel>
                                    <ClassNameModalTextStrong>{t_updateClass}</ClassNameModalTextStrong>
                                    <ClassNameModalNameDesc>{t_updateClassName}</ClassNameModalNameDesc>
                                    <StyledExerciseInputField
                                        value={newClassName}
                                        onChange={(ev) => {
                                            updateClassName(ev.target.value);
                                        }}
                                    />
                                    {newClassName.trim().length === 0 && isClassNameInputTouched && (
                                        <TestNameRequiredWarning>{t_classNameRequired}</TestNameRequiredWarning>
                                    )}
                                </TestNameLabel>
                            </TestNameModalWrapper>
                        </WiderCreateModal>
                        <CreateInfoModal
                            isOpen={isDeleteModalOpen}
                            onRequestClose={closeDeleteModal}
                            continueAction={deleteCurrentSelectedItem}
                            continueText={t_confirm}
                            compact
                        >
                            <DeleteItemModalWrapper>
                                <ClassNameModalTextStrong>{t_deleteConfirmation}</ClassNameModalTextStrong>
                                {deleteModalProperties.itemType === "EXERCISE" && (
                                    <UT
                                        _str="By deleting this exercise also the exercise you have created for your students will be deleted. If they haven’t finished this exercise yet they will not have access to it anymore. Are you sure?"
                                        _inline
                                        _tags="CreateTest,CreateRtHome"
                                    />
                                )}
                                {deleteModalProperties.itemType === "BOOK" && (
                                    <UT
                                        _str="By deleting this book you will delete all exercises. Also all exercises you have created for your students will be deleted. If they haven’t finished these exercises yet they will not have access to them anymore. Are you sure?"
                                        _inline
                                        _tags="CreateTest,CreateRtHome"
                                    />
                                )}
                                {deleteModalProperties.itemType === "GROUP" && (
                                    <UT
                                        _str="By deleting this group also all exercises you have created for your students will be deleted. If they haven’t finished these exercises yet they will not have access to them anymore. Are you sure?"
                                        _inline
                                        _tags="CreateTest,CreateRtHome"
                                    />
                                )}
                            </DeleteItemModalWrapper>
                        </CreateInfoModal>
                        <CreateInfoModal
                            isOpen={copiedLinkModalOpen}
                            onRequestClose={() => {
                                setCopiedLinkModalOpen(false);
                            }}
                            continueAction={() => {
                                setCopiedLinkModalOpen(false);
                            }}
                            continueText={t_close}
                        >
                            <ShareConfirmationWrapper>
                                <ShareConfirmation>
                                    <UT
                                        _str="The link was copied to the clipboard. Over the link, the exercise can be practiced in the phase6 Vocabulary training app"
                                        _inline
                                        _tags="CreateTest,TestListElement"
                                    />
                                </ShareConfirmation>
                            </ShareConfirmationWrapper>
                        </CreateInfoModal>
                        <NoOwnContentModal
                            isOpen={noOwnContentModalOpen}
                            closeModalCallback={() => {
                                setNoOwnContentModalOpen(false);
                            }}
                            continueCallback={openWebAppInAddCardsMode}
                            continueButtonName={t_selfContent}
                        />
                    </ContentWrapper>
                )}
        </CreateRtHomeWrapper>
    );
};

export default CreateRtHome;
