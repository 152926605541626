import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const Arrow = styled(PhaseSixIcon)`
    font-size: 2rem;
    cursor: pointer;
    text-align: center;
`;

export const ArrowIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
