// LIBRARIES
import React from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";
import TestReviewProgressItem from "../../basic/testReviewProgressItem/TestReviewProgressItem";
import { useTestReviewContext } from "../../context/TestReviewContext";

export interface TestReviewExerciseListingProps {}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const NoExercisesText = styled.p`

;`;

const TestReviewExerciseListing: React.FC<TestReviewExerciseListingProps> = (props) => {
    const t = useT();
    const t_noResults = t("There are not results to be evaluated yet.", { _tags: "TestReviewExerciseListing" });

    const { jointResultsData } = useTestReviewContext();

    let keys = Object.keys(jointResultsData);

    return (
        <Wrapper>
            {Object.values(jointResultsData).map((r, i) => (
                <TestReviewProgressItem
                    key={"exe_" + i}
                    itemName={`Übung ${keys[i].replace("e", "")}`}
                    type={"exerciseItem"}
                    itemContent={r}
                    exerciseId={keys[i]}
                />
            ))}
            {Object.values(jointResultsData).length === 0 && <NoExercisesText>{t_noResults}</NoExercisesText>}
        </Wrapper>
    );
};

export default TestReviewExerciseListing;
