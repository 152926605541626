import styled from "styled-components";
import DesignConstants from "../constants/DesignConstants";

export const Background = styled.div`
    height: 100%;
    width: 100%;
`;

export const StudentPracticeBackground = styled.div`
    background-color: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    min-height: 100vh;
    width: 100%;
`;
