import React, { useEffect, useState } from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useT, UT } from "@transifex/react";
import Modal from "react-modal";
import useExerciseTitle from "../../../hooks/useExerciseTitle";

import {
    ModalHeaderActions,
    StyledHeaderActionsIcon,
    CloseIconWrapper,
    ModalContent,
    CTASWrapper,
    ContinueBtn,
    CancelBtn,
    ModalTitle,
    ExercisesNameContainer,
    DeleteCardWarningText,
    modalStylesCompact,
} from "./StyledComponents";

export interface DeleteCardWarningModalProps {
    wordId?: string;
    isModalOpen: boolean;
    closeModalFn: () => any;
}

Modal.setAppElement("#root");

const DeleteCardWarningModal: React.FC<DeleteCardWarningModalProps> = (props) => {
    const { isModalOpen, closeModalFn, wordId } = props;
    const { vocabularyDrawerContent, testContent, removeCardFromSelectedWords, setIsTestSynced } =
        useTestCreationContext();

    const { getExerciseNameFromQuestionMode } = useExerciseTitle();

    const t = useT();
    const t_deleteWord = t("Delete Card", { _tags: "CreateTest,UnitAndCardsWrapper" });
    const t_close = t("Close", { _tags: "CreateTest,UnitAndCardsWrapper" });
    const t_containedIn = t("Contained in:", { _tags: "CreateTest,UnitAndCardsWrapper" });

    const [canDeleteCurrentWord, setCanDeleteCurrentWord] = useState(true);
    const [exercisesForWord, setExercisesForWord] = useState<Array<string>>([]);

    function canDeleteWordFromSelectedWords(wordId: string) {
        const troubleExercises: Array<string> = [];
        const containedInExercises: Array<string> = [];
        const word = vocabularyDrawerContent[wordId];
        if (word && word.exerciseIds && word.exerciseIds?.length > 0) {
            const exerciseIds = word.exerciseIds;

            exerciseIds.forEach((id) => {
                let localCanDelete = true;
                const exercise = testContent.find((t) => t.exerciseId === id);
                if (!exercise) {
                    return;
                }
                const exerciseName = getExerciseNameFromQuestionMode(exercise.questionMode);
                containedInExercises.push(exerciseName);
                if (!exercise.selectedWords) {
                    return;
                }

                localCanDelete = exercise.selectedWords!.length > 3;

                if (!localCanDelete) {
                    troubleExercises.push(exerciseName);
                }
            });
        }
        return {
            canDelete: troubleExercises.length === 0,
            troubleExercises,
            containedInExercises,
        };
    }

    function deleteWordFn() {
        if (wordId) {
            setIsTestSynced(false);
            removeCardFromSelectedWords(wordId);
            closeModalFn();
        }
    }

    useEffect(() => {
        if (wordId) {
            let canDeleteResult = canDeleteWordFromSelectedWords(wordId);
            setCanDeleteCurrentWord(canDeleteResult.canDelete);
            if (canDeleteResult.canDelete) {
                setExercisesForWord(canDeleteResult.containedInExercises);
            } else {
                setExercisesForWord(canDeleteResult.troubleExercises);
            }
        } else {
            closeModalFn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordId]);

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModalFn}
            style={modalStylesCompact}
        >
            <ModalHeaderActions>
                <ModalTitle>{t_containedIn}</ModalTitle>
                <CloseIconWrapper onClick={closeModalFn}>
                    <StyledHeaderActionsIcon name={"close"} />
                </CloseIconWrapper>
            </ModalHeaderActions>
            <ModalContent>
                <ExercisesNameContainer>
                    {exercisesForWord.map((ex) => (
                        <p key={"key_" + ex}>{ex}</p>
                    ))}
                </ExercisesNameContainer>
                <CTASWrapper>
                    <ContinueBtn
                        iconPosition={"LEFT"}
                        icon={"trash"}
                        onClick={deleteWordFn}
                        disabled={!canDeleteCurrentWord}
                        buttonStyle={"PRIMARY"}
                    >
                        {t_deleteWord}
                    </ContinueBtn>
                    <DeleteCardWarningText>
                        {canDeleteCurrentWord ? (
                            <UT
                                _str="Warning: Removing this card will also delete it from these exercises!"
                                _inline
                                _tags="CreateTest,DeleteCardWarningModal"
                            />
                        ) : (
                            <UT
                                _str="Warning: By deleting this word the listed exercises can't be used anymore. Please delete the exercise or add other words to the exercise first."
                                _inline
                                _tags="CreateTest,DeleteCardWarningModal"
                            />
                        )}
                    </DeleteCardWarningText>
                    <CancelBtn
                        onClick={closeModalFn}
                        arrowDirection={"NONE"}
                        buttonStyle={"BLANK"}
                    >
                        {t_close}
                    </CancelBtn>
                </CTASWrapper>
            </ModalContent>
        </Modal>
    );
};

export default DeleteCardWarningModal;
