// LIBRARIES
import React, { useEffect } from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useBookContext } from "../../../context/BookContext";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { useT, useLanguages } from "@transifex/react";
import { getCurrentDateTimeFormatted } from "../../../helpers/DateTime";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import queryString from "query-string";

export interface CreateAnonymousWrapperProps {}

const CreateAnonymousWrapperDiv = styled.div``;

const CreateAnonymousWrapper: React.FC<CreateAnonymousWrapperProps> = (props) => {
    const { setTestName, loadInitArticleIdData, initAppWithBookAndUnit } = useTestCreationContext();
    const { setContentSelectionFiltersForTest } = useBookContext();
    const { setCreatingMode, setUserInfo, setIsIframeMode } = useGeneralContext();

    const currentDate = getCurrentDateTimeFormatted();

    const t = useT();
    const languages = useLanguages();

    const t_giveADescriptionPlaceholder = t("Test from {currentDate}", {
        currentDate,
        _tags: "CreateTest,CreateAnonymousWrapper",
    });

    const history = useHistory();

    const { search } = useLocation();
    const urlParams = queryString.parse(search.replace("?", ""));

    function setAnonymousUserData(disableIframeMode = false) {
        setUserInfo({
            isAnonymousUser: true,
            userName: "anonymous",
            userEmail: "",
            visitorOrUserRole: "other",
            isMobileApp: false,
            isTeacherVerified: false,
            isInternalUser: false,
        });
        setIsIframeMode(!disableIframeMode);
    }

    useEffect(() => {
        //make sure routing only happend after transifex responded with updated data
        if (languages.length) {
            setAnonymousUserData(!!urlParams.fullScreen);
            setCreatingMode("TEST");
            setContentSelectionFiltersForTest();
            if (urlParams.articleId) {
                let aId: string = urlParams.articleId as string;
                loadInitArticleIdData(aId).finally(() => {
                    history.push("/create/select-book");
                });
            } else if (urlParams.articleUuid) {
                const unitFrom = (urlParams.unitUuid || urlParams.unitFromUuid || "") as string;
                const unitTo = (urlParams.unitToUuid || "") as string;
                initAppWithBookAndUnit(urlParams.articleUuid as string, unitFrom, unitTo)
                    .then((redirectTo) => {
                        history.push(redirectTo === "BOOK" ? "/create/select-book" : "/create/select-vocabulary");
                    })
                    .catch(() => {
                        history.push("/create/select-book");
                    });
            } else {
                history.push("/create/select-book");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        languages.length,
        urlParams.articleId,
        urlParams.articleUuid,
        urlParams.fullScreen,
        urlParams.unitFromUuid,
        urlParams.unitToUuid,
        urlParams.unitUuid,
    ]);

    useEffect(() => {
        setTestName(t_giveADescriptionPlaceholder);
    }, [setTestName, t_giveADescriptionPlaceholder]);

    return <CreateAnonymousWrapperDiv>loading....</CreateAnonymousWrapperDiv>;
};

export default CreateAnonymousWrapper;
