import * as React from "react";
import Modal from "react-modal";
import { useT } from "@transifex/react/dist";
import { ReactComponent as IncorrectIcon } from "../../../assets/icons/exercise-failed.svg";

import {
    ModalScrollableContent,
    ModalIcon,
    ModalTitleWrapper,
    ModalTitle,
    CTASWrapper,
    CtaButton,
    CloseButton,
    modalStylesCompact,
    modalStyles,
} from "./StyledComponents";

export interface CreateInfoModalProps {
    isOpen: boolean;
    onRequestClose: () => any;
    modalIcon?: React.FC;
    iconColor?: string;
    modalTitle?: React.ReactNode;
    cancelAction?: () => any;
    cancelText?: string;
    continueAction?: () => any;
    continueActionDisabled?: boolean;
    continueText?: string;
    children?: JSX.Element | JSX.Element[];
    className?: string;
    compact?: boolean;
    continueButtonIsSecondaryButton?: boolean;
    alignButtonsLeft?: boolean;
    marginToButtons?: number | string;
}

Modal.setAppElement("#root");

const CreateInfoModal: React.FC<CreateInfoModalProps> = (props) => {
    const t = useT();
    const t_cancelText = t("Cancel", { _tags: "CreateInfoModal" });
    const t_continue = t("Continue", { _tags: "CreateInfoModal" });

    const {
        modalTitle,
        compact,
        modalIcon,
        iconColor,
        children,
        cancelAction,
        cancelText,
        continueAction,
        continueText,
        isOpen,
        onRequestClose,
        continueButtonIsSecondaryButton,
    } = props;
    const modalProps = { isOpen, onRequestClose };

    return (
        <Modal
            {...modalProps}
            style={!!compact ? modalStylesCompact : modalStyles}
        >
            <CloseButton onClick={onRequestClose}>
                <IncorrectIcon />
            </CloseButton>

            <ModalScrollableContent
                className={props.className}
                noVerticalPadding={!!props.marginToButtons}
            >
                {!!modalIcon && (
                    <ModalIcon
                        svgComponent={modalIcon}
                        color={iconColor}
                    />
                )}

                {!!modalTitle && (
                    <ModalTitleWrapper>
                        <ModalTitle>{modalTitle}</ModalTitle>
                    </ModalTitleWrapper>
                )}
                {children}

                <CTASWrapper
                    alignLeft={props.alignButtonsLeft}
                    marginToButtons={props.marginToButtons}
                >
                    {!!cancelAction && (
                        <CtaButton
                            onClick={cancelAction}
                            buttonStyle={"BLANK"}
                            arrowDirection={"NONE"}
                        >
                            {!!cancelText ? cancelText : t_cancelText}
                        </CtaButton>
                    )}

                    {!!continueAction && (
                        <CtaButton
                            onClick={props.continueActionDisabled ? () => {} : continueAction}
                            arrowDirection={"NONE"}
                            buttonStyle={continueButtonIsSecondaryButton ? "ORANGE_BLANK" : "PRIMARY"}
                            disabled={props.continueActionDisabled}
                        >
                            {!!continueText ? continueText : t_continue}
                        </CtaButton>
                    )}
                </CTASWrapper>
            </ModalScrollableContent>
        </Modal>
    );
};

export default CreateInfoModal;
