import React, { useEffect, useState } from "react";

import { useT } from "@transifex/react/dist";
import { debounce } from "lodash";
import { StudentQuestionAnswer } from "p6m-p6u";
import { ConnectColors } from "../../../constants/ConnectColors";
import ConnectWordsTile from "../connectWordsTile/ConnectWordsTile";

import { ConnectWordsExerciseWrapper, ConnectWordsLeftWrapper, ConnectWordsRightWrapper } from "./StyledComponents";

export interface ConnectWordsExerciseProps {
    studentAnswerItem: StudentQuestionAnswer;
    updateStudentQuestionAnswers: (answ: StudentQuestionAnswer) => any;
}

const updateDataToParentDebounced = debounce(
    (newContent, functionToCall) => {
        functionToCall(newContent);
    },
    1000,
    {
        leading: false,
        trailing: true,
    }
);

const ConnectWordsExercise: React.FC<ConnectWordsExerciseProps> = (props) => {
    const t = useT();
    const t_cantSameColumn = t("Cannot drag to same column", { _tags: "ConnectWordsExercise,inputPlaceholder" });

    const { studentAnswerItem, updateStudentQuestionAnswers } = props;
    const [answersContent, setAnswersContent] = useState(
        studentAnswerItem.studentAnswers ? studentAnswerItem.studentAnswers : []
    );

    const [currentSelectedQuestion, setCurrentSelectedQuestion] = useState<string>("");
    const [currentSelectedAnswer, setCurrentSelectedAnswer] = useState<string>("");

    useEffect(() => {
        if (currentSelectedAnswer !== "" && currentSelectedQuestion !== "") {
            let newAnswers = [...answersContent];
            const answerInOtherQuestionIndex = newAnswers.findIndex((a) => a.studentAnswer === currentSelectedAnswer);
            if (answerInOtherQuestionIndex > -1) {
                newAnswers[answerInOtherQuestionIndex].studentAnswer = "";
            }
            const questionIndex = newAnswers.findIndex((a) => a.question === currentSelectedQuestion);
            newAnswers[questionIndex].studentAnswer = currentSelectedAnswer;
            setCurrentSelectedQuestion("");
            setCurrentSelectedAnswer("");
            setAnswersContent(newAnswers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedQuestion, currentSelectedAnswer]);

    useEffect(() => {
        // console.log("using effect to call parent function");
        updateDataToParentDebounced(
            {
                ...studentAnswerItem,
                studentAnswers: answersContent,
            },
            updateStudentQuestionAnswers
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answersContent]);

    const handleDrop = function (event: React.DragEvent<HTMLDivElement>, type: "answer" | "question", word: string) {
        event.preventDefault();
        let data = event.dataTransfer.getData("text").split("^*");

        if (data[0] === type) {
            alert(t_cantSameColumn);
            return;
        }

        if (data[0] === "answer") {
            setCurrentSelectedAnswer(data[1]);
            setCurrentSelectedQuestion(word);
        } else {
            setCurrentSelectedAnswer(word);
            setCurrentSelectedQuestion(data[1]);
        }
    };

    const renderQuestions = () => {
        let questions: Array<React.ReactElement> = [];
        if (studentAnswerItem.studentAnswers) {
            studentAnswerItem.studentAnswers.forEach((a, i) => {
                if (a.question) {
                    questions.push(
                        <ConnectWordsTile
                            key={a.question?.replace(" ", "")}
                            highlighted={!!a.studentAnswer}
                            type={"question"}
                            word={a.question}
                            selectedVal={currentSelectedQuestion}
                            highlightColor={ConnectColors[i ?? 0 % 5]}
                            onChange={(w) => setCurrentSelectedQuestion(w)}
                            onDrop={handleDrop}
                        />
                    );
                }
            });
            return questions;
        }
    };

    const renderAnswers = () => {
        let answers: Array<React.ReactElement> = [];
        if (studentAnswerItem.connectWordsAnswers) {
            studentAnswerItem.connectWordsAnswers.forEach((a) => {
                const coupleIndex = studentAnswerItem.studentAnswers?.findIndex((s) => s.studentAnswer === a);
                answers.push(
                    <ConnectWordsTile
                        key={a.replace(" ", "")}
                        highlighted={coupleIndex !== undefined && coupleIndex > -1}
                        type={"answer"}
                        word={a}
                        selectedVal={currentSelectedAnswer}
                        highlightColor={ConnectColors[coupleIndex ?? 0 % 5]}
                        onChange={(w) => setCurrentSelectedAnswer(w)}
                        onDrop={handleDrop}
                    />
                );
            });
        }
        return answers;
    };

    return (
        <ConnectWordsExerciseWrapper>
            <ConnectWordsLeftWrapper>{renderQuestions()}</ConnectWordsLeftWrapper>
            <ConnectWordsRightWrapper>{renderAnswers()}</ConnectWordsRightWrapper>
        </ConnectWordsExerciseWrapper>
    );
};

export default ConnectWordsExercise;
