import { StyleSheet, Text, View } from "@react-pdf/renderer";
import DesignConstants from "../../constants/DesignConstants";
import { useT } from "@transifex/react";

const PDFClassNameDate: React.FC = () => {
    const t = useT();

    const t_class = t("Class:", { _tags: "CreateTest,PDFCreation" });
    const t_className = t("Name:", { _tags: "CreateTest,PDFCreation" });
    const t_date = t("Date:", { _tags: "CreateTest,PDFCreation" });

    const labels = [t_class, t_className, t_date];

    return (
        <View style={styles.outerContainer}>
            <View style={styles.labelContainer}>
                {labels.map((label: string, index: number) => (
                    <Text
                        key={`label_${index}`}
                        style={styles.labelText}
                    >
                        {label}
                    </Text>
                ))}
            </View>
            <View style={styles.labelContainer}>
                {labels.map((_, index: number) => (
                    <View
                        key={`dotted_line_${index}`}
                        style={styles.dottedLine}
                    />
                ))}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    outerContainer: {
        display: "flex",
        flexDirection: "row",
        gap: DesignConstants.PDF.GAP_XS,
    },
    labelContainer: {
        gap: DesignConstants.PDF.GAP_XS,
    },
    labelText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
    },
    dottedLine: {
        borderStyle: "dotted",
        borderBottomWidth: 1.5,
        width: 148,
        flex: 1,
    },
});

export default PDFClassNameDate;
