import React, { useEffect, useState } from "react";
import { useT } from "@transifex/react";
import { ExerciseTypes, Exercises } from "../../../../../enums/exercises";
import { useTestCreationContext } from "../../../../../context/TestCreationContext";
import InputWithActions from "../../../../../basic/inputWithActions/InputWithActions";
import useExerciseDescription from "../../../../../hooks/useExerciseDescription";
import ExerciseTaskSuggestion from "./exerciseTaskSuggestion/ExerciseTaskSuggestion";
import { getExerciseTypeByQuestionMode } from "../../../../../helpers/ExerciseTypesHelper";

import { Wrapper, ExerciseTaskWrapper, LabelWrapper, Label } from "./StyledComponents";

interface ExerciseTaskProps {
    showSelectExerciseType?: boolean;
}

const ExerciseTask: React.FC<ExerciseTaskProps> = (props) => {
    const { currentExerciseModel, updateCurrentExerciseProperties, setIsTestSynced } = useTestCreationContext();

    const t = useT();
    const { getUpdatedDescriptionForTextInput } = useExerciseDescription();

    const t_task = t("Exercise Task", { _tags: "CreateTest,CreateOrEditExercise" });
    const t_freeTextPlaceholder = t("Click here to add an Instruction.", { _tags: "CreateTest,CreateOrEditExercise" });

    const isFreeTextExercise = currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FREE_TEXT);

    const [triggerFlash, setTriggerFlash] = useState(false);
    const [isEditing, setIsEditing] = useState(isFreeTextExercise && !currentExerciseModel.extraComments);
    const [currentText, setCurrentText] = useState(currentExerciseModel.extraComments ?? "");

    function triggerFlashingAnimation() {
        setTriggerFlash(true);
        setTimeout(() => setTriggerFlash(false), 800);
    }

    useEffect(() => {
        const exerciseType = currentExerciseModel.questionMode;

        if (
            exerciseType !== ExerciseTypes.get(Exercises.WRITE_SENTENCE) &&
            exerciseType !== ExerciseTypes.get(Exercises.CREATE_TEXT)
        )
            return;

        const updatedDescription = getUpdatedDescriptionForTextInput(exerciseType ?? "", currentText);

        if (updatedDescription) {
            setCurrentText(updatedDescription ?? "");
            updateCurrentExerciseProperties({ extraComments: updatedDescription });
            triggerFlashingAnimation();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentExerciseModel.questionMode, currentExerciseModel.extraComments]);

    function saveChanges() {
        if ((currentText !== "" || isFreeTextExercise) && currentText !== currentExerciseModel.extraComments) {
            updateCurrentExerciseProperties({ extraComments: currentText });
            setIsTestSynced(false);
        } else {
            setCurrentText(currentExerciseModel.extraComments ?? "");
        }

        setIsEditing(false);
    }

    function discardChanges() {
        setCurrentText(currentExerciseModel.extraComments ?? "");
        setIsEditing(false);
    }

    return (
        <Wrapper>
            <ExerciseTaskWrapper>
                <LabelWrapper>
                    <Label>{t_task}</Label>
                </LabelWrapper>
                <InputWithActions
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    currentText={currentText}
                    onChange={(e) => setCurrentText(e.target.value)}
                    onSave={saveChanges}
                    onDiscard={discardChanges}
                    placeholder={isFreeTextExercise && !isEditing ? t_freeTextPlaceholder : ""}
                    showWarning={isFreeTextExercise && !currentText}
                    isSaveButtonDisabled={currentText === ""}
                    triggerFlash={triggerFlash}
                />
            </ExerciseTaskWrapper>

            {props.showSelectExerciseType && (
                <ExerciseTaskSuggestion
                    setCurrentText={setCurrentText}
                    exerciseType={
                        getExerciseTypeByQuestionMode(currentExerciseModel.questionMode ?? "") ??
                        Exercises.WRITE_SENTENCE
                    }
                    setIsEditing={setIsEditing}
                    triggerFlashingAnimation={triggerFlashingAnimation}
                />
            )}
        </Wrapper>
    );
};

export default ExerciseTask;
