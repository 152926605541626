import { useTestCreationContext } from "../context/TestCreationContext";

const useExerciseIdFinder = () => {
    const { testContent } = useTestCreationContext();

    const mapExerciseIdsToExercises = (exerciseIds: string[]) => {
        const numsOfUsedExercises = exerciseIds.map((id) => {
            const index = testContent.findIndex((obj) => obj.exerciseId === id);
            return index + 1;
        });

        return numsOfUsedExercises.sort((a, b) => a - b) ?? [];
    };

    return { mapExerciseIdsToExercises };
};

export default useExerciseIdFinder;
