import { useTestCreationContext } from "../../../../../../context/TestCreationContext";
import { ExerciseDirections } from "../../../../../../enums/directions";
import { sanitizeText } from "../../../../../../helpers/TestCreation";
import { Box, Item, RemoveIconWrapper, RemoveIcon, Word } from "./StyledComponents";

interface ExerciseWordBoxProps {
    onRemoveWord: (wordId?: string) => void;
}

const ExerciseWordBox: React.FC<ExerciseWordBoxProps> = (props) => {
    const { currentExerciseModel } = useTestCreationContext();

    return (
        <Box>
            {currentExerciseModel.wordsInProgress?.map((word) => {
                if ("id" in word) {
                    const wordToShow =
                        currentExerciseModel.direction === ExerciseDirections.ANSWER_TO_QUESTION
                            ? word.answer
                            : word.question;

                    return (
                        <Item key={word.id}>
                            <Word>{sanitizeText(wordToShow || "")}</Word>
                            <RemoveIconWrapper
                                onClick={() => props.onRemoveWord(word.id)}
                                className={currentExerciseModel.wordsInProgress!.length <= 3 ? "disabled" : ""}
                            >
                                <RemoveIcon name={"close"} />
                            </RemoveIconWrapper>
                        </Item>
                    );
                }
                return null;
            })}
        </Box>
    );
};

export default ExerciseWordBox;
