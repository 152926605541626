import styled from "styled-components";
import PhaseSixIcon from "../../../../../../../basic/phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../../../../../../constants/DesignConstants";

export const Wrapper = styled.div`
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    background-color: white;
`;

export const ListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${DesignConstants.SPACES.XS};
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.M};
    border-bottom: ${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    font-size: ${DesignConstants.FONT_SIZES.M};

    &:last-child {
        border-bottom: none;
    }
`;

export const TextWrapper = styled.span`
    font-weight: ${DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
`;

export const RemoveWord = styled.div`
    width: ${DesignConstants.SPACES.M};
    height: ${DesignConstants.SPACES.M};
    cursor: pointer;

    &.disabled {
        opacity: ${DesignConstants.OPACITY.HALF};
        cursor: not-allowed;
    }
`;

export const RemoveWordIcon = styled(PhaseSixIcon)`
    font-size: ${DesignConstants.FONT_SIZES.XL};
`;
