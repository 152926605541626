import * as React from "react";
import styled from "styled-components";
import { PrimaryButton } from "p6-react-shared";
import SvgIcon from "../svgIcon/SvgIcon";

export interface ButtonWithIconProps {
    iconSvgComponent: React.FunctionComponent;
    iconColor?: string;
    className?: string;
    onClick: () => any;
    text: string;
    disabled?: boolean;
}

const StartButton = styled(PrimaryButton)`
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: normal;
    padding: 0.5rem 0;

    & > span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
`;

const StartButtonArrowIcon = styled(SvgIcon)`
    width: 2rem;
`;

const TextPropSpan = styled.span`
    padding-left: 2rem;
    text-align: center;
    flex: 1;
`;

const ButtonWithIcon: React.FC<ButtonWithIconProps> = (props) => {
    return (
        <StartButton
            onClick={props.disabled ? () => {} : props.onClick}
            color={props.disabled ? "#ccc" : undefined}
        >
            <TextPropSpan>{props.text}</TextPropSpan>
            <StartButtonArrowIcon
                svgComponent={props.iconSvgComponent}
                color={props.iconColor}
            />
        </StartButton>
    );
};

export default ButtonWithIcon;
