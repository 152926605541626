import React, { ReactNode } from "react";
import { Font } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import { GetBandInfoResponse, TeacherTestDetails } from "p6m-p6u";
import { ExerciseTypes, Exercises, ExercisesWithoutPageWrapInPDF } from "../../enums/exercises";
import PDFStandardExercise from "./exercises/PDFStandardExercise";
import PDFConnectWordsExercise from "./exercises/PDFConnectWordsExercise";
import PDFGapSentenceExercise from "./exercises/PDFGapSentenceExercise";
import PDFWriteSentenceExercise from "./exercises/PDFWriteSentenceExercise";
import PDFCreateTextExercise from "./exercises/PDFCreateTextExercise";
import PDFFreeTextExercise from "./exercises/PDFFreeTextExercise";
import PDFBasicWrapper from "./PDFBasicWrapper";
import PDFExerciseWrapper from "./pdfSubComponents/PDFExerciseWrapper";
import DesignConstants from "../../constants/DesignConstants";

interface PDFFullTestProps {
    testDetails?: TeacherTestDetails;
    bookArticleId?: string;
    loadInitArticleIdData: (
        articleId: string,
        isUuid?: boolean,
        shouldSetSelectedBookData?: boolean
    ) => Promise<GetBandInfoResponse>;
    isSolution?: boolean;
}

const PDFFullTest: React.FC<PDFFullTestProps> = (props) => {
    Font.register({
        family: DesignConstants.PDF.PROXIMA_FONT,
        fonts: [
            {
                src: window.location.href.includes("localhost")
                    ? "/fonts/proximanova-semibold.ttf"
                    : "https://www.phase-6.de/system/galleries/html/fonts/proximanova-semibold.ttf",
                fontWeight: 600,
            },
            {
                src: window.location.href.includes("localhost")
                    ? "/fonts/proximanova-regular.ttf"
                    : "https://www.phase-6.de/system/galleries/html/fonts/proximanova-regular.ttf",
                fontWeight: 400,
            },
        ],
    });

    const t = useT();

    const t_solutionPDF = t("Exercise Sheet Solution", { _tags: "CreateTest,PDFCreation" });

    const renderExercises = () => {
        let exercises: ReactNode[] = [];
        if (props.testDetails && props.testDetails?.content) {
            props.testDetails?.content.forEach((exercise, index) => {
                let exerciseContent: ReactNode | undefined = undefined;

                switch (exercise.questionMode) {
                    case ExerciseTypes.get(Exercises.CONNECT_WORDS):
                        exerciseContent = (
                            <PDFConnectWordsExercise
                                exercise={exercise}
                                isSolution={props.isSolution}
                            />
                        );
                        break;
                    case ExerciseTypes.get(Exercises.FILL_GAP):
                        exerciseContent = (
                            <PDFGapSentenceExercise
                                exercise={exercise}
                                isSolution={props.isSolution}
                            />
                        );
                        break;
                    case ExerciseTypes.get(Exercises.WRITE_SENTENCE):
                        exerciseContent = (
                            <PDFWriteSentenceExercise
                                exercise={exercise}
                                isSolution={props.isSolution}
                            />
                        );
                        break;
                    case ExerciseTypes.get(Exercises.CREATE_TEXT):
                        exerciseContent = (
                            <PDFCreateTextExercise
                                exercise={exercise}
                                isSolution={props.isSolution}
                            />
                        );
                        break;
                    case ExerciseTypes.get(Exercises.FREE_TEXT):
                        exerciseContent = <PDFFreeTextExercise isSolution={props.isSolution} />;
                        break;
                    default: // standard and fallback - TABLE EXERCISE
                        exerciseContent = (
                            <PDFStandardExercise
                                exercise={exercise}
                                isSolution={props.isSolution}
                            />
                        );
                        break;
                }

                const isOnePageOnlyExercise = ExercisesWithoutPageWrapInPDF.includes(exercise.questionMode ?? "");

                exercises.push(
                    <PDFExerciseWrapper
                        exerciseTask={exercise?.extraComments ?? ""}
                        exerciseNumber={index + 1}
                        starterText={exercise.textFieldStarterText}
                        questionMode={exercise.questionMode}
                        wrapIsEnabled={!isOnePageOnlyExercise || !!props.isSolution}
                        key={exercise.exerciseId ?? "exercise-" + index}
                    >
                        {exerciseContent}
                    </PDFExerciseWrapper>
                );
            });
        }
        return exercises;
    };

    return (
        <PDFBasicWrapper
            title={props.isSolution ? t_solutionPDF : ""}
            bookArticleId={props.bookArticleId}
            loadInitArticleIdData={props.loadInitArticleIdData}
            ownSubjectInfoName={props.testDetails?.ownSubjectInfo?.name}
            isSolution={props.isSolution}
        >
            {renderExercises()}
        </PDFBasicWrapper>
    );
};

export default PDFFullTest;
