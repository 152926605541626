import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTestReviewContext } from "../../../context/TestReviewContext";
import styled from "styled-components/macro";
import TestReviewJointExerciseActions from "../../../complex/testReviewJointExerciseActions/TestReviewJointExerciseActions";
import { useT } from "@transifex/react";
import HighlightedAnswer from "../../../basic/highlightedAnswer/HighlightedAnswer";
import TestReviewSummaryBox from "../../../basic/testReviewSummaryBox/TestReviewSummaryBox";
import ReactTooltip from "react-tooltip";
import InconsistentGradeOnExerciseIcon from "../../../basic/inconsistentGradeOnExerciseIcon/InconsistentGradeOnExerciseIcon";
import StyledButton from "../../../basic/styledButton/StyledButton";
import { useMediaQuery } from "react-responsive";
import { StudentTestAnswer } from "p6m-p6u";
import { debounce } from "lodash";
import { saveStudentTestResult } from "../../../networking/tests";

export interface ReviewIndividualExerciseProps {}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
`;

const GridWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    /* border: 2px solid #ddd; */
    box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
    border-radius: 1rem;
    margin: 0 0 2rem 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        padding: 1rem 0.5rem;
        box-shadow: none;
    }
`;

const InnerGridRow = styled.div`
    display: flex;
    padding: 1rem 0.5rem;
    border-bottom: dashed 1px #ccc;

    &:last-child {
        border: none;
    }
`;

const GridRow = styled.div`
    display: flex;
    margin: 0;
    border-bottom: solid 1px #ccc;

    &:last-child,
    &:first-child {
        border: none;
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-wrap: wrap;
        gap: 0.5rem;
        padding-bottom: 0.5rem;
    }
`;

const GridTitleInner = styled.div`
    flex: 3;
    display: flex;
    text-align: left;
    padding: 0;
`;

const QuestionTitleItem = styled.div`
    flex: 1;
    font-weight: bold;
    text-align: left;
`;

const GridTitleItem = styled.div`
    flex: 2;
    font-weight: bold;
    text-align: left;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex: 3;
    }
`;

const GridActionItem = styled.div`
    flex: 1;
    font-weight: bold;
    text-align: left;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex: 2;
        text-align: center;
    }
`;

const CountTitleItem = styled.div`
    flex: 1;
    font-weight: bold;
    text-align: center;
`;

const BigGridTitleItem = styled.div`
    flex: 3;
    font-weight: bold;
    text-align: left;
`;

const GridItemFlex = styled.div`
    flex: 2;
    display: flex;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex: 3;
    }
`;
const BigGridItem = styled.div`
    flex: 3;
`;
const CenteredGridItem = styled.div`
    flex: 1;
    text-align: center;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex: 2;
        padding: 0 5px;
    }
`;

const CountGridItem = styled.div`
    flex: 1;
    text-align: center;
`;

const RowQuestionWrapper = styled.div`
    flex: 1;
    padding: 1rem 0;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        flex: auto;
    }
`;

const InnerGridRowsWrapper = styled.div`
    flex: 3;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        flex: 1;
    }
`;

const ExerciseInfoWrapper = styled.div`
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
`;

const ExerciseTitle = styled.h3`
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;
`;

const ExerciseInfo = styled.p`
    margin-bottom: 0.5rem;
`;
const JumbledWordsText = styled.p``;

const VerbTrainingGridRowTitle = styled.p`
    font-size: 1rem;
    line-height: 1.5;
`;

const ExerciseCTAsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    left: 0;
    padding: 1rem 2rem;
    bottom: 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    background: #fff;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;

        > div {
            width: unset;
            margin: 0 auto;
        }
    }
`;

const ReviewCTA = styled.div`
    width: 40%;
`;

const NavigationCTAS = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > * {
        max-width: 40%;
        width: 40% !important;
    }
`;

const QuestionText = styled.p`
    line-height: 1.5;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        word-break: break-word;
    }
`;
const EvaluationNameWrapper = styled.div`
    margin: 1rem 0;
    padding: 1rem;
    width: 100%;
    background: #f5f5f5;
    border-radius: 0.5rem;
`;

const EvaluationName = styled.p`
    margin-right: 1rem;
    font-weight: bold;
`;

const updateAnswerDebounced = debounce(
    async (
        saveStudentTestResult,
        currentTestId,
        testIndividualResults,
        updatedStudentTestResults,
        setUpdatedStudentTestResults
    ) => {
        const changedResults = updatedStudentTestResults;
        setUpdatedStudentTestResults([]); // reset for changes that might happen in the meantime

        // go through all changes an save them
        changedResults.forEach((answer: StudentTestAnswer) => {
            const studentTestIndex = testIndividualResults.findIndex((t: StudentTestAnswer) => t.id === answer);
            const currentTestResult = studentTestIndex > -1 ? testIndividualResults[studentTestIndex] : undefined;
            // update individual test result in DB
            if (currentTestResult && currentTestResult.id) {
                saveStudentTestResult(currentTestId, currentTestResult.id ?? "", currentTestResult);
            }
        });
    },
    10000,
    {
        leading: true,
        trailing: true,
    }
);

const ReviewIndividualExercise: React.FC<ReviewIndividualExerciseProps> = (props) => {
    const { exerciseId } = useParams<{ exerciseId: string }>();
    const { jointResultsData, studentTestContent, currentTestId, testIndividualResults } = useTestReviewContext();
    const history = useHistory();

    const [updatedStudentTestResults, setUpdatedStudentTestResults] = useState<Array<string>>([]);

    useEffect(() => {
        if (updatedStudentTestResults.length > 0) {
            updateAnswerDebounced(
                saveStudentTestResult,
                currentTestId,
                testIndividualResults,
                updatedStudentTestResults,
                setUpdatedStudentTestResults
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedStudentTestResults.length]);

    const addChangedTestResultToCollection = (changedTestResult: string) => {
        if (!updatedStudentTestResults.includes(changedTestResult)) {
            const changedResultsCollection = updatedStudentTestResults;
            changedResultsCollection.push(changedTestResult);
            setUpdatedStudentTestResults(changedResultsCollection);
        }
    };

    const t = useT();
    const t_back = t("Back to Overview", { _tags: "ReviewIndividualExercise" });
    const t_next = t("Next Exercise", { _tags: "ReviewIndividualExercise" });
    const t_previous = t("Previous Exercise", { _tags: "ReviewIndividualExercise" });

    const t_question = t("Question", { _tags: "ReviewIndividualExercise" });
    const t_answer = t("Answer", { _tags: "ReviewIndividualExercise" });
    const t_count = t("Count", { _tags: "ReviewIndividualExercise" });
    const t_action = t("Actions", { _tags: "ReviewIndividualExercise" });
    const t_noQuestion = t("<< no question >>", { _tags: "ReviewIndividualExercise" });
    const t_noAnswer = t("<< empty answer >>", { _tags: "ReviewIndividualExercise" });
    const t_words = t("Words:", { _tags: "ReviewIndividualExercise" });
    const t_exerciseTitle = t("Exercise", { _tags: "ReviewIndividualExercise" });
    const t_evaluationName = t("Evaluation by exercise", { _tags: "ReviewIndividualExercise" });

    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const renderGrid = function () {
        let keys = jointResultsData[exerciseId]?.answers ? Object.keys(jointResultsData[exerciseId].answers) : [];
        let answers = jointResultsData[exerciseId]?.answers ? Object.values(jointResultsData[exerciseId].answers) : [];
        let exerciseType = jointResultsData[exerciseId]?.exerciseType;

        const is3ColumnType = exerciseType === "jumbledWords" || exerciseType === "textOnly";
        let grid: Array<JSX.Element> = [];

        const QuestionHeader = <QuestionTitleItem>{t_question}</QuestionTitleItem>;
        const AnswersHeader = (
            <GridTitleInner>
                {is3ColumnType ? (
                    <BigGridTitleItem>{t_answer}</BigGridTitleItem>
                ) : (
                    <GridTitleItem>{t_answer}</GridTitleItem>
                )}
                {!is3ColumnType && <CountTitleItem>{t_count}</CountTitleItem>}
                <GridActionItem>{t_action}</GridActionItem>
            </GridTitleInner>
        );

        if (!isMobileView) {
            grid.push(
                <GridRow key={"exercise_" + exerciseType}>
                    {!is3ColumnType && QuestionHeader}
                    {AnswersHeader}
                </GridRow>
            );
        }

        answers.forEach((questionAnswer, answIndex) => {
            let innerGridRow: Array<JSX.Element> = [];

            if (is3ColumnType) {
                questionAnswer.forEach((questionAnswerItem, index) => {
                    innerGridRow.push(
                        <InnerGridRow key={questionAnswerItem.question + "_" + index}>
                            <BigGridItem>
                                {questionAnswerItem.studentAnswer === ""
                                    ? t_noAnswer
                                    : questionAnswerItem.studentAnswer}
                            </BigGridItem>
                            <CenteredGridItem>
                                <TestReviewJointExerciseActions
                                    exerciseId={exerciseId}
                                    itemId={keys[answIndex]}
                                    itemIndex={index}
                                    hasInconsistentGrades={false}
                                    addChangesToResults={addChangedTestResultToCollection}
                                />
                            </CenteredGridItem>
                        </InnerGridRow>
                    );
                });
            } else {
                questionAnswer.forEach((questionAnswerItem, index) => {
                    let hasInconsistentGrades = false;
                    if (questionAnswerItem.gradeCounts) {
                        let counts = questionAnswerItem.gradeCounts;
                        hasInconsistentGrades =
                            counts.hasOwnProperty("correct") &&
                            counts["correct"] > 0 &&
                            counts.hasOwnProperty("incorrect") &&
                            counts["incorrect"] > 0;
                    }

                    innerGridRow.push(
                        <InnerGridRow key={questionAnswerItem.question + "_" + index}>
                            <GridItemFlex>
                                <HighlightedAnswer grade={questionAnswerItem.teacherGrade}>
                                    {questionAnswerItem.studentAnswer === ""
                                        ? t_noAnswer
                                        : questionAnswerItem.studentAnswer}
                                </HighlightedAnswer>
                                {hasInconsistentGrades && (
                                    <InconsistentGradeOnExerciseIcon
                                        correct={questionAnswerItem.gradeCounts!["correct"]}
                                        incorrect={questionAnswerItem.gradeCounts!["incorrect"]}
                                    />
                                )}
                            </GridItemFlex>
                            <CountGridItem>{questionAnswerItem.answerOwner?.length || 0}</CountGridItem>
                            <CenteredGridItem>
                                <TestReviewJointExerciseActions
                                    exerciseId={exerciseId}
                                    itemId={keys[answIndex]}
                                    itemIndex={index}
                                    hasInconsistentGrades={hasInconsistentGrades}
                                    addChangesToResults={addChangedTestResultToCollection}
                                />
                            </CenteredGridItem>
                        </InnerGridRow>
                    );
                });
            }

            let questionName;
            if (exerciseType === "verbTraining") {
                questionName = (
                    <VerbTrainingGridRowTitle>
                        {questionAnswer[0].verbName} <br /> {questionAnswer[0].conjugationName}
                    </VerbTrainingGridRowTitle>
                );
                // console.log(questionAnswer[0]);
            } else if (exerciseType === "fillInTheGap") {
                questionName = questionAnswer[0].gap_sentence + " (" + questionAnswer[0].question + ")" || t_noQuestion;
            } else {
                questionName = questionAnswer[0].question || t_noQuestion;
            }

            grid.push(
                <GridRow key={exerciseType + "_" + answIndex}>
                    {!is3ColumnType && (
                        <RowQuestionWrapper>
                            {isMobileView && QuestionHeader}
                            <QuestionText>{questionName}</QuestionText>
                        </RowQuestionWrapper>
                    )}
                    <InnerGridRowsWrapper>
                        {isMobileView && AnswersHeader}
                        {innerGridRow}
                    </InnerGridRowsWrapper>
                </GridRow>
            );
        });

        ReactTooltip.rebuild();
        return grid;
    };

    let exerciseDesc = "";
    if (studentTestContent && studentTestContent.content) {
        exerciseDesc = studentTestContent.content.find((i) => i.id === exerciseId)?.extraComments || "";
    }

    const exerciseIds = Object.keys(jointResultsData);
    const currentExerciseIndex = exerciseIds.indexOf(exerciseId);
    const isLastExerciseId = currentExerciseIndex === exerciseIds.length - 1;
    const isFirstExerciseId = currentExerciseIndex === 0;

    const goToNextView = function () {
        let path = `/review/${currentTestId}`;

        if (!isLastExerciseId) {
            path += "/exercise/" + exerciseIds[currentExerciseIndex + 1];
        }

        history.push(path);
    };

    const goToPreviousView = function () {
        let path = `/review/${currentTestId}`;

        if (!isFirstExerciseId) {
            path += "/exercise/" + exerciseIds[currentExerciseIndex - 1];
        }

        history.push(path);
    };

    let isJumbledWords = jointResultsData[exerciseId]?.exerciseType === "jumbledWords" || false;
    let isFreeText = jointResultsData[exerciseId]?.exerciseType === "textOnly" || false;
    let jumbledWords = null;
    let exerciseExtraInfo = null;
    if (isJumbledWords && studentTestContent && studentTestContent.content) {
        const words = studentTestContent.content[currentExerciseIndex].fillWords;
        if (words) {
            jumbledWords = (
                <JumbledWordsText>
                    {t_words} {words.join(" || ")}
                </JumbledWordsText>
            );
        }
    }

    if (
        (isJumbledWords || isFreeText) &&
        studentTestContent.content &&
        studentTestContent.content[currentExerciseIndex].textFieldStarterText
    ) {
        exerciseExtraInfo = (
            <JumbledWordsText>{studentTestContent.content[currentExerciseIndex].textFieldStarterText}</JumbledWordsText>
        );
    }

    return (
        <Wrapper>
            <EvaluationNameWrapper>
                <EvaluationName>{t_evaluationName}</EvaluationName>
            </EvaluationNameWrapper>
            <ExerciseInfoWrapper>
                <ExerciseTitle>
                    {t_exerciseTitle} {exerciseId.replace("e", "")}
                </ExerciseTitle>
                <ExerciseInfo>{exerciseDesc}</ExerciseInfo>
                {jumbledWords}
                {exerciseExtraInfo && <ExerciseInfo>{exerciseExtraInfo}</ExerciseInfo>}
            </ExerciseInfoWrapper>

            <GridWrapper>{renderGrid()}</GridWrapper>
            <TestReviewSummaryBox
                exerciseId={exerciseId}
                extraBottomSpace={true}
            />

            <ExerciseCTAsWrapper>
                <ReviewCTA>
                    {!isLastExerciseId && (
                        <StyledButton
                            onClick={() => {
                                history.push("/review/" + currentTestId);
                            }}
                            buttonStyle={"BLANK"}
                        >
                            {t_back}
                        </StyledButton>
                    )}
                </ReviewCTA>
                <NavigationCTAS>
                    {!isFirstExerciseId && (
                        <StyledButton
                            onClick={goToPreviousView}
                            buttonStyle={"BLANK"}
                        >
                            {t_previous}
                        </StyledButton>
                    )}
                    <StyledButton
                        onClick={goToNextView}
                        buttonStyle={"PRIMARY"}
                    >
                        {isLastExerciseId ? t_back : t_next}
                    </StyledButton>
                </NavigationCTAS>
            </ExerciseCTAsWrapper>
            <ReactTooltip
                effect="solid"
                place={"right"}
                id={"tooltipReviewIndividualExercise"}
            />
        </Wrapper>
    );
};

export default ReviewIndividualExercise;
