import { useState } from "react";
import { useTestCreationContext } from "../../../../context/TestCreationContext";
import { useT } from "@transifex/react";
import { Exercises, ExerciseTypes } from "../../../../enums/exercises";
import SelectExerciseVocabularyContent from "./selectExerciseVocabularyContent/SelectExerciseVocabularyContent";
import { animate } from "framer-motion";
import { ExerciseCreationModel, SidebarSelectedWordsList } from "p6m-p6u";
import SelectExerciseVocabularyHeader from "./SelectExerciseVocabularyHeader/SelectExerciseVocabularyHeader";

import {
    Wrapper,
    StyledInfoIcon,
    NoMoreVocabularyWrapper,
    TextWrapper,
    NoMoreVocabularyInfo,
    NoMoreVocabularyHint,
    NoMoreVocabularyHintText,
    NoMoreConnectWordsWrapper,
    InfoIconWrapper,
    ConnectInfoWrapper,
    ConnectInfoText,
} from "./StyledComponents";

function _filterVocabulary(
    currentExerciseModel: ExerciseCreationModel,
    vocabularyDrawerContent: SidebarSelectedWordsList
) {
    let availableVocabulary = [];
    for (const [key, value] of Object.entries(vocabularyDrawerContent)) {
        if (
            !currentExerciseModel.wordsInProgress ||
            currentExerciseModel.wordsInProgress?.findIndex((word) => ("id" in word ? word.id : "") === key) === -1
        ) {
            availableVocabulary.push(value);
        }
    }
    if (currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP)) {
        availableVocabulary = availableVocabulary.filter(
            (w) => w.wordContent && "gap_sentence" in w.wordContent && w.wordContent?.gap_sentence
        );
    }
    return availableVocabulary;
}

export interface SelectExerciseVocabularyProps {
    onClose: () => void;
}

const SelectExerciseVocabulary: React.FC<SelectExerciseVocabularyProps> = (props) => {
    const { vocabularyDrawerContent, currentExerciseModel } = useTestCreationContext();

    const [queue, setQueue] = useState<string[]>([]);
    const [processQueue, setProcessQueue] = useState(false);

    const t = useT();
    const t_entireSelectedVocabularyUsed = t("You have already used the entire selected vocabulary.", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });
    const t_hint = t("Tip", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });
    const t_selectMoreVocabulary = t("Would you like to add more vocabulary from the selected book?", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });
    const t_chooseMoreVocabulary = t("Choose those from above under 'Choose Vocabulary'.", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });
    const t_maxFiveWords = t("You can only select a maximum of 5 words for this exercise type.", {
        _tags: "CreateTest,SelectExerciseVocabulary",
    });

    const isConnectExercise = currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.CONNECT_WORDS);
    const isGapSentenceExercise = currentExerciseModel.questionMode === ExerciseTypes.get(Exercises.FILL_GAP);
    const isLimitedExercise = isConnectExercise || isGapSentenceExercise;

    const max5WordsReached = currentExerciseModel.wordsInProgress && currentExerciseModel.wordsInProgress.length > 4;
    const currentAmountOfWords = currentExerciseModel.wordsInProgress ? currentExerciseModel.wordsInProgress.length : 0;

    const availableVocabulary = _filterVocabulary(currentExerciseModel, vocabularyDrawerContent);

    const amountOfRemainingCards = isLimitedExercise
        ? Math.min(5 - (currentAmountOfWords + queue.length), availableVocabulary.length - queue.length)
        : availableVocabulary.length - queue.length;

    async function addToQueue(rowId: string, wordId: string) {
        if (amountOfRemainingCards <= 0) return;

        setQueue((prevQueue: string[]) => [...prevQueue, wordId ?? ""]);

        const selectedRow = document.querySelector(`#${rowId}`);
        if (selectedRow) {
            await animate(selectedRow, { y: -152, opacity: 0 }, { duration: 0.35, ease: "easeIn" });
            setProcessQueue(true);
        }
    }

    const renderMax5Hint = () => {
        return (
            <NoMoreConnectWordsWrapper>
                <ConnectInfoWrapper>
                    <StyledInfoIcon name={"info"} />
                </ConnectInfoWrapper>
                <ConnectInfoText>{t_maxFiveWords}</ConnectInfoText>
            </NoMoreConnectWordsWrapper>
        );
    };

    const renderNoMoreVocabulary = () => {
        return (
            <>
                <TextWrapper>
                    <InfoIconWrapper>
                        <StyledInfoIcon name={"info"} />
                    </InfoIconWrapper>
                    <NoMoreVocabularyInfo>{t_entireSelectedVocabularyUsed}</NoMoreVocabularyInfo>
                </TextWrapper>
                {Object.keys(vocabularyDrawerContent).length < 50 && (
                    <>
                        <TextWrapper>
                            <NoMoreVocabularyHint>{`${t_hint}:`}</NoMoreVocabularyHint>
                            <NoMoreVocabularyHintText>{t_selectMoreVocabulary}</NoMoreVocabularyHintText>
                        </TextWrapper>
                        <TextWrapper>
                            <NoMoreVocabularyHintText>{t_chooseMoreVocabulary}</NoMoreVocabularyHintText>
                        </TextWrapper>
                    </>
                )}
            </>
        );
    };

    return (
        <Wrapper>
            <SelectExerciseVocabularyHeader
                onClose={props.onClose}
                disabled={isLimitedExercise && max5WordsReached}
                availableVocabulary={availableVocabulary}
                amountOfRemainingCards={amountOfRemainingCards}
                queue={queue}
                setQueue={setQueue}
                processQueue={processQueue}
                setProcessQueue={setProcessQueue}
            />
            {isLimitedExercise && renderMax5Hint()}
            {availableVocabulary.length > 0 ? (
                <SelectExerciseVocabularyContent
                    availableVocabulary={availableVocabulary}
                    disabled={(isLimitedExercise && max5WordsReached) || amountOfRemainingCards <= 0}
                    addToQueue={addToQueue}
                    queue={queue}
                />
            ) : (
                (!isLimitedExercise || !max5WordsReached) && (
                    <NoMoreVocabularyWrapper>{renderNoMoreVocabulary()}</NoMoreVocabularyWrapper>
                )
            )}
        </Wrapper>
    );
};

export default SelectExerciseVocabulary;
