import { AxiosResponse } from "axios";
import { GetUserTestsResponse } from "p6m-aea-networking";
import { deleteRequest, getRequest, putRequest } from "../helpers/networkHelper";
import {
    StudentTestAnswer,
    StudentTestContent,
    StudentTestContentWrapper,
    TeacherTestDetails,
    UnitCardsResponse,
} from "p6m-p6u";
import { NetworkErrorCodes } from "./errorCodes";

export const getUserTests = async (): Promise<Array<TeacherTestDetails>> => {
    const userTestsList: Array<TeacherTestDetails> = [];

    try {
        const endpointResponse: AxiosResponse<GetUserTestsResponse> = await getRequest("aea/tests/");
        Object.entries(endpointResponse.data).forEach(([id, testContent]) => {
            userTestsList.push({ ...testContent, id });
        });
        userTestsList.sort((t1, t2) => (t2.updatedDate || 0) - (t1.updatedDate || 0));
    } catch (e: any) {
        if (e.response.status === NetworkErrorCodes.FORBIDDEN) {
            console.log("forbidden");
            return Promise.reject();
        }
        console.log(e.response.status);
        console.log("Error while getting tests");
    }

    return userTestsList;
};

export const getCardsForUnit = async (unitId: string): Promise<UnitCardsResponse> => {
    let cardsForUnit: UnitCardsResponse = { count: 0, items: [] };

    try {
        const endpointResponse: AxiosResponse<UnitCardsResponse> = await getRequest("aea/cdb-cards/", { unitId });
        cardsForUnit = endpointResponse.data;
    } catch (e: any) {
        if (e.response.status === NetworkErrorCodes.FORBIDDEN) {
            console.log("forbidden");
            return Promise.reject();
        }
        console.log(e.response.status);
        console.log("Error while getting tests");
    }

    return cardsForUnit;
};

// V1 tests are deprecated and can't be created anymore, but some teachers still have them and might need data from them.
// (will hopefully be deleted in the future)
export const getUserV1Tests = async (): Promise<string[]> => {
    let userTestsList: string[] = [];

    try {
        const endpointResponse: AxiosResponse<string[]> = await getRequest("aea/v1-tests/");
        userTestsList = endpointResponse.data;
    } catch (e: any) {
        if (e.response.status === NetworkErrorCodes.FORBIDDEN) {
            console.log("forbidden");
            return Promise.reject();
        }
        console.log(e.response.status);
        console.log("Error while getting tests");
    }

    return userTestsList;
};

// V1 tests are deprecated and can't be created anymore, but some teachers still have them and might need data from them.
// (will hopefully be deleted in the future)
export const deleteUserV1TestByName = async (testName: string): Promise<boolean> => {
    let wasSuccessful: boolean = false;

    try {
        await deleteRequest("aea/v1-tests/", { file: testName });
        wasSuccessful = true;
    } catch (e: any) {
        if (e.response.status === NetworkErrorCodes.FORBIDDEN) {
            console.log("forbidden");
            return Promise.reject();
        }
        console.log(e.response.status);
        console.log("Error while getting tests");
    }

    return wasSuccessful;
};

export const getStudentTestById = async (testId: string): Promise<StudentTestContent | undefined> => {
    try {
        const studentTestDataResponse: AxiosResponse<StudentTestContent> = await getRequest(
            `aea/student-tests/${testId}/`
        );
        const studentTestData = { ...studentTestDataResponse.data, id: testId };
        return Promise.resolve(studentTestData);
    } catch (e: any) {
        if (e.response.status === NetworkErrorCodes.NOT_FOUND) {
            return Promise.resolve(undefined);
        } else {
            console.log(e);
            return Promise.reject(e);
        }
    }
};

export const getTeacherSharedTests = async (): Promise<StudentTestContentWrapper> => {
    let teacherSharedTests: StudentTestContentWrapper = {};
    try {
        const teacherSharedTestsResponse: AxiosResponse<StudentTestContentWrapper> = await getRequest(
            `aea/student-tests/`
        );
        // Making sure the ID property exists in there.
        Object.entries(teacherSharedTestsResponse.data).forEach(([id, test]) => {
            teacherSharedTests[id] = { ...test, id };
        });
    } catch (e) {
    } finally {
        return teacherSharedTests;
    }
};

export const saveStudentTestResult = async (
    testId: string,
    resultId: string,
    content: StudentTestAnswer
): Promise<boolean> => {
    try {
        await putRequest(`aea/student-tests/${testId}/results/${resultId}/`, { ...content });
        return Promise.resolve(true);
    } catch (e: any) {
        console.log(e);
        return Promise.reject(e);
    }
};
