import React from "react";
import { useTestCreationContext } from "../../../../../../context/TestCreationContext";
import { TestDetailsWord } from "p6m-p6u";
import ExerciseEmptyLines from "../../detailsSubComponents/exerciseEmptyLines/ExerciseEmptyLines";
import AddVocabularyButton from "../../detailsSubComponents/addVocabularyButton/AddVocabularyButton";

import {
    WriteSentenceWrapper,
    ItemWrapper,
    WordAndIconWrapper,
    Word,
    RemoveIcon,
    LinesContainer,
} from "./StyledComponents";
import { Wrapper } from "../../StyledComponents";
import { ExerciseDirections } from "../../../../../../enums/directions";

export interface DetailsWriteSentenceProps {
    hasWords: boolean;
    onRemoveWord: (cardId?: string) => any;
    isSelectExerciseVocabularyOpen: boolean;
    onClickAddVocabularyButton: () => void;
}

const DetailsWriteSentence: React.FC<DetailsWriteSentenceProps> = (props) => {
    const { currentExerciseModel } = useTestCreationContext();

    const getTextToShow = (word: TestDetailsWord) => {
        return currentExerciseModel.direction === ExerciseDirections.ANSWER_TO_QUESTION ? word.answer : word.question;
    };

    const renderWordWithLines = (word: TestDetailsWord) => {
        return (
            <ItemWrapper key={"word_" + word.question}>
                <WordAndIconWrapper>
                    <Word>{getTextToShow(word)}</Word>
                    <RemoveIcon
                        name={"close"}
                        onClick={() => props.onRemoveWord(word.id)}
                    />
                </WordAndIconWrapper>
                <LinesContainer>
                    <ExerciseEmptyLines />
                </LinesContainer>
            </ItemWrapper>
        );
    };

    return (
        <Wrapper>
            {props.hasWords && (
                <WriteSentenceWrapper>
                    {currentExerciseModel.wordsInProgress?.map((word, index) => {
                        if ("id" in word && word.question && word.answer) {
                            return renderWordWithLines(word);
                        } else {
                            // do not render VerbtrainingSelectedWord fallback
                            return <div key={index} />;
                        }
                    })}
                </WriteSentenceWrapper>
            )}
            {!props.isSelectExerciseVocabularyOpen && (
                <AddVocabularyButton onClickAddVocabularyButton={props.onClickAddVocabularyButton} />
            )}
        </Wrapper>
    );
};

export default DetailsWriteSentence;
