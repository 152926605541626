import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import DesignConstants from "../../../constants/DesignConstants";
import { shuffle } from "lodash";

interface PDFWordBoxProps {
    words: Array<string | undefined>;
}

const PDFWordBox: React.FC<PDFWordBoxProps> = (props) => {
    const prepareTriplets = () => {
        const shuffledWords = shuffle(props.words.filter((word) => word !== " ")); // filter out empty strings and shuffle the remaining words

        const wordsInTriplets: Array<string | undefined> = [...shuffledWords];

        // make sure  the array length is dividable by 3
        if (wordsInTriplets.length % 3 === 1) {
            wordsInTriplets[shuffledWords.length - 1] = "";
            wordsInTriplets.push(shuffledWords[shuffledWords.length - 1]);
            wordsInTriplets.push("");
        } else if (wordsInTriplets.length % 3 === 2) {
            wordsInTriplets.push("");
        }

        return wordsInTriplets;
    };

    const renderTriplet = (word: string) => {
        return (
            <View
                style={styles.wordContainer}
                key={"singleWord" + word}
            >
                {word ? <Text style={styles.text}>{word}</Text> : null}
            </View>
        );
    };

    const renderRows = () => {
        let rows: Array<JSX.Element> = [];
        let wordsInTriplets: Array<string | undefined> = prepareTriplets();

        let acc: Array<JSX.Element> = []; // used to collect the words temporarily until the triplet is full
        wordsInTriplets.forEach((word, index) => {
            if (index % 3 === 0) {
                //start new acc
                acc = [renderTriplet(word ?? "")];
            } else {
                // collect in existing acc
                acc.push(renderTriplet(word ?? ""));
            }

            const tripletStyle = index === 2 ? styles.rowContainerFirst : styles.rowContainer;

            if (acc.length === 3) {
                // wrap if acc full
                rows.push(
                    <View
                        style={tripletStyle}
                        key={"row" + index}
                    >
                        {acc}
                    </View>
                );
            }
        });

        return rows;
    };

    return (
        <View
            style={styles.container}
            wrap={false}
        >
            {renderRows()}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: DesignConstants.PDF.GAP_XS,
        marginVertical: DesignConstants.PDF.GAP_XXS,
        marginHorizontal: DesignConstants.PDF.GAP_S,
        borderWidth: DesignConstants.PDF.BORDER_WIDTH_S,
        borderColor: DesignConstants.PDF.GREY,
        borderRadius: DesignConstants.PDF.BORDER_RADIUS_M,
    },
    text: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        paddingHorizontal: DesignConstants.PDF.GAP_XS,
        paddingBottom: DesignConstants.PDF.GAP_XS,
        textAlign: "center",
        lineHeight: 0.9,
    },
    wordContainer: {
        flex: 1,
        justifyContent: "center",
    },
    rowContainer: {
        flexDirection: "row",
        marginTop: DesignConstants.PDF.GAP_XS,
    },
    rowContainerFirst: {
        marginTop: DesignConstants.PDF.GAP_XXS,
        flexDirection: "row",
    },
});

export default PDFWordBox;
