import { ExercisePrefixes, Exercises, ExerciseTypes } from "../enums/exercises";
import { uniqueId, shuffle } from "lodash";
import {
    TestDetailsWord,
    UnitCard,
    UserOwnCard,
    VerbtrainingSelectedWord,
    TestQuestionModel,
    SidebarSelectedWordsList,
    SidebarSelectedWord,
    Exercise,
    ExerciseCreationModel,
} from "p6m-p6u";
import striptags from "striptags";
import { ExerciseDirections } from "../enums/directions";
import { Locales } from "../hooks/useExerciseDescriptionStructure";

/**
 * words that haven't been used yet (meaning: without exerciseIds assigned / newly selected)
 */
export const filterVocabularyForNotSelectedWords = (
    vocabulary: SidebarSelectedWordsList
): Array<SidebarSelectedWord> => {
    return Object.values(vocabulary).filter((c: SidebarSelectedWord) => !c.exerciseIds || c.exerciseIds?.length === 0);
};

export const filterSearchBoxRequest = (
    responseObj: Array<any>,
    filterKey: string = "",
    filterList: Array<string> = []
): Array<any> => {
    if (responseObj.length === 0) {
        // console.log("No data available to filter");
        return [];
    } else if (filterKey === "" || filterList.length === 0) {
        return responseObj;
    }
    return responseObj.filter((element: any) => {
        return filterList.indexOf(element[filterKey]) === -1;
    });
};

export const generateExerciseId = (
    exType: Exercises,
    testContent: Array<TestQuestionModel>,
    suggestionExerciseIds?: Array<string>
): string => {
    const prefix = ExercisePrefixes.get(exType) as string;
    const testContentExerciseIds = testContent.map((e) => e.exerciseId);
    const exerciseIds = suggestionExerciseIds
        ? [...testContentExerciseIds, ...suggestionExerciseIds]
        : testContentExerciseIds;

    const _isUnique = (generatedId: string) => exerciseIds.findIndex((id) => id === generatedId) === -1;
    const _generateId = () => uniqueId(ExercisePrefixes.get(exType));
    const _generateConsecutiveId = () => {
        const exerciseIdsWithSamePrefix = exerciseIds.filter((id) => id && id.includes(prefix)) as string[];
        const idNumbers = exerciseIdsWithSamePrefix.map((id) => id.replace(prefix, ""));
        const sortedIdNumbers = idNumbers.sort((a, b) => +a - +b);
        const consecutiveNumber = +sortedIdNumbers[sortedIdNumbers.length - 1] + 1;
        return prefix + consecutiveNumber;
    };

    const hopefullyUniqueId = _generateId();
    return _isUnique(hopefullyUniqueId) ? hopefullyUniqueId : _generateConsecutiveId();
};

const createExercise = (exerciseType: Exercises, words: Array<SidebarSelectedWord>): Exercise => {
    return {
        type: exerciseType,
        words: [...words],
    };
};

export const translateWordFromModelToQuestion = (word: UnitCard | UserOwnCard): TestDetailsWord => {
    if ("answer_text" in word) {
        return {
            answer: word.answer_text || "",
            id: word.id || "",
            question: word.question_text || "",
            unit_id: String(word.unitId) || "",
            unit_name: "",
            gap_sentence: word.gap_sentence || word.question_example || "",
            answer_example: word.answer_example || "",
        };
    } else if ("isUserCard" in word) {
        return {
            answer: word.answer || "",
            id: word.id || "",
            question: word.question || "",
            unit_id: String(word.unitId) || "",
            unit_name: "",
            gap_sentence: "",
            answer_example: "",
            isUserCard: true,
            ownerId: word.ownerId,
        };
    } else {
        return {};
    }
};
export const translateWordFromQuestionToModel = (word: TestDetailsWord): UnitCard | UserOwnCard => {
    if (word.isUserCard) {
        return {
            answer: word.answer,
            id: word.id,
            ownerId: word.ownerId,
            question: word.question,
            unitId: word.unit_id,
            isUserCard: true,
        };
    }
    return {
        hidden: false,
        answer_text: word.answer || "",
        question_text: word.question || "",
        answer_example: word.answer_example || "",
        gap_sentence: word.gap_sentence || "",
        id: word.id || "",
        unitId: String(word.unit_id) || "",
    };
};

export const sanitizeText = (word: string): string => {
    return striptags(word.replace(/&(.*?);/g, ""));
};

export const generateGapSentence = (wordData: UnitCard): string => {
    // The word object here, is the transformed one in the words selection.
    let sentenceToReturn = "";

    if (wordData.answer_example && wordData.answer_text) {
        let answerText = sanitizeText(wordData.answer_text);
        let answerExample = sanitizeText(wordData.answer_example);

        var wordAnswerArray = answerExample.split(" ");
        if (wordAnswerArray.indexOf(answerText) !== -1) {
            var indexOfWord = wordAnswerArray.indexOf(answerText);
            wordAnswerArray[indexOfWord] = "_".repeat(Math.ceil(answerText.length * 2));
            sentenceToReturn = wordAnswerArray.join(" ");
        } else {
            var answerToReplace = answerText;
            sentenceToReturn = answerExample.replace(
                answerToReplace,
                "_".repeat(Math.ceil(answerToReplace.length * 2))
            );
        }
    }

    // If the sentence doesnt actually have a gap, then dont return it.
    return sentenceToReturn.indexOf("__") === -1 ? "" : sentenceToReturn;
};

export const getExerciseKeyFromExerciseName = (questionMode: string): Exercises | null => {
    if (questionMode) {
        let exerciseTypesVal = Array.from(ExerciseTypes).find(([key, val]) => val === questionMode);
        if (exerciseTypesVal) {
            return exerciseTypesVal[0] as Exercises;
        }
    }
    return null;
};

export const updateWordsInProgressDirection = (
    words: Array<TestDetailsWord | VerbtrainingSelectedWord>,
    direction: ExerciseDirections
): Array<TestDetailsWord | VerbtrainingSelectedWord> => {
    const exerciseWords = [...words];
    for (const word of exerciseWords) {
        updateSingleWordInProgressDirection(word, direction);
    }
    return exerciseWords;
};

export const updateSingleWordInProgressDirection = (
    word: TestDetailsWord | VerbtrainingSelectedWord,
    direction: ExerciseDirections
) => {
    if ("id" in word) {
        const weight =
            direction === ExerciseDirections.QUESTION_TO_ANSWER
                ? 0
                : direction === ExerciseDirections.ANSWER_TO_QUESTION
                ? 0.9
                : Math.random();
        word.isQuestionShown = weight <= 0.5;
    }
    return word;
};

const _countQuestionsShown = (words: Array<TestDetailsWord | VerbtrainingSelectedWord>) => {
    return words.reduce((acc, currentWord) => {
        if ("id" in currentWord && currentWord?.isQuestionShown) {
            return acc + 1;
        } else {
            return acc;
        }
    }, 0);
};

export const getExerciseDirection = (exercise: ExerciseCreationModel) => {
    // update overall direction
    const amountQuestionShown = _countQuestionsShown(exercise.wordsInProgress ?? []);
    const amountWords = exercise.wordsInProgress ? exercise.wordsInProgress.length : 0;

    const direction =
        amountQuestionShown === amountWords // all showing their question
            ? ExerciseDirections.QUESTION_TO_ANSWER
            : amountQuestionShown === 0 // none showing their question
            ? ExerciseDirections.ANSWER_TO_QUESTION
            : ExerciseDirections.RANDOM;

    return direction;
};

export const createTestSuggestionData = (
    testContent: Array<TestQuestionModel>,
    testToUse: Array<TestQuestionModel> | undefined,
    availableVocabulary: SidebarSelectedWord[],
    vocabularyDrawerContent: SidebarSelectedWordsList,
    getDefaultDescription: (exerciseType: Exercises, locale: Locales) => string
) => {
    const testSuggestionData = testToUse ? [...testToUse] : [...testContent];

    const exercises = createNewSuggestionExercises(availableVocabulary);
    const { exercisesData, vocabularyDrawerContent: newVocabularyDrawerContent } = createNewSuggestionExercisesData(
        exercises,
        vocabularyDrawerContent,
        getDefaultDescription,
        testContent
    );

    testSuggestionData.push(...exercisesData);

    return { testSuggestionData, newVocabularyDrawerContent };
};

/**
 * Creates exercises (each with 3 to 5 words):
 *
 * firstly: alternating between _connect_words_ and _table_ exercises
 *
 * secondly: additional _gap_sentence_ exercises if enough _gap_sentence_ words are available
 */
const createNewSuggestionExercises = (vocabulary: Array<SidebarSelectedWord>) => {
    const maxWordsPerExercise = 5;
    const words = shuffle(vocabulary);
    // Check first if gap sentence exercise can be used.
    // If selected word has word content, and is a CDB card with gap sentence,
    // and the gap sentence exists...
    const gapSentenceWords = words.filter(
        (w) => w.wordContent && "gap_sentence" in w.wordContent && w.wordContent.gap_sentence !== ""
    );
    const exercises: Exercise[] = [];

    if (words.length <= 5) {
        // create 1 _table_ and 1 _connect_words_ exercise, each with all words (=> total questions: words * 2).
        // if more than 3 _gap_sentence_ words available:
        // create an additional 3rd exercise with all _gap_sentence_ words.
        [Exercises.CONNECT_WORDS, Exercises.STANDARD].forEach((e) => {
            exercises.push(createExercise(e, words));
        });

        if (gapSentenceWords.length >= 3) {
            exercises.push(createExercise(Exercises.FILL_GAP, gapSentenceWords));
        }
    } else if (words.length > 5 && words.length <= 10) {
        // create 2 _connect_words_ exercises containing all words.
        // create an additional _table_ exercise with 5 random words,
        // reusing words already used in the 2 connect_words exercises.
        // if more than 3 _gap_sentence_ words available: create up to 2 additional _gap_sentence_ exercises,
        // reusing words already used in the previous exercises.
        const firstHalf = words.slice(0, Math.floor(words.length / 2));
        const secondHalf = words.slice(Math.floor(words.length / 2));
        [firstHalf, secondHalf].forEach((half) => {
            exercises.push(createExercise(Exercises.CONNECT_WORDS, half));
        });

        const reshuffledWords = shuffle(words);
        const first5Words = reshuffledWords.slice(0, 5);
        exercises.push(createExercise(Exercises.STANDARD, first5Words));

        if (gapSentenceWords.length >= 3) {
            exercises.push(...createGapSentenceExercisesArray(gapSentenceWords, maxWordsPerExercise));
        }
    } else {
        // words get portioned out an all 3 (or 2, if not enough _gap_sentence_ words available) exercise-types
        // => no multiple (re-)use of words!
        const nonGapSentenceWords = words.filter(
            (w) =>
                w.wordContent &&
                gapSentenceWords.findIndex((g) => g.wordContent && g.wordContent.id === w.wordContent?.id) === -1
        );
        if (gapSentenceWords.length < 3) {
            nonGapSentenceWords.push(...gapSentenceWords);
        } else if (gapSentenceWords.length > nonGapSentenceWords.length) {
            // To make sure we have a variety of exercises,
            // gap sentences can't take up more than half of all exercises.
            // If there are more possible _gap_sentence_ words,
            // only as many as about half of all words get used for _gap_sentence_ exercises,
            // and the others are added to the words for the other two exercise types.
            const splitIndex = Math.floor(words.length / 2);
            const wordsToMove = gapSentenceWords.slice(splitIndex);
            nonGapSentenceWords.push(...wordsToMove);
            gapSentenceWords.splice(splitIndex);
        }

        const exercisesAmount = Math.max(Math.ceil(nonGapSentenceWords.length / maxWordsPerExercise), 2);
        for (let i = 0; i < exercisesAmount; i++) {
            const remainingExercises = exercisesAmount - i;
            const wordsAmountForExercise = Math.ceil(nonGapSentenceWords.length / remainingExercises);
            const wordsForExercise = nonGapSentenceWords.splice(0, wordsAmountForExercise);
            const exerciseType = i % 2 === 0 ? Exercises.CONNECT_WORDS : Exercises.STANDARD;
            exercises.push(createExercise(exerciseType, wordsForExercise));
        }

        if (gapSentenceWords.length >= 3) {
            exercises.push(...createGapSentenceExercisesArray(gapSentenceWords, maxWordsPerExercise));
        }
    }

    return exercises;
};

const createGapSentenceExercisesArray = (
    gapSentenceWords: Array<SidebarSelectedWord>,
    maxWordsPerExercise: number
): Exercise[] => {
    const gapSentenceExercises: Exercise[] = [];
    const gapSentenceExercisesAmount = Math.ceil(gapSentenceWords.length / maxWordsPerExercise);
    for (let i = 0; i < gapSentenceExercisesAmount; i++) {
        const remainingExercises = gapSentenceExercisesAmount - i;
        const wordsAmountForExercise = Math.ceil(gapSentenceWords.length / remainingExercises);
        const wordsForExercise = gapSentenceWords.splice(0, wordsAmountForExercise);
        gapSentenceExercises.push(createExercise(Exercises.FILL_GAP, wordsForExercise));
    }
    return gapSentenceExercises;
};

const createNewSuggestionExercisesData = (
    exercises: Exercise[],
    vocabularyDrawerContent: SidebarSelectedWordsList,
    getDefaultDescription: (exerciseType: Exercises, locale: Locales) => string,
    testContent: Array<TestQuestionModel>
) => {
    const exercisesData: TestQuestionModel[] = [];
    const exerciseIds: string[] = [];
    exercises.forEach((exercise) => {
        const exerciseId = generateExerciseId(exercise.type, testContent, exerciseIds);
        exerciseIds.push(exerciseId);
        const exerciseWords: Array<TestDetailsWord> = [];
        exercise.words.forEach((w) => {
            if (w.wordContent?.id) {
                const exerciseIds: Array<string> = vocabularyDrawerContent[w.wordContent.id].exerciseIds || [];
                exerciseIds.push(exerciseId);
                vocabularyDrawerContent[w.wordContent.id].exerciseIds = exerciseIds;
                vocabularyDrawerContent[w.wordContent.id].isInMoreThanOneExercise = exerciseIds.length > 1;

                exerciseWords.push(translateWordFromModelToQuestion(w.wordContent));
            }
        });

        // mix direction for table exercise
        if (exercise.type === Exercises.STANDARD) {
            updateWordsInProgressDirection(exerciseWords, ExerciseDirections.RANDOM);
        }

        const exerciseData: TestQuestionModel = {
            questionMode: ExerciseTypes.get(exercise.type),
            extraComments: getDefaultDescription(exercise.type, Locales.DE),
            selectedWords: [...exerciseWords],
            automaticallyCreated: true,
            exerciseId,
        };
        if (exercise.type === Exercises.STANDARD) {
            exerciseData.direction = 2;
        }
        exercisesData.push(exerciseData);
    });

    return { exercisesData, vocabularyDrawerContent };
};

export const createNewExercise = (
    exerciseModel: ExerciseCreationModel | TestQuestionModel
): ExerciseCreationModel | TestQuestionModel => {
    exerciseModel.automaticallyCreated = false;
    exerciseModel.selectedWords = exerciseModel.wordsInProgress || [];

    return exerciseModel;
};

/** add new exercise to or replace edited exercise of testContent  */
export const createUpdatedTestContent = (
    testContent: TestQuestionModel[],
    exerciseModel: ExerciseCreationModel,
    newExercise: ExerciseCreationModel
): TestQuestionModel[] => {
    const { status, ...testQuestionExercise } = newExercise;

    if (exerciseModel.status === "NEW") {
        return [...testContent, testQuestionExercise];
    } else {
        // replace edited Exercise with newExercises
        const currentTestContent = [...testContent];
        const exerciseToReplaceIndex = testContent.findIndex((t) => t.exerciseId === exerciseModel.exerciseId);

        if (exerciseToReplaceIndex > -1) {
            currentTestContent.splice(exerciseToReplaceIndex, 1, testQuestionExercise);
        } else {
            currentTestContent.push(testQuestionExercise);
        }
        return currentTestContent;
    }
};

/**
 * create updated VocabularDrawerContent
 * with words containg
 * - the new _exerciseIds_,
 * - the updated property _isInMoreThanOneExercise_,
 */
export const createUpdatedDrawerContent = (
    drawerContent: SidebarSelectedWordsList,
    exerciseModel: ExerciseCreationModel,
    newExercise: ExerciseCreationModel
): SidebarSelectedWordsList => {
    // Update word ids in drawer model.
    for (const drawerWord of Object.values(drawerContent)) {
        if (!drawerWord.wordContent || !drawerWord.wordContent.id) {
            continue;
        }
        // Remove old exerciseId from the VocabularyDrawerContent
        if (drawerWord.exerciseIds && drawerWord.exerciseIds.includes(exerciseModel.exerciseId!)) {
            drawerWord.exerciseIds = drawerWord.exerciseIds.filter((e) => e !== exerciseModel.exerciseId);
        }
        // add newExercise's id to VocabularyDrawerContent
        const wordInExercise = newExercise.selectedWords?.find((w) => {
            const wordId = "id" in w ? w.id : "ID" in w ? w.ID : "";
            return drawerWord.wordContent?.id === wordId;
        });
        if (wordInExercise && newExercise.exerciseId) {
            if (!drawerWord.exerciseIds || !drawerWord.exerciseIds.includes(newExercise.exerciseId)) {
                drawerWord.exerciseIds = [...(drawerWord.exerciseIds || []), newExercise.exerciseId];
            }
        }

        // update isInMoreThanOneExercise
        drawerWord.isInMoreThanOneExercise = drawerWord.exerciseIds && drawerWord.exerciseIds.length > 1;

        // update drawerContent
        drawerContent[drawerWord.wordContent.id] = drawerWord;
    }

    return drawerContent;
};
