import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { TestDetailsWord, VerbtrainingSelectedWord } from "p6m-p6u";
import React from "react";
import DesignConstants from "../../../constants/DesignConstants";
import { sanitizePDFText } from "../../../helpers/PDFHelper";

interface PDFTableProps {
    selectedWords: Array<TestDetailsWord | VerbtrainingSelectedWord>;
    showAllQuestions?: boolean;
    showAllAnswers?: boolean;
}

const PDFTable: React.FC<PDFTableProps> = (props: PDFTableProps) => {
    return (
        <View style={styles.tableContainer}>
            {props.selectedWords &&
                props.selectedWords.length > 0 &&
                props.selectedWords.map((word, index) => {
                    if (!("id" in word && word.id)) {
                        return <View />;
                    } else {
                        const isQuestionShown = word.isQuestionShown ?? !!word.question;
                        return (
                            <View
                                style={styles.row}
                                key={sanitizePDFText(word?.question) ?? "word" + index}
                                wrap={false}
                            >
                                <View
                                    style={[
                                        styles.question,
                                        {
                                            borderBottomWidth:
                                                props.selectedWords && index === props.selectedWords?.length - 1
                                                    ? 0
                                                    : DesignConstants.PDF.BORDER_WIDTH_S,
                                        },
                                    ]}
                                >
                                    <Text style={styles.text}>
                                        {props.showAllQuestions || isQuestionShown
                                            ? sanitizePDFText(word.question)
                                            : ""}
                                    </Text>
                                </View>
                                <View
                                    style={[
                                        styles.answer,
                                        {
                                            borderBottomWidth:
                                                props.selectedWords && index === props.selectedWords?.length - 1
                                                    ? 0
                                                    : DesignConstants.PDF.BORDER_WIDTH_S,
                                        },
                                    ]}
                                >
                                    <Text style={styles.text}>
                                        {props.showAllAnswers || !isQuestionShown ? sanitizePDFText(word.answer) : ""}
                                    </Text>
                                </View>
                            </View>
                        );
                    }
                })}
        </View>
    );
};

const styles = StyleSheet.create({
    tableContainer: {
        width: "100%",
    },
    row: {
        flexDirection: "row",
        width: "100%",
        paddingHorizontal: DesignConstants.PDF.GAP_S,
    },
    question: {
        flex: 1,
        borderColor: DesignConstants.PDF.GREY,
        borderRightWidth: DesignConstants.PDF.BORDER_WIDTH_S,
        padding: DesignConstants.PDF.GAP_XS,
        flexDirection: "column",
    },
    answer: {
        flex: 1,
        borderColor: DesignConstants.PDF.GREY,
        padding: DesignConstants.PDF.GAP_XS,
        justifyContent: "center",
    },
    text: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
    },
});

export default PDFTable;
