import moment from "moment";

export const getDateFromMilliseconds = (time: number): string => {
    return moment.unix(time / 1000).format("DD.MM.YYYY");
};

export const getDateTimeFromMilliseconds = (time: number): string => {
    return moment.unix(time / 1000).format("DD.MM.YYYY - HH:mm");
};

export const convertTimeLimitToSeconds = (timeLimit: string): number => {
    let splitTimeLimit = timeLimit.split(":");
    let totalSeconds = 0;

    if (splitTimeLimit[0] !== "00") {
        totalSeconds += Number(splitTimeLimit[0]) * 3600;
    }

    return totalSeconds + Number(splitTimeLimit[1]) * 60;
};

export const getReadableTimeFromSeconds = (seconds: number): string => {
    let value = moment
        .utc(seconds * 1000)
        .format("HH:mm:ss")
        .split(":");
    let returnString;

    if (value[0] !== "00") {
        returnString = value[0] + ":" + value[1] + " h";
    } else {
        returnString = value[1] + ":" + value[2] + " min";
    }

    return returnString;
};

export const getReadableTimeFromTimeLimit = (timeLimit: string): string => {
    return getReadableTimeFromSeconds(convertTimeLimitToSeconds(timeLimit));
};

export const isDeadlineAlreadyPassed = (time: number): boolean => {
    return moment.unix(time / 1000).isSameOrBefore();
};

export const hasTestSharingLinkExpired = (time: number): boolean => {
    if (time) {
        return moment.unix(time / 1000).isBefore();
    }
    return true;
};

export const getCurrentDateTimeFormatted = (): string => {
    return moment().format("DD.MM.YYYY - HH:mm");
};

export const formatUTCTime = (date: string): string => {
    return moment(date).format("DD.MM.YYYY - HH:mm");
};
